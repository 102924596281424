import { ConditionKindEnum, ConditionPart } from "../Services/Condition/Types";

export const conditionPartListToString = (conditionParts: Array<ConditionPart>): string => {
    return conditionParts
        .reduce((accumulator, currentValue) => accumulator + " " + currentValue.conditionValue, "")
        .substring(1);
};

export const isEditingConditionForbidden = (conditionPartList: ConditionPart[] | undefined) => {
    let cpt = 0;
    let bool = false;

    conditionPartList?.forEach((conditionPart) => {
        if (conditionPart.conditionValue === "(" && conditionPart.conditionKind === ConditionKindEnum.OPENING_PARENTHESIS)
            cpt++;
        if (conditionPart.conditionValue === ")" && conditionPart.conditionKind === ConditionKindEnum.CLOSING_PARENTHESIS)
            cpt--;
        if (cpt === 3)
            bool = true;
    });
    
    return bool;
}
