import { ComputingRule } from "../ComputingRule/Types";
import { ConditionPart } from "../Condition/Types";

export type Variable = {
    id: number;
    name: string;
    variableType: VariableType;
    valueType: VariableValueType;
    rank: number;
    saveQuote: boolean;
    variableConditions: VariableCondition[];
    valid: boolean;
    description: string;

    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    createdOnVersionId: number;
};

export type VariableDTO = {
    name: string;
    variableType: VariableType;
    valueType: VariableValueType;
    saveQuote: boolean;
};

export type VariableCondition = {
    id?: number | null;
    ifCondition: string | null;
    conditionPartList?: ConditionPart[];
    orderCondition?: number;
    refGridCode?: string | null;
    variableCriterias: VariableCriteria[];
    defaultValue?: string | null;
    valid?: boolean;
};

export type VariableCriteria = {
    id?: number | null;
    value?: string | null;
    refDimensionCode?: string | null;
    variableName?: string | null;
    pricingCriteriaName?: string | null;
    computingRule?: ComputingRule | null;
    systemVariable?: SystemVariable | null;
};

export enum VariableType {
    V = "V",
    C = "C",
    R = "R",
}

export enum VariableValueType {
    STRING = "STRING",
    DATE = "DATE",
    NUMERIC = "NUMERIC",
}

export enum SystemVariable {
    EFFECTIVE_DATE = "effectiveDate",
    END_DATE = "endDate",
    SPLITTING_CODE = "splittingCode",
    DEADLINE = "deadline",
    VERSION = "version",
    MOVEMENT_CODE = "movementCode",
    MOVEMENT_TYPE = "movementType",
    NOW = "now",
    INSURANCE_PACKAGE_CODE = "insurancePackageCode",
    POLICY_EFFECTIVE_DATE = "policyEffectiveDate",
}

export type SystemVariableList = {
    staticSystemVariable: SystemVariableDTO[];
    dynamicSystemVariable: SystemVariableDTO[];
};

export type SystemVariableDTO = {
    variableName: SystemVariable;
    variableLabel: string;
    variableCritereType: VariableValueType;
};
