import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Label, Table } from "semantic-ui-react";
import { GridSearch } from "../../../Services/Condition/Types";

type GridSearchViewPropsType = {
    gridSearch: GridSearch;
};

const GridSearchView = ({gridSearch}: GridSearchViewPropsType) => {
    const {t} = useTranslation();
    const [gridSearchOpen, setGridSearchOpen] = useState<boolean>(false);

    return (
        <>
            <Label size="large" basic style={{border: "none"}} horizontal>
                {t("Grid search") + " : "}
            </Label>
            <Button
                icon
                labelPosition="right"
                onClick={() => {
                    setGridSearchOpen(!gridSearchOpen);
                }}>
                {gridSearch.refGridCode}
                {gridSearchOpen ? <Icon name="eye slash" /> : <Icon name="eye" />}
            </Button>
            {gridSearchOpen && (
                <>
                    <Table fixed>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t("Dimension")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("Associated_value_type")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("Associated_value")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {gridSearch.dimensions.map((dimension) => (
                                <Table.Row key={dimension.refDimensionCode}>
                                    <Table.Cell>{dimension.refDimensionCode}</Table.Cell>
                                    <Table.Cell>
                                        {t("enums.DimensionGridSearchValueType." + dimension.valueType)}
                                    </Table.Cell>
                                    <Table.Cell>{dimension.value}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                    {gridSearch.defaultValue !== undefined && gridSearch.defaultValue !== null ? (
                        <>
                            <Label size="large" basic style={{border: "none"}} horizontal>
                                {t("In case of unsuccessful search_default value")}
                            </Label>
                            <Label size="large" className="fixed_value_assignment" horizontal>
                                {gridSearch.defaultValue}
                            </Label>
                        </>
                    ) : (
                        <Label size="large" basic style={{border: "none"}} horizontal>
                            {t("In case of an unsuccessful search, the application is rejected")}
                        </Label>
                    )}
                </>
            )}
        </>
    );
};

export default GridSearchView;
