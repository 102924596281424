import {ComputingRule} from "../ComputingRule/Types";
import {ConditionPart} from "../Condition/Types";
import {RegistryEntryDTO} from "../Registry/Types";

export type InsurancePackageItem = {
    id: number;
    code: string;
    label: string;
    startDate: string;
    endDate: string | null;
    endDateEndorsement: string | null;
    prodDate: string | null | Date;
    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    createdOnVersionId: number;
};

export type Coverage = {
    id: number;
    optional: boolean;
    registry: RegistryEntryDTO;
    group: CoverageGroup;
    derogatoryManagementCommissionRate: number;
    derogatoryContributionCommissionRate: number;
    computingRule: ComputingRule;
    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    createdOnVersionId: number;
    optionCode: string | null;
    optionLabel: string | null;
}

export type CoverageGroup = {
    id: number;
    label: string;

    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    createdOnVersionId: number;
};

export type CoverageDTO = {
    id: number;
    coverageCode: string;
    coverageLabel: string;
    optional: boolean;
    derogatoryManagementCommissionRate: number;
    derogatoryContributionCommissionRate: number;
    registryId: number;

    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    createdOnVersionId: number;
};

export type CoverageRuleDTO = {
    id: number;
    registryId: number;
    code: string;
    label: string;
    optional: boolean;
    groupId: number;
    derogatoryManagementCommissionRate: number;
    derogatoryContributionCommissionRate: number;
    computingRule: ComputingRule;

    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    createdOnVersionId: number;
};

export type CoverageUpdateDTO = {
    id: number;
    code: string;
    label: string | null;
    optional: boolean | null;
    derogatoryManagementCommissionRate: number | null;
    derogatoryContributionCommissionRate: number | null;
    groupId: number | null;
    rule: ComputingRule | null;
};

export type CoverageAddDTO = {
    registryId: number;
    optional: boolean;
    rule: ComputingRule | null;
    derogatoryManagementCommissionRate: number | null;
    derogatoryContributionCommissionRate: number | null;
};

export enum ComputingRuleRoundingMode {
    ROUND_HALF_UP = "ROUND_HALF_UP",
    ROUND_HALF_DOWN = "ROUND_HALF_DOWN",
    ROUND_UP = "ROUND_UP",
    ROUND_DOWN = "ROUND_DOWN",
}

export type FeeCharge = {
    id: number;
    code: string;
    label: string;
    type: FeeChargeType;
    feeType: FeeType;
    registryId: number | null;
    computingRule: ComputingRule;

    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    createdOnVersionId: number;
};

export enum FeeChargeType {
    FEE = "FEE",
    CHARGE = "CHARGE",
}

export enum FeeType {
    FC = "FC",
    FR = "FR",
    RC = "RC",
}

export type FeeChargeDTO = {
    id: number
    registryId: number | null;
    code: string;
    label: string;
    type: FeeChargeType;
    feeType: FeeType;
    computingRule: ComputingRule;
};

export type FeeChargeAddDTO = {
    registryId: number | null;
    type: FeeChargeType;
    feeType: FeeType;
    rule: ComputingRule | null;
};

export type CoverageOrOptionType = {
    id: number;
    code: string | null;
    label: string | null;
    optional: boolean;
    derogatoryManagementCommissionRate: number;
    derogatoryContributionCommissionRate: number;
    registryId: number | undefined;
    registryCode: string;
    registryLabel: string;

    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    createdOnVersionId: number;
};

export enum CoverageOrOption {
    COVERAGE = "Coverage",
    OPTION = "Option",
}

export type Option = {
    id: number;
    code: string;
    label: string;
    registry: RegistryEntryDTO;
    coverageGroup: CoverageGroup;
    computingRule: ComputingRule;
    derogatoryManagementCommissionRate: number;
    derogatoryContributionCommissionRate: number;

    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    createdOnVersionId: number;
};

export type OptionAddDTO = {
    code: string;
    label: string;
    registryId: number;
    computingRule: ComputingRule | null;
    derogatoryManagementCommissionRate: number | null;
    derogatoryContributionCommissionRate: number | null;
};

export type OptionUpdateDTO = {
    code: string;
    label: string;
    registryId: number;
    coverageGroupId: number;
    computingRule?: ComputingRule;
    derogatoryManagementCommissionRate: number;
    derogatoryContributionCommissionRate: number;
};

/* ------ Commissions ------ */
export type CommissionType = {
    initialRate: number;
    currentValue: number | null;
    derogatoryChecked: boolean;
};

export type CommissionsStateType = {
    contribution: CommissionType;
    management: CommissionType;
};

export type InsurancePackageExclusion = {
    id: number;
    ifCondition: string | null;
    conditionPartList: ConditionPart[];
    conditionOrder: number;
};

export type InsurancePackageExclusionDTO = {
    ifCondition: string | null;
    conditionPartList?: ConditionPart[];
    conditionOrder?: number;
};

export type DerogatoryTaxDTO = {
    taxCategoryCode: string;
    registryCode: string;
    conditionOrder?: number;
    ifCondition: string | null;
    conditionPartList?: Array<ConditionPart>;
};
