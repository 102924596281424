import FileSaver from "file-saver";
import {ChangeEvent, Fragment, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Container, Grid, Header, Icon, Menu, Segment} from "semantic-ui-react";
import Sections from "../../Components/CustomBreadcrumb/Fragments/Sections";
import ModalUpdate from "../../Components/Modals/ModalUpdate";
import {Breadcrumb} from "../../Components/Routes/BreadcrumbsInjectorWrapper";
import Sidebar from "../../Components/Sidebar/Sidebar";
import SimpleList from "../../Components/SimpleList/SimpleList";
import {ColumnSimpleList, DataTypeColumnEnum} from "../../Components/SimpleList/SimpleListTypes";
import queryClient from "../../Config/queryClient";
import {useProductsSidebarMenu} from "../../Hooks/useProductsSidebarMenu";
import {
    useExportCommercialReductions,
    useGetCommercialReductions,
    useImportCommercialReductionsMutation,
} from "../Services/CommercialReductions/Queries";

const commercialReductionsPageColumns: Array<ColumnSimpleList> = [
    {
        text: "commercial_reductions_table_name",
        dataField: "name",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "commercial_reductions_table_code",
        dataField: "code",
        type: DataTypeColumnEnum.STRING,
    },
    {text: "commercial_reductions_table_reduction", dataField: "reduction", type: DataTypeColumnEnum.STRING},
    {
        text: "commercial_reductions_table_effective_date_start",
        dataField: "effectiveDateStart",
        type: DataTypeColumnEnum.DATE,
    },
    {
        text: "commercial_reductions_table_effective_date_end",
        dataField: "effectiveDateEnd",
        type: DataTypeColumnEnum.DATE,
    },
    {
        text: "commercial_reductions_table_nbr_of_splittings",
        dataField: "nbrOfSplittings",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "commercial_reductions_table_product_code_list",
        dataField: "productCodes",
        type: DataTypeColumnEnum.LIST,
    },
];

const CommercialReductionsPage = (props: {sections: Array<Breadcrumb>}) => {
    const {t} = useTranslation("coverlife");
    const menus = useProductsSidebarMenu();

    const getCommercialReductionsQuery = useGetCommercialReductions();

    // Import / Export
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const [selectedFile, setSelectedFile] = useState<BlobPart | null>(null);

    const exportCommercialReductionsQuery = useExportCommercialReductions(false);
    const handleExportCommercialReductions = () => exportCommercialReductionsQuery.refetch();

    const importCommercialReductionsMutation = useImportCommercialReductionsMutation();
    const handleSetFile = ({target: {files}}: ChangeEvent<HTMLInputElement>) => {
        if (files !== null) {
            setSelectedFile(files[0]);
        }
    };
    const handleImportCommercialReductionsClick = () => {
        if (hiddenFileInput.current !== null) {
            hiddenFileInput.current.click();
        }
    };
    const handleMutateImportCommercialReductions = (file: BlobPart) => importCommercialReductionsMutation.mutate(file);

    const clearSelectedFile = () => {
        setSelectedFile(null);
        if (hiddenFileInput.current !== null) {
            hiddenFileInput.current.value = "";
        }
    };

    useEffect(() => {
        if (exportCommercialReductionsQuery.isSuccess && exportCommercialReductionsQuery.data) {
            const blob = new Blob([exportCommercialReductionsQuery.data as BlobPart], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileSaver.saveAs(blob, "reductions_commerciales.xlsx");

            queryClient.removeQueries({queryKey: ["exportCommercialReductions"]});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exportCommercialReductionsQuery.isSuccess, exportCommercialReductionsQuery.data]);

    return (
        <Fragment>
            <Sidebar title={t("Product", {count: 2})} menus={menus} />

            <div className="header-container" data-cy="custom-breadcrumb-header">
                <Header as="h2" className="top-header">
                    {t("commercial_reductions")}
                </Header>

                <div className="sub-header">
                    <Grid>
                        <Grid.Column width={6} floated={"left"}>
                            <Sections sections={props.sections} />
                        </Grid.Column>
                        <Grid.Column width={6} floated={"right"} textAlign={"right"}></Grid.Column>
                    </Grid>
                </div>
            </div>

            <Container fluid>
                <div className="main-container">
                    <Grid centered>
                        <Grid.Row>
                            <Grid.Column>
                                <Segment>
                                    <Menu borderless style={{border: "0", boxShadow: "none", padding: 0}}>
                                        <Menu.Item style={{padding: 0, gap: "4px"}} position="right">
                                            <Button
                                                data-cy="download-commercial-reductions-button"
                                                onClick={handleExportCommercialReductions}
                                                positive
                                                icon
                                            >
                                                <Icon name={"download"} />
                                            </Button>
                                            <Button
                                                data-cy="upload-commercial-reductions-button"
                                                onClick={handleImportCommercialReductionsClick}
                                                positive
                                                icon
                                            >
                                                <input
                                                    ref={hiddenFileInput}
                                                    type={"file"}
                                                    hidden
                                                    onChange={handleSetFile}
                                                />
                                                <Icon name={"upload"} />
                                            </Button>
                                        </Menu.Item>
                                    </Menu>

                                    <Segment
                                        data-cy="commercial-reductions-table"
                                        style={{overflow: "auto", border: "none", boxShadow: "none", padding: 0}}
                                    >
                                        <SimpleList
                                            columns={commercialReductionsPageColumns}
                                            rows={getCommercialReductionsQuery.data ?? []}
                                            isLoading={getCommercialReductionsQuery.isPending}
                                            globalData={[]}
                                            translationNameSpace="coverlife"
                                        />
                                    </Segment>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Container>

            {selectedFile !== null && (
                <ModalUpdate
                    onValidate={() => handleMutateImportCommercialReductions(selectedFile)}
                    isModalOpenAtStart
                    customContent={t("you_are_about_to_replace_all_the_commercial_reductions_for_this_product")}
                    onCancel={clearSelectedFile}
                    onSuccess={clearSelectedFile}
                    onClose={clearSelectedFile}
                    resetMutation={importCommercialReductionsMutation.reset}
                    isSuccess={importCommercialReductionsMutation.isSuccess}
                    isPending={importCommercialReductionsMutation.isPending}
                    isError={importCommercialReductionsMutation.isError}
                    error={importCommercialReductionsMutation.error}
                />
            )}
        </Fragment>
    );
};

export default CommercialReductionsPage;
