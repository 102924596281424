import {useTranslation} from "react-i18next";
import {Icon, Popup} from "semantic-ui-react";

const SaveQuoteLabel = () => {
    const {t} = useTranslation();

    return (
        <Popup
            trigger={
                <Icon
                    style={{backgroundColor: "lightseagreen", color: "white", boxShadow: "none"}}
                    circular
                    fitted
                    name="file alternate outline"
                />
            }
            content={t("Save quote")}
            mouseEnterDelay={500}
            basic
            position="left center"
            size="mini"
        />
    );
};

export default SaveQuoteLabel;
