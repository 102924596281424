import * as FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import {
    Button,
    Container, Dimmer, Divider,
    Grid,
    Header, Icon, Loader, Message, Modal, Segment
} from "semantic-ui-react";
import CustomBreadcrumb from "../../../Components/CustomBreadcrumb/CustomBreadcrumb";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import { useVersionSidebarMenus } from "../../../Hooks/useVersionSidebarMenus";
import { useGetProductDetailsQuery } from "../../../Services/Product/Queries";
import { displayResponseError } from "../../../Services/RequestUtils";
import {
    useExecuteVersionMassTestMutation,
    useGetVersionMassTestFileStructureQuery,
    useGetVersionQuery
} from "../../../Services/Version/Queries";

interface IParams extends Params {
    id: string;
    versionId: string;
}

const MassTestPage = ({...props}) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const [showModal, setShowmodal] = useState(false);
    const menus = useVersionSidebarMenus(params.id);

    const [sections, setSections] = useState(Array<{name: string; path: string}>(props.sections));

    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const {data: productData} = useGetProductDetailsQuery(params.id);
    const {data: version} = useGetVersionQuery(params.id, params.versionId);

    const importVersionMassTestMutation = useExecuteVersionMassTestMutation(params.id, params.versionId);
    const {data, status, refetch} = useGetVersionMassTestFileStructureQuery(params.id, params.versionId, false);

    useEffect(() => {
        setSections(props.sections);
    }, [productData, props.sections, version]);

    useEffect(() => {
        if (status === "success" && data !== undefined) {
            const blob = new Blob([data as BlobPart], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            let fileName = "Tests_masse_";
            if (productData !== undefined) {
                fileName = fileName + productData.code + "_";
            }
            fileName = fileName + new Date().toLocaleDateString().replaceAll("/", "");
            FileSaver.saveAs(blob, fileName + ".xlsx");
        }
    }, [data, productData, status]);

    const versionTestInfoContent = [
        t("How_to_test.Download test structure"),
        t("How_to_test.Perform your test cases"),
        t("How_to_test.Import test cases"),
        t("How_to_test.The results will be sent to by email"),
    ];

    const handleClick = () => {
        if (hiddenFileInput.current !== null) {
            hiddenFileInput.current.click();
        }
    };

    const handleExportData = () => refetch();

    const handleImportData = ({target: {files}}: any) => {
        setShowmodal(true);
        importVersionMassTestMutation.mutate(files[0]);
    };

    const handleCloseModal = () => {
        setShowmodal(false);
        importVersionMassTestMutation.reset();
        if (hiddenFileInput.current !== null) {
            hiddenFileInput.current.value = "";
        }
    };

    return (
        <Container fluid>
            <Sidebar title={t("Versions")} menus={menus} />
            <CustomBreadcrumb sections={props.sections} />
            <div className="main-container">
                <Grid centered>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Segment className="card-container">
                                <Header as={"h2"} dividing>
                                    {t("Test a version")}
                                </Header>
                                <Message info>
                                    <Message.Header>{t("How_to_test.title")}</Message.Header>
                                    <Message.List items={versionTestInfoContent} />
                                </Message>
                                <Segment placeholder>
                                    <Grid columns={2} stackable textAlign="center">
                                        <Divider vertical>{t("ou").toUpperCase()}</Divider>
                                        <Grid.Row verticalAlign="middle">
                                            <Grid.Column>
                                                <Header icon>
                                                    <Icon name="download" />
                                                    {t("Export structure")}
                                                </Header>
                                                <Button
                                                    primary
                                                    onClick={handleExportData}
                                                    name="download_file_structure">
                                                    {t("Download file")}
                                                </Button>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Header icon>
                                                    <Icon name="upload" />
                                                    {t("Import test cases")}
                                                </Header>
                                                <Button onClick={handleClick} primary name="import_file">
                                                    <input
                                                        ref={hiddenFileInput}
                                                        type="file"
                                                        hidden
                                                        onChange={handleImportData}
                                                    />
                                                    {t("Import file")}
                                                </Button>
                                                <Modal open={showModal} dimmer="blurring" onClose={handleCloseModal}>
                                                    {importVersionMassTestMutation.isPending ? (
                                                        <Modal.Content style={{margin: "2em 0"}}>
                                                            <Dimmer active inverted>
                                                                <Loader size="small">
                                                                    {t("Mass test request in progress")}
                                                                </Loader>
                                                            </Dimmer>
                                                        </Modal.Content>
                                                    ) : importVersionMassTestMutation.isError ? (
                                                        <>
                                                            <Modal.Header>
                                                                {t("An error occurred") + "..."}
                                                            </Modal.Header>
                                                            <Modal.Content>
                                                                <Message negative>
                                                                    <strong> {t("Action did not succeed")} : </strong>{" "}
                                                                    {importVersionMassTestMutation.error === null
                                                                        ? t("An error occurred please contact support")
                                                                        : t(
                                                                              displayResponseError(
                                                                                  importVersionMassTestMutation.error
                                                                              )
                                                                          )}
                                                                    {importVersionMassTestMutation.error !== null &&
                                                                        importVersionMassTestMutation.error.details &&
                                                                        importVersionMassTestMutation.error.details
                                                                            .length > 0 && (
                                                                            <ul>
                                                                                {importVersionMassTestMutation.error?.details.map(
                                                                                    (detail, key) => {
                                                                                        const splittedDetail = detail.split(
                                                                                            /(\s\d+\s:\s)/
                                                                                        );
                                                                                        if (
                                                                                            splittedDetail[2].startsWith(
                                                                                                "mandatories_value_missing"
                                                                                            )
                                                                                        ) {
                                                                                            const valueKey = splittedDetail[2].substring(
                                                                                                "mandatories_value_missing_"
                                                                                                    .length
                                                                                            );
                                                                                            return (
                                                                                                <li key={key}>
                                                                                                    {t(
                                                                                                        splittedDetail[0]
                                                                                                    ) +
                                                                                                        splittedDetail[1] +
                                                                                                        t(
                                                                                                            "request_errors.mandatories_value_missing",
                                                                                                            {
                                                                                                                key: valueKey,
                                                                                                            }
                                                                                                        )}
                                                                                                </li>
                                                                                            );
                                                                                        } else {
                                                                                            return (
                                                                                                <li key={key}>
                                                                                                    {t(
                                                                                                        splittedDetail[0]
                                                                                                    ) +
                                                                                                        splittedDetail[1] +
                                                                                                        t(
                                                                                                            "request_errors." +
                                                                                                                splittedDetail[2]
                                                                                                        )}
                                                                                                </li>
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                )}
                                                                            </ul>
                                                                        )}
                                                                </Message>
                                                            </Modal.Content>
                                                            <Modal.Actions>
                                                                <Button secondary onClick={handleCloseModal}>
                                                                    {t("Close")}
                                                                </Button>
                                                            </Modal.Actions>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Modal.Header>{t("Mass test request")}</Modal.Header>
                                                            <Modal.Content>
                                                                {t(
                                                                    "Request has been successfully processed, You will receive results by email"
                                                                )}
                                                            </Modal.Content>
                                                            <Modal.Actions>
                                                                <Button secondary onClick={handleCloseModal}>
                                                                    {t("Close")}
                                                                </Button>
                                                            </Modal.Actions>
                                                        </>
                                                    )}
                                                </Modal>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    );
};

export default MassTestPage;
