import {useEffect, useState} from "react";
import {Control, Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid, Icon, Message} from "semantic-ui-react";

type FormPasswordPropsType = {
    control: Control<{password: string; "repeat-password": string}>;
    password: string;
    repeatedPassword: string;
};

const FormPassword = ({control, password, repeatedPassword}: FormPasswordPropsType) => {
    const {t} = useTranslation();

    const [passwordComplexity, setPasswordComplexity] = useState({
        atLeastTwelveCaracters: false,
        atLeastOneLowercase: false,
        atLeastOneUppercase: false,
        atLeastOneDigit: false,
        atLeastOneSpecialCaracter: false,
        bothPasswordAreEquals: false,
    });

    useEffect(() => {
        setPasswordComplexity({
            atLeastTwelveCaracters: password.length >= 12,
            atLeastOneLowercase: /[a-z]/.test(password),
            atLeastOneUppercase: /[A-Z]/.test(password),
            atLeastOneDigit: /\d/.test(password),
            atLeastOneSpecialCaracter: /^[a-zA-Z0-9#?!@$%^&*-]+$/.test(password) && /[#?!@$%^&*-]/.test(password),
            bothPasswordAreEquals: password !== "" && password === repeatedPassword,
        });
    }, [password, repeatedPassword]);

    return (
        <Form>
            <Grid centered>
                <Grid.Row>
                    <Grid.Column>
                        <Controller
                            control={control}
                            defaultValue={""}
                            name="password"
                            rules={{
                                validate: {
                                    atLeastTwelveCaracters: (password: any) => password.length >= 12,
                                    atLeastOneLowercase: (password: any) => /[a-z]/.test(password),
                                    atLeastOneUppercase: (password: any) => /[A-Z]/.test(password),
                                    atLeastOneDigit: (password: any) => /\d/.test(password),
                                    atLeastOneSpecialCaracter: (password: any) =>
                                        /^[a-zA-Z0-9#?!@$%^&*-]+$/.test(password) && /[#?!@$%^&*-]/.test(password),
                                },
                            }}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    name={name}
                                    type="password"
                                    label={t("New password")}
                                    placeholder={t("Type the new password")}
                                    required
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    maxLength="50"
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            defaultValue={""}
                            name="repeat-password"
                            rules={{
                                validate: {
                                    bothPasswordAreEquals: (repeatedPassword: any) =>
                                        password !== "" && password === repeatedPassword,
                                },
                            }}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    name={name}
                                    type="password"
                                    label={t("Confirm new password")}
                                    placeholder={t("Please type a second time the new password")}
                                    required
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    maxLength="50"
                                />
                            )}
                        />

                        <Message data-cy="password-complexity-requirements">
                            <Message.Header>{t("Password complexity requirements")}</Message.Header>
                            <Message.List>
                                <div>
                                    {passwordComplexity.atLeastTwelveCaracters ? (
                                        <Icon name="check" color="green" />
                                    ) : (
                                        <Icon name="close" color="red" />
                                    )}
                                    {t("At least twelve caracter")}
                                </div>
                                <div>
                                    {passwordComplexity.atLeastOneLowercase ? (
                                        <Icon name="check" color="green" />
                                    ) : (
                                        <Icon name="close" color="red" />
                                    )}
                                    {t("At least one lowercase caracter")}
                                </div>
                                <div>
                                    {passwordComplexity.atLeastOneUppercase ? (
                                        <Icon name="check" color="green" />
                                    ) : (
                                        <Icon name="close" color="red" />
                                    )}
                                    {t("At least one uppercase caracter")}
                                </div>
                                <div>
                                    {passwordComplexity.atLeastOneDigit ? (
                                        <Icon name="check" color="green" />
                                    ) : (
                                        <Icon name="close" color="red" />
                                    )}
                                    {t("At least one digit")}
                                </div>
                                <div>
                                    {passwordComplexity.atLeastOneSpecialCaracter ? (
                                        <Icon name="check" color="green" />
                                    ) : (
                                        <Icon name="close" color="red" />
                                    )}
                                    {t("At least one special caracter only in the following") + ": #?!@$%^&*-"}
                                </div>
                                <div>
                                    {passwordComplexity.bothPasswordAreEquals ? (
                                        <Icon name="check" color="green" />
                                    ) : (
                                        <Icon name="close" color="red" />
                                    )}
                                    {t("Both password are equals")}
                                </div>
                            </Message.List>
                        </Message>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormPassword;
