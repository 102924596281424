import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Container, Dropdown, Grid, Header, Menu, Segment} from "semantic-ui-react";
import {AuthContext} from "../../../Components/AuthProvider/AuthProvider";
import FormUser from "../../../Components/Forms/FormUser";
import ModalAdd from "../../../Components/Modals/ModalAdd";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import SimpleList from "../../../Components/SimpleList/SimpleList";
import {DataTypeColumnEnum} from "../../../Components/SimpleList/SimpleListTypes";
import useTransverseFeatureSidebarMenus from "../../../Hooks/useTransverseFeatureSidebarMenus";
import {useAddUserMutation, useGetSelfCompanyQuery, useGetUsersQuery} from "../../../Services/Company/Queries";
import {Role, UserAddDTO, UserDTO} from "../../../Services/Company/Types";

const usersTableColumns = [
    {
        text: "Username",
        dataField: "username",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Email",
        dataField: "email",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "profile",
        dataField: "role",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Enabled",
        dataField: "enabled",
        type: DataTypeColumnEnum.BOOLEAN,
    },
    {
        text: "Blocked",
        dataField: "blocked",
        type: DataTypeColumnEnum.BOOLEAN,
    },
    {
        text: "Credentials Expired",
        dataField: "credentialsExpired",
        type: DataTypeColumnEnum.BOOLEAN,
    },
    {
        text: "Last login date",
        dataField: "lastLoginDate",
        type: DataTypeColumnEnum.DATETIME,
    },
    {
        text: "",
        dataField: "/transversal_settings/users/:id",
        type: DataTypeColumnEnum.LINK,
    },
];

const UsersPage = () => {
    const {t} = useTranslation();
    const {user: loggedUser} = React.useContext(AuthContext);

    const roleOptions = [
        {key: "role-none", text: t("All profiles"), value: undefined},
        ...Object.values(Role)
            .map((role: Role, index: number) => {
                return {
                    key: `role-${index}`,
                    text: t(`enums.Role.${role}`),
                    value: role,
                };
            })
            .filter((role) => role.value !== Role.GESTIONNAIRE && role.value !== Role.APPORTEUR),
    ];

    const [usersToDisplay, setUsersToDisplay] = useState<UserDTO[]>([]);

    const companyQueryResult = useGetSelfCompanyQuery();
    const usersQueryResult = useGetUsersQuery(
        String(companyQueryResult?.data?.id),
        companyQueryResult?.data !== undefined
    );

    const handleFilter = (value: Role | undefined) => {
        if (usersQueryResult.status === "success" && usersQueryResult.data !== undefined) {
            if (value === undefined) setUsersToDisplay(usersQueryResult.data);
            else setUsersToDisplay(usersQueryResult.data.filter((user) => user.role === value));
        }
    };

    useEffect(() => {
        if (usersQueryResult.isSuccess && usersQueryResult.data) {
            setUsersToDisplay(usersQueryResult.data);
        }
    }, [usersQueryResult.isSuccess, usersQueryResult.data]);

    const menus = useTransverseFeatureSidebarMenus(
        loggedUser?.authorities?.some((authority) => authority === "SUPER_ADMIN" || authority === "ADMINISTRATEUR") ||
            false
    );

    // Add user
    const addUserMutation = useAddUserMutation(String(companyQueryResult?.data?.id));
    const addUserForm = useForm<UserAddDTO>();
    const handleUserSubmit = (formData: UserAddDTO) => {
        addUserMutation.mutate({...formData, password: null});
    };
    const [defaultValueAddUser, setDefaultValueAddUser] = useState<UserAddDTO>({} as UserAddDTO);

    return usersQueryResult && usersQueryResult?.status === "success" ? (
        <Container fluid>
            <Sidebar title={t("transversal_settings")} menus={menus} />
            <div className="main-container">
                <Grid>
                    <Grid.Column key={"users"} width={16}>
                        <Segment className="card-container" textAlign="center">
                            <Menu borderless secondary style={{border: "0", boxShadow: "none", padding: 0}}>
                                <Menu.Item>
                                    <Dropdown
                                        icon="filter"
                                        labeled
                                        button
                                        className="icon"
                                        name="profileFilter"
                                        selection
                                        options={roleOptions}
                                        placeholder={t("Filter by profile")}
                                        onChange={(_, {value}) => handleFilter(value as Role | undefined)}
                                    />
                                </Menu.Item>
                                <Menu.Menu position="right">
                                    <Menu.Item>
                                        <ModalAdd
                                            isValidationDisabled={
                                                Object.keys(addUserForm.formState.errors).length !== 0
                                            }
                                            isPending={addUserMutation.isPending}
                                            isSuccess={addUserMutation.isSuccess}
                                            isError={addUserMutation.isError}
                                            error={addUserMutation.error}
                                            resetMutation={addUserMutation.reset}
                                            objectToAdd="User"
                                            objectToAddContext="male"
                                            onValidate={addUserForm.handleSubmit(handleUserSubmit)}
                                            onCancel={() => {
                                                setDefaultValueAddUser({} as UserAddDTO);
                                                addUserForm.reset();
                                            }}
                                            onClose={() => addUserForm.reset()}
                                            iconOnOpenButton
                                            openButtonIcon="plus"
                                            renderModalContent={() => (
                                                <FormUser
                                                    control={addUserForm.control}
                                                    errors={addUserForm.formState.errors}
                                                    defaultValueAddUser={defaultValueAddUser}
                                                    setDefaultValueAddUser={setDefaultValueAddUser}
                                                />
                                            )}
                                        />
                                    </Menu.Item>
                                </Menu.Menu>
                            </Menu>
                            <SimpleList columns={usersTableColumns} rows={usersToDisplay} globalData={[]} />
                            <Menu borderless secondary style={{border: "0", boxShadow: "none", padding: 0}}>
                                <Menu.Menu position="right">
                                    <Menu.Item>
                                        <Header as="h4">
                                            {usersToDisplay.length + " " + t("users").toLowerCase()}
                                        </Header>
                                    </Menu.Item>
                                </Menu.Menu>
                            </Menu>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>
        </Container>
    ) : (
        <></>
    );
};

export default UsersPage;
