import {UseMutationResult} from "@tanstack/react-query";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {AccordionTitle, Icon, Label} from "semantic-ui-react";
import {DndPropertiesType} from "../../Hooks/useDragAndDropProperties";
import {ConditionPart} from "../../Services/Condition/Types";
import {CustomError} from "../../Services/RequestUtils";
import {VersionDTO} from "../../Services/Version/Types";
import {conditionPartListToString} from "../../Utils/ConditionPartUtils";
import ModalUpdate from "../Modals/ModalUpdate";

type ConditionBase = {
    id?: number | null;
    ifCondition: string | null;
    conditionPartList?: ConditionPart[];
    valid?: boolean;
};

type OrderCondition = {
    orderCondition?: number;
    conditionOrder?: never;
};

type ConditionOrder = {
    orderCondition?: never;
    conditionOrder?: number;
};

type ConditionGeneric = (ConditionBase & OrderCondition) | (ConditionBase & ConditionOrder);

interface ConditionAccordionTitleType<T extends ConditionGeneric> {
    dndProperties: DndPropertiesType<T>;
    version?: VersionDTO;
    condition: T;
    index: number;
    activeIndex: number;
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
    numberOfConditions?: number;
    reorderFunction: UseMutationResult<void, CustomError, number, Error>;
    customTitle?: string;
    onSuccess?: () => void;
}

const ConditionAccordionTitle = <T extends ConditionGeneric>({
    dndProperties,
    version,
    condition,
    index,
    activeIndex,
    setActiveIndex,
    numberOfConditions,
    reorderFunction,
    customTitle,
    onSuccess,
}: ConditionAccordionTitleType<T>) => {
    const order = condition.conditionOrder ?? condition.orderCondition;
    const draggegItemOrder = dndProperties.draggedItem?.conditionOrder ?? dndProperties.draggedItem?.orderCondition;

    const {t} = useTranslation();

    const movingConditionStyle = {
        transition: "0.3s",
        cursor: version?.pipDate === null && condition.ifCondition ? "grab" : "pointer",
    };

    const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);

    const isDraggable = () => {
        if (version === undefined && !!condition.ifCondition) return true;
        else if (version?.pipDate === null && !!condition.ifCondition) return true;
        else return false;
    };

    return (
        <>
            {openUpdateModal && (
                <ModalUpdate
                    hidden
                    onValidate={() => {
                        reorderFunction.mutate(dndProperties.renderedItems.indexOf(condition) + 1, {onSuccess});
                    }}
                    onSuccess={() => {
                        setOpenUpdateModal(false);
                        dndProperties.setTemporaryItems(undefined);
                        dndProperties.setInitialTargetItemId(undefined);
                        dndProperties.setDraggedItem(undefined);
                    }}
                    isModalOpenAtStart
                    customContent={t("You are about to reorder the conditions")}
                    onCancel={() => {
                        setOpenUpdateModal(false);
                        dndProperties.refreshData();
                    }}
                    onClose={() => {
                        setOpenUpdateModal(false);
                        dndProperties.refreshData();
                    }}
                    resetMutation={reorderFunction.reset}
                    isSuccess={reorderFunction.isSuccess}
                    isPending={reorderFunction.isPending}
                    isError={reorderFunction.isError}
                    error={reorderFunction.error}
                />
            )}

            <AccordionTitle
                active={activeIndex === index}
                index={index}
                name={"condition-" + order}
                style={{
                    ...movingConditionStyle,
                    userSelect: "none",
                    opacity:
                        dndProperties.draggedItem !== undefined && dndProperties.draggedItem.id === condition.id
                            ? 0.3
                            : 1,
                    display: "flex",
                    alignItems: "center",
                }}
                onClick={() => {
                    if (activeIndex === index) setActiveIndex(-1);
                    else setActiveIndex(index);
                }}
                draggable={isDraggable()}
                onDragStart={() => {
                    setActiveIndex(-1);
                    dndProperties.setDraggedItem(condition);
                }}
                onDragOver={(e: {preventDefault: () => void}) => {
                    e.preventDefault();

                    if (!condition.ifCondition) return;

                    if (dndProperties.temporaryItems === undefined && condition.id)
                        dndProperties.setInitialTargetItemId(condition.id);
                    if (!dndProperties.draggedItem || dndProperties.draggedItem === condition) return;

                    const currentIndex = dndProperties.renderedItems.indexOf(dndProperties.draggedItem);
                    const targetIndex = dndProperties.renderedItems.indexOf(condition);

                    if (currentIndex !== -1 && targetIndex !== -1) {
                        const newItems = [...dndProperties.renderedItems];
                        newItems.splice(currentIndex, 1);
                        newItems.splice(targetIndex, 0, dndProperties.draggedItem);
                        dndProperties.setTemporaryItems(newItems);
                    }
                }}
                onDragEnd={() => {
                    dndProperties.setDraggedItem(undefined);
                }}
                onDrop={() => {
                    if (dndProperties.initialTargetItemId && dndProperties.draggedItem) {
                        const initialItemIndex = dndProperties.renderedItems.indexOf(dndProperties.draggedItem);
                        if (
                            dndProperties.draggedItem.id !== dndProperties.initialTargetItemId &&
                            draggegItemOrder !== initialItemIndex + 1 &&
                            condition.ifCondition
                        )
                            setOpenUpdateModal(true);
                        else dndProperties.refreshData();
                    }
                }}
            >
                <Icon name="dropdown" />
                <div style={{display: "flex", alignItems: "center"}}>
                    {customTitle !== undefined ? customTitle + " " + order : t("Condition") + " " + order}
                    {activeIndex !== index && numberOfConditions !== 1 && (
                        <p style={{marginLeft: "4px", fontWeight: "lighter", fontSize: "12px", color: "grey"}}>
                            {` - ${
                                condition.ifCondition && condition.conditionPartList
                                    ? conditionPartListToString(condition.conditionPartList)
                                    : t("Else")
                            }`}
                        </p>
                    )}
                </div>

                {condition.valid !== undefined && !condition.valid && (
                    <Label size="small" color="red" basic style={{marginLeft: "15px"}}>
                        <Icon name="warning sign" />
                        {t("Invalid").toUpperCase()}
                    </Label>
                )}
            </AccordionTitle>
        </>
    );
};

export default ConditionAccordionTitle;
