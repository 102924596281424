import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Params, useParams} from "react-router-dom";
import ModalUpdateForm from "../../../../Components/Modals/ModalUpdateForm";
import {useUpdateInsurancePackageMutation} from "../../../../Services/InsurancePackage/Queries";
import {InsurancePackageItem} from "../../../../Services/InsurancePackage/Types";
import FormInsurancePackage, {FormInsurancePackageFormTypes} from "./FormInsurancePackage";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
}

type PropsType = {
    insurancePackage: InsurancePackageItem;
};

const ModalUpdateInsurancePackage = ({...props}: PropsType) => {
    const params = useParams() as IParams;

    const updateInsurancePackageMutation = useUpdateInsurancePackageMutation(
        params.versionId,
        params.insurancePackageId
    );
    const updateInsurancePackageForm = useForm<FormInsurancePackageFormTypes>();

    const [modifiedInsurancePackage, setModifiedInsurancePackage] = useState<FormInsurancePackageFormTypes>(
        props.insurancePackage
    );

    useEffect(() => {
        updateInsurancePackageForm.reset();
        setModifiedInsurancePackage(props.insurancePackage);
    }, [props.insurancePackage, updateInsurancePackageForm]);

    const submitUpdateInsurancePackage = (formData: FormInsurancePackageFormTypes) => {
        updateInsurancePackageMutation.mutate({
            ...formData,
            startDate: formData.startDate + "T00:00:00.000Z",
            endDate: formData.endDate ? formData.endDate + "T00:00:00.000Z" : null,
            endDateEndorsement: formData.endDateEndorsement ? formData.endDateEndorsement + "T00:00:00.000Z" : null,
            id: params.insurancePackageId,
            prodDate: props.insurancePackage.prodDate,
        });
    };

    return (
        <ModalUpdateForm
            isValidationDisabled={Object.keys(updateInsurancePackageForm.formState.errors).length !== 0}
            isPending={updateInsurancePackageMutation.isPending}
            isSuccess={updateInsurancePackageMutation.isSuccess}
            isError={updateInsurancePackageMutation.isError}
            resetMutation={updateInsurancePackageMutation.reset}
            error={updateInsurancePackageMutation.error}
            onValidate={updateInsurancePackageForm.handleSubmit(submitUpdateInsurancePackage)}
            objectToUpdate={"Insurance package"}
            openModalButtonColor="blue"
            validateButtonColor="green"
            objectToUpdateContext="female"
            renderModalContent={() => (
                <FormInsurancePackage
                    errors={updateInsurancePackageForm.formState.errors}
                    control={updateInsurancePackageForm.control}
                    modifiedInsurancePackage={modifiedInsurancePackage}
                    setModifiedInsurancePackage={setModifiedInsurancePackage}
                />
            )}
        />
    );
};

export default ModalUpdateInsurancePackage;
