import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Grid } from "semantic-ui-react";
import { AuthContext } from "../../../Components/AuthProvider/AuthProvider";
import Sidebar, { SidebarItem, SidebarMenu } from "../../../Components/Sidebar/Sidebar";
import { useGetSelfCompanyQuery, useGetSelfUserQuery } from "../../../Services/Company/Queries";
import UserProfile from "./Fragments/UserProfile";

const UserAccountPage = () => {
    const {t} = useTranslation();
    const {logout} = React.useContext(AuthContext);

    const itemList = [
        {
            name: "My account",
            path: "/account",
        },
        {
            name: "logout",
            path: "/login",
        },
    ];

    const menus = new SidebarMenu(
        "",
        itemList.map((item) =>
            item.name === "logout"
                ? new SidebarItem(t(item.name), t(item.name), item.path, false, logout)
                : new SidebarItem(t(item.name), t(item.name), item.path)
        )
    );

    const companyQueryResult = useGetSelfCompanyQuery();
    const userDetailsRequest = useGetSelfUserQuery();

    return (
        <Container fluid>
            <Sidebar title={`${t("user account")}`} menus={[menus]} />
            <div className="main-container">
                <Grid columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            {companyQueryResult.data !== undefined && userDetailsRequest.data !== undefined && (
                                <UserProfile
                                    companyId={String(companyQueryResult.data.id)}
                                    user={userDetailsRequest.data}
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    );
};

export default UserAccountPage;
