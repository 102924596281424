import {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {Container, Grid} from "semantic-ui-react";
import CustomBreadcrumb from "../../../Components/CustomBreadcrumb/CustomBreadcrumb";
import Sidebar, {SidebarItem, SidebarMenu} from "../../../Components/Sidebar/Sidebar";
import {useGetRegistryQuery, useGetRegistriesByTypeQuery} from "../../../Services/Registry/Queries";
import {RegistryEntryType} from "../../../Services/Registry/Types";
import EntryDetails from "./Fragments/EntryDetails";

const EntryPage = ({...props}) => {
    const {t} = useTranslation();
    const params = useParams() as {registryEntryId: string};

    const getRegistryListQuery = useGetRegistriesByTypeQuery(null);
    const getEntryQuery = useGetRegistryQuery(params.registryEntryId);

    const [menus, setMenus] = useState<Array<SidebarMenu>>([]);

    useEffect(() => {
        if (getRegistryListQuery.isSuccess && getRegistryListQuery.data !== undefined) {
            const sidebarMenuCoverages: SidebarMenu = new SidebarMenu(
                t(`enums.RegistryEntryType.${RegistryEntryType.G}`),
                getRegistryListQuery.data
                    .filter((registryEntry) => registryEntry.type === RegistryEntryType.G)
                    .sort((a, b) => (a.label < b.label ? -1 : 1))
                    .map((type) => new SidebarItem(type.code, type.label, `/transversal_settings/registry/${type.id}`))
            );
            const sidebarMenuFee: SidebarMenu = new SidebarMenu(
                t(`enums.RegistryEntryType.${RegistryEntryType.F}`),
                getRegistryListQuery.data
                    .filter((registryEntry) => registryEntry.type === RegistryEntryType.F)
                    .sort((a, b) => (a.label < b.label ? -1 : 1))
                    .map((type) => new SidebarItem(type.code, type.label, `/transversal_settings/registry/${type.id}`))
            );

            const menus: Array<SidebarMenu> = [];
            menus.push(sidebarMenuCoverages);
            menus.push(sidebarMenuFee);
            setMenus(menus);
        }
    }, [getRegistryListQuery.isSuccess, getRegistryListQuery.data, t]);

    return (
        <Container fluid>
            {menus !== undefined && <Sidebar title={t("Registry")} menus={menus} />}
            {getEntryQuery.isLoading && <CustomBreadcrumb sections={props.sections} />}

            {getEntryQuery.isSuccess && getEntryQuery.data !== undefined && (
                <Fragment>
                    <CustomBreadcrumb sections={props.sections} title={getEntryQuery.data.label} />
                    <div className="main-container">
                        <Grid columns={2} stackable>
                            <Grid.Row>
                                <Grid.Column width={6}>
                                    <EntryDetails
                                        registryEntryId={params.registryEntryId}
                                        registryEntry={getEntryQuery.data}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Fragment>
            )}
        </Container>
    );
};

export default EntryPage;
