import {useTranslation} from "react-i18next";
import {SemanticICONS} from "semantic-ui-react";
import {CustomError} from "../../Services/RequestUtils";
import CustomModal from "./CustomModal";

type ModalDeleteType = {
    isPending: boolean;
    isSuccess: boolean;
    isError: boolean;
    resetMutation?: () => void;
    onCancel?: () => void;
    onClose?: () => void;
    onSuccess?: () => void;
    onValidate: () => void;
    isModalDisabled?: boolean;
    objectToDelete: string;
    objectType: "male" | "female" | "nonbinary" | "plurial";
    openModalButtonName?: string;
    error?: CustomError | null;
    iconOnOpenButton?: boolean;
    openButtonIcon?: SemanticICONS;
    openButton?: SemanticICONS;
    basicButton?: boolean;
    renderOpenButton?: () => JSX.Element;
    openModalButtonText?: string;
    iconAndTextOnOpenButton?: boolean;
};

const ModalDelete: React.FC<ModalDeleteType> = ({
    isPending,
    isSuccess,
    isError,
    resetMutation,
    onCancel,
    onClose,
    onSuccess,
    onValidate,
    isModalDisabled,
    objectToDelete,
    objectType,
    openModalButtonName = "delete",
    error = null,
    iconOnOpenButton = false,
    openButtonIcon,
    basicButton,
    renderOpenButton,
    openModalButtonText = "Remove",
}) => {
    const {t} = useTranslation();

    const renderModalContent = () => {
        return (
            <p>
                {t("You are about to delete", {
                    name: t(objectToDelete, {count: 1}).toLowerCase(),
                    context: objectType,
                })}
            </p>
        );
    };

    return (
        <CustomModal
            openModalButtonName={openModalButtonName}
            openModalButtonText={t(openModalButtonText)}
            openModalButtonColor="red"
            cancelButtonColor="black"
            validateButtonColor="red"
            iconOnValidateButton
            validateButtonIcon="trash alternate"
            validateButtonText={t("Remove", {
                name: t(objectToDelete, {count: 1}).toLowerCase(),
                context: objectType,
            })}
            loadingText={t("Delete in progress")}
            errorText={t("An error occurred during deletion")}
            modalHeader={t("Would you like to continue ?")}
            renderModalContent={renderModalContent}
            isModalDisabled={isModalDisabled}
            isPending={isPending}
            isSuccess={isSuccess}
            isError={isError}
            resetMutation={resetMutation}
            onCancel={onCancel}
            onClose={onClose}
            onSuccess={onSuccess}
            onValidate={onValidate}
            error={error}
            iconOnOpenButton={iconOnOpenButton}
            openButtonIcon={openButtonIcon}
            basicButton={basicButton}
            renderOpenButton={renderOpenButton}
        />
    );
};

export default ModalDelete;
