import axios, { AxiosError } from "axios";
import axiosInstance from "../ProductEngine.instance";
import { buildCustomError } from "../RequestUtils";
import { EditedProductDetailsType, NewProductDTO, ProductDetailsType, ProductRecap, ProductsByRiskType } from "./Types";

export const getProductDetails = async (id: string): Promise<ProductDetailsType> => {
    try {
        const response = await axiosInstance.get<ProductDetailsType>(`/products/${id}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const editProductApi = async (
    productId: string,
    newProductDetails: EditedProductDetailsType
): Promise<ProductDetailsType> => {
    try {
        const response = await axiosInstance.put<ProductDetailsType>(`/products/${productId}`, newProductDetails);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteProductApi = async (productId: string) => {
    try {
        await axiosInstance.delete<EditedProductDetailsType>(`/products/${productId}`);
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getProductsByRiskType = async () => {
    try {
        const response = await axiosInstance.get<ProductsByRiskType[]>("/products/_byRiskType");
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addProduct = async (product: NewProductDTO): Promise<ProductDetailsType> => {
    try {
        const response = await axiosInstance.post<ProductDetailsType>(`/products`, product);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getProducts = async () => {
    try {
        const response = await axiosInstance.get<ProductRecap[]>("/products");
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const duplicateProduct = async (
    productId: string,
    code: string,
    label: string,
    workingVersion: boolean
): Promise<ProductDetailsType> => {
    try {
        const response = await axiosInstance.post<ProductDetailsType>(
            `/products/${productId}/_duplicate?code=${code}&label=${label}&workingVersion=${workingVersion}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};
