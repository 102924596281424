import {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Header, Menu, Message, Segment} from "semantic-ui-react";
import ModalAdd from "../../../../../Components/Modals/ModalAdd";
import useContainerDimensions from "../../../../../Hooks/useContainerDimensions";
import {RegistryNotAssociatedDTO} from "../../../../../Services/Company/Types";
import {useAddOptionMutation, useGetOptionsByGroupQuery} from "../../../../../Services/InsurancePackage/Queries";
import {CoverageOrOption, CoverageOrOptionType, Option} from "../../../../../Services/InsurancePackage/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import CoverageCard from "../Coverages/CoverageCard";
import FormCoverage from "../Coverages/FormCoverage";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
}

type OptionListPropsType = {
    coverageOrOption: string;
    activeCoverageGroupId: string;
    version: VersionDTO | undefined;
    selectCoverageOptions: any;
};

const OptionsList = ({
    coverageOrOption,
    activeCoverageGroupId,
    version,
    selectCoverageOptions,
}: OptionListPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    const optionsByGroupQueryResult = useGetOptionsByGroupQuery(activeCoverageGroupId);

    const addOptionMutation = useAddOptionMutation(
        params.id,
        activeCoverageGroupId,
        params.insurancePackageId,
        params.versionId
    );
    const addOptionForm = useForm<any>();
    const submitAddOptionForm = (form: any) => {
        addOptionMutation.mutate({
            code: form.code,
            label: form.label,
            registryId: form.selectedItem.registryId,
            computingRule: null,
            derogatoryManagementCommissionRate: form.managementCommissionRadioButton
                ? Number(form.managementCommissionInput)
                : null,
            derogatoryContributionCommissionRate: form.contributionCommissionRadioButton
                ? Number(form.contributionCommissionInput)
                : null,
        });
    };

    const [defaultValueCode, setDefaultValueCode] = useState<string>("");
    const [defaultValueLabel, setDefaultValueLabel] = useState<string>("");
    const [defaultValueSelectedItem, setDefaultValueSelectedItem] = useState<RegistryNotAssociatedDTO | null>(null);
    const [defaultValueContribCom, setDefaultValueContribCom] = useState<string>("");
    const [defaultValueContribComCheck, setDefaultValueContribComCheck] = useState<boolean>(false);
    const [defaultValueManagementCom, setDefaultValueManagementCom] = useState<string>("");
    const [defaultValueManagementCheck, setDefaultValueManagementCheck] = useState<boolean>(false);

    const optionToCoverageOrOption = (option: Option): CoverageOrOptionType => {
        return {
            id: option.id,
            code: option.code,
            label: option.label,
            optional: false,
            derogatoryManagementCommissionRate: option.derogatoryManagementCommissionRate,
            derogatoryContributionCommissionRate: option.derogatoryContributionCommissionRate,
            registryId: option.registry.id,
            registryCode: option.registry.code,
            registryLabel: option.registry.label,

            createdDate: option.createdDate,
            modifiedDate: option.modifiedDate,
            createdBy: option.createdBy,
            modifiedBy: option.modifiedBy,
            createdOnVersionId: option.createdOnVersionId,
        };
    };

    const {columnNumber, columnNumberAsText, ref} = useContainerDimensions();

    return (
        <>
            <Menu borderless style={{border: "0", boxShadow: "none"}}>
                <Menu.Item style={{padding: "0px 10px"}}>
                    <Header size="large">{t(coverageOrOption, {count: 2})}</Header>
                </Menu.Item>
                <Menu.Menu position="right">
                    <Menu.Item style={{padding: "0px 10px"}}>
                        {version !== undefined && version.pipDate === null && (
                            <ModalAdd
                                isValidationDisabled={Object.keys(addOptionForm.formState.errors).length !== 0}
                                isPending={addOptionMutation.isPending}
                                isSuccess={addOptionMutation.isSuccess}
                                isError={addOptionMutation.isError}
                                error={addOptionMutation.error}
                                resetMutation={addOptionMutation.reset}
                                onValidate={addOptionForm.handleSubmit(submitAddOptionForm)}
                                onCancel={() => {
                                    setDefaultValueCode("");
                                    setDefaultValueLabel("");
                                    setDefaultValueSelectedItem(null);
                                    setDefaultValueContribCom("");
                                    setDefaultValueContribComCheck(false);
                                    setDefaultValueManagementCom("");
                                    setDefaultValueManagementCheck(false);
                                }}
                                onSuccess={() => {
                                    setDefaultValueCode("");
                                    setDefaultValueLabel("");
                                    setDefaultValueSelectedItem(null);
                                    setDefaultValueContribCom("");
                                    setDefaultValueContribComCheck(false);
                                    setDefaultValueManagementCom("");
                                    setDefaultValueManagementCheck(false);
                                }}
                                objectToAdd={"Option"}
                                iconOnOpenButton
                                openButtonIcon="plus"
                                renderModalContent={() => (
                                    <FormCoverage
                                        control={addOptionForm.control}
                                        errors={addOptionForm.formState.errors}
                                        setValue={addOptionForm.setValue}
                                        coverageOrOption={CoverageOrOption.OPTION}
                                        selectCoverageOptions={selectCoverageOptions}
                                        defaultValueCode={defaultValueCode}
                                        setDefaultValueCode={setDefaultValueCode}
                                        defaultValueLabel={defaultValueLabel}
                                        setDefaultValueLabel={setDefaultValueLabel}
                                        defaultValueSelectedItem={defaultValueSelectedItem}
                                        setDefaultValueSelectedItem={setDefaultValueSelectedItem}
                                        defaultValueContribCom={defaultValueContribCom}
                                        setDefaultValueContribCom={setDefaultValueContribCom}
                                        defaultValueContribComCheck={defaultValueContribComCheck}
                                        setDefaultValueContribComCheck={setDefaultValueContribComCheck}
                                        defaultValueManagementCom={defaultValueManagementCom}
                                        setDefaultValueManagementCom={setDefaultValueManagementCom}
                                        defaultValueManagementCheck={defaultValueManagementCheck}
                                        setDefaultValueManagementCheck={setDefaultValueManagementCheck}
                                    />
                                )}
                                objectToAddContext={"female"}
                            />
                        )}
                    </Menu.Item>
                </Menu.Menu>
            </Menu>

            {optionsByGroupQueryResult !== undefined &&
                optionsByGroupQueryResult.data !== undefined &&
                optionsByGroupQueryResult.data.length === 0 && (
                    <Segment style={{border: "0", boxShadow: "none"}}>
                        <Message warning header={t("No option")} content={t("There is no option for this group")} />
                    </Segment>
                )}

            {optionsByGroupQueryResult !== undefined &&
                optionsByGroupQueryResult.isSuccess &&
                optionsByGroupQueryResult.data !== null && (
                    <div className={"ui cards " + columnNumberAsText} ref={ref}>
                        {optionsByGroupQueryResult.data.map((option, index) => (
                            <CoverageCard
                                key={"option-card-" + index}
                                coverageOrOption={optionToCoverageOrOption(option)}
                                activeCoverageGroupId={Number(activeCoverageGroupId)}
                            />
                        ))}
                    </div>
                )}
        </>
    );
};

export default OptionsList;
