import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Form, Header, Message, Radio, Table, Transition } from "semantic-ui-react";
import { useRiskTypesFilterByType } from "../../Hooks/useRiskTypesFilterByType";
import { HealthProductFormContext } from "./HealthProductFormContext";

const ProductInformationForm: React.FC = () => {
    const {t} = useTranslation();
    const healthRiskType = useRiskTypesFilterByType("Santé");
    const {state, handleChange, displayError, errors} = useContext(HealthProductFormContext);

    return (
        <>
            <Header as="h2">{t("Product")}</Header>
            <Form.Group widths="equal">
                <Form.Input
                    fluid
                    required
                    maxLength="10"
                    label={t("Product code")}
                    name="productCode"
                    placeholder={t("Product code")}
                    onChange={handleChange}
                    value={state.productCode}
                    error={displayError("productCode")}
                />
                <Form.Input
                    fluid
                    required
                    name="productLabel"
                    maxLength="50"
                    label={t("Label")}
                    placeholder={t("Label")}
                    onChange={handleChange}
                    value={state.productLabel}
                    error={displayError("productLabel")}
                />
                <Form.Input
                    fluid
                    required
                    name="principalCompanyCode"
                    maxLength="5"
                    label={t("Company code")}
                    placeholder={t("Company code")}
                    onChange={handleChange}
                    value={state.principalCompanyCode}
                    error={displayError("principalCompanyCode")}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Select
                    fluid
                    name="rangeId"
                    onChange={handleChange}
                    label={t("Range")}
                    defaultValue={state.rangeId}
                    options={
                        healthRiskType
                            ? healthRiskType.ranges.map((range: any) => {
                                  return {key: range.label, value: range.id, text: range.label};
                              })
                            : []
                    }
                    placeholder={t("Select entity", {entity: t("Range").toLowerCase(), context: "female"})}
                    error={displayError("rangeId")}
                />
                <Form.Input
                    fluid
                    name="brand"
                    label={t("Brand")}
                    required
                    maxLength="50"
                    onChange={handleChange}
                    placeholder={t("Brand")}
                    value={state.brand}
                    error={displayError("brand")}
                />
                <Form.Input
                    fluid
                    required
                    name="effectiveDate"
                    label={t("Commercialization date")}
                    type="date"
                    onChange={handleChange}
                    value={state.effectiveDate}
                    placeholder={t("Commercialization date")}
                    error={displayError("effectiveDate")}
                />
            </Form.Group>
            <Header as="h2" style={{marginBottom: "1em"}}>
                {t("Splittings")}
            </Header>
            <Transition visible={displayError("splittings") !== undefined} animation="zoom" duration={250}>
                <Message negative>
                    {displayError("splittings") !== undefined && (
                        <>
                            <Message.Header>{t(errors.splittings[0])}</Message.Header>
                            <Message.Content>{t(errors.splittings[1])}</Message.Content>
                        </>
                    )}
                </Message>
            </Transition>
            <Table compact celled definition width={"5em"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell textAlign="center">{t("Authorise")}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">{t("Défaut")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {[
                        {name: t("Monthly"), value: "monthlySplitting", code: "M"},
                        {name: t("Quarterly"), value: "quarterlySplitting", code: "T"},
                        {name: t("Half-yearly"), value: "halfYearlySplitting", code: "S"},
                        {name: t("Annual"), value: "annualSplitting", code: "A"},
                    ].map((splitting) => (
                        <Table.Row key={splitting.value} className="no-hover">
                            <Table.Cell textAlign="center">{splitting.name}</Table.Cell>
                            <Table.Cell textAlign="center">
                                <Checkbox
                                    name={splitting.value}
                                    checked={state[splitting.value]}
                                    slider
                                    onClick={(event, data) => handleChange(event, data)}
                                />
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                <Radio
                                    value={splitting.code}
                                    name="defaultSplitting"
                                    slider
                                    onChange={handleChange}
                                    checked={state.defaultSplitting === splitting.code}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </>
    );
};

export default ProductInformationForm;
