import {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Grid, Header, Icon, Label} from "semantic-ui-react";
import {AuthContext} from "../../../../Components/AuthProvider/AuthProvider";
import Sections from "../../../../Components/CustomBreadcrumb/Fragments/Sections";
import TextOverflow from "../../../../Components/Text/TextOverflow";
import {Variable} from "../../../../Services/Variables/Types";
import {VersionDTO} from "../../../../Services/Version/Types";
import {displayAuditLog} from "../../../../Utils/AuditUtils";
import VariablesActions from "./VariablesActions";

interface IParams extends Params {
    versionId: string;
}

type BreadcrumbVariablesPropsType = {
    variable: Variable;
    version: VersionDTO;
    sections: Array<{name: string; path: string}>;
};

const BreadcrumbVariables = ({variable, version, sections}: BreadcrumbVariablesPropsType) => {
    const params = useParams() as IParams;
    const {user} = useContext(AuthContext);
    const {t} = useTranslation();

    return (
        <>
            <div className="header-container" data-cy="custom-breadcrumb-header">
                <Grid>
                    <Grid.Column floated={"left"} style={{width: "auto", margin: 0}}>
                        <Header as="h2" className="top-header">
                            <TextOverflow inline value={variable.name} />
                            <Label size="small" basic color="teal">
                                {t("Type")} : {t("enums.VariableValueType." + variable.valueType)}
                            </Label>
                            <Label size="small" basic color="blue">
                                {t("Save quote")} : {variable.saveQuote ? t("yes") : t("no")}
                            </Label>
                            {!variable.valid && (
                                <Label size="small" color="red" style={{maxHeight: "25px"}}>
                                    <Icon name="warning sign" />
                                    {t("Invalid").toUpperCase()}
                                </Label>
                            )}
                        </Header>
                        <div className="sub-header">
                            <Sections sections={sections} variableType={variable.variableType} />
                            {variable !== undefined && user !== undefined && (
                                <div className="audit-display">
                                    <i>
                                        {displayAuditLog(
                                            {
                                                entityContext: "female",
                                                createdDate: variable.createdDate,
                                                modifiedDate: variable.modifiedDate,
                                                createdBy: variable.createdBy,
                                                modifiedBy: variable.modifiedBy,
                                                createdOnVersionId: variable.createdOnVersionId,
                                            },
                                            user,
                                            params.versionId,
                                            t
                                        )}
                                    </i>
                                </div>
                            )}
                        </div>
                    </Grid.Column>

                    {variable !== undefined && version !== undefined && (
                        <VariablesActions isVersionValid={version.pipDate !== null} variable={variable} />
                    )}
                </Grid>
            </div>
        </>
    );
};

export default BreadcrumbVariables;
