import {useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Card, Grid, Input, Label, Menu, Tab} from "semantic-ui-react";
import FormUserGroup, {FormUserGroupFieldsTypes} from "../../../../Components/Forms/FormUserGroup";
import ModalAdd from "../../../../Components/Modals/ModalAdd";
import useContainerDimensions from "../../../../Hooks/useContainerDimensions";
import {useAddUserGroupMutation, useGetUserGroupsQuery} from "../../../../Services/Company/Queries";
import {UserGroupAuthorizationsDTO, UserGroupDTO} from "../../../../Services/Company/Types";

type PropsType = {
    companyId: string;
};

const UserGroupList = ({companyId}: PropsType) => {
    const {t} = useTranslation();
    const userGroupsQueryResult = useGetUserGroupsQuery(companyId, true);

    const [defaultValueForm, setDefaultValueForm] = useState<FormUserGroupFieldsTypes>({
        name: "",
        nbDaysAuthorizedBeforePricingDate: 0,
    });
    const addUserGroupMutation = useAddUserGroupMutation(companyId);
    const addUserGroupForm = useForm<FormUserGroupFieldsTypes>();

    const [search, setSearch] = useState<string>("");
    const userGroupFiltered = useMemo((): UserGroupDTO[] => {
        if (userGroupsQueryResult.data !== undefined && userGroupsQueryResult.data.length > 0) {
            return userGroupsQueryResult.data.filter((userGroup) =>
                userGroup.name.toLowerCase().includes(search.toLowerCase())
            );
        }

        return [];
    }, [search, userGroupsQueryResult.status, userGroupsQueryResult.data]);

    const handleUserGroupSubmit = (formData: FormUserGroupFieldsTypes) => {
        const userGroupAuthorizations: UserGroupAuthorizationsDTO = {
            id: null,
            nbDaysAuthorizedBeforePricingDate: Number(formData.nbDaysAuthorizedBeforePricingDate),
        };

        const newUserGroup: UserGroupDTO = {
            id: null,
            name: formData.name,
            userGroupAuthorizations: userGroupAuthorizations,
        };

        addUserGroupMutation.mutate(newUserGroup);
    };

    const resetAddUserGroupForm = () => {
        addUserGroupForm.reset();
        setDefaultValueForm({
            name: "",
            nbDaysAuthorizedBeforePricingDate: 0,
        });
    };

    const {columnNumberAsText, ref} = useContainerDimensions();

    return (
        <Tab.Pane>
            <Grid divided>
                <Grid.Row>
                    <Grid.Column width={16}>
                        {userGroupsQueryResult && userGroupsQueryResult?.status === "success" && (
                            <>
                                <Menu borderless secondary style={{border: "0", boxShadow: "none", padding: 0}}>
                                    <Menu.Menu position="right">
                                        <Menu.Item>
                                            <Input
                                                icon="search"
                                                name="search"
                                                placeholder={t("Find entity", {
                                                    context: "male",
                                                    entity: t("User group").toLowerCase(),
                                                })}
                                                defaultValue={""}
                                                onChange={(_, data) => setSearch(data.value)}
                                            />
                                        </Menu.Item>
                                        <Menu.Item>
                                            <ModalAdd
                                                isValidationDisabled={
                                                    Object.keys(addUserGroupForm.formState.errors).length !== 0
                                                }
                                                isPending={addUserGroupMutation.isPending}
                                                isSuccess={addUserGroupMutation.isSuccess}
                                                isError={addUserGroupMutation.isError}
                                                error={addUserGroupMutation.error}
                                                resetMutation={addUserGroupMutation.reset}
                                                onValidate={addUserGroupForm.handleSubmit(handleUserGroupSubmit)}
                                                onSuccess={resetAddUserGroupForm}
                                                onCancel={resetAddUserGroupForm}
                                                objectToAdd={"User group"}
                                                iconOnOpenButton
                                                openButtonIcon="plus"
                                                renderModalContent={() => (
                                                    <FormUserGroup
                                                        control={addUserGroupForm.control}
                                                        errors={addUserGroupForm.formState.errors}
                                                        defaultValueForm={defaultValueForm}
                                                        setDefaultValueForm={setDefaultValueForm}
                                                    />
                                                )}
                                                objectToAddContext={"male"}
                                            />
                                        </Menu.Item>
                                    </Menu.Menu>
                                </Menu>

                                <div className={"ui cards " + columnNumberAsText} ref={ref}>
                                    {userGroupFiltered.map((userGroup) => (
                                        <Card
                                            key={userGroup.name}
                                            as={Link}
                                            to={`/transversal_settings/rest_api_access/user_groups/${userGroup.id}`}
                                        >
                                            <Card.Content>
                                                <Card.Header>{userGroup.name}</Card.Header>
                                            </Card.Content>
                                        </Card>
                                    ))}
                                </div>
                            </>
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Label attached="bottom right" basic style={{border: "none", marginRight: "20px"}} size="large">
                        {userGroupFiltered.length + " " + t("User groups").toLowerCase()}
                    </Label>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
};

export default UserGroupList;
