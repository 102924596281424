import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import Validator, { ValidationErrors } from "validatorjs";
import inputsByStepHealthProductForm, {
    FormValidationType,
    PRODUCT_INFO_STEP_NAME,
    SPECIFIC_INFORMATION_STEP_NAME
} from "./InputsByStepHealthProductForm";

export const HealthProductFormContext = React.createContext<any>({});

export const HealthProductFormProvider: React.FC<{children: ReactElement | null}> = ({...props}) => {
    const {t} = useTranslation();
    const [state, setState] = useState({
        productCode: "",
        productLabel: "",
        principalCompanyCode: "",
        rangeId: undefined,
        brand: "",
        effectiveDate: "",
        monthlySplitting: true,
        quarterlySplitting: false,
        halfYearlySplitting: false,
        annualSplitting: false,
        defaultSplitting: "M",
        insurancePackages: [],
        questions: {
            numberZones: 0,
            healthPlanRG: false,
            healthPlanAM: false,
            healthPlanTNS: false,
            healthPlanEXP: false,
            healthPlanETU: false,
            assuranceSlices: [],
            assistanceSlices: [],
            renfortSlices: [],
            protectionJuridiqueSlices: [],
            santeClairSlices: [],
            fraisAssociationSlices: [],
            medecineDouceSlices: [],
        },
        options: [],
    });
    const [errors, setErrors] = useState<ValidationErrors | undefined>(undefined);

    const handleChange = (_: any, data: any) => {
        if (data.type === "checkbox") setState({...state, [data.name]: data.checked});
        else setState({...state, [data.name]: data.value});
    };

    const stateChange = (fieldName: string, data: any) => {
        setState({...state, [fieldName]: data});
    };

    const stepStateChange = (
        stepObjectName: "questions" | "insurancePackages" | "options",
        fieldName: string,
        data: any
    ) => {
        setState({...state, [stepObjectName]: {...state[stepObjectName], [fieldName]: data}});
    };

    const validate = (
        stepName: string,
        data: any = undefined,
        validationType: FormValidationType = FormValidationType.GLOBAL_VALIDATION
    ) => {
        if (errors !== undefined) setErrors(undefined);
        let rules: any = {};
        const inputsStep = inputsByStepHealthProductForm.find((input) => input.stepName === stepName)?.inputs;

        inputsStep?.forEach((input) => {
            if (input.validationType === validationType) {
                if (rules[input.field] === undefined) rules = Object.assign({[input.field]: input.rules}, rules);
                else rules = rules[input.field].push(input.rules);
            }
        });

        let validation = new Validator(data !== undefined ? data : state, rules);
        validation.setAttributeFormatter((attribute) =>
            t(inputsStep?.find((input) => input.field === attribute)?.name as string).toLocaleLowerCase()
        );
        
        let passes = validation.passes();
        let validationErrors = validation.errors.all();
        if (stepName === PRODUCT_INFO_STEP_NAME) {
            if (
                !state.monthlySplitting &&
                !state.halfYearlySplitting &&
                !state.quarterlySplitting &&
                !state.annualSplitting
            ) {
                validationErrors = Object.assign(
                    {splittings: ["No splitting authorized", "Please authorize at least one splitting"]},
                    validationErrors
                );
                passes = false;
            } else if (
                (state.defaultSplitting === "M" && !state.monthlySplitting) ||
                (state.defaultSplitting === "S" && !state.halfYearlySplitting) ||
                (state.defaultSplitting === "T" && !state.quarterlySplitting) ||
                (state.defaultSplitting === "A" && !state.annualSplitting)
            ) {
                validationErrors = Object.assign(
                    {
                        splittings: [
                            "Selected default splitting isn't authorized",
                            "You must select a splitting to be able to set it as default",
                        ],
                    },
                    validationErrors
                );
                passes = false;
            }
        }
        if (stepName === SPECIFIC_INFORMATION_STEP_NAME) {
            if (
                !data.healthPlanRG &&
                !data.healthPlanAM &&
                !data.healthPlanTNS &&
                !data.healthPlanEXP &&
                !data.healthPlanETU
            ) {
                validationErrors = Object.assign(
                    {healthPlan: [t("You must select at least one plan")]},
                    validationErrors
                );
                passes = false;
            }
        }
        setErrors(validationErrors);
        return passes;
    };

    const displayError = (fieldName: string) => {
        return errors && errors[fieldName] && errors[fieldName][0];
    };

    const resetErrors = () => errors !== undefined && setErrors(undefined);

    return (
        <HealthProductFormContext.Provider
            value={{
                state,
                handleChange,
                validate,
                inputsByStep: inputsByStepHealthProductForm,
                errors,
                displayError,
                resetErrors,
                stateChange,
                stepStateChange,
            }}>
            {props.children}
        </HealthProductFormContext.Provider>
    );
};
