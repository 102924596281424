export type BasicGridType = {
    id: number;
    label: string;
    code: string;
    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    createdOnVersionId: number;
};

export enum CellTypeEnum {
    DIM = "DIM",
    VAL = "VAL",
    DVAL = "DVAL",
    VIDE = "VIDE",
}

export type PricingGridCellType = {
    type: CellTypeEnum;
    colspan: number;
    id: number | null;
    value: string | number;
};

export type PricingGridLineType = {
    cells: Array<PricingGridCellType>;
};

export type PricingGridViewType = {
    code: string;
    label: string | null;
    lines: Array<PricingGridLineType>;
};

export type DimensionGridDTO = {
    id?: number;
    code: string;
    label: string;
    dimensionType: DimensionType;
    dimensionValueType: DimensionValueType;
    dimensionOrderType: DimensionOrderType;
    visualizationGridDTO: VisualizationGridDTO;
    dimensionGridValueDTOs: DimensionGridValueDTO[];
};

export type DimensionGridDTOWithId = {
    id: number;
    code: string;
    label: string;
    dimensionType: DimensionType;
    dimensionValueType: DimensionValueType;
    dimensionOrderType: DimensionOrderType;
    visualizationGridDTO: VisualizationGridDTO;
    dimensionGridValueDTOs: DimensionGridValueDTOWithId[];
};

export enum DimensionType {
    F = "F",
    T = "T",
}

export enum DimensionOrderType {
    VALEUR = "VALEUR",
    ORDRE= "ORDRE",
}

export enum DimensionValueType {
    INT = "INT",
    STRING = "STRING",
    DOUBLE = "DOUBLE",
    DATE = "DATE",
}

export type VisualizationGridDTO = {
    axe: Axe;
    rank: number;
};

export enum Axe {
    L = "L",
    C = "C",
}

export type DimensionGridValueDTO = {
    id?: number;
    value: string;
    min: number;
    max: number;
    minDate: string;
    maxDate: string;
    label: string;
    order: number;
};

export type DimensionGridValueDTOWithId = {
    id: number;
    value: string;
    min: number;
    max: number;
    minDate: string;
    maxDate: string;
    label: string;
    order: number;
};

export type PricingGridAddType = {
    code: string;
    label: string;
    dimensionGridDTOs: [];
    gridValueDTOs: [];
};

export type PricingGridFormType = {
    code: string | undefined;
    label: string | undefined;
    dimensionType: DimensionType | undefined;
    dimensionValueType: DimensionValueType | undefined;
    dimensionOrderType: DimensionOrderType | undefined;
    dimensionAxe: Axe | undefined;
}
