import {useTranslation} from "react-i18next";
import {Button, Divider, Grid} from "semantic-ui-react";
import {OfferType, PromotionalCampaign} from "../../../../Services/PromotionalCampagins/Types";
import {useDeletePromotionalCampaignMutation} from "../../../../Services/PromotionalCampagins/Queries";
import PromotionalCampaignFreeMonthDetailsRead from "./FreeMonth/PromotionalCampaignFreeMonthDetailsRead";
import ModalDelete from "../../../../Components/Modals/ModalDelete";
import {handleNullableProperty} from "../../../../Utils/TextUtils";

type PromotionalCampaignDetailsReadProps = {
    productId: string;
    promotionalCampaignId: string;
    promotionalCampaign: PromotionalCampaign;
    setEdit: Function;
};

const PromotionalCampaignDetailsRead: React.FC<PromotionalCampaignDetailsReadProps> = ({
    productId,
    promotionalCampaignId,
    promotionalCampaign,
    setEdit,
}) => {
    const {t} = useTranslation();

    const deletePromotionalCampaignMutation = useDeletePromotionalCampaignMutation(productId, promotionalCampaignId);
    const handleDelete = () => {
        deletePromotionalCampaignMutation.mutate(promotionalCampaignId);
    };

    return (
        <>
            <Grid columns="equal" verticalAlign="middle" className="grid-padding" style={{padding: "10px"}}>
                <Grid.Row style={{paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Code")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{promotionalCampaign.code}</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Label")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{handleNullableProperty(promotionalCampaign.label, t)}</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Description")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{handleNullableProperty(promotionalCampaign.description, t)}</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Start date")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{t("format_datetime", {value: promotionalCampaign.startDate})}</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("End date")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{t("format_datetime", {value: promotionalCampaign.endDate})}</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Offer type")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{t(`enums.OfferType.${promotionalCampaign.offerType}`)}</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Application mode")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        {t(`enums.ApplicationMode.${promotionalCampaign.applicationMode}`)}
                    </Grid.Column>
                </Grid.Row>

                {promotionalCampaign.offerType === OfferType.MOIS_GRATUIT && (
                    <PromotionalCampaignFreeMonthDetailsRead
                        productId={productId}
                        promotionalCampaignId={promotionalCampaignId}
                        promotionalCampaign={promotionalCampaign}
                    />
                )}
            </Grid>

            <Divider />
            <div className="bottom-button-card">
                <Button primary name="edit" onClick={() => setEdit(true)}>
                    {t("edit")}
                </Button>

                <ModalDelete
                    openModalButtonName="deletePromotionalCampaign"
                    isSuccess={deletePromotionalCampaignMutation.isSuccess}
                    isPending={deletePromotionalCampaignMutation.isPending}
                    isError={deletePromotionalCampaignMutation.isError}
                    error={deletePromotionalCampaignMutation.error}
                    resetMutation={deletePromotionalCampaignMutation.reset}
                    onValidate={handleDelete}
                    objectToDelete="Promotional campaign"
                    objectType="female"
                />
            </div>
        </>
    );
};

export default PromotionalCampaignDetailsRead;
