import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {UseMutationResult} from "@tanstack/react-query";
import {Params, useParams} from "react-router-dom";
import {
    Button,
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    Container,
    Divider,
    Dropdown,
    DropdownItemProps,
    Form,
    Grid,
    GridColumn,
    GridRow,
    Segment,
    Tab,
} from "semantic-ui-react";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import ModalDuplicate from "../../../../../Components/Modals/ModalDuplicate";
import ModalUpdate from "../../../../../Components/Modals/ModalUpdate";
import Sidebar, {SidebarItem, SidebarMenu} from "../../../../../Components/Sidebar/Sidebar";
import {useGetNotAssociatedRegistriesQuery} from "../../../../../Services/Company/Queries";
import {RegistryNotAssociatedDTO} from "../../../../../Services/Company/Types";
import {
    useDeleteOptionMutation,
    useDuplicateOptionMutation,
    useGetCoverageGroupsQuery,
    useGetCoveragesQuery,
    useGetFeeChargesQuery,
    useGetOptionQuery,
    useGetOptionsByGroupQuery,
    useUpdateOptionMutation,
} from "../../../../../Services/InsurancePackage/Queries";
import {CommissionsStateType, Option, OptionUpdateDTO} from "../../../../../Services/InsurancePackage/Types";
import {useGetProductBehaviourByRegistryIdQuery} from "../../../../../Services/ProductBehaviour/Queries";
import {CustomError} from "../../../../../Services/RequestUtils";
import {useGetVersionQuery} from "../../../../../Services/Version/Queries";
import {VersionDTO} from "../../../../../Services/Version/Types";
import getErrorMessage from "../../../../Global/Form/ErrorMessage";
import {codeValidation} from "../../../../Global/Form/Validation";
import ComputingRuleList from "../../../ComputingRule/ComputingRuleList";
import {CommissionsDisplayMode} from "../Coverages/CoverageCard";
import DerogatoriesTaxes from "../Coverages/DerogatoriesTaxes";
import UpdateDerogatoryCommissionRate from "../DerogatoryCommissionRate/UpdateDerogatoryCommissionRate";
import BreadcrumbOption from "./BreadcrumbOption";
import FormDuplicateOption from "./FormDuplicateOption";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
    coverageGroupId: string;
    optionId: string;
}

const OptionPage = ({...props}) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const [menus, setMenus] = useState<Array<SidebarMenu>>([]);

    const getVersionQuery = useGetVersionQuery(params.id, params.versionId);
    const getCoveragesGroupQuery = useGetCoverageGroupsQuery(params.insurancePackageId);
    const getCoveragesQuery = useGetCoveragesQuery(params.coverageGroupId);
    const getFeeChargesQuery = useGetFeeChargesQuery(params.insurancePackageId);
    const getOptionsByGroupQuery = useGetOptionsByGroupQuery(params.coverageGroupId);
    const getNotAssociatedRegistriesQuery = useGetNotAssociatedRegistriesQuery(params.insurancePackageId);
    const {
        data: option,
        status: getOptionQueryStatus,
        refetch: refetchOption,
        isFetching: isOptionFetching,
    } = useGetOptionQuery(params.coverageGroupId, params.optionId);

    const updateOptionMutation = useUpdateOptionMutation(
        params.id,
        params.versionId,
        params.insurancePackageId,
        params.coverageGroupId,
        params.optionId
    );
    const deleteOptionMutation = useDeleteOptionMutation(
        params.id,
        params.versionId,
        params.insurancePackageId,
        params.coverageGroupId
    );

    useEffect(
        () => {
            let sidebarMenus: Array<SidebarMenu> = [];

            if (getCoveragesQuery.isSuccess && getCoveragesQuery.data !== undefined) {
                const coveragesSidebarMenu = new SidebarMenu(
                    t("Coverages"),
                    getCoveragesQuery.data.map(
                        (coverageItem) =>
                            new SidebarItem(
                                coverageItem.coverageCode,
                                coverageItem.coverageCode,
                                `/products/${params.id}/versions/${params.versionId}/insurance-packages/${params.insurancePackageId}/coverages-groups/${params.coverageGroupId}/coverages/${coverageItem.id}`
                            )
                    )
                );
                sidebarMenus.push(coveragesSidebarMenu);
            }

            if (getOptionsByGroupQuery.isSuccess && getOptionsByGroupQuery.data !== undefined) {
                const optionsSidebarMenu = new SidebarMenu(
                    t("Options"),
                    getOptionsByGroupQuery.data.map(
                        (optionItem) =>
                            new SidebarItem(
                                optionItem.code,
                                optionItem.code,
                                `/products/${params.id}/versions/${params.versionId}/insurance-packages/${params.insurancePackageId}/coverages-groups/${params.coverageGroupId}/options/${optionItem.id}`
                            )
                    )
                );
                sidebarMenus.push(optionsSidebarMenu);
            }

            if (getFeeChargesQuery.isSuccess && getFeeChargesQuery.data !== undefined) {
                const feeChargesSidebarMenu = new SidebarMenu(
                    t("Fee"),
                    getFeeChargesQuery.data.map(
                        (feeChargeItem) =>
                            new SidebarItem(
                                feeChargeItem.code,
                                feeChargeItem.code,
                                `/products/${params.id}/versions/${params.versionId}/insurance-packages/${params.insurancePackageId}/fee-charges/${feeChargeItem.id}`
                            )
                    )
                );
                sidebarMenus.push(feeChargesSidebarMenu);
            }

            setMenus(sidebarMenus);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            getCoveragesQuery.isSuccess,
            getCoveragesQuery.data,
            getOptionsByGroupQuery.isSuccess,
            getOptionsByGroupQuery.data,
            getFeeChargesQuery.isSuccess,
            getFeeChargesQuery.data,
        ]
    );

    return (
        <>
            <Sidebar menus={menus} />
            {getOptionQueryStatus === "success" &&
                option !== undefined &&
                getVersionQuery.isSuccess &&
                getVersionQuery.data !== undefined &&
                getCoveragesGroupQuery.isSuccess &&
                getCoveragesGroupQuery.data !== undefined &&
                getNotAssociatedRegistriesQuery.isSuccess &&
                getNotAssociatedRegistriesQuery.data !== undefined && (
                    <>
                        <BreadcrumbOption option={option} sections={props.sections} />
                        <Container fluid>
                            <div className="main-container">
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width="16">
                                            <Segment className="card-container">
                                                <Tab
                                                    panes={[
                                                        {
                                                            menuItem: t("Coverage Informations"),
                                                            render: () => (
                                                                <OptionPageGenericsInfos
                                                                    option={option}
                                                                    version={getVersionQuery.data}
                                                                    coveragesGroupsOptions={getCoveragesGroupQuery.data.map(
                                                                        (coverageGroup) => {
                                                                            return {
                                                                                key: coverageGroup.id,
                                                                                value: coverageGroup.id,
                                                                                text: coverageGroup.label,
                                                                            };
                                                                        }
                                                                    )}
                                                                    coveragesOptions={getNotAssociatedRegistriesQuery.data
                                                                        .filter(
                                                                            (registry: RegistryNotAssociatedDTO) =>
                                                                                registry.type === "G"
                                                                        )
                                                                        .map((registry: RegistryNotAssociatedDTO) => {
                                                                            return {
                                                                                text: `${registry.label} (${registry.code})`,
                                                                                value: registry.registryId,
                                                                                key: registry.registryId,
                                                                            };
                                                                        })}
                                                                    updateOptionMutation={updateOptionMutation}
                                                                    deleteOptionMutation={deleteOptionMutation}
                                                                />
                                                            ),
                                                        },
                                                        {
                                                            menuItem: t("Computing rule"),
                                                            render: () => (
                                                                <ComputingRuleList
                                                                    computingRule={option.computingRule}
                                                                    version={getVersionQuery.data}
                                                                    refetch={refetchOption}
                                                                    isQueryStatusFetching={isOptionFetching}
                                                                    queryToInvalidate={
                                                                        {queryKey: ["getOption", {coverageGroupId: params.coverageGroupId, optionId: params.optionId}]}
                                                                    }
                                                                />
                                                            ),
                                                        },
                                                        {
                                                            menuItem: t("Derogatories Taxes"),
                                                            render: () => (
                                                                <DerogatoriesTaxes
                                                                    insurancePackageId={params.insurancePackageId}
                                                                    registryCode={option.registry.code}
                                                                    version={getVersionQuery.data}
                                                                />
                                                            ),
                                                        },
                                                    ]}
                                                />
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Container>
                    </>
                )}
        </>
    );
};

/* ----- OptionPageGenericsInfos ----- */
interface OptionPageGenericsInfosPropsType {
    option: Option;
    version: VersionDTO;
    coveragesGroupsOptions: Array<DropdownItemProps>;
    coveragesOptions: Array<DropdownItemProps>;

    updateOptionMutation: UseMutationResult<Option, CustomError, OptionUpdateDTO, Error>;
    deleteOptionMutation: UseMutationResult<void, CustomError, string, Error>;
}

const OptionPageGenericsInfos = ({
    option,
    version,
    coveragesGroupsOptions,
    coveragesOptions,

    updateOptionMutation,
    deleteOptionMutation,
}: OptionPageGenericsInfosPropsType) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const editOptionForm = useForm({mode: "onBlur"});

    const [editingMode, setEditingMode] = useState<boolean>(false);
    const [editOptionCode, setEditOptionCode] = useState<string>(option.code);
    const [editOptionLabel, setEditOptionLabel] = useState<string>(option.label);
    const [editOptionRegistry, setEditOptionRegistry] = useState<number | undefined>(option.registry.id);
    const [editGroupSelectedId, setEditGroupSelectedId] = useState<number | undefined>(option.coverageGroup.id);
    const [commissions, setCommissions] = useState<CommissionsStateType>({
        contribution: {
            initialRate: 0,
            currentValue: null,
            derogatoryChecked: false,
        },
        management: {
            initialRate: 0,
            currentValue: null,
            derogatoryChecked: false,
        },
    });

    const productBehaviorQueryResult = useGetProductBehaviourByRegistryIdQuery(params.id, editOptionRegistry);

    // Duplicate option
    const duplicateOptionMutation = useDuplicateOptionMutation(
        params.id,
        params.versionId,
        params.insurancePackageId,
        params.coverageGroupId,
        params.optionId
    );
    const duplicateOptionForm = useForm<{duplicated_option_code: string; duplicated_option_label: string}>();
    const sumbitduplicateOptionForm = (form: {duplicated_option_code: string; duplicated_option_label: string}) => {
        duplicateOptionMutation.mutate({
            code: form.duplicated_option_code,
            label: form.duplicated_option_label,
        });
    };
    const [duplicateOptionCode, setDuplicateOptionCode] = useState("");
    const [duplicateOptionLabel, setDuplicateOptionLabel] = useState("");
    const resetFormDuplicateProduct = () => {
        setDuplicateOptionCode("");
        setDuplicateOptionLabel("");
    };

    const handleUpdate = () => {
        updateOptionMutation.mutate({
            code: editOptionCode,
            label: editOptionLabel,
            registryId: editOptionRegistry,
            derogatoryManagementCommissionRate: commissions.management.derogatoryChecked
                ? commissions.management.currentValue
                : null,
            derogatoryContributionCommissionRate: commissions.contribution.derogatoryChecked
                ? commissions.contribution.currentValue
                : null,
            coverageGroupId: editGroupSelectedId,
            computingRule: option.computingRule,
        } as OptionUpdateDTO);
    };

    const isDerogatory = (currentValue: number | null, initialValue: number | null) => {
        if (currentValue === null || currentValue === initialValue) return false;
        else return true;
    };

    const handleModifyClick = () => {
        setEditingMode(true);

        setCommissions({
            contribution: {
                ...commissions.contribution,
                currentValue: option.derogatoryContributionCommissionRate,
                derogatoryChecked: isDerogatory(
                    option.derogatoryContributionCommissionRate,
                    commissions.contribution.initialRate
                ),
            },
            management: {
                ...commissions.management,
                currentValue: option.derogatoryManagementCommissionRate,
                derogatoryChecked: isDerogatory(
                    option.derogatoryManagementCommissionRate,
                    commissions.management.initialRate
                ),
            },
        });
    };

    useEffect(() => {
        if (productBehaviorQueryResult.isSuccess) {
            setCommissions((actualCommissions: CommissionsStateType) => {
                return {
                    contribution: {
                        ...actualCommissions.contribution,
                        initialRate: productBehaviorQueryResult.data.productRegistryJoin.contributionCommissionRate,
                    },
                    management: {
                        ...actualCommissions.management,
                        initialRate: productBehaviorQueryResult.data.productRegistryJoin.managementCommissionRate,
                    },
                };
            });
        }
    }, [productBehaviorQueryResult.isSuccess, productBehaviorQueryResult.data]);

    useEffect(() => {
        productBehaviorQueryResult.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editOptionRegistry]);

    return (
        <Tab.Pane style={{padding: "20px"}}>
            <Grid>
                <GridRow>
                    <GridColumn width={6}>
                        <Card>
                            {editingMode ? (
                                <CardContent>
                                    <Form>
                                        <Grid padded>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Controller
                                                        control={editOptionForm.control}
                                                        defaultValue={editOptionCode}
                                                        name={"edit-option-code"}
                                                        rules={{
                                                            required: true,
                                                            maxLength: 20,
                                                            pattern: codeValidation(),
                                                        }}
                                                        render={({field: {name, onChange, onBlur}}) => (
                                                            <Form.Input
                                                                fluid
                                                                type="text"
                                                                maxLength="20"
                                                                required
                                                                name={name}
                                                                label={t("Option code")}
                                                                placeholder={t("Code")}
                                                                onChange={(_, {value}) => {
                                                                    setEditOptionCode(value);
                                                                    onChange(value);
                                                                }}
                                                                onBlur={onBlur}
                                                                value={editOptionCode}
                                                                error={getErrorMessage(
                                                                    t,
                                                                    editOptionForm.formState.errors,
                                                                    name
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Controller
                                                        control={editOptionForm.control}
                                                        defaultValue={editOptionLabel}
                                                        name={"edit-option-label"}
                                                        rules={{required: true, maxLength: 90}}
                                                        render={({field: {name, onChange, onBlur}}) => (
                                                            <Form.Input
                                                                fluid
                                                                type="text"
                                                                maxLength="90"
                                                                labelPosition="left"
                                                                required
                                                                name={name}
                                                                label={t("Option label")}
                                                                placeholder={t("Label")}
                                                                onChange={(_, {value}) => {
                                                                    setEditOptionLabel(value);
                                                                    onChange(value);
                                                                }}
                                                                onBlur={onBlur}
                                                                value={editOptionLabel}
                                                                error={getErrorMessage(
                                                                    t,
                                                                    editOptionForm.formState.errors,
                                                                    name
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Form.Field>
                                                        <label>{t("Coverage")}</label>
                                                        <Dropdown
                                                            fluid
                                                            options={coveragesOptions}
                                                            selection
                                                            name="coverage"
                                                            onChange={(_, data) => {
                                                                setEditOptionRegistry(Number(data.value));
                                                            }}
                                                            defaultValue={editOptionRegistry}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>

                                            <UpdateDerogatoryCommissionRate
                                                control={editOptionForm.control}
                                                errors={editOptionForm.formState.errors}
                                                productBehaviourDTO={productBehaviorQueryResult.data}
                                                derogatoryCommissionRate={commissions}
                                                setDerogatoryCommissionRate={setCommissions}
                                            />

                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Form.Field>
                                                        <label>{t("Coverages group")}</label>
                                                        <Dropdown
                                                            fluid
                                                            options={coveragesGroupsOptions}
                                                            selection
                                                            name="coverageGroup"
                                                            onChange={(_, data) =>
                                                                setEditGroupSelectedId(Number(data.value))
                                                            }
                                                            defaultValue={editGroupSelectedId}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Form>

                                    <div className="bottom-button-card">
                                        <Divider style={{marginTop: "30px"}} />
                                        <Button name="cancel-option" secondary onClick={() => setEditingMode(false)}>
                                            {t("Cancel")}
                                        </Button>
                                        <ModalUpdate
                                            isModalDisabled={Object.keys(editOptionForm.formState.errors).length !== 0}
                                            isSuccess={updateOptionMutation.isSuccess}
                                            isPending={updateOptionMutation.isPending}
                                            isError={updateOptionMutation.isError}
                                            resetMutation={updateOptionMutation.reset}
                                            error={updateOptionMutation.error}
                                            onSuccess={() => setEditingMode(false)}
                                            onValidate={handleUpdate}
                                            objectToUpdate={"Option"}
                                            objectType={"nonbinary"}
                                            openModalButtonName={"update-option"}
                                        />
                                    </div>
                                </CardContent>
                            ) : (
                                <CardContent style={{padding: "25px"}}>
                                    <CardHeader>{option.label}</CardHeader>
                                    <Divider />
                                    <CardDescription>
                                        <Grid columns="equal" verticalAlign="middle" className="grid-padding">
                                            <Grid.Column width={8}>
                                                <strong>{t("Code")} : </strong>
                                            </Grid.Column>
                                            <Grid.Column width={8}>{option.code}</Grid.Column>

                                            <Grid.Column width={8}>
                                                <strong>{t("Coverage")} : </strong>
                                            </Grid.Column>
                                            <Grid.Column width={8}>{option.registry.code}</Grid.Column>

                                            <Grid.Column width={8}>
                                                <strong>{t("Derogatory commission rate")} : </strong>
                                            </Grid.Column>

                                            <Grid.Column width={8}>
                                                <div id="contribution">
                                                    <CommissionsDisplayMode
                                                        t={t}
                                                        name="Contribution"
                                                        initialRate={commissions.contribution.initialRate}
                                                        derogatoryRate={option.derogatoryContributionCommissionRate}
                                                    />
                                                </div>

                                                <div id="management">
                                                    <CommissionsDisplayMode
                                                        t={t}
                                                        name="Management"
                                                        initialRate={commissions.management.initialRate}
                                                        derogatoryRate={option.derogatoryManagementCommissionRate}
                                                    />
                                                </div>
                                            </Grid.Column>
                                        </Grid>
                                        {version.pipDate === null && (
                                            <div className="bottom-button-card">
                                                <Divider style={{marginTop: "30px"}} />
                                                <Button name="edit-option" color="blue" onClick={handleModifyClick}>
                                                    {t("edit")}
                                                </Button>

                                                <ModalDuplicate
                                                    isValidationDisabled={
                                                        Object.keys(duplicateOptionForm.formState.errors).length !== 0
                                                    }
                                                    isPending={duplicateOptionMutation.isPending}
                                                    isSuccess={duplicateOptionMutation.isSuccess}
                                                    isError={duplicateOptionMutation.isError}
                                                    error={duplicateOptionMutation.error}
                                                    resetMutation={duplicateOptionMutation.reset}
                                                    onCancel={resetFormDuplicateProduct}
                                                    onSuccess={resetFormDuplicateProduct}
                                                    onValidate={duplicateOptionForm.handleSubmit(
                                                        sumbitduplicateOptionForm
                                                    )}
                                                    objectToDuplicate="option"
                                                    objectToDuplicateCodeOrLabel={option.code}
                                                    objectToDuplicateContext="nonbinary"
                                                    renderModalContent={() => (
                                                        <FormDuplicateOption
                                                            errors={duplicateOptionForm.formState.errors}
                                                            control={duplicateOptionForm.control}
                                                            defaultValueCode={duplicateOptionCode}
                                                            setDefaultValueCode={setDuplicateOptionCode}
                                                            defaultValueLabel={duplicateOptionLabel}
                                                            setDefaultValueLabel={setDuplicateOptionLabel}
                                                        />
                                                    )}
                                                    renderOpenButton={() => {
                                                        return (
                                                            <Button name="duplicate-option" color="green">
                                                                {t("Duplicate")}
                                                            </Button>
                                                        );
                                                    }}
                                                />

                                                <ModalDelete
                                                    onValidate={() => deleteOptionMutation.mutate(params.optionId)}
                                                    objectToDelete={"Option"}
                                                    objectType={"nonbinary"}
                                                    openModalButtonName={"delete-option"}
                                                    isSuccess={deleteOptionMutation.isSuccess}
                                                    isPending={deleteOptionMutation.isPending}
                                                    isError={deleteOptionMutation.isError}
                                                    resetMutation={deleteOptionMutation.reset}
                                                />
                                            </div>
                                        )}
                                    </CardDescription>
                                </CardContent>
                            )}
                        </Card>
                    </GridColumn>
                </GridRow>
            </Grid>
        </Tab.Pane>
    );
};

export default OptionPage;
