import React, {useEffect, useState} from "react";
import {Control, Controller, DeepMap, FieldErrors} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import {Params, useParams} from "react-router-dom";
import {
    Button,
    Container,
    Divider,
    Dropdown,
    DropdownItemProps,
    Form,
    Grid,
    GridColumn,
    GridRow,
    Icon,
    Input,
    Label,
    Table,
} from "semantic-ui-react";
import {DimensionGridSearchValueType} from "../../../../../Services/Condition/Types";
import {useGetPricingCriteriasList} from "../../../../../Services/PricingCriterias/Queries";
import {PricingCriteriaItemType} from "../../../../../Services/PricingCriterias/Types";
import {getPricingGridDimensions} from "../../../../../Services/PricingGrid/AxiosRequests";
import {useGetAllGridQuery, useGetPricingGridDimensionsQuery} from "../../../../../Services/PricingGrid/Queries";
import {BasicGridType, DimensionValueType} from "../../../../../Services/PricingGrid/Types";
import {useGetSystemVariableList, useGetVariablesList} from "../../../../../Services/Variables/Queries";
import {VariableCondition, VariableType, VariableValueType} from "../../../../../Services/Variables/Types";
import {GenericReducerActionType} from "../../../../../Utils/ReducerUtils";
import {
    AssignmentDimensionType,
    AssignmentType,
    VariableConditionActionType,
} from "./VariableConditionAssignementReducer";

type VariableConditionGridSearchEditPropsType = {
    versionId: string;
    conditionState: VariableCondition;
    originalCondition: VariableCondition;

    dispatchCondition: React.Dispatch<VariableConditionActionType>;

    control: Control<any>;
    errors: DeepMap<any, FieldErrors>;
    setValue: (
        name: string,
        value: any,
        config?:
            | Partial<{
                  shouldValidate: boolean;
                  shouldDirty: boolean;
              }>
            | undefined
    ) => void;
    trigger: (name?: string | string[] | undefined) => Promise<boolean>;
    variableValueType: string;
};

interface IParams extends Params {
    id: string;
    versionId: string;
    variableId: string;
}

const VariableConditionGridSearchEdit = ({
    versionId,
    conditionState,
    originalCondition,

    dispatchCondition,

    control,
    errors,
    setValue,
    trigger,
    variableValueType,
}: VariableConditionGridSearchEditPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    const gridsQueryResult = useGetAllGridQuery(versionId);
    const [selectedGrid, setSelectedGrid] = useState<BasicGridType | undefined>();
    const {data: pricingCriteriasList} = useGetPricingCriteriasList(versionId, true);
    const {data: variableList} = useGetVariablesList(versionId, VariableType.V);
    const [mount, setMount] = useState<boolean>(false);
    const [defaultValueChecked, setDefaultValueChecked] = useState<boolean>(conditionState.defaultValue === null);

    const getDimensionsQuery = useQuery({
        queryKey: ["getPricingGridDimensions", selectedGrid?.id],
        queryFn: () => getPricingGridDimensions(String(selectedGrid?.id)),
        enabled: selectedGrid !== undefined,
    });

    const systemVariables = useGetSystemVariableList(params.versionId);

    const [systemVariablesOptions, setSystemVariablesOptions] = useState<DropdownItemProps[]>([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setOptionsVariables(), [systemVariables.data]);

    const setOptionsVariables = () => {
        if (systemVariables?.data) {
            const staticVariables = systemVariables.data.staticSystemVariable.map((systemVariable) => {
                return {
                    value: systemVariable.variableLabel,
                    key: systemVariable.variableName,
                    text: t(`enums.SystemVariable.${systemVariable.variableName}`),
                };
            });

            const dynamicVariables = systemVariables.data.dynamicSystemVariable.map((systemVariable) => {
                return {
                    value: systemVariable.variableLabel,
                    key: systemVariable.variableName,
                    text: systemVariable.variableName,
                };
            });

            setSystemVariablesOptions([...staticVariables, ...dynamicVariables]);
        }
    };

    useEffect(() => {
        if (
            selectedGrid !== undefined &&
            getDimensionsQuery.isSuccess &&
            (conditionState.variableCriterias.length === 0 || !mount)
        ) {
            dispatchCondition({
                type: GenericReducerActionType.EDIT,
                assignmentType: AssignmentType.GRID_SEARCH,
                payload: {
                    refGridCode: selectedGrid.code,
                    variableCriterias: getDimensionsQuery.data.map((dimension) => {
                        let variable = conditionState.variableCriterias.find(
                            (conditionState) => conditionState.refDimensionCode === dimension.code
                        );
                        if (variable !== undefined) {
                            return {
                                refDimensionCode: dimension.code,
                                value: variable.value !== undefined ? variable.value : null,
                                systemVariable: variable.systemVariable !== undefined ? variable.systemVariable : null,
                                pricingCriteriaName:
                                    variable.pricingCriteriaName !== undefined ? variable.pricingCriteriaName : null,
                                variableName: variable.variableName !== undefined ? variable.variableName : null,
                            };
                        } else {
                            return {
                                refDimensionCode: dimension.code,
                                value: null,
                                systemVariable: null,
                                pricingCriteriaName: null,
                                variableName: null,
                            };
                        }
                    }),
                    defaultValue: conditionState.defaultValue,
                },
            });

            setMount(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getDimensionsQuery.data]);

    useEffect(() => {
        if (
            selectedGrid === undefined &&
            gridsQueryResult.isSuccess &&
            gridsQueryResult.data.length > 0 &&
            conditionState !== null &&
            conditionState.refGridCode !== undefined &&
            conditionState.refGridCode !== null &&
            conditionState.refGridCode.length > 0
        ) {
            const newGrid = gridsQueryResult.data.find((grid) => grid.code === conditionState.refGridCode);
            setSelectedGrid(newGrid);
            if (newGrid !== undefined) {
                setValue("gridCodeDropdown", newGrid.code);
            }
        }
    }, [gridsQueryResult.isSuccess, gridsQueryResult.data, selectedGrid, conditionState, setValue]);

    const checkDimensionHaveAnyValue = (dimensionCode: string) => {
        const dimension = conditionState.variableCriterias.find(
            (variable) => variable.refDimensionCode === dimensionCode
        );
        return (
            (dimension?.value === null &&
                dimension?.systemVariable === null &&
                dimension?.pricingCriteriaName === null &&
                dimension?.variableName === null) ||
            dimension === undefined
        );
    };
    const checkDimensionIsFixedValueType = (dimensionCode: string) => {
        const dimension = conditionState.variableCriterias.find(
            (variable) => variable.refDimensionCode === dimensionCode
        );
        return (
            dimension?.value !== null &&
            dimension?.systemVariable === null &&
            dimension?.pricingCriteriaName === null &&
            dimension?.variableName === null
        );
    };
    const checkDimensionIsSystemVariableType = (dimensionCode: string) => {
        const dimension = conditionState.variableCriterias.find(
            (variable) => variable.refDimensionCode === dimensionCode
        );
        return (
            dimension?.value === null &&
            dimension?.systemVariable !== null &&
            dimension?.pricingCriteriaName === null &&
            dimension?.variableName === null
        );
    };
    const checkDimensionIsPricingCriteriaNameType = (dimensionCode: string) => {
        const dimension = conditionState.variableCriterias.find(
            (variable) => variable.refDimensionCode === dimensionCode
        );
        return (
            dimension?.value === null &&
            dimension?.systemVariable === null &&
            dimension?.pricingCriteriaName !== null &&
            dimension?.variableName === null
        );
    };
    const checkDimensionIsVariableType = (dimensionCode: string) => {
        const dimension = conditionState.variableCriterias.find(
            (variable) => variable.refDimensionCode === dimensionCode
        );
        return (
            dimension?.value === null &&
            dimension?.systemVariable === null &&
            dimension?.pricingCriteriaName === null &&
            dimension?.variableName !== null
        );
    };

    const getInputTypeBasedOnDimensionType = (dimensionValueType: DimensionValueType) => {
        switch (dimensionValueType) {
            case DimensionValueType.INT:
            case DimensionValueType.DOUBLE:
                return "number";
            case DimensionValueType.STRING:
                return "text";
            case DimensionValueType.DATE:
                return "date";
        }
    };

    return (
        <>
            {gridsQueryResult.isSuccess && gridsQueryResult.data !== undefined && (
                <Grid>
                    <GridRow>
                        <GridColumn width={10}>
                            <Controller
                                control={control}
                                defaultValue={selectedGrid !== undefined ? selectedGrid.code : ""}
                                rules={{required: true}}
                                name="gridCodeDropdown"
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <>
                                        <Container className="input">
                                            <Form.Select
                                                fluid
                                                name={name}
                                                style={{minWidth: "15em"}}
                                                placeholder={t("Select a grid")}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={(_, {name, value}) => {
                                                    onChange(value);
                                                    const selectedGrid = gridsQueryResult.data?.find(
                                                        (grid) => grid.code === value
                                                    );
                                                    if (selectedGrid !== undefined) {
                                                        dispatchCondition({
                                                            type: GenericReducerActionType.EDIT,
                                                            assignmentType: AssignmentType.GRID_SEARCH,
                                                            payload: {
                                                                refGridCode: selectedGrid.code,
                                                                variableCriterias: [],
                                                                defaultValue: conditionState.defaultValue,
                                                            },
                                                        });

                                                        setSelectedGrid(selectedGrid);
                                                        setValue(name, selectedGrid.code);
                                                        trigger(name);
                                                    }
                                                }}
                                                options={gridsQueryResult.data.map((grid) => {
                                                    return {key: grid.code, text: grid.code, value: grid.code};
                                                })}
                                            />
                                            <Button
                                                attached="right"
                                                name="delete"
                                                icon="delete"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    dispatchCondition({
                                                        type: GenericReducerActionType.REMOVE,
                                                        assignmentType: AssignmentType.GRID_SEARCH,
                                                        payload: {},
                                                    });
                                                }}
                                            />
                                        </Container>
                                        {errors.gridCodeDropdown && (
                                            <Label basic color="red" pointing="above">
                                                {t("this field is required")}
                                            </Label>
                                        )}
                                    </>
                                )}
                            />
                        </GridColumn>
                    </GridRow>
                </Grid>
            )}

            <Grid divided>
                <Grid.Row>
                    <GridColumn width={8}>
                        <Table fixed celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t("Dimension")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("Associated_value")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {getDimensionsQuery.data !== undefined &&
                                    selectedGrid &&
                                    conditionState !== null &&
                                    conditionState.variableCriterias !== null &&
                                    conditionState.variableCriterias.length > 0 &&
                                    getDimensionsQuery.data.map((dimension) => (
                                        <Table.Row key={dimension.code}>
                                            <Table.Cell>{dimension.code}</Table.Cell>
                                            <Table.Cell style={{overflow: "visible"}}>
                                                {/* Sélectionner le type de valeur associé sur une dimension dans la recherche de grille : valeur fixe ou critère tarifaire */}
                                                {checkDimensionHaveAnyValue(dimension.code) && (
                                                    <Controller
                                                        control={control}
                                                        rules={{
                                                            required: true,
                                                            validate: (value) =>
                                                                value !== AssignmentDimensionType.NOTHING,
                                                        }}
                                                        defaultValue={AssignmentDimensionType.NOTHING}
                                                        name={dimension.code + "_assignmentDimensionType"}
                                                        render={({field: {name, value, onBlur}}) => (
                                                            <Dropdown
                                                                fluid
                                                                name={name}
                                                                value={value}
                                                                floating={true}
                                                                placeholder={t("Select a type of associated value")}
                                                                onBlur={onBlur}
                                                                onChange={(_, {value}) => {
                                                                    const criteriaAlreadyExists = conditionState.variableCriterias.find(
                                                                        (criteria) =>
                                                                            criteria.refDimensionCode === dimension.code
                                                                    );
                                                                    if (!criteriaAlreadyExists)
                                                                        conditionState.variableCriterias.push({
                                                                            id: null,
                                                                            value: null,
                                                                            refDimensionCode: dimension.code,
                                                                            variableName: null,
                                                                            pricingCriteriaName: null,
                                                                            computingRule: null,
                                                                            systemVariable: null,
                                                                        });

                                                                    dispatchCondition({
                                                                        type: GenericReducerActionType.EDIT,
                                                                        assignmentType: AssignmentType.GRID_SEARCH,
                                                                        assignmentDimensionType: value as AssignmentDimensionType,
                                                                        payload: {
                                                                            conditionState: conditionState,
                                                                            dimensionCode: dimension.code,
                                                                            value:
                                                                                value ===
                                                                                AssignmentDimensionType.FIXED_VALUE
                                                                                    ? ""
                                                                                    : null,
                                                                            systemVariable:
                                                                                value ===
                                                                                AssignmentDimensionType.SYSTEM_VARIABLE
                                                                                    ? ""
                                                                                    : null,
                                                                            pricingCriteria:
                                                                                value ===
                                                                                AssignmentDimensionType.PRICING_CRITERIA
                                                                                    ? ""
                                                                                    : null,
                                                                            variable:
                                                                                value ===
                                                                                AssignmentDimensionType.VARIABLE
                                                                                    ? ""
                                                                                    : null,
                                                                        },
                                                                    });
                                                                }}
                                                                error={errors[name] ? true : false}
                                                                options={Object.keys(AssignmentDimensionType)
                                                                    .filter(
                                                                        (assignmentDimensionType) =>
                                                                            assignmentDimensionType !==
                                                                            AssignmentDimensionType.NOTHING
                                                                    )
                                                                    .map((assignmentDimensionType) => {
                                                                        return {
                                                                            value: assignmentDimensionType,
                                                                            key: assignmentDimensionType,
                                                                            text: t(
                                                                                "enums.AssignmentType." +
                                                                                    assignmentDimensionType
                                                                            ),
                                                                        };
                                                                    })}
                                                                selection
                                                            />
                                                        )}
                                                    />
                                                )}

                                                {/* Input en mode valeur fixe */}
                                                {checkDimensionIsFixedValueType(dimension.code) && (
                                                    <Controller
                                                        control={control}
                                                        rules={{required: true}}
                                                        defaultValue={
                                                            conditionState.variableCriterias.find(
                                                                (variable) =>
                                                                    variable.refDimensionCode === dimension.code
                                                            )?.value || ""
                                                        }
                                                        name={dimension.code + "_fixedValue"}
                                                        render={({field: {name, value, onChange, onBlur}}) => (
                                                            <Input
                                                                fluid
                                                                required
                                                                value={value}
                                                                name={name}
                                                                maxLength="100"
                                                                placeholder={t("Fixed value")}
                                                                type={getInputTypeBasedOnDimensionType(
                                                                    dimension.dimensionValueType
                                                                )}
                                                                step={
                                                                    dimension.dimensionValueType ===
                                                                    DimensionValueType.DOUBLE
                                                                        ? "0.01"
                                                                        : "1"
                                                                }
                                                                onBlur={onBlur}
                                                                onChange={(e, {value}) => {
                                                                    onChange(e);
                                                                    dispatchCondition({
                                                                        type: GenericReducerActionType.EDIT,
                                                                        assignmentType: AssignmentType.GRID_SEARCH,
                                                                        assignmentDimensionType:
                                                                            AssignmentDimensionType.FIXED_VALUE,
                                                                        payload: {
                                                                            conditionState: conditionState,
                                                                            dimensionCode: dimension.code,
                                                                            value: value,
                                                                        },
                                                                    });
                                                                    setValue(name, value);
                                                                    trigger(name);
                                                                }}
                                                                error={errors[name] ? true : false}
                                                                action>
                                                                <input />
                                                                <Button
                                                                    name={dimension.code + "_delete"}
                                                                    icon="delete"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        dispatchCondition({
                                                                            type: GenericReducerActionType.REMOVE,
                                                                            assignmentType: AssignmentType.GRID_SEARCH,
                                                                            assignmentDimensionType:
                                                                                AssignmentDimensionType.FIXED_VALUE,
                                                                            payload: {
                                                                                conditionState: conditionState,
                                                                                dimensionCode: dimension.code,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            </Input>
                                                        )}
                                                    />
                                                )}

                                                {/* Dropdown sélection d'une variable système à affecter */}
                                                {checkDimensionIsSystemVariableType(dimension.code) && (
                                                    <Controller
                                                        control={control}
                                                        rules={{required: true}}
                                                        defaultValue={
                                                            conditionState.variableCriterias.find(
                                                                (variable) =>
                                                                    variable.refDimensionCode === dimension.code
                                                            )?.systemVariable || ""
                                                        }
                                                        name={dimension.code + "_systemVariable"}
                                                        render={({field: {name, value, onChange, onBlur}}) => (
                                                            <Container className="input">
                                                                <Dropdown
                                                                    fluid
                                                                    required
                                                                    name={name}
                                                                    placeholder={t("Select entity", {
                                                                        entity: t("System variable").toLowerCase(),
                                                                        context: "female",
                                                                    })}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, {value}) => {
                                                                        onChange(e);
                                                                        dispatchCondition({
                                                                            type: GenericReducerActionType.EDIT,
                                                                            assignmentType: AssignmentType.GRID_SEARCH,
                                                                            assignmentDimensionType:
                                                                                AssignmentDimensionType.SYSTEM_VARIABLE,
                                                                            payload: {
                                                                                conditionState: conditionState,
                                                                                dimensionCode: dimension.code,
                                                                                systemVariable: value,
                                                                            },
                                                                        });
                                                                        setValue(name, value);
                                                                        trigger(name);
                                                                    }}
                                                                    options={systemVariablesOptions}
                                                                    error={errors[name] ? true : false}
                                                                    selection
                                                                />
                                                                <Button
                                                                    attached="right"
                                                                    name={dimension.code + "_delete"}
                                                                    icon="delete"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        dispatchCondition({
                                                                            type: GenericReducerActionType.REMOVE,
                                                                            assignmentType: AssignmentType.GRID_SEARCH,
                                                                            assignmentDimensionType:
                                                                                AssignmentDimensionType.SYSTEM_VARIABLE,
                                                                            payload: {
                                                                                conditionState: conditionState,
                                                                                dimensionCode: dimension.code,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            </Container>
                                                        )}
                                                    />
                                                )}

                                                {/* Dropdown sélection d'un critère tarifaire à affecter */}
                                                {checkDimensionIsPricingCriteriaNameType(dimension.code) &&
                                                    pricingCriteriasList && (
                                                        <Controller
                                                            control={control}
                                                            rules={{required: true}}
                                                            defaultValue={
                                                                conditionState.variableCriterias.find(
                                                                    (variable) =>
                                                                        variable.refDimensionCode === dimension.code
                                                                )?.pricingCriteriaName || ""
                                                            }
                                                            name={dimension.code + "_pricingCriteriaName"}
                                                            render={({field: {name, value, onChange, onBlur}}) => (
                                                                <>
                                                                    <Container className="input">
                                                                        <Dropdown
                                                                            fluid
                                                                            required
                                                                            name={name}
                                                                            placeholder={t("Select entity", {
                                                                                entity: t(
                                                                                    "Pricing criteria"
                                                                                ).toLowerCase(),
                                                                                context: "male",
                                                                            })}
                                                                            value={value}
                                                                            onBlur={onBlur}
                                                                            onChange={(e, {value}) => {
                                                                                onChange(e);
                                                                                dispatchCondition({
                                                                                    type: GenericReducerActionType.EDIT,
                                                                                    assignmentType:
                                                                                        AssignmentType.GRID_SEARCH,
                                                                                    assignmentDimensionType:
                                                                                        AssignmentDimensionType.PRICING_CRITERIA,
                                                                                    payload: {
                                                                                        conditionState: conditionState,
                                                                                        dimensionCode: dimension.code,
                                                                                        pricingCriteria: value,
                                                                                    },
                                                                                });
                                                                                setValue(name, value);
                                                                                trigger(name);
                                                                            }}
                                                                            options={pricingCriteriasList
                                                                                .filter((criteria) => {
                                                                                    return (
                                                                                        String(criteria.type) ===
                                                                                            PricingCriteriaItemType.STRING ||
                                                                                        String(criteria.type) ===
                                                                                            String(
                                                                                                dimension.dimensionValueType
                                                                                            ) ||
                                                                                        (criteria.type ===
                                                                                            PricingCriteriaItemType.NUMERIC &&
                                                                                            (dimension.dimensionValueType ===
                                                                                                DimensionValueType.INT ||
                                                                                                dimension.dimensionValueType ===
                                                                                                    DimensionValueType.DOUBLE))
                                                                                    );
                                                                                })
                                                                                .map((criteria) => {
                                                                                    return {
                                                                                        key: criteria.name,
                                                                                        text: criteria.name,
                                                                                        value: criteria.name,
                                                                                    };
                                                                                })}
                                                                            error={errors[name] ? true : false}
                                                                            selection
                                                                        />
                                                                        <Button
                                                                            attached="right"
                                                                            name={dimension.code + "_delete"}
                                                                            icon="delete"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                dispatchCondition({
                                                                                    type:
                                                                                        GenericReducerActionType.REMOVE,
                                                                                    assignmentType:
                                                                                        AssignmentType.GRID_SEARCH,
                                                                                    assignmentDimensionType:
                                                                                        AssignmentDimensionType.PRICING_CRITERIA,
                                                                                    payload: {
                                                                                        conditionState: conditionState,
                                                                                        dimensionCode: dimension.code,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </Container>
                                                                </>
                                                            )}
                                                        />
                                                    )}

                                                {/* Dropdown sélection d'une variable à affecter */}
                                                {checkDimensionIsVariableType(dimension.code) && variableList && (
                                                    <Controller
                                                        control={control}
                                                        rules={{required: true}}
                                                        defaultValue={
                                                            conditionState.variableCriterias.find(
                                                                (variable) =>
                                                                    variable.refDimensionCode === dimension.code
                                                            )?.variableName || ""
                                                        }
                                                        name={dimension.code + "_variableName"}
                                                        render={({field: {name, value, onChange, onBlur}}) => (
                                                            <>
                                                                <Container className="input">
                                                                    <Dropdown
                                                                        fluid
                                                                        required
                                                                        name={name}
                                                                        placeholder={t("Select entity", {
                                                                            entity: t("Variable").toLowerCase(),
                                                                            context: "female",
                                                                        })}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        onChange={(e, {value}) => {
                                                                            onChange(e);
                                                                            dispatchCondition({
                                                                                type: GenericReducerActionType.EDIT,
                                                                                assignmentType:
                                                                                    AssignmentType.GRID_SEARCH,
                                                                                assignmentDimensionType:
                                                                                    AssignmentDimensionType.VARIABLE,
                                                                                payload: {
                                                                                    conditionState: conditionState,
                                                                                    dimensionCode: dimension.code,
                                                                                    variable: value,
                                                                                },
                                                                            });
                                                                            setValue(name, value);
                                                                            trigger(name);
                                                                        }}
                                                                        options={variableList
                                                                            .filter((variable) => {
                                                                                return (
                                                                                    String(variable.valueType) ===
                                                                                        PricingCriteriaItemType.STRING ||
                                                                                    String(variable.valueType) ===
                                                                                        String(
                                                                                            dimension.dimensionValueType
                                                                                        ) ||
                                                                                    (variable.valueType ===
                                                                                        VariableValueType.NUMERIC &&
                                                                                        (dimension.dimensionValueType ===
                                                                                            DimensionValueType.INT ||
                                                                                            dimension.dimensionValueType ===
                                                                                                DimensionValueType.DOUBLE))
                                                                                );
                                                                            })
                                                                            .map((variable) => {
                                                                                return {
                                                                                    key: variable.name,
                                                                                    text: variable.name,
                                                                                    value: variable.name,
                                                                                };
                                                                            })}
                                                                        error={errors[name] ? true : false}
                                                                        selection
                                                                    />
                                                                    <Button
                                                                        attached="right"
                                                                        name={dimension.code + "_delete"}
                                                                        icon="delete"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            dispatchCondition({
                                                                                type: GenericReducerActionType.REMOVE,
                                                                                assignmentType:
                                                                                    AssignmentType.GRID_SEARCH,
                                                                                assignmentDimensionType:
                                                                                    AssignmentDimensionType.VARIABLE,
                                                                                payload: {
                                                                                    conditionState: conditionState,
                                                                                    dimensionCode: dimension.code,
                                                                                },
                                                                            });
                                                                        }}
                                                                    />
                                                                </Container>
                                                            </>
                                                        )}
                                                    />
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                            </Table.Body>
                        </Table>
                    </GridColumn>
                </Grid.Row>
            </Grid>

            <Grid columns="equal" verticalAlign="middle" className="grid-padding">
                <Grid.Row>
                    <GridColumn width={7}>
                        <strong>{t("Reject pricing if search is unsuccessful")} * : </strong>
                    </GridColumn>
                    <GridColumn width={9}>
                        <Controller
                            control={control}
                            name="reject"
                            defaultValue={defaultValueChecked}
                            render={({field: {onChange, value, name, onBlur}}) => (
                                <Form.Checkbox
                                    toggle
                                    name={name}
                                    checked={value}
                                    onBlur={onBlur}
                                    onChange={(_, {checked}) => {
                                        setDefaultValueChecked(checked !== undefined ? checked : false);
                                        onChange(checked);
                                        if (checked) {
                                            dispatchCondition({
                                                type: GenericReducerActionType.EDIT,
                                                assignmentType: AssignmentType.GRID_SEARCH,
                                                payload: {
                                                    refGridCode: conditionState.refGridCode,
                                                    variableCriterias: conditionState.variableCriterias,
                                                    defaultValue: null,
                                                },
                                            });
                                        }
                                    }}
                                />
                            )}
                        />
                    </GridColumn>

                    {!defaultValueChecked && (
                        <>
                            <GridColumn width={7}>
                                <strong>{t("Default value")} * : </strong>
                            </GridColumn>
                            <GridColumn width={3}>
                                <Controller
                                    control={control}
                                    defaultValue={conditionState.defaultValue}
                                    name={"defaultValue"}
                                    rules={{required: true}}
                                    render={({field: {name, value, onChange, onBlur}}) => (
                                        <Form.Input
                                            fluid
                                            required
                                            type={variableValueType}
                                            name={name}
                                            placeholder={t("Default value")}
                                            onChange={(e, {value}) => {
                                                onChange(e);
                                                dispatchCondition({
                                                    type: GenericReducerActionType.EDIT,
                                                    assignmentType: AssignmentType.GRID_SEARCH,
                                                    payload: {
                                                        refGridCode: conditionState.refGridCode,
                                                        variableCriterias: conditionState.variableCriterias,
                                                        defaultValue: value,
                                                    },
                                                });
                                                setValue(name, value);
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            maxLength="100"
                                            error={errors[name] ? true : false}
                                        />
                                    )}
                                />
                            </GridColumn>
                        </>
                    )}
                </Grid.Row>
            </Grid>

            <VariableConditionGridSearchOriginalView
                variableCondition={originalCondition}
                selectedGrid={selectedGrid}
                gridsQueryResult={gridsQueryResult.data}
            />
        </>
    );
};

type VariableConditionGridSearchOriginalViewPropsType = {
    variableCondition: VariableCondition;
    selectedGrid: BasicGridType | undefined;
    gridsQueryResult: BasicGridType[] | undefined;
};

const VariableConditionGridSearchOriginalView = React.memo<VariableConditionGridSearchOriginalViewPropsType>(
    ({variableCondition, selectedGrid, gridsQueryResult}) => {
        const {t} = useTranslation();
        const originalGridDimensionsQuery = useGetPricingGridDimensionsQuery(
            String(gridsQueryResult?.find((grid) => grid.code === variableCondition.refGridCode)?.id ?? 0),
            !!gridsQueryResult
        );

        const renderBodyContent = () => {
            if (
                originalGridDimensionsQuery !== undefined &&
                originalGridDimensionsQuery.data !== undefined &&
                originalGridDimensionsQuery.data.length >= variableCondition.variableCriterias.length
            ) {
                // Invalidation suite à l'ajout d'une ou plusieurs dimension(s)
                return originalGridDimensionsQuery.data.map((dimension) => {
                    const variableCriteria = variableCondition.variableCriterias?.find(
                        (variableCriteriaCDTO) => variableCriteriaCDTO.refDimensionCode === dimension.code
                    );
                    return (
                        <Table.Row key={dimension.code} error={variableCriteria === undefined}>
                            <Table.Cell>
                                {variableCriteria === undefined && <Icon name="attention" />}
                                {dimension.code}
                            </Table.Cell>
                            <Table.Cell>
                                {t(
                                    "enums.DimensionGridSearchValueType." +
                                        (variableCriteria?.value !== null
                                            ? DimensionGridSearchValueType.VALUE
                                            : variableCriteria?.systemVariable !== null
                                            ? DimensionGridSearchValueType.SOFTWARE_CRITERIA
                                            : variableCriteria?.pricingCriteriaName !== null
                                            ? DimensionGridSearchValueType.PRICING_CRITERIA
                                            : variableCriteria?.variableName !== null
                                            ? DimensionGridSearchValueType.VARIABLE
                                            : DimensionGridSearchValueType.VALUE)
                                )}
                            </Table.Cell>
                            <Table.Cell>
                                {variableCriteria?.value !== null
                                    ? variableCriteria?.value
                                    : variableCriteria?.systemVariable !== null
                                    ? t("enums.SystemVariable." + variableCriteria.systemVariable)
                                    : variableCriteria?.pricingCriteriaName !== null
                                    ? variableCriteria.pricingCriteriaName
                                    : variableCriteria?.variableName !== null
                                    ? variableCriteria.variableName
                                    : ""}
                            </Table.Cell>
                        </Table.Row>
                    );
                });
            } else if (
                originalGridDimensionsQuery.data !== undefined &&
                originalGridDimensionsQuery.data.length < variableCondition.variableCriterias.length
            ) {
                // Invalidation suite à la suppression d'une ou plusieurs dimension(s)
                return variableCondition.variableCriterias.map((variableCriteria) => {
                    const dimension = originalGridDimensionsQuery.data.find(
                        (dimension) => dimension.code === variableCriteria.refDimensionCode
                    );
                    return (
                        <Table.Row key={variableCriteria.refDimensionCode} error={dimension === undefined}>
                            <Table.Cell>
                                {dimension === undefined && <Icon name="attention" />}
                                {variableCriteria.refDimensionCode}
                            </Table.Cell>
                            <Table.Cell>
                                {t(
                                    "enums.DimensionGridSearchValueType." +
                                        (variableCriteria?.value !== null
                                            ? DimensionGridSearchValueType.VALUE
                                            : variableCriteria?.systemVariable !== null
                                            ? DimensionGridSearchValueType.SOFTWARE_CRITERIA
                                            : variableCriteria?.pricingCriteriaName !== null
                                            ? DimensionGridSearchValueType.PRICING_CRITERIA
                                            : variableCriteria?.variableName !== null
                                            ? DimensionGridSearchValueType.VARIABLE
                                            : DimensionGridSearchValueType.VALUE)
                                )}
                            </Table.Cell>
                            <Table.Cell>
                                {variableCriteria?.value !== null
                                    ? variableCriteria?.value
                                    : variableCriteria?.systemVariable !== null
                                    ? variableCriteria.systemVariable
                                    : variableCriteria?.pricingCriteriaName !== null
                                    ? variableCriteria.pricingCriteriaName
                                    : variableCriteria?.variableName !== null
                                    ? variableCriteria.variableName
                                    : ""}
                            </Table.Cell>
                        </Table.Row>
                    );
                });
            }
        };

        return (
            <>
                {((!variableCondition.valid && variableCondition.refGridCode === selectedGrid?.code) ||
                    variableCondition.refGridCode !== selectedGrid?.code) &&
                    originalGridDimensionsQuery.data !== undefined && (
                        <>
                            <Divider />
                            <p>
                                {t("Actual grid assignement") + " : "}
                                <strong>{variableCondition.refGridCode}</strong>
                            </p>

                            <Table fixed name="old_assignement">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{t("Dimension")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Associated_value_type")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Associated_value")}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>{renderBodyContent()}</Table.Body>
                            </Table>
                        </>
                    )}
            </>
        );
    }
);

export default VariableConditionGridSearchEdit;
