import {Control, Controller, DeepMap, FieldErrors} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid} from "semantic-ui-react";
import getErrorMessage from "../../../../Global/Form/ErrorMessage";

type FormDuplicateOptionPropsType = {
    control: Control<any>;
    errors: DeepMap<any, FieldErrors>;
    defaultValueCode: string;
    setDefaultValueCode: React.Dispatch<React.SetStateAction<string>>;
    defaultValueLabel: string;
    setDefaultValueLabel: React.Dispatch<React.SetStateAction<string>>;
};

const FormDuplicateOption = ({
    control,
    errors,
    defaultValueCode,
    setDefaultValueCode,
    defaultValueLabel,
    setDefaultValueLabel,
}: FormDuplicateOptionPropsType) => {
    const {t} = useTranslation();

    return (
        <Form id="modal-form">
            <Grid centered columns={2}>
                <Grid.Row centered>
                    <Grid.Column>
                        <Controller
                            control={control}
                            defaultValue={defaultValueCode}
                            rules={{required: true, maxLength: 20}}
                            name={"duplicated_option_code"}
                            render={({field: {name, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Code")}
                                    placeholder={t("Code")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueCode(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueCode}
                                    maxLength="20"
                                    error={getErrorMessage(t, errors, name, undefined, 20)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="duplicated_option_label"
                            defaultValue={defaultValueLabel}
                            rules={{required: true}}
                            render={({field: {name, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Label")}
                                    placeholder={t("Label")}
                                    onChange={(e, {value}) => {
                                        setDefaultValueLabel(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueLabel}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormDuplicateOption;
