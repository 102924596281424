import {useEffect, useState} from "react";
import {Control, Controller, DeepMap, FieldErrors} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Form, Grid} from "semantic-ui-react";
import getErrorMessage from "../../Pages/Global/Form/ErrorMessage";
import {useGetNotAssociatedRegistriesQuery} from "../../Services/Company/Queries";
import {RegistryNotAssociatedDTO} from "../../Services/Company/Types";
import {FeeType} from "../../Services/InsurancePackage/Types";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
}

type FormFeeChargePropsType = {
    control: Control<any>;
    errors: DeepMap<any, FieldErrors>;

    defaultFeeChargeRegistryId: number | undefined;
    setDefaultFeeChargeRegistryId: Function;
    defaultFeeChargeFeeType: FeeType | undefined;
    setDefaultFeeChargeFeeType: Function;
};

const FormFeeCharge = ({
    control,
    errors,
    defaultFeeChargeRegistryId,
    setDefaultFeeChargeRegistryId,
    defaultFeeChargeFeeType,
    setDefaultFeeChargeFeeType,
}: FormFeeChargePropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    const [feeChargeOptions, setFeeChargesOptions] = useState<any>([]);
    const getNotAssociatedRegistriesQuery = useGetNotAssociatedRegistriesQuery(params.insurancePackageId);

    useEffect(() => {
        if (
            getNotAssociatedRegistriesQuery.status === "success" &&
            getNotAssociatedRegistriesQuery.data !== undefined
        ) {
            setFeeChargesOptions(
                getNotAssociatedRegistriesQuery.data
                    .filter((registry: RegistryNotAssociatedDTO) => registry.type === "F")
                    .map((item: RegistryNotAssociatedDTO) => ({
                        text: `${item.label} (${item.code})`,
                        value: item.registryId,
                        key: item.registryId,
                    }))
            );
        }
    }, [getNotAssociatedRegistriesQuery.data, getNotAssociatedRegistriesQuery.status]);

    const handleSelect = (registrySelectedId: any, onChange: any) => {
        const feeCharge = getNotAssociatedRegistriesQuery.data?.find(
            (registry: RegistryNotAssociatedDTO) => registry.registryId === registrySelectedId
        );
        if (feeCharge !== undefined) {
            setDefaultFeeChargeRegistryId(registrySelectedId);
            onChange(registrySelectedId);
        }
    };

    return (
        <Form id="modal-form">
            <Grid columns={"equal"} verticalAlign={"middle"} className={"grid-padding"}>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Controller
                            control={control}
                            rules={{required: true}}
                            defaultValue={defaultFeeChargeRegistryId}
                            name={"form_fee_charge_fee_registry"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Select
                                    label={t("Choose a fee")}
                                    name={name}
                                    placeholder={t("Fee")}
                                    value={value}
                                    onChange={(_, {value}) => handleSelect(value, onChange)}
                                    onBlur={onBlur}
                                    error={getErrorMessage(t, errors, name)}
                                    options={feeChargeOptions}
                                />
                            )}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Controller
                            control={control}
                            rules={{required: true}}
                            defaultValue={defaultFeeChargeFeeType}
                            name={"form_fee_charge_fee_type"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Select
                                    label={t("Choose a fee type")}
                                    name={name}
                                    disabled={defaultFeeChargeRegistryId === undefined}
                                    onBlur={onBlur}
                                    placeholder={t("Fee")}
                                    onChange={(_, {value}) => {
                                        onChange(value);
                                        setDefaultFeeChargeFeeType(value);
                                    }}
                                    value={value}
                                    options={Object.keys(FeeType).map((feeType, index) => {
                                        return {
                                            value: feeType,
                                            key: index,
                                            text: t("enums.FeeType." + feeType),
                                        };
                                    })}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormFeeCharge;
