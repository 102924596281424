import {useMutation, UseMutationResult, useQuery, UseQueryResult} from "@tanstack/react-query";
import queryClient from "../../Config/queryClient";
import {CustomError} from "../RequestUtils";
import {addRejectionRule, deleteRejectionRule, getRejectionRules, updateRejectionRule} from "./AxiosRequests";
import {RejectionRule} from "./Types";

export const useGetRejectionRulesMutation = (versionId: string): UseQueryResult<Array<RejectionRule>, Error> => {
    return useQuery({
        queryKey: ["getRejectionRules", versionId],
        queryFn: () => getRejectionRules(versionId),
    });
};

export const useAddRejectionRuleMutation = (
    versionId: string
): UseMutationResult<RejectionRule, CustomError, RejectionRule, Error> => {
    return useMutation<RejectionRule, CustomError, RejectionRule, Error>({
        mutationFn: (rejectionRule: RejectionRule) => addRejectionRule(versionId, rejectionRule),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getRejectionRules", versionId]});
        },
    });
};

export const useUpdateRejectionRuleMutation = (
    versionId: string,
    rejectRuleId: number
): UseMutationResult<RejectionRule, CustomError, RejectionRule, Error> => {
    return useMutation<RejectionRule, CustomError, RejectionRule, Error>({
        mutationFn: (rejectionRule: RejectionRule) => updateRejectionRule(versionId, rejectRuleId, rejectionRule),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getRejectionRules", versionId]});
        },
    });
};

export const useDeleteRejectionRuleMutation = (
    versionId: string,
    rejectionRuleId: number
): UseMutationResult<void, CustomError, void, Error> => {
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteRejectionRule(versionId, rejectionRuleId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getRejectionRules", versionId]});
        },
    });
};

