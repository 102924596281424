import {DeepMap, FieldError} from "react-hook-form";

const getErrorMessage = (
    t: Function,
    errors: Record<string, {message: string; type: string}> | DeepMap<any, FieldError>,
    fieldName: string,
    minLength?: number,
    maxLength?: number,
    validateRuleErrorMessage?: string,
    min?: number,
    max?: number
) => {
    const errorMessageByType = (errorType: string, validateRuleErrorMessage: string | undefined) => {
        switch (errorType) {
            case "required":
                return t("this field is required");
            case "maxLength":
                return t("The field must contains max length", {max: maxLength});
            case "minLength":
                return t("The field must contains min length", {min: minLength});
            case "date":
                return t("The date must be in this format 01/01/2020");
            case "validate":
                return validateRuleErrorMessage ? t(validateRuleErrorMessage) : t("Rules are not satisfied");
            case "min":
                return t("The field must be greater than or equal to", {min: min});
            case "max":
                return t("The field must be less than or equal to", {max: max});
        }
    };

    const errorAsArray = () => {
        let fieldNameArray = fieldName.split(".");
        let fieldNameWithoutIndex = fieldNameArray[0];
        let index = fieldNameArray[1].replace(".", "");

        return (
            errors[fieldNameWithoutIndex] &&
            errors[fieldNameWithoutIndex][index] && {
                content:
                    t(errors[fieldNameWithoutIndex][index].message) ||
                    errorMessageByType(errors[fieldNameWithoutIndex][index].type, validateRuleErrorMessage),
            }
        );
    };

    return fieldName.match(/\w+\.\d+/)
        ? errorAsArray()
        : errors[fieldName] && {
              content:
                  t(errors[fieldName].message) || errorMessageByType(errors[fieldName].type, validateRuleErrorMessage),
          };
};

export default getErrorMessage;
