import {Fragment} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Grid, Loader, Message, Table} from "semantic-ui-react";
import ModalAdd from "../../../Components/Modals/ModalAdd";
import {
    useCreateESelectionMatrixMutation,
    useGetESelectionMatrixExportDTOQuery,
} from "../../Services/ESelection/Queries";
import {
    ESelectionDimensionType,
    ESelectionDimensionValueRecapDTO,
    ESelectionMatrixDTO,
    ESelectionMatrixExportDTOCell,
    ESelectionMatrixExportDTOCellType,
    ESelectionMatrixExportDTOLine,
} from "../../Services/ESelection/Types";
import FormESelection from "./Fragment/FormESelection";

type ESelectionMatrixProps = {
    productId: string;
};

const ESelectionMatrix = ({productId}: ESelectionMatrixProps) => {
    const {t} = useTranslation();

    const {data, isSuccess, isLoading, isError, error} = useGetESelectionMatrixExportDTOQuery(productId);
    const createESelectionMatrixMutation = useCreateESelectionMatrixMutation(productId);
    const createESelectionMatrixForm = useForm({mode: "onChange", shouldUnregister: true});
    const sumbitCreateESelectionMatrixForm = (form: any) => {
        let eSelectionMatrixDTO: ESelectionMatrixDTO = {
            eSelectionDimensionDTOs: [],
            eSelectionValueDTOs: [],
        };

        const DCIsActive = form["dc-is-active"];
        const IJIsActive = form["ij-is-active"];
        const FICTIVEIsActive = form["fictive-is-active"];

        eSelectionMatrixDTO.eSelectionDimensionDTOs.push({
            type: ESelectionDimensionType.AGE,
            eSelectionDimensionValueDTOs: form[ESelectionDimensionType.AGE],
        });

        if (DCIsActive) {
            eSelectionMatrixDTO.eSelectionDimensionDTOs.push({
                type: ESelectionDimensionType.DC,
                eSelectionDimensionValueDTOs: form[ESelectionDimensionType.DC],
            });
        }

        if (IJIsActive) {
            eSelectionMatrixDTO.eSelectionDimensionDTOs.push({
                type: ESelectionDimensionType.IJ,
                eSelectionDimensionValueDTOs: form[ESelectionDimensionType.IJ],
            });
        }

        if (FICTIVEIsActive) {
            eSelectionMatrixDTO.eSelectionDimensionDTOs.push({
                type: ESelectionDimensionType.FICTIVE,
                eSelectionDimensionValueDTOs: form[ESelectionDimensionType.FICTIVE],
            });
        }

        const ageSlicesSize = form["age-slices-size"];
        const amountSlicesSize = form["amount-slices-size"];

        for (let i = 0; i < ageSlicesSize; i++) {
            for (let j = 0; j < amountSlicesSize; j++) {
                let recap: Array<ESelectionDimensionValueRecapDTO> = [];

                recap.push({
                    type: ESelectionDimensionType.AGE,
                    min: form[ESelectionDimensionType.AGE][i].min,
                    max: form[ESelectionDimensionType.AGE][i].max,
                    rank: null,
                });

                if (DCIsActive) {
                    recap.push({
                        type: ESelectionDimensionType.DC,
                        min: form[ESelectionDimensionType.DC][j].min,
                        max: form[ESelectionDimensionType.DC][j].max,
                        rank: null,
                    });
                }

                if (IJIsActive) {
                    recap.push({
                        type: ESelectionDimensionType.IJ,
                        min: form[ESelectionDimensionType.IJ][j].min,
                        max: form[ESelectionDimensionType.IJ][j].max,
                        rank: null,
                    });
                }

                if (FICTIVEIsActive) {
                    recap.push({
                        type: ESelectionDimensionType.FICTIVE,
                        min: form[ESelectionDimensionType.FICTIVE][j].min,
                        max: form[ESelectionDimensionType.FICTIVE][j].max,
                        rank: null,
                    });
                }

                eSelectionMatrixDTO.eSelectionValueDTOs.push({
                    id: null,
                    value: "",
                    eSelectionDimensionValueRecapDTOs: recap,
                });
            }
        }

        createESelectionMatrixMutation.mutate(eSelectionMatrixDTO);
    };

    const setCSSstyle = (cellType: ESelectionMatrixExportDTOCellType) => {
        switch (cellType) {
            case ESelectionMatrixExportDTOCellType.DIM:
                return {backgroundColor: "#EDEDED", fontWeight: "bold"};
            case ESelectionMatrixExportDTOCellType.VAL:
                return {backgroundColor: "white"};
            case ESelectionMatrixExportDTOCellType.DVAL:
                return {backgroundColor: "#F3F4F5"};
            case ESelectionMatrixExportDTOCellType.VIDE:
                return {background: "white", border: "none"};
        }
    };

    return (
        <Fragment>
            {isLoading && (
                <Loader active inline="centered" size="medium">
                    {t("Loading")}
                </Loader>
            )}

            {isSuccess && data && (
                <Table compact celled data-cy="e-selection-grid">
                    <Table.Body>
                        {data.lines.map((row: ESelectionMatrixExportDTOLine, rowIndex: number) => (
                            <Table.Row textAlign={"center"} key={`row-${rowIndex}`} data-cy={`row-${rowIndex}`}>
                                {row.cells.map((cell: ESelectionMatrixExportDTOCell, columnIndex: number) => (
                                    <Table.Cell
                                        key={`cell-${rowIndex}-${columnIndex}`}
                                        data-cy={`cell-${rowIndex}-${columnIndex}`}
                                        colSpan={cell.colspan}
                                        style={setCSSstyle(cell.type)}>
                                        {cell.value}
                                    </Table.Cell>
                                ))}
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )}

            {isError && error !== null && error.message === "eselection_matrix_not_found" && (
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Message warning>
                                <Message.Header>{t("No eSelection grid")}</Message.Header>
                                <Message.Content>{t("There is no eSelection grid for this product")}</Message.Content>
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{paddingTop: "0"}}>
                        <Grid.Column textAlign="center">
                            <ModalAdd
                                isPending={createESelectionMatrixMutation.isPending}
                                isSuccess={createESelectionMatrixMutation.isSuccess}
                                isError={createESelectionMatrixMutation.isError}
                                error={createESelectionMatrixMutation.error}
                                resetMutation={createESelectionMatrixMutation.reset}
                                objectToAdd="eSelection grid"
                                objectToAddContext="female"
                                onValidate={createESelectionMatrixForm.handleSubmit(sumbitCreateESelectionMatrixForm)}
                                onCancel={() => {}}
                                onSuccess={() => {}}
                                renderModalContent={() => (
                                    <FormESelection
                                        control={createESelectionMatrixForm.control}
                                        errors={createESelectionMatrixForm.formState.errors}
                                        getValues={createESelectionMatrixForm.getValues}
                                        trigger={createESelectionMatrixForm.trigger}
                                        defaultValues={{
                                            dimensions: [
                                                {
                                                    type: ESelectionDimensionType.AGE,
                                                    eSelectionDimensionValueDTOs: [],
                                                },
                                                {
                                                    type: ESelectionDimensionType.DC,
                                                    eSelectionDimensionValueDTOs: [],
                                                },
                                                {
                                                    type: ESelectionDimensionType.IJ,
                                                    eSelectionDimensionValueDTOs: [],
                                                },
                                                {
                                                    type: ESelectionDimensionType.FICTIVE,
                                                    eSelectionDimensionValueDTOs: [],
                                                },
                                            ],
                                            age_slices_size: undefined,
                                            amount_slices_size: undefined,
                                            dc_is_active: true,
                                            ij_is_active: true,
                                            fictive_is_active: true,
                                        }}
                                    />
                                )}
                                scrolling
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </Fragment>
    );
};

export default ESelectionMatrix;
