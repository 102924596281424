import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import { Container, Grid } from "semantic-ui-react";
import Sidebar, { SidebarItem, SidebarMenu } from "../../../Components/Sidebar/Sidebar";
import { useGetSelfCompanyQuery, useGetUsersQuery } from "../../../Services/Company/Queries";
import UserDetails from "./Fragments/UserDetails";

interface IParams extends Params {
    userId: string;
}

const UserPage = () => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    const [menus, setMenus] = useState<Array<SidebarMenu>>([]);

    const getSelfCompanyQuery = useGetSelfCompanyQuery();
    const getUsersQuery = useGetUsersQuery(
        String(getSelfCompanyQuery?.data?.id),
        getSelfCompanyQuery?.data !== undefined
    );

    useEffect(() => {
        if (getSelfCompanyQuery.data !== undefined && getUsersQuery.data !== undefined) {
            const sidebarItems = getUsersQuery.data.map(
                (user) => new SidebarItem(user.username, user.username, `/transversal_settings/users/${user.id}`)
            );
            setMenus([new SidebarMenu("", sidebarItems)]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUsersQuery.data]);

    return (
        <Container fluid>
            <Sidebar title={`${t("users")}`} menus={menus} />
            <div className="main-container">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            {getSelfCompanyQuery.data !== undefined && (
                                <UserDetails companyId={String(getSelfCompanyQuery.data.id)} userId={params.userId} />
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    );
};

export default UserPage;
