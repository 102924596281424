import React from "react";
import {useTranslation} from "react-i18next";
import {SemanticICONS} from "semantic-ui-react";
import {CustomError} from "../../Services/RequestUtils";
import CustomModal from "./CustomModal";

type ModalDuplicateType = {
    isPending: boolean;
    isSuccess: boolean;
    isError: boolean;
    error?: CustomError | null;
    resetMutation?: () => void;

    onCancel?: () => void;
    onClose?: () => void;
    onSuccess?: () => void;
    onValidate: () => void;

    objectToDuplicate: string;
    objectToDuplicateContext: "male" | "female" | "nonbinary";
    objectToDuplicateCodeOrLabel: string;

    isModalDisabled?: boolean;
    isValidationDisabled?: boolean;
    openModalButtonName?: string;
    iconOnOpenButton?: boolean;
    openButtonIcon?: SemanticICONS;

    renderModalContent: () => JSX.Element;
    renderOpenButton: () => JSX.Element;
};

const ModalDuplicate: React.FC<ModalDuplicateType> = ({
    isPending,
    isSuccess,
    isError,
    error = null,
    resetMutation,

    onCancel,
    onClose,
    onSuccess,
    onValidate,

    objectToDuplicate,
    objectToDuplicateContext = "male",
    objectToDuplicateCodeOrLabel,

    isModalDisabled,
    isValidationDisabled,
    openModalButtonName = "duplicate",
    iconOnOpenButton = false,
    openButtonIcon,

    renderModalContent,
    renderOpenButton,
}) => {
    const {t} = useTranslation();

    return (
        <CustomModal
            openModalButtonName={openModalButtonName}
            iconOnOpenButton={iconOnOpenButton}
            openButtonIcon={openButtonIcon}
            openModalButtonText={t("New entity", {
                entity: t(objectToDuplicate).toLowerCase(),
                context: objectToDuplicateContext,
            })}
            openModalButtonColor="blue"
            cancelButtonColor="black"
            cancelButtonText={t("Cancel")}
            validateButtonColor="blue"
            validateButtonText={t("Validate")}
            loadingText={t("Duplication of the entity this action can take a few minutes", {
                name: t(objectToDuplicate).toLowerCase(),
                context: objectToDuplicateContext,
            })}
            errorText={t("An error occurred during the duplication")}
            modalHeader={t("Duplicate_entity", {
                entityType: t(objectToDuplicate, {count: 1}).toLowerCase(),
                name: objectToDuplicateCodeOrLabel,
                context: objectToDuplicateContext,
            })}
            renderModalContent={renderModalContent}
            renderOpenButton={renderOpenButton}
            isModalDisabled={isModalDisabled}
            isValidationDisabled={isValidationDisabled}
            isPending={isPending}
            isSuccess={isSuccess}
            isError={isError}
            resetMutation={resetMutation}
            onCancel={onCancel}
            onClose={onClose}
            onSuccess={onSuccess}
            onValidate={onValidate}
            error={error}
            returnToFormStepIfError={true}
        />
    );
};

export default ModalDuplicate;
