import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import { Container } from "semantic-ui-react";
import Description from "../../../../../Components/Description/Description";
import Sidebar, { SidebarItem, SidebarMenu } from "../../../../../Components/Sidebar/Sidebar";
import { useGetDeductibleCapital, useGetDeductibleCapitals, useUpdateDeductibleCapital } from "../../../../../Services/DeductibleCapital/Queries";
import { DeductibleCapital, DeductibleCapitalType } from "../../../../../Services/DeductibleCapital/Types";
import { useGetVersionQuery } from "../../../../../Services/Version/Queries";
import BreadcrumbDeductibleCapital from "./BreadcrumbDeductibleCapital";
import DeductibleCapitalList from "./DeductibleCapitalList";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
    deductibleCapitalId: string;
}

const DeductibleCapitalPage = ({...props}) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();
    const updateDeductibleCapitalForm = useForm<DeductibleCapital>();

    const versionRequest = useGetVersionQuery(params.id, params.versionId);

    const deductibleCapitalsRequest = useGetDeductibleCapitals(params.insurancePackageId);
    const deductibleCapitalRequest = useGetDeductibleCapital(params.insurancePackageId, params.deductibleCapitalId);
    const updateDeductibleCapital = useUpdateDeductibleCapital(params.insurancePackageId, params.deductibleCapitalId);

    const franchiseMenu = deductibleCapitalsRequest.data
        ?.filter((el) => el.type === DeductibleCapitalType.F)
        .map(
            (franchise) =>
                new SidebarItem(
                    franchise.label,
                    franchise.label,
                    `/products/${params.id}/versions/${params.versionId}/insurance-packages/${params.insurancePackageId}/deductible-capitals/${franchise.id}`
                )
        );

    const capitalMenu = deductibleCapitalsRequest.data
        ?.filter((el) => el.type === DeductibleCapitalType.C)
        .map(
            (capital) =>
                new SidebarItem(
                    capital.label,
                    capital.label,
                    `/products/${params.id}/versions/${params.versionId}/insurance-packages/${params.insurancePackageId}/deductible-capitals/${capital.id}`
                )
        );

    const handleUpdateDeductibleCapitalDescription = (formData: {description: string}) => {
        const updatedDeductibleCapital = {
            ...deductibleCapitalRequest.data,
            deductibleCapitalConditionDTOs: deductibleCapitalRequest.data?.deductibleCapitalConditions.map(
                (condition) => {
                    return {
                        ...condition,
                        deductibleCapitalConditionElementDTOs: condition.deductibleCapitalConditionElements,
                    };
                }
            ),
            description: formData.description,
        };
        updateDeductibleCapital.mutate(updatedDeductibleCapital);
    };

    return (
        <Container fluid>
            {franchiseMenu !== undefined && capitalMenu !== undefined && (
                <Sidebar
                    title={t("Deductible capitals")}
                    menus={[
                        new SidebarMenu(t("Deductible"), franchiseMenu),
                        new SidebarMenu(t("Capital"), capitalMenu),
                    ]}
                />
            )}

            {deductibleCapitalRequest.data && versionRequest.data !== undefined && versionRequest.isSuccess && (
                <BreadcrumbDeductibleCapital
                    deductibleCapital={deductibleCapitalRequest.data}
                    version={versionRequest.data}
                    sections={props.sections}
                />
            )}

            <div className="main-container">
                {deductibleCapitalRequest !== undefined &&
                    deductibleCapitalRequest.data &&
                    deductibleCapitalRequest && (
                        <>
                            <Description
                                entityName="Deductible capitals"
                                entityType="female"
                                description={deductibleCapitalRequest.data.description || ""}
                                version={versionRequest.data}
                                form={updateDeductibleCapitalForm}
                                entityMutation={updateDeductibleCapital}
                                handleEntityUpdate={handleUpdateDeductibleCapitalDescription}
                            />

                            <DeductibleCapitalList
                                version={versionRequest.data}
                                deductibleCapital={deductibleCapitalRequest.data}
                            />
                        </>
                    )}
            </div>
        </Container>
    );
};

export default DeductibleCapitalPage;
