import {AccordionTitle, Icon, Placeholder, PlaceholderHeader, PlaceholderLine} from "semantic-ui-react";

type ConditionPlaceholderType = {
    refreshData: () => void;
    numberOfConditions?: number;
};

const ConditionPlaceholder = ({refreshData, numberOfConditions}: ConditionPlaceholderType) => {
    refreshData();

    const render = () => {
        return Array.from(Array(numberOfConditions ?? 3), (index) => {
            return (
                <AccordionTitle style={{display: "flex", alignItems: "center"}} key={index}>
                    <Icon name="dropdown" />
                    <Placeholder style={{width: "100%"}}>
                        <PlaceholderHeader>
                            <PlaceholderLine length="full" />
                        </PlaceholderHeader>
                    </Placeholder>
                </AccordionTitle>
            );
        });
    };

    return <>{render()}</>;
};

export default ConditionPlaceholder;
