import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Tab} from "semantic-ui-react";
import FormPromotionalCampaign from "../../../../Components/Forms/FormPromotionalCampaign";
import ModalAdd from "../../../../Components/Modals/ModalAdd";
import SimpleList from "../../../../Components/SimpleList/SimpleList";
import {DataTypeColumnEnum} from "../../../../Components/SimpleList/SimpleListTypes";
import {
    useAddPromotionalCampaignMutation,
    useGetPromotionalCampaignsQuery,
} from "../../../../Services/PromotionalCampagins/Queries";
import {PromotionalCampaign} from "../../../../Services/PromotionalCampagins/Types";

type PromotionalCampaignTabPaneProps = {
    productId: string;
};

const promotionalCampaignsTableColumns = [
    {
        text: "Code",
        dataField: "code",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Start date",
        dataField: "startDate",
        type: DataTypeColumnEnum.DATETIME,
    },
    {
        text: "End date",
        dataField: "endDate",
        type: DataTypeColumnEnum.DATETIME,
    },
    {
        text: "Offer type",
        dataField: "offerType",
        type: DataTypeColumnEnum.ENUM,
    },
    {
        text: "Application mode",
        dataField: "applicationMode",
        type: DataTypeColumnEnum.ENUM,
    },
    {
        text: "",
        dataField: "/products/:productId/promotional_campaigns/:id",
        type: DataTypeColumnEnum.LINK,
    },
];

const PromotionalCampaignTabPane: React.FC<PromotionalCampaignTabPaneProps> = ({productId}) => {
    const getPromotionalCampaigns = useGetPromotionalCampaignsQuery(productId);

    const addPromotionalCampaignMutation = useAddPromotionalCampaignMutation(productId);
    const addPromotionalCampaignForm = useForm<PromotionalCampaign>();
    const [defaultValueAddPromotionalCampaignForm, setDefaultValueAddPromotionalCampaignForm] =
        useState<PromotionalCampaign>({} as PromotionalCampaign);
    const submitAddPromotionalCampaignForm = (formData: PromotionalCampaign) => {
        if (Object.keys(addPromotionalCampaignForm.formState.errors).length === 0) {
            addPromotionalCampaignMutation.mutate({
                ...formData,
                label: formData.label && formData.label.length > 0 ? formData.label : undefined,
                description:
                    formData.description && formData.description?.length > 0 ? formData.description : undefined,
            });
        }
    };

    const resetForm = () => {
        addPromotionalCampaignForm.reset();
        setDefaultValueAddPromotionalCampaignForm({} as PromotionalCampaign);
    };

    return (
        <Tab.Pane style={{overflowX: "auto"}}>
            <SimpleList
                tableName="promotional_campaigns"
                columns={promotionalCampaignsTableColumns}
                globalData={[{key: "productId", value: productId}]}
                rows={
                    getPromotionalCampaigns.isSuccess && getPromotionalCampaigns.data
                        ? getPromotionalCampaigns.data
                        : []
                }
                isLoading={getPromotionalCampaigns.isLoading}
            />
            <div className="bottom-button-card">
                <ModalAdd
                    isValidationDisabled={Object.keys(addPromotionalCampaignForm.formState.errors).length !== 0}
                    isPending={addPromotionalCampaignMutation.isPending}
                    isSuccess={addPromotionalCampaignMutation.isSuccess}
                    isError={addPromotionalCampaignMutation.isError}
                    resetMutation={addPromotionalCampaignMutation.reset}
                    error={addPromotionalCampaignMutation.error}
                    onValidate={addPromotionalCampaignForm.handleSubmit(submitAddPromotionalCampaignForm)}
                    onSuccess={resetForm}
                    onCancel={resetForm}
                    objectToAdd="Promotional campaign"
                    objectToAddContext="female"
                    openModalButtonName="add-promotional-campaign"
                    renderModalContent={() => (
                        <FormPromotionalCampaign
                            control={addPromotionalCampaignForm.control}
                            errors={addPromotionalCampaignForm.formState.errors}
                            defaultValueAddPromotionalCampaignForm={defaultValueAddPromotionalCampaignForm}
                            setDefaultValueAddPromotionalCampaignForm={setDefaultValueAddPromotionalCampaignForm}
                        />
                    )}
                />
            </div>
        </Tab.Pane>
    );
};

export default PromotionalCampaignTabPane;
