import {UseQueryResult, useMutation, UseMutationResult, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import queryClient from "../../Config/queryClient";
import {CustomError} from "../RequestUtils";
import {
    addProductRetrocession,
    deleteProductRetrocession,
    getProductRetrocession,
    getProductRetrocessions,
    updateProductRetrocession,
} from "./AxiosRequests";
import {ProductRetrocession} from "./Types";

export const useGetProductRetrocessionsQuery = (
    productId: string
): UseQueryResult<Array<ProductRetrocession>, Error> => {
    return useQuery({
        queryKey: ["getProductRetrocessions", {productId}],
        queryFn: () => getProductRetrocessions(productId),
    });
};

export const useGetProductRetrocessionQuery = (
    productId: string,
    retrocessionId: string
): UseQueryResult<ProductRetrocession, Error> => {
    return useQuery({
        queryKey: ["getProductRetrocession", {productId, retrocessionId}],
        queryFn: () => getProductRetrocession(productId, retrocessionId),
    });
};

export const useAddProductRetrocessionsMutation = (
    productId: string
): UseMutationResult<ProductRetrocession, CustomError, ProductRetrocession, Error> => {
    const navigate = useNavigate();
    return useMutation<ProductRetrocession, CustomError, ProductRetrocession, Error>({
        mutationFn: (productRetrocession: ProductRetrocession) =>
            addProductRetrocession(productId, productRetrocession),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["getProductRetrocessions", {productId}]});
            navigate(`/products/${productId}/retrocessions/${result.id}`);
        },
    });
};

export const useUpdateProductRetrocessionMutation = (
    productId: string,
    retrocessionId: string
): UseMutationResult<ProductRetrocession, CustomError, ProductRetrocession, Error> => {
    return useMutation<ProductRetrocession, CustomError, ProductRetrocession, Error>({
        mutationFn: (productRetrocession: ProductRetrocession) =>
            updateProductRetrocession(productId, retrocessionId, productRetrocession),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getProductRetrocessions", {productId}]});
            await queryClient.invalidateQueries({
                queryKey: ["getProductRetrocession", {productId, retrocessionId}],
            });
        },
    });
};

export const useDeleteProductRetrocessionMutation = (
    productId: string,
    retrocessionId: number
): UseMutationResult<void, CustomError, void, Error> => {
    const navigate = useNavigate();
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteProductRetrocession(productId, retrocessionId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getProductRetrocessions", {productId}]});
            queryClient.removeQueries({queryKey: ["getProductRetrocession", {productId, retrocessionId}]});
            navigate(`/products/${productId}`, {state: {activeTabIndex: 2}});
        },
    });
};

