import axios, {AxiosError} from "axios";
import axiosInstance from "../ProductEngine.instance";
import {buildCustomError} from "../RequestUtils";
import {VersionAddDTO, VersionDTO} from "./Types";

export const getVersion = async (productId: string, versionId: string): Promise<VersionDTO> => {
    try {
        const response = await axiosInstance.get<VersionDTO>(`/products/${productId}/versions/${versionId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getVersions = async (productId: string): Promise<VersionDTO[]> => {
    try {
        const response = await axiosInstance.get<VersionDTO[]>(`/products/${productId}/versions`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addVersion = async (productId: string, formData: VersionAddDTO): Promise<VersionDTO> => {
    try {
        const response = await axiosInstance.post<VersionDTO>(`/products/${productId}/versions`, formData);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateVersion = async (
    productId: string,
    versionId: string,
    formData: {label: string; effectiveDate: string; pipDate: string | null}
): Promise<VersionDTO> => {
    try {
        const response = await axiosInstance.put<VersionDTO>(`/products/${productId}/versions/${versionId}`, formData);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteVersion = async (productId: string, versionId: string): Promise<void> => {
    try {
        const response = await axiosInstance.delete(`/products/${productId}/versions/${versionId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getVersionMassTestFileStructure = async (productId: string, versionId: string): Promise<unknown> => {
    try {
        const response = await axiosInstance.get(
            `/products/${productId}/versions/${versionId}/mass_test/file_structure`,
            {
                responseType: "arraybuffer",
            }
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const executeVersionMassTest = async (
    productId: string,
    versionId: string,
    formData: FormData
): Promise<void> => {
    try {
        const response = await axiosInstance.post<void>(
            `/products/${productId}/versions/${versionId}/mass_test/execute`,
            formData,
            {
                headers: {"Content-Type": "multipart/form-data"},
            }
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

