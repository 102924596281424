import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import { Container, Grid } from "semantic-ui-react";
import Sidebar, { SidebarItem, SidebarMenu } from "../../../Components/Sidebar/Sidebar";
import { useGetApplicationAccessesQuery, useGetSelfCompanyQuery } from "../../../Services/Company/Queries";
import ApplicationAccessDetails from "./Fragments/ApplicationAccessDetails";

interface IParams extends Params {
    applicationAccessId: string;
}

const ApplicationAccessPage = () => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    const [menus, setMenus] = useState<Array<SidebarMenu>>([]);

    const companyQueryResult = useGetSelfCompanyQuery();
    const applicationAccessesQueryResult = useGetApplicationAccessesQuery(
        String(companyQueryResult?.data?.id),
        companyQueryResult?.data !== undefined
    );

    useEffect(() => {
        if (companyQueryResult.data !== undefined && applicationAccessesQueryResult.data !== undefined) {
            const sidebarItems = applicationAccessesQueryResult.data.map(
                (applicationAccess) =>
                    new SidebarItem(
                        applicationAccess.username,
                        applicationAccess.username,
                        `/transversal_settings/rest_api_access/${applicationAccess.id}`
                    )
            );
            setMenus([new SidebarMenu("", sidebarItems)]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationAccessesQueryResult.data]);

    return (
        <Container fluid>
            <Sidebar title={`${t("rest_api_access")}`} menus={menus} />
            <div className="main-container">
                <Grid columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            {companyQueryResult.data !== undefined && (
                                <ApplicationAccessDetails
                                    companyId={String(companyQueryResult.data.id)}
                                    applicationAccessId={params.applicationAccessId}
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    );
};

export default ApplicationAccessPage;
