export type ProductDetailsType = {
    code: string;
    currency: string;
    deadlineByDay: number;
    deadlineByMonth: number;
    id: number;
    label: string;
    mainTransient: number;
    principalCompanyCode: string;
    productInfos: Array<ProductInfosItemType> | undefined;
    quoteValidityDuration: number;
    endorsementQuoteValidityDuration: number;
    range: {
        id: number;
        label: string;
    };
    riskType: {
        id: number;
        label: string;
    };
    secondaryTransient: number;
    transientMode: string;
    openingDate: string;
    debitMode: string;
    delayedEffectLimit: number;
};

export type ProductInfosItemType = {
    id?: number;
    label: string;
    value: string;
};

export type EditedProductDetailsType = {
    code: string;
    label: string;
    deadlineByDay: number;
    deadlineByMonth: number;
    principalCompanyCode: string;
    currency: string;
    mainTransient: number;
    secondaryTransient: number;
    transientMode: string;
    riskTypeId: number;
    rangeId: number;
    productInfos: Array<{
        infoValue: string;
        infoLabel: string;
    }> | null;

    quoteValidityDuration: number | undefined;
    endorsementQuoteValidityDuration: number | undefined;
    debitMode: DebitModeType | undefined;
    openingDate: string | undefined;
    delayedEffectLimit: number | undefined;
    mainDeadlineDerogation: boolean | undefined;
};

export type EditProductParamsType = {
    productId: string;
    newProductDetails: EditedProductDetailsType;
};

export type ProductsByRiskType = {
    riskType: string;
    ranges: Array<ProductsByRange>;
};

export type ProductsByRange = {
    range: string;
    products: Array<ProductRecap>;
};

export type ProductRecap = {
    id: number;
    code: string;
    label: string;
    riskType: string;
    range: string;
};

export type NewProductDTO = {
    code: string;
    label: string;
    deadlineByDay: number;
    deadlineByMonth: number;
    principalCompanyCode: string;
    quoteValidityDuration: number;
    endorsementQuoteValidityDuration: number;
    riskTypeId: number;
    rangeId?: number;
    currency: string;
    openingDate: string;
    debitMode: string;
    delayedEffectLimit: number;
    transientMode: string;
};

export enum DebitModeType {
    A_ECHOIR = "A_ECHOIR",
    ECHU = "ECHU",
}
