import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {Container, Grid, Segment, Tab} from "semantic-ui-react";
import {AuthContext} from "../../../Components/AuthProvider/AuthProvider";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import useTransverseFeatureSidebarMenus from "../../../Hooks/useTransverseFeatureSidebarMenus";
import {useGetSelfCompanyQuery} from "../../../Services/Company/Queries";
import RestApiAccessRightsList from "./Fragments/RestApiAccessRightsList";
import UserGroupList from "./Fragments/UserGroupList";

const RestApiAccesPage = () => {
    const {t} = useTranslation();
    const location = useLocation();

    const [activeTabIndex, setActiveIndex] = useState(location.state ? location.state.activeTabIndex : 0);
    const {user: loggedUser} = React.useContext(AuthContext);

    const companyQueryResult = useGetSelfCompanyQuery();

    const panes = [
        {
            menuItem: {key: "partners", content: t("Partners", {count: 2})},
            render: () => <RestApiAccessRightsList companyId={String(companyQueryResult.data?.id)} />,
        },
        {
            menuItem: {key: "groups", content: t("Groups", {count: 2})},
            render: () => <UserGroupList companyId={String(companyQueryResult.data?.id)} />,
        },
    ];

    const menus = useTransverseFeatureSidebarMenus(
        loggedUser?.authorities?.some((authority) => authority === "SUPER_ADMIN" || authority === "ADMINISTRATEUR") ||
            false
    );

    return companyQueryResult && companyQueryResult?.status === "success" ? (
        <Container fluid>
            <Sidebar title={t("transversal_settings")} menus={menus} />
            <div className="main-container">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Segment className="card-container" textAlign="center">
                                <Tab
                                    panes={panes}
                                    activeIndex={activeTabIndex}
                                    onTabChange={(_, data) => setActiveIndex(data.activeIndex as number)}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    ) : (
        <></>
    );
};

export default RestApiAccesPage;
