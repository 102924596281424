import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Button, Icon, Menu} from "semantic-ui-react";
import TextOverflow from "../Text/TextOverflow";
import "./sidebar.css";

export type SidebarContent = {
    title?: string;
    menus?: Array<SidebarMenu>;
    renderAdditionnalButton?: () => JSX.Element;
};

export class SidebarMenu {
    name: string | undefined;
    items: Array<SidebarItem | SidebarMenu>;

    constructor(name: string | undefined, items: Array<SidebarItem | SidebarMenu>) {
        this.name = name;
        this.items = items;
    }
}

export class SidebarItem {
    key: string;
    name: string;
    link: string;
    boldText?: boolean;
    onClick?: Function;
    style?: React.CSSProperties;

    constructor(
        key: string,
        name: string,
        link: string,
        boldText = false,
        onClick = () => {},
        style?: React.CSSProperties
    ) {
        this.key = key;
        this.name = name;
        this.link = link;
        this.boldText = boldText;
        this.onClick = onClick;
        this.style = style;
    }
}

/**
 * Sidebar dynamique à mettre dans chaque page qui doit en contenir.
 *
 * @param title Le titre à afficher en haut de la Sidebar
 * @param menus Liste des menus à afficher peut contenir un sous niveau de menu
 */
const Sidebar: React.FC<SidebarContent> = ({title, menus, renderAdditionnalButton}: SidebarContent) => {
    const {t} = useTranslation();
    const [activeItem, setActiveItem] = useState<string | undefined>("");

    return (
        <div className="left-menus">
            <Menu vertical secondary className="sidebar-menu">
                {title !== undefined && (
                    <Menu.Item data-cy="menu-group-title" className="menu-group-title">
                        {title}
                    </Menu.Item>
                )}
                {menus !== undefined &&
                    menus.map((menu) => (
                        <Menu.Item
                            key={menu.name !== undefined ? menu.name : "menu-subtitle"}
                            data-cy="menu-subtitle"
                            className="menu-subtitle">
                            {menu.name !== undefined && menu.name !== "" && <Menu.Header>{menu.name}</Menu.Header>}
                            <div>
                                {menu.items !== undefined &&
                                    menu.items.map((item) => {
                                        if (item instanceof SidebarMenu) {
                                            return (
                                                <div key={item.name}>
                                                    <Menu.Header
                                                        className="menu-second-level"
                                                        data-cy="menu-second-level">
                                                        {item.name != null ? <Icon name="angle right" /> : ""}
                                                        {item.name}
                                                    </Menu.Header>
                                                    {item.items.map((itemSecondLevel) => {
                                                        return (
                                                            <Menu.Item
                                                                className="menu-second-level-items"
                                                                data-cy="menu-second-level-items"
                                                                key={
                                                                    itemSecondLevel instanceof SidebarItem
                                                                        ? itemSecondLevel.key
                                                                        : itemSecondLevel.name
                                                                }
                                                                name={itemSecondLevel.name}
                                                                as={Link}
                                                                to={
                                                                    !(itemSecondLevel instanceof SidebarMenu)
                                                                        ? itemSecondLevel.link
                                                                        : ""
                                                                }
                                                                onClick={() => setActiveItem(itemSecondLevel.name)}
                                                                active={activeItem === itemSecondLevel.name}>
                                                                {itemSecondLevel instanceof SidebarItem &&
                                                                itemSecondLevel.boldText ? (
                                                                    <strong>
                                                                        <TextOverflow value={itemSecondLevel.name} />
                                                                    </strong>
                                                                ) : (
                                                                    <TextOverflow value={itemSecondLevel.name} />
                                                                )}
                                                            </Menu.Item>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <Menu.Item
                                                    data-cy="menu-subtitle-items"
                                                    key={item.key}
                                                    name={item.name}
                                                    as={Link}
                                                    to={item.link}
                                                    onClick={() => {
                                                        item.onClick !== undefined && item.onClick();
                                                        setActiveItem(item.name);
                                                    }}
                                                    active={activeItem === item.name}
                                                    style={item.style}>
                                                    {item.boldText ? (
                                                        <strong>
                                                            <TextOverflow value={item.name} />
                                                        </strong>
                                                    ) : (
                                                        <TextOverflow value={item.name} />
                                                    )}
                                                </Menu.Item>
                                            );
                                        }
                                    })}
                            </div>
                        </Menu.Item>
                    ))}

                {title !== undefined && title === "Produits" && (
                    <div>
                        <Menu.Item style={{marginBottom: "1em"}}>
                            <Button primary name="new_product" className="menu-button" as={Link} to={"/new_product"}>
                                {t("New entity", {entity: t("Product").toLowerCase(), context: "male"})}
                            </Button>
                        </Menu.Item>
                    </div>
                )}

                {renderAdditionnalButton && (
                    <div>
                        <Menu.Item style={{marginBottom: "1em"}}>{renderAdditionnalButton()}</Menu.Item>
                    </div>
                )}
            </Menu>
        </div>
    );
};

export default Sidebar;
