import FileSaver from "file-saver";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Grid, Header, Icon} from "semantic-ui-react";
import Sections from "../../../../Components/CustomBreadcrumb/Fragments/Sections";
import ModalUpdate from "../../../../Components/Modals/ModalUpdate";
import {
    useExportCommissionPackages,
    useImportCommissionPackagesMutation,
} from "../../../Services/CommissionPackages/Queries";
import queryClient from "../../../../Config/queryClient";

const BreadcrumbCommissionPackage = ({...props}) => {
    const {t} = useTranslation();

    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const {data, status, refetch} = useExportCommissionPackages(props.productCode, false);
    const handleExportCommissionPackages = () => refetch();

    const importCommissionPackagesMutation = useImportCommissionPackagesMutation(props.productCode);
    const handleSetFile = ({target: {files}}: any) => setSelectedFile(files[0]);
    const handleImportCommissionPackagesClick = () => {
        if (hiddenFileInput.current !== null) {
            hiddenFileInput.current.click();
        }
    };
    const handleMutateImportCommissionPackages = () => importCommissionPackagesMutation.mutate(selectedFile);

    const clearSelectedFile = () => {
        setSelectedFile(null);
        if (hiddenFileInput.current !== null) {
            hiddenFileInput.current.value = "";
        }
    };

    useEffect(() => {
        if (status === "success" && data !== undefined && data !== null) {
            const blob = new Blob([data as BlobPart], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            FileSaver.saveAs(blob, `${props.productCode}.xlsx`);

            queryClient.removeQueries({queryKey: ["exportCommissionPackages"]});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, status, props.productCode]);

    return (
        <>
            <div className="header-container" data-cy="custom-breadcrumb-header">
                <Header as="h2" className="top-header">
                    {t("commission_packages")}
                </Header>

                <div className="sub-header">
                    <Grid>
                        <Grid.Column width={6} floated={"left"}>
                            <Sections sections={props.sections} />
                        </Grid.Column>
                        <Grid.Column width={6} floated={"right"} textAlign={"right"}>
                            <Button name="upload" onClick={handleImportCommissionPackagesClick} positive>
                                <input ref={hiddenFileInput} type={"file"} hidden onChange={handleSetFile} />
                                <Icon name={"upload"} /> {t("Import")}
                            </Button>
                            <Button name="download" onClick={handleExportCommissionPackages} positive>
                                <Icon name={"download"} /> {t("Export")}
                            </Button>
                        </Grid.Column>
                    </Grid>
                </div>
            </div>

            {selectedFile !== null && (
                <ModalUpdate
                    onValidate={handleMutateImportCommissionPackages}
                    isModalOpenAtStart
                    customContent={t("You are about to replace all the commission packages for this product")}
                    onCancel={clearSelectedFile}
                    onSuccess={clearSelectedFile}
                    onClose={clearSelectedFile}
                    resetMutation={importCommissionPackagesMutation.reset}
                    isSuccess={importCommissionPackagesMutation.isSuccess}
                    isPending={importCommissionPackagesMutation.isPending}
                    isError={importCommissionPackagesMutation.isError}
                    error={importCommissionPackagesMutation.error}
                />
            )}
        </>
    );
};

export default BreadcrumbCommissionPackage;
