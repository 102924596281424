import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import ModalUpdateForm from "../../../../Components/Modals/ModalUpdateForm";
import {useUpdateGridMutation} from "../../../../Services/PricingGrid/Queries";
import FormPricingGrid from "./FormPricingGrid";

interface IParams extends Params {
    id: string;
    versionId: string;
    gridId: string;
}

type PropsType = {
    pricingGridLabel: string;
};

const ModalUpdatePricingGrid = ({pricingGridLabel}: PropsType) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const updatePricingGridMutation = useUpdateGridMutation(params.versionId, params.gridId);
    const updatePricingGridForm = useForm<any>();

    const submitUpdatePricingGrid = (formData: {label: string}) => {
        updatePricingGridMutation.mutate(formData.label);
    };

    const [defaultValueLabel, setDefaultValueLabel] = useState<string>("");
    useEffect(() => {
        setDefaultValueLabel(pricingGridLabel);
    }, [pricingGridLabel]);

    const resetInputs = () => {
        setDefaultValueLabel(pricingGridLabel);
    };

    return (
        <ModalUpdateForm
            isValidationDisabled={Object.keys(updatePricingGridForm.formState.errors).length !== 0}
            isPending={updatePricingGridMutation.isPending}
            isSuccess={updatePricingGridMutation.isSuccess}
            isError={updatePricingGridMutation.isError}
            resetMutation={updatePricingGridMutation.reset}
            error={updatePricingGridMutation.error}
            onValidate={updatePricingGridForm.handleSubmit(submitUpdatePricingGrid)}
            onCancel={() => resetInputs()}
            onSuccess={() => resetInputs()}
            openButtonIcon="edit"
            objectToUpdate={t("Pricing grid")}
            openModalButtonColor="blue"
            validateButtonColor="green"
            objectToUpdateContext="female"
            renderModalContent={() => {
                return (
                    <FormPricingGrid
                        errors={updatePricingGridForm.formState.errors}
                        control={updatePricingGridForm.control}
                        defaultValueLabel={defaultValueLabel}
                        setDefaultValueLabel={setDefaultValueLabel}
                        updateMode
                    />
                );
            }}
        />
    );
};

export default ModalUpdatePricingGrid;
