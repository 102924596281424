import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import {
    AccordionContent,
    Button,
    Divider,
    Grid,
    GridColumn,
    GridRow,
    Header,
    Message
} from "semantic-ui-react";
import ConditionAccordionTitle from "../../../../../Components/Condition/ConditionAccordionTitle";
import { ConditionWarningEditForbidden } from "../../../../../Components/Condition/ConditionWarningEditForbidden";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import { DndPropertiesType } from "../../../../../Hooks/useDragAndDropProperties";

import { ConditionPart, OperandEnum } from "../../../../../Services/Condition/Types";
import { useDeleteVariableCondition, useReorderVariableCondtionMutation, useUpdateVariableCondition } from "../../../../../Services/Variables/Queries";
import { Variable, VariableCondition, VariableType } from "../../../../../Services/Variables/Types";
import { VersionDTO } from "../../../../../Services/Version/Types";
import ConditionHandling from "../../../Condition/ConditionHandling";
import ConditionView from "../../../Condition/Fragments/ConditionView";
import VariableConditionAssignement from "./VariableConditionAssignement";
import { isEditingConditionForbidden } from "../../../../../Utils/ConditionPartUtils";

interface IParams extends Params {
    id: string;
    versionId: string;
    variableId: string;
}

type VariableConditionAccordionPropsType = {
    condition: VariableCondition;
    version: VersionDTO | undefined;
    index: number;
    activeIndex: number;
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
    variable: Variable;
    numberOfConditions: number;
    dndProperties: DndPropertiesType<VariableCondition>
};

const VariableConditionAccordion = ({
    condition,
    version,
    index,
    activeIndex,
    setActiveIndex,
    variable,
    numberOfConditions,
    dndProperties,
}: VariableConditionAccordionPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const [editCondition, setEditCondition] = useState<boolean>(false);

    const conditionKindList =
        variable.variableType === VariableType.R
            ? [
                  {key: 1, text: "System variable", value: OperandEnum.SOFTWARE_CRITERIA, type: "female"},
                  {key: 2, text: "Pricing criteria", value: OperandEnum.CRITERE, type: "male"},
                  {key: 3, text: "Variable", value: OperandEnum.VARIABLE, type: "female"},
                  {
                      key: 4,
                      text: "Computing rule variables",
                      value: OperandEnum.VARIABLE_COMPUTING_RULE,
                      type: "female",
                  },
                  {key: 5, text: "Fixed value", value: OperandEnum.FIXED_VALUE, type: "female"},
              ]
            : [
                  {key: 1, text: "System variable", value: OperandEnum.SOFTWARE_CRITERIA, type: "female"},
                  {key: 2, text: "Pricing criteria", value: OperandEnum.CRITERE, type: "male"},
                  {key: 3, text: "Variable", value: OperandEnum.VARIABLE, type: "female"},
                  {key: 4, text: "Fixed value", value: OperandEnum.FIXED_VALUE, type: "female"},
              ];

    const updateVariableConditionMutation = useUpdateVariableCondition(
        params.versionId,
        params.variableId,
        String(condition.id),
        variable.variableType as VariableType.R | VariableType.V
    );
    const deleteVariableConditionMutation = useDeleteVariableCondition(
        params.versionId,
        params.variableId,
        String(condition.id),
        variable.variableType as VariableType.R | VariableType.V
    );

    const reorderVariableCondition = useReorderVariableCondtionMutation(
        params.versionId,
        params.variableId,
        String(condition.id),
    )

    useEffect(() => {
        if (activeIndex !== index) {
            setEditCondition(false);
        }
    }, [activeIndex, index]);

    return (
        <div key={index}>
            <ConditionAccordionTitle<VariableCondition>
                version={version}
                condition={condition}
                index={index}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                numberOfConditions={numberOfConditions}
                dndProperties={dndProperties}
                reorderFunction={reorderVariableCondition}
            />
            <AccordionContent active={activeIndex === index}>
                {condition.ifCondition === "( ( 1 == 1 ) )" && (
                    <Message warning>
                        {t(
                            "This condition contains default values, it would be convenient to update it before validating product version's"
                        )}
                    </Message>
                )}
                <Divider horizontal>
                    <Header as="h4">{t("Condition")}</Header>
                </Divider>

                {editCondition ? (
                    <ConditionHandling
                        versionId={params.versionId}
                        conditionKindList={conditionKindList}
                        conditionPartList={condition.conditionPartList ? condition.conditionPartList : []}
                        setConditionPartList={(conditionPartListUpdate: ConditionPart[]) => {
                            const conditionUpdate: VariableCondition = {
                                ifCondition: null,
                                conditionPartList: conditionPartListUpdate,
                                orderCondition: condition.orderCondition,
                                refGridCode: condition.refGridCode,
                                variableCriterias: condition.variableCriterias.map((variableCriteria) => {
                                    if (variableCriteria.computingRule) {
                                        variableCriteria.computingRule.id = null;
                                        variableCriteria.computingRule.computingRuleConditions[0].id = null;
                                    }
                                    variableCriteria.id = null;
                                    return variableCriteria;
                                }),
                                defaultValue: condition.defaultValue,
                            };
                            updateVariableConditionMutation.mutate(conditionUpdate);
                        }}
                        setEditCondition={setEditCondition}
                        updateMutation={updateVariableConditionMutation}
                    />
                ) : (
                    <>
                        <Grid columns={3}>
                            {variable.variableConditions.length === 1 ? (
                                <GridColumn width={2} textAlign="left" verticalAlign="middle">
                                    {t("Default value")}
                                </GridColumn>
                            ) : index !== variable.variableConditions.length - 1 ? (
                                <>
                                    <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                        {t("If")} :
                                    </GridColumn>
                                    <GridColumn width={14} textAlign="center" className="condition">
                                        <ConditionView conditionPartList={condition.conditionPartList ? condition.conditionPartList : []} />
                                    </GridColumn>
                                    {version !== undefined && version.pipDate === null && (
                                        <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                            {isEditingConditionForbidden(condition.conditionPartList) ? (
                                                <ConditionWarningEditForbidden />
                                            ) : (
                                                <Button
                                                    name={"editCondition" + Number(index + 1)}
                                                    color="grey"
                                                    icon="edit"
                                                    compact
                                                    basic
                                                    onClick={() => {
                                                        setEditCondition(true);
                                                    }}
                                                />
                                            )}
                                        </GridColumn>
                                    )}
                                </>
                            ) : (
                                <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                    {t("Else")}
                                </GridColumn>
                            )}
                        </Grid>
                        <Divider horizontal>
                            <Header as="h4">{t("Assignment")}</Header>
                        </Divider>

                        <VariableConditionAssignement
                            variable={variable}
                            variableCondition={condition}
                            version={version}
                            updateVariableConditionMutation={updateVariableConditionMutation}
                            activeIndex={activeIndex}
                            index={index}
                        />

                        {index !== variable.variableConditions.length - 1 &&
                            version !== undefined &&
                            version.pipDate === null && (
                                <>
                                    <Divider />
                                    <Grid>
                                        <GridRow>
                                            <GridColumn width={14} />
                                            <GridColumn width={2} textAlign={"right"}>
                                                <ModalDelete
                                                    isSuccess={deleteVariableConditionMutation.isSuccess}
                                                    isPending={deleteVariableConditionMutation.isPending}
                                                    isError={deleteVariableConditionMutation.isError}
                                                    error={deleteVariableConditionMutation.error}
                                                    resetMutation={deleteVariableConditionMutation.reset}
                                                    objectToDelete="Condition"
                                                    objectType="female"
                                                    openModalButtonName={"deleteCondition" + Number(index + 1)}
                                                    onValidate={() =>
                                                        deleteVariableConditionMutation.mutate()
                                                    }
                                                    onSuccess={() => setActiveIndex(-1)}
                                                />
                                            </GridColumn>
                                        </GridRow>
                                    </Grid>
                                </>
                            )}
                    </>
                )}
            </AccordionContent>
        </div>
    );
};

export default VariableConditionAccordion;
