import React, {Fragment} from "react";
import {Controller, UseFormReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid} from "semantic-ui-react";
import getErrorMessage from "../../Pages/Global/Form/ErrorMessage";
import {codeValidation} from "../../Pages/Global/Form/Validation";
import {SplittingDTO, SplittingType, SplittingUnit} from "../../Services/Splitting/Types";

type FormSplittingType = {
    form: UseFormReturn<SplittingDTO>;
    defaultSplitting: Partial<SplittingDTO>;
    setDefaultSplitting: React.Dispatch<React.SetStateAction<Partial<SplittingDTO>>>;
};

const FormSplitting = ({form, defaultSplitting, setDefaultSplitting}: FormSplittingType) => {
    const {t} = useTranslation();
    const [splittingType] = form.watch(["splittingType"]);

    const handleSplittingTypeChange = (value: SplittingType) => {
        if (value !== SplittingType.X) {
            const code = value;
            const label = t(`enums.SplittingType.${value}`);

            form.setValue("code", code);
            form.setValue("label", label);

            setDefaultSplitting({
                ...defaultSplitting,
                splittingType: value,
                code,
                label,
            });
        } else {
            setDefaultSplitting({
                ...defaultSplitting,
                splittingType: value,
            });
        }
    };

    const handleChangeFormValue = (name: keyof SplittingDTO, value: SplittingDTO[keyof SplittingDTO]) => {
        setDefaultSplitting({
            ...defaultSplitting,
            [name]: value,
        });
    };

    const splittingTypeOptions = Object.values(SplittingType).map((splittingType: SplittingType) => {
        return {
            key: splittingType.toString(),
            text: t("enums.SplittingType." + splittingType),
            value: splittingType,
        };
    });

    const splittingUnitOptions = Object.values(SplittingUnit).map((splittingUnit: SplittingUnit) => {
        return {
            key: splittingUnit.toString(),
            text: t("enums.SplittingUnit." + splittingUnit),
            value: splittingUnit,
        };
    });

    return (
        <Form id="modal-form" data-cy="form-splitting">
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Controller
                            control={form.control}
                            name={"splittingType"}
                            defaultValue={defaultSplitting.splittingType}
                            rules={{required: true}}
                            render={({field: {onChange, value, name}}) => (
                                <Form.Select
                                    required
                                    fluid
                                    name={name}
                                    label={t("Splitting type")}
                                    placeholder={t("Splitting type")}
                                    onChange={(_, {value}) => {
                                        handleSplittingTypeChange(value as SplittingType);
                                        onChange(value);
                                    }}
                                    value={value}
                                    options={splittingTypeOptions}
                                    error={getErrorMessage(t, form.formState.errors, name)}
                                />
                            )}
                        />

                        <Controller
                            control={form.control}
                            defaultValue={defaultSplitting.code}
                            rules={{required: true, maxLength: 5, pattern: codeValidation()}}
                            name={"code"}
                            render={({field: {name, onChange, onBlur, value}}) => (
                                <Form.Input
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Code")}
                                    placeholder={t("Code")}
                                    onChange={(_, {value}) => {
                                        handleChangeFormValue(name, value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    maxLength={5}
                                    error={getErrorMessage(t, form.formState.errors, name, undefined, 5)}
                                />
                            )}
                        />

                        <Controller
                            control={form.control}
                            defaultValue={defaultSplitting.label}
                            rules={{required: true, maxLength: 30}}
                            name={"label"}
                            render={({field: {name, onBlur, onChange, value}}) => (
                                <Form.Input
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    maxLength={"30"}
                                    placeholder={t("Label")}
                                    label={t("Label")}
                                    onChange={(_, {value}) => {
                                        handleChangeFormValue(name, value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, form.formState.errors, name, undefined, 30)}
                                />
                            )}
                        />

                        {splittingType === SplittingType.X && (
                            <Fragment>
                                <Controller
                                    control={form.control}
                                    name={"unit"}
                                    rules={{required: true}}
                                    defaultValue={defaultSplitting.unit}
                                    render={({field: {onChange, value, name}}) => (
                                        <Form.Select
                                            fluid
                                            required={true}
                                            label={t("Unity")}
                                            name={name}
                                            placeholder={t("Unity")}
                                            onChange={(_, {value}) => {
                                                handleChangeFormValue(name, value as SplittingUnit);
                                                onChange(value);
                                            }}
                                            value={value as SplittingUnit}
                                            options={splittingUnitOptions}
                                            error={getErrorMessage(t, form.formState.errors, name)}
                                        />
                                    )}
                                />

                                <Controller
                                    name="duration"
                                    control={form.control}
                                    defaultValue={defaultSplitting.duration}
                                    rules={{
                                        required: true,
                                        min: {value: 0, message: t("The value must be greater than 0")},
                                        max: {value: 999, message: t("The value must be less than 999")},
                                    }}
                                    render={({field: {name, value, onBlur, onChange}}) => (
                                        <Form.Input
                                            type="number"
                                            required={true}
                                            name={name}
                                            label={t("Duration")}
                                            fluid
                                            min={0}
                                            max={999}
                                            placeholder={t("Duration")}
                                            onChange={(_, {value}) => {
                                                handleChangeFormValue(name, value);
                                                onChange(Number.parseInt(value) || undefined);
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            error={getErrorMessage(t, form.formState.errors, name)}
                                        />
                                    )}
                                />
                            </Fragment>
                        )}

                        <Controller
                            name="increaseCoef"
                            control={form.control}
                            defaultValue={defaultSplitting.increaseCoef ?? 1}
                            rules={{required: true, min: {value: 0, message: t("The value must be greater than 0")}}}
                            render={({field: {name, value, onBlur, onChange}}) => (
                                <Form.Input
                                    required
                                    type={"number"}
                                    name={name}
                                    fluid
                                    min={0}
                                    step={".01"}
                                    placeholder={t("Coefficient increase")}
                                    onChange={(_, {value}) => {
                                        handleChangeFormValue(name, value);
                                        onChange(Number.parseFloat(value) || "");
                                    }}
                                    onBlur={onBlur}
                                    label={t("Coefficient increase")}
                                    value={value}
                                    error={getErrorMessage(t, form.formState.errors, name)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormSplitting;
