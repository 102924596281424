import { useTranslation } from "react-i18next";
import { Header, Label } from "semantic-ui-react";
import { VersionDTO } from "../../../../Services/Version/Types";

type VersionHeaderType = {
    version: VersionDTO;
};

const VersionHeader = ({version}: VersionHeaderType) => {
    const {t} = useTranslation();

    return (
        <Header as="h2" className="top-header">
            {version.label}
            {version.pipDate !== null ? (
                <Label size="small" color="green">
                    {t("Validated on", {date: t("format_date", {value: version.pipDate})})}
                </Label>
            ) : (
                <Label size="small" color="red">
                    {t("Not validated", {context: "female"})}
                </Label>
            )}
            <Label size="small" basic color="teal">
                {t("Effective date on", {date: t("format_date", {value: version.effectiveDate})})}
            </Label>
            <Label size="small" basic color="blue">
                {t("Creation date on", {date: t("format_date", {value: version.creationDate})})}
            </Label>
        </Header>
    );
};

export default VersionHeader;
