import React from "react";
import { useTranslation } from "react-i18next";
import { Header } from "semantic-ui-react";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import { useProductsSidebarMenu } from "../../../Hooks/useProductsSidebarMenu";
import "./Dashboard.css";

const DashboardPage: React.FC = () => {
    const {t} = useTranslation();
    const menus = useProductsSidebarMenu();

    return (
        <>
            <Sidebar title={t("Product", {count: 2})} menus={menus} />
            <Header as="h1">Dashboard</Header>
        </>
    );
};

export default DashboardPage;
