import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Button, Container} from "semantic-ui-react";
import Description from "../../../Components/Description/Description";
import Sidebar, {SidebarItem, SidebarMenu} from "../../../Components/Sidebar/Sidebar";
import {
    useGetPricingCriteria,
    useGetPricingCriteriasList,
    useUpdateGlobalInfosPricingCriteriaMutation,
} from "../../../Services/PricingCriterias/Queries";
import {useGetVersionQuery} from "../../../Services/Version/Queries";
import BreadcrumbPricingCriterias from "./Fragments/BreadcrumbPricingCriterias";
import ModalAddPricingCriteria from "./Fragments/ModalAddPricingCriteria";
import PricingCriteriaConditionsList from "./Fragments/PricingCriteriaConditions/PricingCriteriaConditionsList";

interface IParams extends Params {
    id: string;
    versionId: string;
    pricingCriteriaId: string;
}

const PricingCriteriaPage = ({...props}) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    const [sidebarMenus, setSidebarMenus] = useState<Array<SidebarMenu>>([]);

    const getPricingCriteriasList = useGetPricingCriteriasList(params.versionId, true);
    const getPricingCriteria = useGetPricingCriteria(params.versionId, params.pricingCriteriaId);
    const getVersionQuery = useGetVersionQuery(params.id, params.versionId);

    const form = useForm<{description: string}>();
    const pricingCriteriaUpdateMutation = useUpdateGlobalInfosPricingCriteriaMutation(
        params.versionId,
        params.pricingCriteriaId
    );
    const handlePricingCriteriaUpdate = (formData: {description: string}) => {
        const updatedPricingCriteria = {
            ...getPricingCriteria.data,
            description: formData.description,
        };
        pricingCriteriaUpdateMutation.mutate(updatedPricingCriteria);
    };

    useEffect(() => {
        if (getPricingCriteriasList.isSuccess && getPricingCriteriasList.data !== undefined) {
            setSidebarMenus([
                new SidebarMenu(
                    "",
                    getPricingCriteriasList.data.map(
                        (pricingCriteria) =>
                            new SidebarItem(
                                pricingCriteria.name,
                                pricingCriteria.name,
                                `/products/${params.id}/versions/${params.versionId}/pricing-criterias/${pricingCriteria.id}`,
                                false,
                                () => {},
                                pricingCriteria.valid ? {} : {color: "red"}
                            )
                    )
                ),
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPricingCriteriasList.isSuccess, getPricingCriteriasList.data]);

    return (
        <Container fluid>
            <Sidebar
                title={t("Pricing criterias")}
                menus={sidebarMenus}
                renderAdditionnalButton={
                    getVersionQuery.isSuccess &&
                    getVersionQuery.data !== undefined &&
                    getVersionQuery.data.pipDate === null
                        ? () => (
                              <ModalAddPricingCriteria
                                  alternateOpenButton={() => (
                                      <Button primary name="new_pricing_criteria" className="menu-button">
                                          {t("New entity", {
                                              entity: t("Pricing criteria").toLowerCase(),
                                              context: "male",
                                          })}
                                      </Button>
                                  )}
                              />
                          )
                        : undefined
                }
            />

            {getVersionQuery.isSuccess &&
                getVersionQuery.data !== undefined &&
                getPricingCriteria.isSuccess &&
                getPricingCriteria.data !== undefined && (
                    <>
                        <BreadcrumbPricingCriterias
                            pricingCriteria={getPricingCriteria.data}
                            version={getVersionQuery.data}
                            sections={props.sections}
                        />

                        <div className="main-container">
                            <Description
                                entityName="Pricing criteria"
                                entityType="male"
                                description={getPricingCriteria.data.description}
                                version={getVersionQuery.data}
                                form={form}
                                entityMutation={pricingCriteriaUpdateMutation}
                                handleEntityUpdate={handlePricingCriteriaUpdate}
                            />

                            <PricingCriteriaConditionsList
                                pricingCriteria={getPricingCriteria.data}
                                version={getVersionQuery.data}
                            />
                        </div>
                    </>
                )}
        </Container>
    );
};

export default PricingCriteriaPage;
