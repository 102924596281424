import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Card, Label, SemanticCOLORS } from "semantic-ui-react"
import { AuthContext } from "../../../../../Components/AuthProvider/AuthProvider"
import TextOverflow from "../../../../../Components/Text/TextOverflow"
import { QuestionDTO, QuestionValueTypeEnum } from "../../../../../Services/Question/Types"
import { displayAuditLog } from "../../../../../Utils/AuditUtils"

type QuestionCardType = {
    versionId: string | undefined;
    question: QuestionDTO;
    onClick: (() => void) | ((operandItem: QuestionDTO) => void);
}

const QuestionCard = ({
    versionId,
    question,
    onClick,
}: QuestionCardType) => {

    const {t} = useTranslation();
    const userContext = useContext(AuthContext);

    const getColorOfValueTypeLabel = (questionValueType: QuestionValueTypeEnum): SemanticCOLORS | undefined => {
        switch (questionValueType) {
            case QuestionValueTypeEnum.STRING:
                return "blue";
            case QuestionValueTypeEnum.DATE:
                return "green";
            case QuestionValueTypeEnum.NOMBRE:
                return "purple";
            case QuestionValueTypeEnum.LISTE:
                return "brown";
            default:
                return undefined;
        }
    };

    return (
        <Card
            onClick={() => onClick(question)}
        >
            <Card.Content>
                <Card.Header>
                    <TextOverflow value={question.name} />
                </Card.Header>
                <Card.Description>
                    <Label
                        data-cy="question-card-valueType"
                        color={getColorOfValueTypeLabel(question.valueType)}>
                        {t("enums.QuestionValueType." + question.valueType)}
                    </Label>
                </Card.Description>

                {question.defaultValue && (
                    <Card.Description
                        style={{marginTop: "10px"}}
                        data-cy="question-card-defaultValue">
                        {t("Default value")} :{" "}
                        <strong>
                            {(question?.valueType == QuestionValueTypeEnum.DATE &&
                                t("format_date", {value: question?.defaultValue})) ||
                                question?.defaultValue}
                        </strong>
                    </Card.Description>
                )}

                {question.mandatory && (
                    <Label corner="right" color="orange" size="tiny" icon="asterisk" />
                )}
            </Card.Content>

            {userContext.user !== undefined && (
                <Card.Content extra textAlign="right" style={{fontStyle: "italic"}}>
                    <span>
                        {versionId && displayAuditLog(
                            {
                                entityContext: "female",
                                createdDate: question.createdDate,
                                modifiedDate: question.modifiedDate,
                                createdBy: question.createdBy,
                                modifiedBy: question.modifiedBy,
                                createdOnVersionId: question.createdOnVersionId,
                            },
                            userContext.user,
                            versionId,
                            t
                        )}
                    </span>
                </Card.Content>
            )}
        </Card>
    );
}

export default QuestionCard;