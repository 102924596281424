import {useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid} from "semantic-ui-react";
import getErrorMessage from "../../Pages/Global/Form/ErrorMessage";
import {useGetSelfCompanyQuery, useGetUserGroupsQuery} from "../../Services/Company/Queries";

const FormRestApiAccess = ({
    control,
    errors,
    defaultValueUserName,
    setDefaultValueUserName,
    defaultValueAuthentificationMode,
    setDefaultValueAuthentificationMode,
    defaultValueIdentifier,
    setDefaultValueIdentifier,
    defaultValueToken,
    setDefaultValueToken,
    defaultValueRole,
    setDefaultValueRole,
    defaultValueSelectedItem,
    setDefaultValueSelectedItem,
}: any) => {
    const {t} = useTranslation();

    const companyQueryResult = useGetSelfCompanyQuery();

    const {data, status} = useGetUserGroupsQuery(
        String(companyQueryResult?.data?.id),
        companyQueryResult?.data !== undefined
    );

    const formatToken = (value: string) => {
        let valueInit = value.replaceAll("-", "").substring(0, 32);

        let valueReturn = valueInit.substring(0, 8);
        if (valueInit.length > 8) {
            valueReturn += "-";
        }
        valueReturn += valueInit.substring(8, 12);
        if (valueInit.length > 12) {
            valueReturn += "-";
        }
        valueReturn += valueInit.substring(12, 16);
        if (valueInit.length > 16) {
            valueReturn += "-";
        }
        valueReturn += valueInit.substring(16, 20);
        if (valueInit.length > 20) {
            valueReturn += "-";
        }
        valueReturn += valueInit.substring(20, 32);

        return valueReturn;
    };

    const [selectedGroups, setSelectedGroups] = useState<any>([]);

    const RoleOptions = [
        {key: 1, value: "APPORTEUR", text: t("BusinessProvider")},
        {key: 2, value: "GESTIONNAIRE", text: t("Manager")},
    ];

    const authentModeOptions = [
        {key: 1, value: "bearerToken", text: "Bearer token"},
        {key: 2, value: "oAuth2", text: "oAuth 2"},
    ];

    const handleSelect = (value: any, onChange: any) => {
        const foundItem = data?.find((item: any) => item.id === value);
        if (foundItem !== undefined) {
            setDefaultValueSelectedItem(foundItem);
            onChange(foundItem);
        }
    };

    const handleChange = (onchange: (param: string) => void, value: any) => {
        value = formatToken(value);
        setDefaultValueToken(value);
        onchange(value);
    };

    const changeAuthentificationMode = (onChange: any, value: any) => {
        if (
            (value === "oAuth2" &&
                defaultValueIdentifier.length === 0 &&
                defaultValueAuthentificationMode === "" &&
                defaultValueUserName &&
                defaultValueUserName.length > 0) ||
            defaultValueAuthentificationMode === "bearerToken"
        ) {
            const userNameSliced = defaultValueUserName.slice();
            setDefaultValueIdentifier(userNameSliced);
        }

        setDefaultValueAuthentificationMode(value);
        onChange(value);
    };

    useEffect(() => {
        if (status === "success" && data !== undefined) {
            const foundOptions = data.map((item: any) => ({
                text: item.name,
                value: item.id,
                key: item.id,
            }));
            setSelectedGroups([{text: "Selectionner...", value: null}, ...foundOptions]);
        }
    }, [data, status]);

    return (
        <Form id="modal-form">
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Controller
                            control={control}
                            defaultValue={defaultValueUserName}
                            rules={{required: true}}
                            name={"username"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Partner")}
                                    placeholder={t("RestApiAccessUsername")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueUserName(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            rules={{required: true}}
                            defaultValue={defaultValueAuthentificationMode}
                            name={"authentMode"}
                            render={({field: {name, onChange, onBlur}}) => (
                                <Form.Select
                                    name={name}
                                    label={t("RestApiAccessAuthentMode")}
                                    required
                                    onBlur={onBlur}
                                    placeholder={t("RestApiAccessAuthentMode")}
                                    onChange={(_, {value}) => changeAuthentificationMode(onChange, value)}
                                    value={defaultValueAuthentificationMode}
                                    error={getErrorMessage(t, errors, name)}
                                    options={authentModeOptions}
                                />
                            )}
                        />

                        {defaultValueAuthentificationMode &&
                            (defaultValueAuthentificationMode === "bearerToken" ? (
                                <Controller
                                    control={control}
                                    defaultValue={defaultValueToken}
                                    rules={{
                                        required: defaultValueAuthentificationMode === "bearerToken",
                                        maxLength: 36,
                                        minLength: 36,
                                    }}
                                    name={"token"}
                                    render={({field: {name, value, onChange, onBlur}}) => (
                                        <Form.Input
                                            fluid
                                            required={defaultValueAuthentificationMode === "bearerToken"}
                                            type={"text"}
                                            name={name}
                                            label={t("Token")}
                                            placeholder="00000000-0000-0000-0000-00000000000"
                                            onChange={(_, {value}) => handleChange(onChange, value)}
                                            onBlur={onBlur}
                                            value={value}
                                            maxLength={36}
                                            error={getErrorMessage(t, errors, name, 36, 36)}
                                        />
                                    )}
                                />
                            ) : (
                                <Controller
                                    control={control}
                                    defaultValue={defaultValueIdentifier}
                                    rules={{required: true}}
                                    name={"identifier"}
                                    render={({field: {name, value, onChange, onBlur}}) => (
                                        <Form.Input
                                            fluid
                                            required={true}
                                            type={"text"}
                                            name={name}
                                            label={t("Identifier")}
                                            placeholder={t("Identifier")}
                                            onChange={(_, {value}) => onChange(value)}
                                            onBlur={onBlur}
                                            value={value}
                                            error={getErrorMessage(t, errors, name)}
                                        />
                                    )}
                                />
                            ))}

                        <Controller
                            control={control}
                            rules={{required: true}}
                            defaultValue={defaultValueRole}
                            name={"role"}
                            render={({field: {name, onChange, onBlur}}) => (
                                <Form.Select
                                    name={name}
                                    label={t("Role")}
                                    required
                                    onBlur={onBlur}
                                    placeholder={t("Role")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueRole(value);
                                        onChange(value);
                                    }}
                                    value={defaultValueRole}
                                    error={getErrorMessage(t, errors, name)}
                                    options={RoleOptions}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            rules={{required: true}}
                            defaultValue={defaultValueSelectedItem}
                            name={"selectedItem"}
                            render={({field: {name, onChange, onBlur}}) => (
                                <Form.Select
                                    required
                                    label={t("Choose a group")}
                                    name={name}
                                    onBlur={onBlur}
                                    placeholder={t("Group")}
                                    onChange={(_, {value}) => handleSelect(value, onChange)}
                                    error={getErrorMessage(t, errors, name)}
                                    options={selectedGroups}
                                    value={defaultValueSelectedItem !== null ? defaultValueSelectedItem.id : null}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormRestApiAccess;
