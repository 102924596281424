/* eslint-disable @typescript-eslint/no-explicit-any */

import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Icon, Pagination, Placeholder, Table, TableCell} from "semantic-ui-react";
import {ColumnSimpleList, DataTypeColumnEnum} from "./SimpleListTypes";

type SimpleListProps = {
    tableName?: string;
    columns: Array<ColumnSimpleList>;
    rows: Array<any>;
    globalData: Array<{key: string; value: any}>;
    translationNameSpace?: string;
    isLoading?: boolean;
};

type ConfigType = {
    column: null | string;
    direction: undefined | "descending" | "ascending";
    data: any;
};

const SimpleList: React.FC<SimpleListProps> = ({...props}) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [activePage, setActivePage] = useState<number>(1);
    const [totalPage, setTotalPage] = useState<number>(0);
    const pageLimit = 10;

    const originalColumns = props.columns;

    const [config, setConfig] = useState<ConfigType>({
        column: null,
        direction: undefined,
        data: [...props.rows],
    });

    useEffect(() => {
        let rows = props.rows;
        if (rows.length > pageLimit) {
            const trimStart = (activePage - 1) * pageLimit;
            const trimEnd = trimStart + pageLimit;
            rows = rows.slice(trimStart, trimEnd);
            setTotalPage(Math.ceil(props.rows.length / pageLimit));
        }
        setConfig({
            ...config,
            data: [...rows],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.rows]);

    const formatCellValue = (type: DataTypeColumnEnum, value: any) => {
        switch (type) {
            case "DATE": {
                if (!value) return t("Not specified");
                return t("format_date", {value}) === "Invalid Date" ? value : t("format_date", {value});
            }
            case "DATETIME": {
                if (!value) return t("Not specified");
                return t("format_datetime", {value}) === "Invalid Date" ? value : t("format_datetime", {value});
            }
            case "BOOLEAN": {
                return (
                    <div style={{textAlign: "center"}}>
                        {value ? (
                            <Icon name="check" data-cy="boolean-icon" />
                        ) : (
                            <Icon name="close" data-cy="boolean-icon" />
                        )}
                    </div>
                );
            }
            case "ENUM": {
                return value ? handleTranslationNamespace(`enums.${value}`) : t("Not specified");
            }
            case "LIST": {
                return value.join(", ");
            }
            default: {
                return value ? value : t("Not specified");
            }
        }
    };

    const handleTranslationNamespace = (key: string) =>
        t(`${props.translationNameSpace !== undefined ? `${props.translationNameSpace}:` : ""}${key}`);

    const buildLink = (row: any) => {
        const linkColumn = props.columns.find((column) => column.type === DataTypeColumnEnum.LINK);
        if (linkColumn !== undefined) {
            let link = linkColumn.dataField;
            linkColumn.dataField
                .split("/")
                .filter((placeholder) => placeholder.startsWith(":"))
                .map((placeholder) => ({
                    key: placeholder,
                    value:
                        row[placeholder.substring(1)] ||
                        props.globalData.find((data) => data.key === placeholder.substring(1))?.value,
                }))
                .map((placeholder) => (link = link?.replace(placeholder.key, placeholder.value)));
            return link;
        }
        return "";
    };

    const handleClick = (column: ColumnSimpleList) => () => {
        if (column.dataField === config.column) {
            setConfig({
                ...config,
                data: config.data.reverse(),
                direction: config.direction === "ascending" ? "descending" : "ascending",
            });
        } else {
            props.columns = originalColumns;
            setConfig({
                ...config,
                column: column.dataField,
                data: sortByColumn(config.data, column),
                direction: "ascending",
            });
        }
    };

    const sortByColumn = (array: any[], column: ColumnSimpleList) => {
        return array.sort((a: any, b: any) => {
            if (a[column.dataField] === null || b[column.dataField] === null) {
                return a[column.dataField] === null ? (b[column.dataField] === null ? 0 : -1) : 1;
            }
            if (a[column.dataField] !== null && b[column.dataField] !== null) {
                if (column.type === DataTypeColumnEnum.BOOLEAN)
                    return a[column.dataField] === b[column.dataField] ? 0 : a[column.dataField] ? -1 : 1;
                if (column.type === DataTypeColumnEnum.LIST)
                    return (
                        a[column.dataField].length === b[column.dataField].length &&
                        JSON.stringify(a[column.dataField]) === JSON.stringify(b[column.dataField])
                    );
                if (column.type === DataTypeColumnEnum.STRING) {
                    const dataFieldA = Number(a[column.dataField]);
                    const dataFieldB = Number(b[column.dataField]);
                    if (!isNaN(dataFieldA) && !isNaN(dataFieldB)) return dataFieldA - dataFieldB;
                }
            }
            return a[column.dataField].localeCompare(b[column.dataField]);
        });
    };

    const hasLinkDataType = () => props.columns.find((column) => column.type === DataTypeColumnEnum.LINK) !== undefined;

    const handlePageChange = (activePage: number) => {
        setActivePage(activePage);

        let rows = props.rows;
        const trimStart = (activePage - 1) * pageLimit;
        const trimEnd = trimStart + pageLimit;
        rows = rows.slice(trimStart, trimEnd);
        setConfig({
            ...config,
            data: [...rows],
        });
    };

    const getTableBody = () => {
        if (props.isLoading) {
            return (
                <>
                    {Array.from(Array(4).keys()).map((key) => (
                        <Table.Row key={key}>
                            <TableCell colSpan={props.columns.length}>
                                <Placeholder fluid>
                                    <Placeholder.Header>
                                        <Placeholder.Line length="full" />
                                    </Placeholder.Header>
                                </Placeholder>
                            </TableCell>
                        </Table.Row>
                    ))}
                </>
            );
        }

        if (config.data.length === 0) {
            return (
                <Table.Row colSpan={props.columns.length} textAlign="center">
                    <Table.Cell colSpan={props.columns.length}>{handleTranslationNamespace("no_data")}</Table.Cell>
                </Table.Row>
            );
        }

        return config.data.map((row: any, key: number) => (
            <Table.Row
                style={hasLinkDataType() ? {cursor: "pointer"} : {}}
                key={key}
                aria-rowindex={key}
                onClick={() => hasLinkDataType() && navigate(buildLink(row))}
            >
                {props.columns.map(
                    (column, key) =>
                        column.type !== DataTypeColumnEnum.LINK && (
                            <Table.Cell key={key} collapsing={true}>
                                {formatCellValue(column.type, row[column.dataField])}
                            </Table.Cell>
                        )
                )}
            </Table.Row>
        ));
    };

    return (
        <Table
            data-cy="simple-list"
            name={props.tableName || "list"}
            sortable
            striped
            celled
            aria-rowcount={props.rows.length}
        >
            <Table.Header>
                <Table.Row>
                    {props.columns.map((column) => {
                        const isEmptyColumn = config.data.filter((item: any) => item[column.dataField]).length === 0;
                        if (column.type !== DataTypeColumnEnum.LINK) {
                            return !isEmptyColumn ? (
                                <Table.HeaderCell
                                    sorted={config.column === column.dataField ? config.direction : undefined}
                                    onClick={handleClick(column)}
                                    key={column.dataField}
                                >
                                    {handleTranslationNamespace(column.text)}
                                </Table.HeaderCell>
                            ) : (
                                <Table.HeaderCell key={column.dataField}>
                                    {handleTranslationNamespace(column.text)}
                                </Table.HeaderCell>
                            );
                        }

                        return null;
                    })}
                </Table.Row>
            </Table.Header>

            <Table.Body>{getTableBody()}</Table.Body>
            {props.rows.length > pageLimit && (
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={props.columns.length}>
                            <Pagination
                                data-cy="simple-list-pagination"
                                activePage={activePage}
                                firstItem={null}
                                lastItem={null}
                                totalPages={totalPage}
                                onPageChange={(_, currentPage) => {
                                    handlePageChange(Number(currentPage.activePage));
                                }}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            )}
        </Table>
    );
};

export default SimpleList;
