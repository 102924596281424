import {QueryObserverResult, RefetchOptions} from "@tanstack/query-core";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Accordion, Grid, Message, Tab} from "semantic-ui-react";
import ConditionsPlaceholder from "../../../Components/Condition/ConditionsPlaceholder";
import ModalAdd from "../../../Components/Modals/ModalAdd";
import useDragAndDropProperties from "../../../Hooks/useDragAndDropProperties";
import {useAddComputingRuleCondition} from "../../../Services/ComputingRule/Queries";
import {ComputingRule, ComputingRuleCondition} from "../../../Services/ComputingRule/Types";
import {
    ComputingRuleRoundingMode,
    CoverageRuleDTO,
    FeeChargeDTO,
    Option,
} from "../../../Services/InsurancePackage/Types";
import {VersionDTO} from "../../../Services/Version/Types";
import ComputingRuleAccordion from "./ComputingRuleAccordion";

interface ComputingRulePropsType {
    isQueryStatusFetching: boolean;
    computingRule: ComputingRule;
    version: VersionDTO;
    refetch: (
        options?: RefetchOptions | undefined
    ) => Promise<QueryObserverResult<FeeChargeDTO | CoverageRuleDTO | Option, Error>>;
    queryToInvalidate:
        | {queryKey: (string | {coverageGroupId: string; coverageId: string})[]}
        | {queryKey: (string | {insurancePackageId: string; feeChargeId: string})[]}
        | {queryKey: (string | {coverageGroupId: string; optionId: string})[]};
}

const ComputingRuleList = ({
    computingRule,
    version,
    refetch,
    queryToInvalidate,
    isQueryStatusFetching,
}: ComputingRulePropsType) => {
    const {t} = useTranslation();
    const [activeComputingRuleConditionIndex, setActiveComputingRuleConditionIndex] = useState<number>(0);

    const addComputingRuleConditionMutation = useAddComputingRuleCondition(computingRule.id ?? 0);

    const onAddConditionValidation = () => {
        const condition = {
            ifCondition: "( ( 1 == 1 ) )",
            roundingMode: ComputingRuleRoundingMode.ROUND_HALF_UP,
            decimalCount: 2,
        };
        addComputingRuleConditionMutation.mutate(condition);
    };
    const onAddConditionSuccess = () => {
        refetch();
        if (computingRule !== undefined) {
            setActiveComputingRuleConditionIndex(computingRule.computingRuleConditions.length - 1);
        }
    };

    const dndProperties = useDragAndDropProperties<ComputingRuleCondition>(computingRule.computingRuleConditions);

    const renderConditions = () => {
        return dndProperties.renderedItems.map((condition, index) => {
            return (
                <ComputingRuleAccordion
                    computingRule={computingRule}
                    version={version}
                    computingRuleCondition={condition}
                    numberOfConditions={computingRule.computingRuleConditions.length}
                    index={index}
                    activeIndex={activeComputingRuleConditionIndex}
                    setActiveIndex={setActiveComputingRuleConditionIndex}
                    refetch={refetch}
                    key={condition.id}
                    dndProperties={dndProperties}
                    queryToInvalidate={queryToInvalidate}
                />
            );
        });
    };

    return (
        <Tab.Pane style={{padding: "20px"}}>
            <Accordion styled fluid onMouseLeave={() => dndProperties.refreshData()}>
                {isQueryStatusFetching ? (
                    <ConditionsPlaceholder
                        refreshData={dndProperties.refreshData}
                        numberOfConditions={computingRule.computingRuleConditions.length}
                    />
                ) : (
                    renderConditions()
                )}
            </Accordion>

            {version !== undefined && version.pipDate === null && (
                <Grid style={{marginTop: 0}}>
                    <Grid.Row>
                        <Grid.Column textAlign="center">
                            <ModalAdd
                                isPending={addComputingRuleConditionMutation.isPending}
                                isSuccess={addComputingRuleConditionMutation.isSuccess}
                                isError={addComputingRuleConditionMutation.isError}
                                resetMutation={addComputingRuleConditionMutation.reset}
                                error={addComputingRuleConditionMutation.error}
                                onValidate={() => onAddConditionValidation()}
                                onSuccess={() => onAddConditionSuccess()}
                                objectToAdd="Condition"
                                objectToAddContext="female"
                                openModalButtonName="addCondition"
                                renderModalContent={() => (
                                    <Message info>
                                        {t(
                                            "You are about to add a new condition, this condition will be added second to last with default values which will be convenient to update"
                                        )}
                                    </Message>
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </Tab.Pane>
    );
};

export default ComputingRuleList;
