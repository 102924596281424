import {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Button, Grid} from "semantic-ui-react";
import ModalDelete from "../../../../Components/Modals/ModalDelete";
import ModalDuplicate from "../../../../Components/Modals/ModalDuplicate";
import {useDeleteVariable, useDuplicateVariable} from "../../../../Services/Variables/Queries";
import {Variable} from "../../../../Services/Variables/Types";
import FormDuplicateVariable from "./FormDuplicateVariable";
import ModalUpdateVariable from "./ModalUpdateVariable";

interface IParams extends Params {
    id: string;
    versionId: string;
    variableId: string;
}

type VariablesActionsType = {
    variable: Variable;
    isVersionValid: boolean;
};

const VariablesActions = ({variable, isVersionValid}: VariablesActionsType) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const deleteVariableMutation = useDeleteVariable(params.id, params.versionId, variable.id, variable.variableType);
    const handleDelete = (variableId: number) => {
        deleteVariableMutation.mutate(variableId);
    };

    const duplicateVariableMutation = useDuplicateVariable(params.id, params.versionId, variable.id);
    const duplicateVariableForm = useForm<any>();
    const sumbitDuplicateVariableForm = (form: any) => {
        duplicateVariableMutation.mutate({
            name: form.duplicated_variable_name,
            description: form.duplicated_variable_description,
        });
    };

    const [duplicateVariableName, setDuplicateVariableName] = useState("");
    const [duplicateVariableDescription, setDuplicateVariableDescription] = useState("");
    const resetFormDuplicateVariable = () => {
        setDuplicateVariableName("");
        setDuplicateVariableDescription("");
    };

    return (
        <Grid.Column textAlign={"right"} className={"breadcrumb-actions"}>
            <div className={"breadcrumb-actions-button-list"}>
                {!isVersionValid && (
                    <>
                        <ModalUpdateVariable variable={variable} />
                        <ModalDuplicate
                            isValidationDisabled={Object.keys(duplicateVariableForm.formState.errors).length !== 0}
                            isPending={duplicateVariableMutation.isPending}
                            isSuccess={duplicateVariableMutation.isSuccess}
                            isError={duplicateVariableMutation.isError}
                            error={duplicateVariableMutation.error}
                            resetMutation={duplicateVariableMutation.reset}
                            onCancel={resetFormDuplicateVariable}
                            onSuccess={resetFormDuplicateVariable}
                            onValidate={duplicateVariableForm.handleSubmit(sumbitDuplicateVariableForm)}
                            objectToDuplicate="Variable"
                            objectToDuplicateCodeOrLabel={variable.name}
                            objectToDuplicateContext="female"
                            renderModalContent={() => (
                                <FormDuplicateVariable
                                    errors={duplicateVariableForm.formState.errors}
                                    control={duplicateVariableForm.control}
                                    defaultValueName={duplicateVariableName}
                                    setDefaultValueName={setDuplicateVariableName}
                                    defaultValueDescription={duplicateVariableDescription}
                                    setDefaultValueDescription={setDuplicateVariableDescription}
                                />
                            )}
                            renderOpenButton={() => {
                                return (
                                    <Button name="duplicateVariable" color="green">
                                        {t("Duplicate")}
                                    </Button>
                                );
                            }}
                        />
                        <ModalDelete
                            isSuccess={deleteVariableMutation.isSuccess}
                            isPending={deleteVariableMutation.isPending}
                            isError={deleteVariableMutation.isError}
                            error={deleteVariableMutation.error}
                            resetMutation={deleteVariableMutation.reset}
                            objectToDelete="Variable"
                            objectType="female"
                            onValidate={() => handleDelete(variable.id)}
                        />
                    </>
                )}
            </div>
        </Grid.Column>
    );
};

export default VariablesActions;
