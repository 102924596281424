import { Dispatch, SetStateAction } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { Button, Grid, Header } from "semantic-ui-react";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import ModalUpdateForm from "../../../../../Components/Modals/ModalUpdateForm";
import { useDeletePricingGridDimensionMutation, useUpdatePricingGridDimensionMutation } from "../../../../../Services/PricingGrid/Queries";
import { Axe, DimensionGridDTO, DimensionGridDTOWithId, PricingGridFormType } from "../../../../../Services/PricingGrid/Types";
import { VersionDTO } from "../../../../../Services/Version/Types";
import { FormDimension } from "./FormDimension";

type PricinPricingGridDimensionType = {
    index: number;
    version: VersionDTO;
    gridId: string;
    dimensionGrid: DimensionGridDTOWithId;
    setActiveItem: Dispatch<SetStateAction<DimensionGridDTO | null | undefined>>;
    dimensionAxe: Axe;
}

export const PricingGridDimension = ({
    index,
    version,
    gridId,
    dimensionGrid,
    setActiveItem,
    dimensionAxe,
}: PricinPricingGridDimensionType) => {

    // Update dimension
    const updateDimensionMutation = useUpdatePricingGridDimensionMutation(
        version.id.toString(),
        gridId,
        dimensionGrid.id.toString()
    );
    const updateDimensionForm = useForm<PricingGridFormType>({
        defaultValues: {
            ...dimensionGrid,
            dimensionAxe: dimensionGrid.visualizationGridDTO.axe,
        },
    });
    const submitUpdateDimension = (form: FieldValues) => {
        const dimension = {
            id: dimensionGrid.id,
            code: form.code,
            label: form.label,
            dimensionType: form.dimensionType,
            dimensionValueType: form.dimensionValueType,
            dimensionOrderType: form.dimensionOrderType,
            visualizationGridDTO: {
                axe: form.dimensionAxe,
                rank: form.dimensionRank,
            },
            dimensionGridValueDTOs: [],
        };
        updateDimensionMutation.mutate(dimension);
    };

    // Delete dimension
    const deleteDimensionMutation = useDeletePricingGridDimensionMutation(version.id.toString(), gridId);
    const handleDeleteDimension = () => deleteDimensionMutation.mutate(dimensionGrid.id.toString());

    return (
        <Grid>
            <Grid.Column width={8} verticalAlign={"middle"}>
                <Header as="h5">
                    {dimensionGrid.code}
                    <Header.Subheader>{dimensionGrid.label}</Header.Subheader>
                </Header>
            </Grid.Column>
            <Grid.Column width={8} verticalAlign={"middle"}>
                {version !== undefined && version.pipDate === null && (
                    <Button.Group basic floated="right">
                        <ModalUpdateForm
                            isValidationDisabled={
                                Object.keys(updateDimensionForm.formState.errors).length !== 0
                            }
                            isPending={updateDimensionMutation.isPending}
                            isSuccess={updateDimensionMutation.isSuccess}
                            isError={updateDimensionMutation.isError}
                            resetMutation={updateDimensionMutation.reset}
                            error={updateDimensionMutation.error}
                            onValidate={updateDimensionForm.handleSubmit(submitUpdateDimension)}
                            iconOnOpenButton
                            onSuccess={updateDimensionForm.reset}
                            onCancel={updateDimensionForm.reset}
                            openButtonIcon="edit"
                            objectToUpdate="Dimension"
                            objectToUpdateContext="female"
                            openModalButtonName={dimensionAxe === Axe.C ? `pricing-grid-structure-edit-dimension-C-${index}` : `pricing-grid-structure-edit-dimension-L-${index}`}
                            basicButton
                            returnToFormStepIfError={true}
                            renderModalContent={() => {
                                return (
                                    <FormDimension
                                        form={updateDimensionForm}
                                        dimension={dimensionGrid}
                                    />
                                );
                            }}
                        />

                        <ModalDelete
                            isSuccess={deleteDimensionMutation.isSuccess}
                            isPending={deleteDimensionMutation.isPending}
                            isError={deleteDimensionMutation.isError}
                            error={deleteDimensionMutation.error}
                            resetMutation={deleteDimensionMutation.reset}
                            onValidate={handleDeleteDimension}
                            onSuccess={() => setActiveItem(null)}
                            objectToDelete="Dimension"
                            objectType="female"
                            openButtonIcon="trash alternate"
                            openModalButtonName={dimensionAxe === Axe.C ? `pricing-grid-structure-delete-dimension-C-${index}` : `pricing-grid-structure-delete-dimension-L-${index}`}
                            iconOnOpenButton
                            basicButton
                        />
                    </Button.Group>
                )}
            </Grid.Column>
        </Grid>
    );
}