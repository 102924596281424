import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomError} from "../RequestUtils";
import {
    addComputingRuleCondition,
    checkSyntax,
    deleteComputingRuleCondition,
    reorderComputingRuleConditionMutation,
    updateComputingRuleCondition,
} from "./AxiosRequest";
import {ComputingRuleCondition, ComputingRuleConditionDTO, RulePart} from "./Types";

export const useCheckSyntax = (): UseMutationResult<boolean, CustomError, RulePart[], Error> => {
    return useMutation<boolean, CustomError, RulePart[], Error>({
        mutationFn: (rulePartList: RulePart[]) => checkSyntax(rulePartList),
    });
};

export const useUpdateComputingRuleCondition = (
    computingRuleId: number,
    conditionId: number
): UseMutationResult<ComputingRuleCondition, CustomError, ComputingRuleConditionDTO, Error> => {
    return useMutation<ComputingRuleCondition, CustomError, ComputingRuleConditionDTO, Error>({
        mutationFn: (condition: ComputingRuleConditionDTO) =>
            updateComputingRuleCondition(computingRuleId, conditionId, condition),
    });
};

export const useDeleteComputingRRuleCondition = (
    computingRuleId: number,
    conditionId: number
): UseMutationResult<void, CustomError, void, Error> => {
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteComputingRuleCondition(computingRuleId, conditionId),
    });
};

export const useAddComputingRuleCondition = (
    computingRuleId: number
): UseMutationResult<ComputingRuleCondition, CustomError, ComputingRuleConditionDTO, Error> => {
    return useMutation<ComputingRuleCondition, CustomError, ComputingRuleConditionDTO, Error>({
        mutationFn: (condition: ComputingRuleConditionDTO) => addComputingRuleCondition(computingRuleId, condition),
    });
};

export const useReorderComputingRuleCondtionMutation = (
    computingRuleId: string,
    conditionId: string,
): UseMutationResult<void, CustomError, number, Error> => {
    return useMutation<void, CustomError, number, Error>({
        mutationFn: (order: number) => reorderComputingRuleConditionMutation(computingRuleId, conditionId, order),
    });
};
