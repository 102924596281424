import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid} from "semantic-ui-react";
import getErrorMessage from "../../../Global/Form/ErrorMessage";
import {emailValidation} from "../../Form/Validation";

export const FormResetUserPassword = ({control, errors}: any) => {
    const {t} = useTranslation();

    return (
        <Form id="modal-form">
            <Grid centered columns={2}>
                <Grid.Row centered>
                    <Grid.Column>
                        <Controller
                            name="email"
                            defaultValue=""
                            rules={{
                                required: true,
                                pattern: emailValidation(),
                            }}
                            control={control}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    required
                                    type="text"
                                    name={name}
                                    label={t("Email")}
                                    placeholder={t("Email")}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};
