import {UseMutationResult, UseQueryResult, useMutation, useQuery} from "@tanstack/react-query";
import queryClient from "../../Config/queryClient";
import {CustomError} from "../RequestUtils";
import {
    addQuestion,
    deleteQuestion,
    duplicateQuestion,
    getQuestion,
    getQuestions,
    importValuesFromDimensionGrid,
    updateQuestion,
    uploadQuestionValuesList,
} from "./AxiosRequests";

import {useNavigate} from "react-router-dom";
import {QuestionAddDTO, QuestionDTO} from "./Types";
import { AxiosPromise } from "axios";

export const useGetQuestionQuery = (versionId: string, questionId: string): UseQueryResult<QuestionDTO, Error> => {
    return useQuery({
        queryKey: ["getQuestion", {versionId, questionId}],
        queryFn: () => getQuestion(versionId, questionId),
    });
};

export const useGetQuestionsQuery = (
    versionId: string,
    enabled: boolean = true
): UseQueryResult<Array<QuestionDTO>, Error> => {
    return useQuery({
        queryKey: ["getQuestions", {versionId}],
        queryFn: () => getQuestions(versionId),
        enabled,
    });
};

export const useAddQuestionMutation = (
    productId: string,
    versionId: string
): UseMutationResult<QuestionDTO, CustomError, QuestionAddDTO, Error> => {
    const navigate = useNavigate();
    return useMutation<QuestionDTO, CustomError, QuestionAddDTO, Error>({
        mutationFn: (questionAddDTO: QuestionAddDTO) => addQuestion(versionId, questionAddDTO),
        onSuccess: async (result) => {
            navigate(`/products/${productId}/versions/${versionId}/questions/${result.id}`);
            await queryClient.invalidateQueries({queryKey: ["getQuestions", {versionId}]});
        },
    });
};

export const useUpdateQuestionMutation = (
    versionId: string,
    questionId: string
): UseMutationResult<QuestionDTO, CustomError, QuestionAddDTO, Error> => {
    return useMutation<QuestionDTO, CustomError, QuestionAddDTO, Error>({
        mutationFn: (questionUpdateDTO: QuestionAddDTO) => updateQuestion(versionId, questionId, questionUpdateDTO),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getQuestion", {versionId, questionId}]});
            await queryClient.invalidateQueries({queryKey: ["getQuestions", {versionId}]});
        },
    });
};

export const useDeleteQuestionMutation = (
    productId: string,
    versionId: string,
    questionId: string
): UseMutationResult<void, CustomError, void, Error> => {
    const navigate = useNavigate();
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteQuestion(versionId, questionId),
        onSuccess: async () => {
            queryClient.removeQueries({queryKey: ["getQuestion", {versionId, questionId}]});
            await queryClient.invalidateQueries({queryKey: ["getQuestions", {versionId}]});
            navigate(`/products/${productId}/versions/${versionId}`, {state: {activeTabIndex: 1}});
        },
    });
};

export const useDuplicateQuestionMutation = (
    productId: string,
    versionId: string,
    questionId: string
): UseMutationResult<QuestionDTO, CustomError, {name: string}, Error> => {
    const navigate = useNavigate();
    return useMutation<QuestionDTO, CustomError, {name: string}, Error>({
        mutationFn: (data: {name: string}) => duplicateQuestion(versionId, questionId, data.name),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["getQuestions", {versionId}]});
            navigate(`/products/${productId}/versions/${versionId}/questions/${result.id}`);
        },
    });
};

export const useUploadQuestionListValues = (
    versionId: string,
    questionId: string
): UseMutationResult<void, CustomError, File, Error> => {
    return useMutation<void, CustomError, File, Error>({
        mutationFn: (files: File) => {
            const formData = new FormData();

            formData.append(
                "file",
                new Blob([files], {
                    type: "text/csv",
                })
            );

            return uploadQuestionValuesList(versionId, questionId, formData);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getQuestion", {versionId, questionId}]});
        },
    });
};

export const useImportValuesFromDimensionGridMutation = (
    versionId: string,
    questionId: string
): UseMutationResult<AxiosPromise, CustomError, { gridCode: string, dimensionCode: string }, Error> => {
    return useMutation<AxiosPromise, CustomError, { gridCode: string, dimensionCode: string }, Error>({
        mutationFn: ({ gridCode, dimensionCode }) => importValuesFromDimensionGrid(versionId, questionId, gridCode, dimensionCode),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["getQuestion", { versionId, questionId }] });
        }        
    })
}

