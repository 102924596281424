import * as FileSaver from "file-saver";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumb, Button, Grid, Header, Icon } from "semantic-ui-react";
import Sections from "../Components/CustomBreadcrumb/Fragments/Sections";
import ModalUpdate from "../Components/Modals/ModalUpdate";
import { downloadPremiumsPricingRights } from "./Services/PremiumsPricingRights/AxiosRequests";
import { useUploadPremiumsPricingRights } from "./Services/PremiumsPricingRights/Queries";

type CustomBreadcrumbProps = {
    sections: Array<{name: string; path: string}>;
};

const PremiumsPricingRightsBreadcrumb: React.FC<CustomBreadcrumbProps> = ({sections}) => {
    const {t} = useTranslation();
    const uploadMutation = useUploadPremiumsPricingRights();

    const [selectedFile, setSelectedFile] = useState(null);
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const handleExport = async () => {
        const blob = new Blob([await downloadPremiumsPricingRights()], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileSaver.saveAs(blob, "premium_pricing_rights.xlsx");
    };

    const handleSetFile = ({target: {files}}: any) => setSelectedFile(files[0]);

    const handleMutate = () => uploadMutation.mutate(selectedFile);

    const handleClick = () => {
        if (hiddenFileInput.current !== null) {
            hiddenFileInput.current.click();
        }
    };

    const clearSelectedFile = () => {
        setSelectedFile(null);
        if (hiddenFileInput.current !== null) {
            hiddenFileInput.current.value = "";
        }
    };

    return (
        <div className="header-container" data-cy="custom-breadcrumb-header">
            <Header as="h2" className="top-header">
                {t(`sections.${sections[sections?.length - 1].name}`)}
            </Header>

            <div className="sub-header">
                <Grid>
                    <Grid.Column width={6} floated={"left"}>
                        <Breadcrumb>
                            <Sections sections={sections} />
                        </Breadcrumb>
                    </Grid.Column>
                    <Grid.Column width={6} floated={"right"} textAlign={"right"}>
                        <Button name="download" positive onClick={handleExport}>
                            <Icon name={"download"} /> {t("Export")}
                        </Button>
                        {selectedFile !== null && (
                            <ModalUpdate
                                hidden
                                isModalOpenAtStart
                                customContent={t("You are about to replace all premium pricing rights")}
                                onSuccess={clearSelectedFile}
                                onCancel={clearSelectedFile}
                                onClose={clearSelectedFile}
                                resetMutation={uploadMutation.reset}
                                isSuccess={uploadMutation.isSuccess}
                                isPending={uploadMutation.isPending}
                                isError={uploadMutation.isError}
                                error={uploadMutation.error}
                                onValidate={handleMutate}
                            />
                        )}
                        <Button name="upload" onClick={handleClick} positive>
                            <input ref={hiddenFileInput} type={"file"} hidden onChange={handleSetFile} />
                            <Icon name={"upload"} /> {t("Import")}
                        </Button>
                    </Grid.Column>
                </Grid>
            </div>
        </div>
    );
};

export default PremiumsPricingRightsBreadcrumb;
