import {useContext} from "react";
import {Params, useParams} from "react-router-dom";
import {Grid, Header} from "semantic-ui-react";
import PricingGridActions from "../../../../Components/CustomBreadcrumb/Fragments/PricingGridActions";
import Sections from "../../../../Components/CustomBreadcrumb/Fragments/Sections";
import TextOverflow from "../../../../Components/Text/TextOverflow";
import {BasicGridType} from "../../../../Services/PricingGrid/Types";
import {VersionDTO} from "../../../../Services/Version/Types";
import {displayAuditLog} from "../../../../Utils/AuditUtils";
import {AuthContext} from "./../../../../Components/AuthProvider/AuthProvider";
import {useTranslation} from "react-i18next";

type BreadcrumbPricingGridType = {
    pricingGrid: BasicGridType;
    version: VersionDTO;
    sections: Array<{name: string; path: string}>;
};
interface IParams extends Params {
    id: string;
    versionId: string;
}

const BreadcrumbPricingGrid = ({pricingGrid, version, sections}: BreadcrumbPricingGridType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const {user} = useContext(AuthContext);

    return (
        <>
            <div className="header-container" data-cy="custom-breadcrumb-header">
                <Grid>
                    <Grid.Column floated={"left"} style={{width: "auto", margin: 0}}>
                        <Header as="h2" className="top-header" style={{display: "flex"}}>
                            <TextOverflow
                                inline
                                element={
                                    <>
                                        {pricingGrid.label}{" "}
                                        <p style={{display: "contents", color: "#9b9b9b", fontWeight: "lighter"}}>
                                            - <TextOverflow inline value={pricingGrid.code} />
                                        </p>
                                    </>
                                }
                            />
                        </Header>
                        <div className="sub-header">
                            <Sections sections={sections} />
                            {pricingGrid !== undefined && user !== undefined && (
                                <div className="audit-display">
                                    <i>
                                        {displayAuditLog(
                                            {
                                                entityContext: "female",
                                                createdDate: pricingGrid.createdDate,
                                                modifiedDate: pricingGrid.modifiedDate,
                                                createdBy: pricingGrid.createdBy,
                                                modifiedBy: pricingGrid.modifiedBy,
                                                createdOnVersionId: pricingGrid.createdOnVersionId,
                                            },
                                            user,
                                            params.versionId,
                                            t
                                        )}
                                    </i>
                                </div>
                            )}
                        </div>
                    </Grid.Column>
                    {pricingGrid !== undefined && version !== undefined && (
                        <PricingGridActions
                            pricingGrid={pricingGrid}
                            isVersionValid={version.pipDate !== null}
                            gridLabel={pricingGrid.label}
                        />
                    )}
                </Grid>
            </div>
        </>
    );
};

export default BreadcrumbPricingGrid;
