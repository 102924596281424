import { Grid } from "semantic-ui-react";
import Sections from "../../../../Components/CustomBreadcrumb/Fragments/Sections";
import VersionActions from "../../../../Components/CustomBreadcrumb/Fragments/VersionActions";
import { VersionDTO } from "../../../../Services/Version/Types";
import VersionHeader from "./VersionHeader";

type BreadcrumbVersionType = {
    version: VersionDTO;
    versionList: VersionDTO[];
    sections: Array<{name: string; path: string}>;
};

const BreadcrumbVersion = ({version, versionList, sections}: BreadcrumbVersionType) => {
    return (
        <>
            <div className="header-container" data-cy="custom-breadcrumb-header">
                <Grid>
                    <Grid.Column floated={"left"} style={{width: "auto", margin: 0}}>
                        <VersionHeader version={version} />
                        <div className="sub-header">
                            <Sections sections={sections} />
                        </div>
                    </Grid.Column>
                    {version !== undefined && versionList !== undefined && (
                        <VersionActions version={version} versionList={versionList} />
                    )}
                </Grid>
            </div>
        </>
    );
};

export default BreadcrumbVersion;
