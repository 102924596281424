import { useContext, useEffect, useState } from "react";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Form, Grid, GridColumn, Header, Icon, Menu, MenuItem } from "semantic-ui-react";
import ModalAdd from "../../Components/Modals/ModalAdd";
import ModalDelete from "../../Components/Modals/ModalDelete";
import ModalUpdate from "../../Components/Modals/ModalUpdate";
import getErrorMessage from "../../Pages/Global/Form/ErrorMessage";
import { HealthProductFormContext } from "./HealthProductFormContext";

const RenfortForm = () => {
    const {t} = useTranslation();

    const {state, stateChange} = useContext(HealthProductFormContext);

    const [options, setOptions] = useState<string[]>(state.options);
    const [addFormSuccess, setAddFormSuccess] = useState<boolean>(false);
    const [addFormError, setAddFormError] = useState<boolean>(false);

    const addForm = useForm<any>({mode: "onBlur"});
    const updateForm = useForm<any>({mode: "onBlur"});

    const handleAddOption = (form: any) => {
        if (options.find((option) => option === form.label) !== undefined) {
            setAddFormError(true);
        } else {
            let modifiedOptions = options.concat(form.label);
            setOptions(modifiedOptions);
            stateChange("options", modifiedOptions);
            setAddFormSuccess(true);
        }
    };

    const [defaultValueLabel, setDefaultValueLabel] = useState<string>("");

    useEffect(() => {
        setAddFormSuccess(false);
        setAddFormError(false);
    }, [options]);

    return (
        <>
            <Grid centered>
                <GridColumn width={8}>
                    <Menu fluid vertical>
                        <MenuItem>
                            <Header size="medium">{t("Renforts list")}</Header>
                        </MenuItem>
                        {options.map((option: string, index) => (
                            <RenfortItem
                                key={index}
                                options={options}
                                setOptions={setOptions}
                                option={option}
                                index={index}
                                updateForm={updateForm}
                                stateChange={stateChange}
                            />
                        ))}
                        <ModalAdd
                            isPending={false}
                            isSuccess={addFormSuccess}
                            isError={addFormError}
                            onValidate={addForm.handleSubmit(handleAddOption)}
                            onSuccess={() => {
                                setDefaultValueLabel("");
                            }}
                            onCancel={() => {
                                setDefaultValueLabel("");
                            }}
                            objectToAdd="Renfort"
                            objectToAddContext="male"
                            error={{
                                status: 401,
                                statusText: "test",
                                message: "cant_add_renfort_with_same_label",
                                details: [],
                            }}
                            renderModalContent={() => (
                                <Form>
                                    <Controller
                                        control={addForm.control}
                                        defaultValue={defaultValueLabel}
                                        rules={{required: true, maxLength: 150}}
                                        name={"label"}
                                        render={({field: {name, value, onChange, onBlur}}) => (
                                            <Form.Input
                                                fluid
                                                required
                                                type="text"
                                                maxLength="150"
                                                name={name}
                                                label={t("Label")}
                                                placeholder={t("Label")}
                                                onChange={(e, {value}) => {
                                                    setDefaultValueLabel(value);
                                                    onChange(value);
                                                }}
                                                onBlur={onBlur}
                                                value={defaultValueLabel}
                                                error={getErrorMessage(t, addForm.formState.errors, "label", undefined, 150)}
                                            />
                                        )}
                                    />
                                </Form>
                            )}
                            renderOpenButton={() => {
                                return (
                                    <Menu.Item>
                                        <Icon name="add" color="blue" />
                                        {t("Add renfort")}
                                    </Menu.Item>
                                );
                            }}
                        />
                    </Menu>
                </GridColumn>
            </Grid>
        </>
    );
};

type RenfortItemPropsType = {
    options: string[];
    setOptions: React.Dispatch<React.SetStateAction<string[]>>;
    option: string;
    index: number;
    updateForm: UseFormReturn<any>;
    stateChange: any;
};

const RenfortItem = ({options, setOptions, option, index, updateForm, stateChange}: RenfortItemPropsType) => {
    const {t} = useTranslation();
    const [edit, setEdit] = useState<boolean>(false);
    const [updateFormSuccess, setUpdateFormSuccess] = useState<boolean>(false);
    const [updateFormError, setUpdateFormError] = useState<boolean>(false);

    const handleUpdateOption = (index: number) => (form: any) => {
        if (options.find((option) => option === form.label) !== undefined) {
            setUpdateFormError(true);
        } else {
            let modifiedOptions = options.map((option) => {
                if (options.indexOf(option) === index) {
                    return form.label;
                } else {
                    return option;
                }
            });
            setOptions(modifiedOptions);
            stateChange("options", modifiedOptions);
            setEdit(false);
            setUpdateFormSuccess(true);
        }
    };

    const handleRemoveOption = (optionSelected: string) => {
        let modifiedOptions = options
            .filter((option) => options.indexOf(option) !== options.indexOf(optionSelected))
            .map((option) => {
                return option;
            });
        setOptions(modifiedOptions);
        stateChange("options", modifiedOptions);
    };

    return edit ? (
        <MenuItem name={option} key={index}>
            <Grid>
                <Grid.Column width={10}>
                    <Controller
                        control={updateForm.control}
                        defaultValue={option}
                        rules={{required: true}}
                        name={"label"}
                        render={({field: {name, value, onChange, onBlur}}) => (
                            <Form.Input
                                fluid
                                required
                                type={"text"}
                                name={name}
                                placeholder={t("label")}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                error={getErrorMessage(t, updateForm.formState.errors, "label")}
                            />
                        )}
                    />
                </Grid.Column>
                <Grid.Column width={6}>
                    <Button.Group floated="right">
                        <Button icon="cancel" color="black" onClick={() => setEdit(false)}></Button>
                        <ModalUpdate
                            isPending={false}
                            isSuccess={updateFormSuccess}
                            isError={updateFormError}
                            onSuccess={() => setEdit(false)}
                            onValidate={updateForm.handleSubmit(handleUpdateOption(index))}
                            objectToUpdate={t("Renfort")}
                            objectType="male"
                            iconOnOpenButton={true}
                            openButtonIcon="check"
                            error={{
                                status: 401,
                                statusText: "test",
                                message: "cant_add_renfort_with_same_label",
                                details: [],
                            }}
                        />
                    </Button.Group>
                </Grid.Column>
            </Grid>
        </MenuItem>
    ) : (
        <MenuItem name={`groupe-${option}`} key={`groupe-${option}`}>
            <Grid>
                <GridColumn width={10} verticalAlign="middle">
                    <Header as="h5" floated="left" style={{wordBreak: "break-word"}}>
                        {option}
                    </Header>
                </GridColumn>
                <GridColumn width={6} verticalAlign="middle">
                    <Button.Group basic floated="right">
                        <Button basic icon name="edit" onClick={() => setEdit(true)}>
                            <Icon name={"edit"} color="black" />
                        </Button>
                        <ModalDelete
                            isPending={false}
                            isSuccess={true}
                            isError={false}
                            onValidate={() => handleRemoveOption(option)}
                            objectToDelete="Renfort"
                            objectType="male"
                            renderOpenButton={() => {
                                return (
                                    <Button basic icon name="delete">
                                        <Icon name={"trash alternate"} color="red" />
                                    </Button>
                                );
                            }}
                        />
                    </Button.Group>
                </GridColumn>
            </Grid>
        </MenuItem>
    );
};

export default RenfortForm;
