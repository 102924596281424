import {Control, Controller, DeepMap, FieldErrors} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid, GridColumn} from "semantic-ui-react";
import getErrorMessage from "../../../Pages/Global/Form/ErrorMessage";

type FormDuplicateInsurancePackagePropsType = {
    control: Control<any>;
    errors: DeepMap<any, FieldErrors>;
    defaultValueCode: string;
    setDefaultValueCode: React.Dispatch<React.SetStateAction<string>>;
    defaultValueLabel: string;
    setDefaultValueLabel: React.Dispatch<React.SetStateAction<string>>;
};

const FormDuplicateInsurancePackage = ({
    control,
    errors,
    defaultValueCode,
    setDefaultValueCode,
    defaultValueLabel,
    setDefaultValueLabel,
}: FormDuplicateInsurancePackagePropsType) => {
    const {t} = useTranslation();

    return (
        <Form id="modal-form">
            <Grid centered columns={2}>
                <Grid.Row centered>
                    <GridColumn>
                        <Controller
                            control={control}
                            rules={{required: true, maxLength: 10}}
                            defaultValue={defaultValueCode}
                            name={"duplicated_insurance_package_code"}
                            render={({field: {name, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Code")}
                                    placeholder={t("Code")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueCode(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueCode}
                                    maxLength="10"
                                    error={getErrorMessage(
                                        t,
                                        errors,
                                        "duplicated_insurance_package_code",
                                        undefined,
                                        10
                                    )}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            rules={{required: true, maxLength: 50}}
                            defaultValue={defaultValueLabel}
                            name={"duplicated_insurance_package_label"}
                            render={({field: {name, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Label")}
                                    placeholder={t("Label")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueLabel(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueLabel}
                                    maxLength="50"
                                    error={getErrorMessage(
                                        t,
                                        errors,
                                        "duplicated_insurance_package_label",
                                        undefined,
                                        50
                                    )}
                                />
                            )}
                        />
                    </GridColumn>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormDuplicateInsurancePackage;
