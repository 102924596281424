import axios, {AxiosError} from "axios";
import {AuthenticationDTO} from "../../Pages/Global/Login/LoginPage";
import axiosInstance from "../ProductEngine.instance";
import {buildCustomError} from "../RequestUtils";
import {
    ApplicationAccessAddUpdateDTO,
    ApplicationAccessDTO,
    Company,
    CompanyProductInfosType,
    EmailDTO,
    PasswordDTO,
    RangeType,
    RegistryNotAssociatedDTO,
    RiskTypeItemType,
    UserAddDTO,
    UserDTO,
    UserGroupDTO,
    UserUpdateDTO,
    UserUpdateSelfDTO,
    VerifyTokenDTO,
} from "./Types";

// ------------------------------------ AUTHENTIFICATION ------------------------------------
export const signIn = async (authenticationDTO: AuthenticationDTO): Promise<{token: string}> => {
    try {
        const response = await axiosInstance.post<{token: string}>("/auth/sign-in", authenticationDTO);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

// ------------------------------------ SOCIETE ------------------------------------
export const getSelfCompany = async () => {
    try {
        const response = await axiosInstance.get<Company>(`/companies/_self`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getCompany = async (companyId: string) => {
    try {
        const response = await axiosInstance.get<Company>(`/companies/${companyId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getCompanies = async () => {
    try {
        const response = await axiosInstance.get<Company[]>(`/companies`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addCompany = async (company: Company) => {
    try {
        const response = await axiosInstance.post<Company>(`/companies`, company);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateCompany = async (companyId: string, company: Company) => {
    try {
        const response = await axiosInstance.put<Company>(`/companies/${companyId}`, company);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteCompany = async (companyId: string) => {
    try {
        await axiosInstance.delete<Company>(`/companies/${companyId}`);
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

// ------------------------------------ SOCIETE INFOS ------------------------------------
export const getCompanyProductInfos = async () => {
    try {
        const response = await axiosInstance.get<CompanyProductInfosType[]>(`/company_product_infos`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getCompanyProductInfo = async (companyProductInfoId: string) => {
    try {
        const response = await axiosInstance.get<CompanyProductInfosType>(
            `/company_product_infos/${companyProductInfoId}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addCompanyProductInfo = async (
    companyProductInfo: CompanyProductInfosType
): Promise<CompanyProductInfosType> => {
    try {
        const response = await axiosInstance.post(`/company_product_infos`, companyProductInfo);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateCompanyProductInfo = async (
    companyProductInfoId: string,
    companyProductInfo: CompanyProductInfosType
): Promise<CompanyProductInfosType> => {
    try {
        const response = await axiosInstance.patch(
            `/company_product_infos/${companyProductInfoId}`,
            companyProductInfo
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteCompanyProductInfo = async (companyProductInfoId: string) => {
    try {
        await axiosInstance.delete(`company_product_infos/${companyProductInfoId}`);
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

// ------------------------------------ UTILISATEURS CONSOLE ------------------------------------
export const getUsers = async (companyId: string) => {
    try {
        const response = await axiosInstance.get<UserDTO[]>(`/companies/${companyId}/users`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getUser = async (companyId: string, userId: string) => {
    try {
        const response = await axiosInstance.get<UserDTO>(`/companies/${companyId}/users/${userId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getSelfUser = async () => {
    try {
        const response = await axiosInstance.get<UserDTO>(`/companies/users/_self`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateSelfUser = async (userUpdateSelfDTO: UserUpdateSelfDTO): Promise<UserDTO> => {
    try {
        const response = await axiosInstance.put<UserDTO>(`/companies/users/_self`, userUpdateSelfDTO);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addUser = async (companyId: string, userAddDTO: UserAddDTO): Promise<UserDTO> => {
    try {
        const response = await axiosInstance.post(`companies/${companyId}/users`, userAddDTO);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateUser = async (companyId: string, userId: string, userUpdateDTO: UserUpdateDTO) => {
    try {
        await axiosInstance.put(`companies/${companyId}/users/${userId}`, userUpdateDTO);
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteUser = async (companyId: string, userId: string) => {
    try {
        await axiosInstance.delete(`companies/${companyId}/users/${userId}`);
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const resetUserPassword = async (companyId: string, userId: string) => {
    try {
        await axiosInstance.patch(`companies/${companyId}/users/${userId}/reset_password`);
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const resetSelfPasswordRequest = async (email: EmailDTO) => {
    try {
        await axiosInstance.post(`companies/users/reset_password`, email);
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const resetSelfPassword = async (password: PasswordDTO, token: string) => {
    try {
        let headers: any = {
            Authorization: `Bearer ${token}`,
        };

        if (process.env.NODE_ENV === "development") {
            headers["b27b31e5-4d97-4d01-b1ae-fafd9a9477ac"] = "e72dbd57-d834-44a1-b401-47533e26a683";
        }

        const axiosInstanceResetSelfPassword = axios.create({
            baseURL: `/moteurproduit/api`,
            headers: headers,
        });

        await axiosInstanceResetSelfPassword.put(`companies/users/password`, password);
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const activateAccount = async (password: PasswordDTO, token: string) => {
    try {
        let headers: any = {
            Authorization: `Bearer ${token}`,
        };

        if (process.env.NODE_ENV === "development") {
            headers["b27b31e5-4d97-4d01-b1ae-fafd9a9477ac"] = "e72dbd57-d834-44a1-b401-47533e26a683";
        }

        const axiosInstanceActivateAccount = axios.create({
            baseURL: `/moteurproduit/api`,
            headers: headers,
        });

        await axiosInstanceActivateAccount.put(`companies/users/activate_account`, password);
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const verifyAuthToken = async (verifyTokenDTO: VerifyTokenDTO) => {
    try {
        const axiosInstanceVerifyAuthToken = axios.create({
            baseURL: `/moteurproduit/api`,
        });

        await axiosInstanceVerifyAuthToken.post(`auth/verify-token`, verifyTokenDTO);
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

// ------------------------------------ UTILISATEURS APPPLICATIF ------------------------------------
export const getUserGroups = async (companyId: string) => {
    try {
        const response = await axiosInstance.get<UserGroupDTO[]>(`/companies/${companyId}/user_groups`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getUserGroup = async (companyId: string, userGroupId: string) => {
    try {
        const response = await axiosInstance.get<UserGroupDTO>(`/companies/${companyId}/user_groups/${userGroupId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addUserGroup = async (companyId: string, userGroupDTO: UserGroupDTO) => {
    try {
        await axiosInstance.post(`companies/${companyId}/user_groups`, userGroupDTO);
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteUserGroup = async (companyId: string, userGroupId: string) => {
    try {
        await axiosInstance.delete(`companies/${companyId}/user_groups/${userGroupId}`);
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateUserGroup = async (companyId: string, userGroupId: string, userGroupUpdateDTO: UserGroupDTO) => {
    try {
        const response = await axiosInstance.put(
            `companies/${companyId}/user_groups/${userGroupId}`,
            userGroupUpdateDTO
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getApplicationAccesses = async (companyId: string) => {
    try {
        const response = await axiosInstance.get<ApplicationAccessDTO[]>(
            `/companies/${companyId}/application_accesses`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getApplicationAccess = async (companyId: string, applicationAccessId: string) => {
    try {
        const response = await axiosInstance.get<ApplicationAccessDTO>(
            `/companies/${companyId}/application_accesses/${applicationAccessId}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addApplicationAccess = async (
    companyId: string,
    applicationAccessAddDTO: ApplicationAccessAddUpdateDTO
): Promise<ApplicationAccessDTO> => {
    try {
        const response = await axiosInstance.post<ApplicationAccessDTO>(
            `companies/${companyId}/application_accesses`,
            applicationAccessAddDTO
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateApplicationAccess = async (
    companyId: string,
    applicationAccessId: string,
    applicationAccessUpdateDTO: ApplicationAccessAddUpdateDTO
): Promise<ApplicationAccessDTO> => {
    try {
        const response = await axiosInstance.put(
            `companies/${companyId}/application_accesses/${applicationAccessId}`,
            applicationAccessUpdateDTO
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteApplicationAccess = async (companyId: string, applicationAccessId: string) => {
    try {
        await axiosInstance.delete(`companies/${companyId}/application_accesses/${applicationAccessId}`);
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

// ------------------------------------ COMPORTEMENTS ------------------------------------
export const getNotAssociatedRegistries = async (insurancePackageId: string) => {
    try {
        const response = await axiosInstance.get<Array<RegistryNotAssociatedDTO>>(
            `/registries/_not_associated_insurancePackage`,
            {params: {insurancePackageId: insurancePackageId}}
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

// ------------------------------------ TYPES DE RISQUES ------------------------------------
export const getRiskTypesApi = async (): Promise<Array<RiskTypeItemType>> => {
    try {
        const response = await axiosInstance.get<Array<RiskTypeItemType>>("/risk_types");
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addRiskType = async (riskType: {label: string}): Promise<RiskTypeItemType> => {
    try {
        const response = await axiosInstance.post<RiskTypeItemType>("/risk_types", riskType);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateRiskType = async (riskTypeId: number, riskTypeNewLabel: string): Promise<RiskTypeItemType> => {
    try {
        const response = await axiosInstance.patch<RiskTypeItemType>(
            `/risk_types/${riskTypeId}?newRiskTypeLabel=${riskTypeNewLabel}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteRiskType = async (riskTypeId: number) => {
    try {
        const response = await axiosInstance.delete(`/risk_types/${riskTypeId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

// ------------------------------------ GAMMES ------------------------------------
export const getRangesApi = async (riskTypeId: string): Promise<RangeType[]> => {
    try {
        const response = await axiosInstance.get<RangeType[]>(`/risk_types/${riskTypeId}/ranges`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addRange = async (riskTypeId: number, range: RangeType): Promise<RangeType> => {
    try {
        const response = await axiosInstance.post<RangeType>(`/risk_types/${riskTypeId}/ranges`, range);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const editRange = async (riskTypeId: number, rangeId: number, newRangeLabel: string) => {
    try {
        const response = await axiosInstance.patch(
            `/risk_types/${riskTypeId}/ranges/${rangeId}?newRangeLabel=${newRangeLabel}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteRange = async (riskTypeId: number, rangeId: number) => {
    try {
        const response = await axiosInstance.delete(`/risk_types/${riskTypeId}/ranges/${rangeId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

