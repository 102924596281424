import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Button, Icon} from "semantic-ui-react";

const BackToLoginButton = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <Button name="back-to-login" primary fluid size="large" onClick={() => navigate("/login")} type="button">
            <Icon name="arrow left" />
            {t("Back to the login page")}
        </Button>
    );
};

export default BackToLoginButton;
