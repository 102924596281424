import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseMutationResult } from "@tanstack/react-query";
import { Params, useParams } from "react-router-dom";
import {
    Button,
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    Container,
    Divider,
    Dropdown,
    Form,
    Grid,
    GridColumn,
    GridRow,
    Segment,
    Tab
} from "semantic-ui-react";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../../../Components/Modals/ModalUpdate";
import Sidebar, { SidebarItem, SidebarMenu } from "../../../../../Components/Sidebar/Sidebar";
import {
    useDeleteFeeChargeMutation,
    useGetFeeChargeQuery,
    useGetFeeChargesQuery, useUpdateFeeChargeMutation
} from "../../../../../Services/InsurancePackage/Queries";
import { FeeCharge, FeeChargeDTO, FeeType } from "../../../../../Services/InsurancePackage/Types";
import { CustomError } from "../../../../../Services/RequestUtils";
import { useGetVersionQuery } from "../../../../../Services/Version/Queries";
import { VersionDTO } from "../../../../../Services/Version/Types";
import ComputingRuleList from "../../../ComputingRule/ComputingRuleList";
import DerogatoriesTaxes from "../Coverages/DerogatoriesTaxes";
import BreadcrumbFeeCharge from "./BreadcrumbFeeCharge";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
    feeChargeId: string;
}

const FeeChargePage = ({...props}) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const [menus, setMenus] = useState<Array<SidebarMenu>>([]);

    const getVersionQuery = useGetVersionQuery(params.id, params.versionId);
    const getFeeChargesQuery = useGetFeeChargesQuery(params.insurancePackageId);
    const {
        data: feeCharge,
        status: feeChargeStatus,
        refetch: refetchFeeCharge,
        isFetching: isFeeChargeFetching,
    } = useGetFeeChargeQuery(params.insurancePackageId, params.feeChargeId);

    const deleteFeeChargeMutation = useDeleteFeeChargeMutation(
        params.id,
        params.versionId,
        params.insurancePackageId,
        params.feeChargeId
    );
    const updateFeeChargeMutation = useUpdateFeeChargeMutation(params.insurancePackageId, params.feeChargeId);

    useEffect(() => {
        let sidebarMenus: Array<SidebarMenu> = [];

        if (getFeeChargesQuery.isSuccess && getFeeChargesQuery.data !== undefined) {
            const feeChargesSidebarMenu = new SidebarMenu(
                t("Fee"),
                getFeeChargesQuery.data.map(
                    (feeChargeItem) =>
                        new SidebarItem(
                            feeChargeItem.code,
                            feeChargeItem.code,
                            `/products/${params.id}/versions/${params.versionId}/insurance-packages/${params.insurancePackageId}/fee-charges/${feeChargeItem.id}`
                        )
                )
            );
            sidebarMenus.push(feeChargesSidebarMenu);
        }

        setMenus(sidebarMenus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFeeChargesQuery.isSuccess, getFeeChargesQuery.data]);

    return (
        <>
            <Sidebar menus={menus} />
            {feeChargeStatus === "success" &&
                feeCharge !== undefined &&
                getVersionQuery.isSuccess &&
                getVersionQuery.data !== undefined && (
                    <>
                        <BreadcrumbFeeCharge feeCharge={feeCharge} sections={props.sections} />
                        <Container fluid>
                            <div className="main-container">
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width="16">
                                            <Segment className="card-container">
                                                <Tab
                                                    panes={[
                                                        {
                                                            menuItem: t("Fee Informations"),
                                                            render: () => (
                                                                <FeeChargePageGenericsInfos
                                                                    feeCharge={feeCharge}
                                                                    version={getVersionQuery.data}
                                                                    updateFeeChargeMutation={updateFeeChargeMutation}
                                                                    deleteFeeChargeMutation={deleteFeeChargeMutation}
                                                                />
                                                            ),
                                                        },
                                                        {
                                                            menuItem: t("Computing rule"),
                                                            render: () => (
                                                                <ComputingRuleList
                                                                    computingRule={feeCharge.computingRule}
                                                                    version={getVersionQuery.data}
                                                                    refetch={refetchFeeCharge}
                                                                    isQueryStatusFetching={isFeeChargeFetching}
                                                                    queryToInvalidate={
                                                                        {queryKey: ["getFeeCharge", {insurancePackageId: params.insurancePackageId, feeChargeId: params.feeChargeId}]}
                                                                    }
                                                                />
                                                            ),
                                                        },
                                                        {
                                                            menuItem: t("Derogatories Taxes"),
                                                            render: () => (
                                                                <DerogatoriesTaxes
                                                                    insurancePackageId={params.insurancePackageId}
                                                                    registryCode={feeCharge.code}
                                                                    version={getVersionQuery.data}
                                                                />
                                                            ),
                                                        },
                                                    ]}
                                                />
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Container>
                    </>
                )}
        </>
    );
};

/* ----- FeeChargePageGenericsInfos ----- */
interface FeeChargePageGenericsInfosPropsType {
    feeCharge: FeeChargeDTO;
    version: VersionDTO;

    updateFeeChargeMutation: UseMutationResult<FeeCharge, CustomError, FeeChargeDTO, Error>;
    deleteFeeChargeMutation: UseMutationResult<void, CustomError, void, Error>;
}

const FeeChargePageGenericsInfos = ({
    feeCharge,
    version,
    updateFeeChargeMutation,
    deleteFeeChargeMutation,
}: FeeChargePageGenericsInfosPropsType) => {
    const {t} = useTranslation();

    const [editingMode, setEditingMode] = useState<boolean>(false);
    const [editFeeChargeFeeType, setEditFeeChargeFeeType] = useState<FeeType>(feeCharge.feeType);

    const handleUpdate = () => {
        updateFeeChargeMutation.mutate({
            id: feeCharge.id,
            registryId: null,
            code: feeCharge.code,
            label: feeCharge.label,
            type: feeCharge.type,
            feeType: editFeeChargeFeeType,
            computingRule: feeCharge.computingRule,
        });
    };

    return (
        <Tab.Pane style={{padding: "20px"}}>
            <Grid>
                <GridRow>
                    <GridColumn width={6}>
                        <Card>
                            {editingMode ? (
                                <CardContent style={{padding: "25px"}}>
                                    <CardHeader>{feeCharge.label}</CardHeader>
                                    <Divider />
                                    <CardDescription>
                                        <Grid columns="equal" className="grid-padding">
                                            <Grid.Column width={16}>
                                                <Form.Field>
                                                    <label>{t("Fee type")}</label>
                                                    <Dropdown
                                                        fluid
                                                        options={Object.keys(FeeType).map((feeType, index) => {
                                                            return {
                                                                value: feeType,
                                                                key: index,
                                                                text: t("enums.FeeType." + feeType),
                                                            };
                                                        })}
                                                        selection
                                                        name="edit-feeCharge-feeType"
                                                        onChange={(_e, data) => {
                                                            setEditFeeChargeFeeType(data.value as FeeType);
                                                        }}
                                                        defaultValue={editFeeChargeFeeType}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>
                                        <div className="bottom-button-card">
                                            <Divider style={{marginTop: "30px"}} />
                                            <Button
                                                name="cancel-feeCharge"
                                                secondary
                                                onClick={() => setEditingMode(false)}
                                            >
                                                {t("Cancel")}
                                            </Button>
                                            <ModalUpdate
                                                isSuccess={updateFeeChargeMutation.isSuccess}
                                                isPending={updateFeeChargeMutation.isPending}
                                                isError={updateFeeChargeMutation.isError}
                                                resetMutation={updateFeeChargeMutation.reset}
                                                error={updateFeeChargeMutation.error}
                                                onSuccess={() => setEditingMode(false)}
                                                onValidate={handleUpdate}
                                                objectToUpdate={"Fee"}
                                                objectType={"male"}
                                                openModalButtonName={"update-feeCharge"}
                                            />
                                        </div>
                                    </CardDescription>
                                </CardContent>
                            ) : (
                                <CardContent style={{padding: "25px"}}>
                                    <CardHeader>{feeCharge.label}</CardHeader>
                                    <Divider />
                                    <CardDescription>
                                        <Grid columns="equal" verticalAlign="middle" className="grid-padding">
                                            <Grid.Column width={8}>
                                                <strong>{t("Fee type")} : </strong>
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                {feeCharge.feeType + " - "}
                                                {t("enums.FeeType." + feeCharge.feeType)}
                                            </Grid.Column>
                                        </Grid>

                                        {version.pipDate === null && (
                                            <div className="bottom-button-card">
                                                <Divider style={{marginTop: "20px"}} />
                                                <Button
                                                    name="edit-feeCharge"
                                                    color="blue"
                                                    onClick={() => setEditingMode(true)}
                                                >
                                                    {t("edit")}
                                                </Button>
                                                <ModalDelete
                                                    onValidate={() =>
                                                        deleteFeeChargeMutation.mutate()
                                                    }
                                                    objectToDelete={"Fee"}
                                                    objectType={"male"}
                                                    openModalButtonName={"delete-feeCharge"}
                                                    isSuccess={deleteFeeChargeMutation.isSuccess}
                                                    isPending={deleteFeeChargeMutation.isPending}
                                                    isError={deleteFeeChargeMutation.isError}
                                                    resetMutation={deleteFeeChargeMutation.reset}
                                                />
                                            </div>
                                        )}
                                    </CardDescription>
                                </CardContent>
                            )}
                        </Card>
                    </GridColumn>
                </GridRow>
            </Grid>
        </Tab.Pane>
    );
};

export default FeeChargePage;
