import axios, { AxiosError } from "axios";
import axiosInstance from "../ProductEngine.instance";
import { buildCustomError } from "../RequestUtils";
import { RejectionRule } from "./Types";

export const getRejectionRules = async (versionId: string): Promise<Array<RejectionRule>> => {
    try {
        const response = await axiosInstance.get<Array<RejectionRule>>(`/versions/${versionId}/rejection_rules`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getRejectionRule = async (versionId: string, rejectionRuleId: number): Promise<Array<RejectionRule>> => {
    try {
        const response = await axiosInstance.get<Array<RejectionRule>>(
            `/versions/${versionId}/rejection_rules/${rejectionRuleId}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addRejectionRule = async (versionId: string, rejectionRule: RejectionRule): Promise<RejectionRule> => {
    try {
        const response = await axiosInstance.post<RejectionRule>(
            `/versions/${versionId}/rejection_rules`,
            rejectionRule
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateRejectionRule = async (
    versionId: string,
    rejectionRuleId: number,
    rejectionRule: RejectionRule
): Promise<RejectionRule> => {
    try {
        const response = await axiosInstance.put<RejectionRule>(
            `/versions/${versionId}/rejection_rules/${rejectionRuleId}`,
            rejectionRule
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteRejectionRule = async (versionId: string, rejectionRuleId: number): Promise<void> => {
    try {
        const response = await axiosInstance.delete(`/versions/${versionId}/rejection_rules/${rejectionRuleId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};
