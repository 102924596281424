import axios, {AxiosError} from "axios";
import axiosInstance from "../ProductEngine.instance";
import {buildCustomError} from "../RequestUtils";
import {ProductRetrocession} from "./Types";

export const getProductRetrocessions = async (productId: string): Promise<Array<ProductRetrocession>> => {
    try {
        const response = await axiosInstance.get<Array<ProductRetrocession>>(`/products/${productId}/retrocessions`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addProductRetrocession = async (
    productId: string,
    productRetrocession: ProductRetrocession
): Promise<ProductRetrocession> => {
    try {
        const response = await axiosInstance.post<ProductRetrocession>(
            `/products/${productId}/retrocessions`,
            productRetrocession
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getProductRetrocession = async (
    productId: string,
    retrocessionId: string
): Promise<ProductRetrocession> => {
    try {
        const response = await axiosInstance.get<ProductRetrocession>(
            `/products/${productId}/retrocessions/${retrocessionId}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteProductRetrocession = async (productId: string, retrocessionId: number): Promise<void> => {
    try {
        const response = await axiosInstance.delete(`products/${productId}/retrocessions/${retrocessionId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateProductRetrocession = async (
    productId: string,
    retrocessionId: string,
    retrocession: ProductRetrocession
): Promise<ProductRetrocession> => {
    try {
        const response = await axiosInstance.put<ProductRetrocession>(
            `/products/${productId}/retrocessions/${retrocessionId}`,
            retrocession
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};
