import { useEffect, useState } from "react";
import { SidebarItem, SidebarMenu } from "../Components/Sidebar/Sidebar";
import { useGetProductsByRiskTypeQuery } from "../Services/Product/Queries";

export const useProductsSidebarMenu = () => {
    const productsByRiskType = useGetProductsByRiskTypeQuery();
    const [menus, setMenus] = useState<Array<SidebarMenu>>([]);

    useEffect(() => {
        const adaptDataForSidebar = () => {
            if (productsByRiskType.isSuccess) {
                const sidebarMenus: SidebarMenu[] = productsByRiskType.data.map(
                    (typeRisk) =>
                        new SidebarMenu(
                            typeRisk.riskType,
                            typeRisk.ranges.map(
                                (range) =>
                                    new SidebarMenu(
                                        range.range,
                                        range.products.map(
                                            (product) =>
                                                new SidebarItem(product.code, product.label, `/products/${product.id}`)
                                        )
                                    )
                            )
                        )
                );
                setMenus(sidebarMenus);
            }
        };

        adaptDataForSidebar();
    }, [productsByRiskType.isSuccess, productsByRiskType.data]);

    return menus;
};
