import {Fragment, useMemo, useState} from "react";
import {Controller, UseFormReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Form, Grid} from "semantic-ui-react";
import getErrorMessage from "../../Pages/Global/Form/ErrorMessage";
import {VariableCard} from "../../Pages/Products/Version/Fragments/Variables/VariableCard";
import {ProductRetrocession, RetrocessionMode, RetrocessionType} from "../../Services/ProductRetrocession/Types";
import {useGetVariablesList} from "../../Services/Variables/Queries";
import {Variable, VariableType, VariableValueType} from "../../Services/Variables/Types";
import {useGetVersionsQuery} from "../../Services/Version/Queries";
import {VersionDTO} from "../../Services/Version/Types";
import {sortOptionsId, sortOptionsModificationDate, sortOptionsName} from "../../Utils/SortUtils";
import EntityPicker from "../Modals/EntityPicker";

type FormProductRetrocessionPropsType = {
    form: UseFormReturn<ProductRetrocession>;
};

const FormProductRetrocession = ({form}: FormProductRetrocessionPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as {id: string};
    const {
        control,
        watch,
        setValue,
        formState: {errors},
    } = form;

    // Options
    const retrocessionModeOptions = Object.values(RetrocessionMode).map((retrocessionMode: RetrocessionMode) => {
        return {
            key: retrocessionMode.toString(),
            text: t("enums.RetrocessionMode." + retrocessionMode),
            value: retrocessionMode,
        };
    });

    const retrocessionTypeOptions = Object.values(RetrocessionType).map((retrocessionType: RetrocessionType) => {
        return {
            key: retrocessionType.toString(),
            text: t("enums.RetrocessionType." + retrocessionType),
            value: retrocessionType,
        };
    });

    // Entity Picker
    const [variableNameBaseRateEntityPickerIsOpen, setVariableNameBaseRateEntityPickerIsOpen] =
        useState<boolean>(false);
    const [variableNameCoveragesBaseEntityPickerIsOpen, setVariableNameCoveragesBaseEntityPickerIsOpen] =
        useState<boolean>(false);

    // Version
    const getVersions = useGetVersionsQuery(params.id);
    const versionId: string | undefined = useMemo(() => {
        if (getVersions.isSuccess && getVersions.data) {
            return String(
                [...getVersions.data].sort(
                    (a: VersionDTO, b: VersionDTO) =>
                        new Date(b.effectiveDate).getTime() - new Date(a.effectiveDate).getTime()
                )[0].id
            );
        }
    }, [getVersions.isSuccess, getVersions.data]);

    const [retrocessionMode, rateDerogation] = watch(["retrocessionMode", "rateDerogation"]);

    if (versionId === undefined) return null;

    return (
        <Form id="modal-form">
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Controller
                            control={control}
                            name={"businessProviderRank"}
                            rules={{required: true, min: 1, max: 5}}
                            render={({field: {onChange, onBlur, value, name}}) => (
                                <Form.Input
                                    data-cy={`form-retrocession-${name}`}
                                    autoFocus
                                    required
                                    fluid
                                    name={name}
                                    type="number"
                                    min="1"
                                    max="5"
                                    label={t("Business provider rank")}
                                    placeholder={t("Business provider rank")}
                                    onChange={onChange}
                                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                        let newValue: number = Number(e.target.value);
                                        if (newValue > 5) newValue = 5;
                                        else if (newValue < 1) newValue = 1;
                                        setValue(name, newValue);
                                        onBlur();
                                    }}
                                    value={value}
                                    error={getErrorMessage(t, errors, name, undefined, undefined, undefined, 1, 5)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name={"retrocessionMode"}
                            rules={{required: true}}
                            render={({field: {onChange, value, name}}) => (
                                <Form.Select
                                    data-cy={`form-retrocession-${name}`}
                                    required
                                    fluid
                                    name={name}
                                    label={t("Retrocession mode")}
                                    placeholder={t("Retrocession mode")}
                                    onChange={(_, {value}) => onChange(value)}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                    options={retrocessionModeOptions}
                                />
                            )}
                        />

                        {retrocessionMode !== undefined && retrocessionMode === RetrocessionMode.PRODUIT && (
                            <Fragment>
                                <Controller
                                    control={control}
                                    name={"retrocessionType"}
                                    rules={{required: true}}
                                    render={({field: {onChange, value, name}}) => (
                                        <Form.Select
                                            data-cy={`form-retrocession-${name}`}
                                            required
                                            fluid
                                            name={name}
                                            label={t("Base amount")}
                                            placeholder={t("Base amount")}
                                            onChange={(_, {value}) => onChange(value)}
                                            value={value ?? ""}
                                            error={getErrorMessage(t, errors, name)}
                                            options={retrocessionTypeOptions}
                                        />
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name={"variableNameCoveragesBase"}
                                    rules={{required: true}}
                                    render={({field: {onChange, value, name}}) => {
                                        if (variableNameCoveragesBaseEntityPickerIsOpen)
                                            return (
                                                <EntityPicker
                                                    object={"Variable"}
                                                    objectContext={"female"}
                                                    entityListGetMethod={useGetVariablesList}
                                                    entityListGetParameters={[versionId, VariableType.V]}
                                                    renderCardContent={(variable: Variable) => (
                                                        <VariableCard
                                                            versionId={versionId}
                                                            variable={variable}
                                                            onClick={(variable: Variable) => {
                                                                onChange(variable.name);
                                                                setVariableNameCoveragesBaseEntityPickerIsOpen(false);
                                                            }}
                                                        />
                                                    )}
                                                    filterEntity={(variable: Variable) =>
                                                        variable.valueType === VariableValueType.STRING
                                                    }
                                                    filterBySearch={(variable: Variable, search: string) =>
                                                        variable.name.toLowerCase().includes(search.toLowerCase()) ||
                                                        variable.valueType
                                                            .toLowerCase()
                                                            .includes(search.toLowerCase()) ||
                                                        search === ""
                                                    }
                                                    onCancel={() =>
                                                        setVariableNameCoveragesBaseEntityPickerIsOpen(false)
                                                    }
                                                    sortOptions={[
                                                        ...sortOptionsName,
                                                        ...sortOptionsId,
                                                        ...sortOptionsModificationDate,
                                                    ]}
                                                    defaultSortMethod={(a: Variable, b: Variable) =>
                                                        a.name.localeCompare(b.name)
                                                    }
                                                />
                                            );

                                        return (
                                            <div className="required field" data-cy={`form-retrocession-${name}`}>
                                                <label>{t("Variable name coverages base")}</label>
                                                <div className="ui fluid action input" style={{height: "38px"}}>
                                                    <button
                                                        className="ui icon right labeled button fluid"
                                                        style={
                                                            errors[name]
                                                                ? {
                                                                      borderRadius: ".28571429rem",
                                                                      background: "#fff6f6",
                                                                      border: "1px solid #e0b4b4",
                                                                      color: "#9f3a38",
                                                                  }
                                                                : {
                                                                      borderRadius: ".28571429rem",
                                                                      background: "#fff",
                                                                      border: "1px solid rgba(34,36,38,.15)",
                                                                  }
                                                        }
                                                        onClick={() =>
                                                            setVariableNameCoveragesBaseEntityPickerIsOpen(true)
                                                        }
                                                    >
                                                        {value !== undefined && value !== "" ? (
                                                            <p style={{color: "#000000C0", fontWeight: 400}}>{value}</p>
                                                        ) : (
                                                            <p
                                                                style={
                                                                    errors[name]
                                                                        ? {
                                                                              color: "#9f3a38",
                                                                              fontWeight: 400,
                                                                          }
                                                                        : {
                                                                              color: "rgba(191,191,191,.87)",
                                                                              fontWeight: 400,
                                                                          }
                                                                }
                                                            >
                                                                {t("Choose", {
                                                                    name: "variable",
                                                                    context: "female",
                                                                })}
                                                                ...
                                                            </p>
                                                        )}
                                                    </button>
                                                </div>
                                                {errors[name] && (
                                                    <div
                                                        className="ui pointing above prompt label"
                                                        role="alert"
                                                        aria-atomic="true"
                                                    >
                                                        {t("this field is required")}
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }}
                                />
                            </Fragment>
                        )}

                        {retrocessionMode !== undefined && retrocessionMode === RetrocessionMode.CONVENTION && (
                            <Controller
                                control={control}
                                name={"rateDerogation"}
                                defaultValue={false}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Checkbox
                                        data-cy={`form-retrocession-${name}`}
                                        toggle
                                        label={t("Product retrocession rate derogation")}
                                        name={name}
                                        checked={value ?? false}
                                        onBlur={onBlur}
                                        onChange={(_e, {checked}) => onChange(checked)}
                                    />
                                )}
                            />
                        )}

                        {retrocessionMode !== undefined &&
                            (retrocessionMode === RetrocessionMode.PRODUIT || rateDerogation) && (
                                <Controller
                                    control={control}
                                    name={"variableNameBaseRate"}
                                    rules={{required: true}}
                                    render={({field: {onChange, value, name}}) => {
                                        if (variableNameBaseRateEntityPickerIsOpen)
                                            return (
                                                <EntityPicker
                                                    object={"Variable"}
                                                    objectContext={"female"}
                                                    entityListGetMethod={useGetVariablesList}
                                                    entityListGetParameters={[versionId, VariableType.V]}
                                                    renderCardContent={(variable: Variable) => (
                                                        <VariableCard
                                                            versionId={versionId}
                                                            variable={variable}
                                                            onClick={(variable: Variable) => {
                                                                onChange(variable.name);
                                                                setVariableNameBaseRateEntityPickerIsOpen(false);
                                                            }}
                                                        />
                                                    )}
                                                    filterEntity={(variable: Variable) =>
                                                        variable.valueType === VariableValueType.NUMERIC
                                                    }
                                                    filterBySearch={(variable: Variable, search: string) =>
                                                        variable.name.toLowerCase().includes(search.toLowerCase()) ||
                                                        variable.valueType
                                                            .toLowerCase()
                                                            .includes(search.toLowerCase()) ||
                                                        search === ""
                                                    }
                                                    onCancel={() => setVariableNameBaseRateEntityPickerIsOpen(false)}
                                                    sortOptions={[
                                                        ...sortOptionsName,
                                                        ...sortOptionsId,
                                                        ...sortOptionsModificationDate,
                                                    ]}
                                                    defaultSortMethod={(a: Variable, b: Variable) =>
                                                        a.name.localeCompare(b.name)
                                                    }
                                                />
                                            );

                                        return (
                                            <div className="required field" data-cy={`form-retrocession-${name}`}>
                                                <label>{t("Variable name base rate")}</label>
                                                <div className="ui fluid action input" style={{height: "38px"}}>
                                                    <button
                                                        className="ui icon right labeled button fluid"
                                                        style={
                                                            errors[name]
                                                                ? {
                                                                      borderRadius: ".28571429rem",
                                                                      background: "#fff6f6",
                                                                      border: "1px solid #e0b4b4",
                                                                      color: "#9f3a38",
                                                                  }
                                                                : {
                                                                      borderRadius: ".28571429rem",
                                                                      background: "#fff",
                                                                      border: "1px solid rgba(34,36,38,.15)",
                                                                  }
                                                        }
                                                        onClick={() => {
                                                            setVariableNameBaseRateEntityPickerIsOpen(true);
                                                        }}
                                                    >
                                                        {value !== undefined && value !== "" ? (
                                                            <p style={{color: "#000000C0", fontWeight: 400}}>{value}</p>
                                                        ) : (
                                                            <p
                                                                style={
                                                                    errors[name]
                                                                        ? {
                                                                              color: "#9f3a38",
                                                                              fontWeight: 400,
                                                                          }
                                                                        : {
                                                                              color: "rgba(191,191,191,.87)",
                                                                              fontWeight: 400,
                                                                          }
                                                                }
                                                            >
                                                                {t("Choose", {
                                                                    name: "variable",
                                                                    context: "female",
                                                                })}
                                                                ...
                                                            </p>
                                                        )}
                                                    </button>
                                                </div>
                                                {errors[name] && (
                                                    <div
                                                        className="ui pointing above prompt label"
                                                        role="alert"
                                                        aria-atomic="true"
                                                    >
                                                        {t("this field is required")}
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }}
                                />
                            )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormProductRetrocession;
