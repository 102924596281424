import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {Button, Form, Grid, Header, Icon, Menu, Segment, Tab} from "semantic-ui-react";
import ModalAdd from "../../../../../Components/Modals/ModalAdd";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../../../Components/Modals/ModalUpdate";
import TextOverflow from "../../../../../Components/Text/TextOverflow";
import {useGetNotAssociatedRegistriesQuery} from "../../../../../Services/Company/Queries";
import {RegistryNotAssociatedDTO} from "../../../../../Services/Company/Types";
import {
    useAddCoveragesGroupMutation,
    useDeleteCoveragesGroupMutation,
    useGetCoverageGroupsQuery,
    useUpdateCoveragesGroupMutation,
} from "../../../../../Services/InsurancePackage/Queries";
import {CoverageGroup, CoverageOrOption} from "../../../../../Services/InsurancePackage/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import getErrorMessage from "../../../../Global/Form/ErrorMessage";
import OptionsList from "../Options/OptionsList";
import CoverageList from "./CoverageList";
import FormCoverageGroup from "./FormCoverageGroup";

const CoverageGroupList: React.FC<{insurancePackageId: string; version: VersionDTO | undefined}> = ({...props}) => {
    const {t} = useTranslation();
    const location = useLocation();

    const coverageGroupsQueryResult = useGetCoverageGroupsQuery(props.insurancePackageId);
    const {data, status} = useGetNotAssociatedRegistriesQuery(props.insurancePackageId);

    const [activeItem, setActiveItem] = useState<CoverageGroup | undefined>();
    const [editMode, setEditMode] = useState<Boolean>(false);
    const [selectCoverageOptions, setSelectCoverageOptions] = useState<any>([]);

    // Update Coverages group
    const updateCoverageGroupsMutation = useUpdateCoveragesGroupMutation(props.insurancePackageId);
    const updateCoverageGroupsForm = useForm<any>({mode: "onBlur"});
    const submitUpdateCoverageGroupsForm = (form: any) => {
        updateCoverageGroupsMutation.mutate({
            id: activeItem?.id,
            ...form,
        });
    };

    // Add Coverages group
    const addCoverageGroupsMutation = useAddCoveragesGroupMutation(props.insurancePackageId);
    const addCoverageGroupsForm = useForm<any>();
    const submitAddCoverageGroupsForm = (form: any) => {
        addCoverageGroupsMutation.mutate({
            ...form,
        });
    };

    const [defaultValueLabel, setDefaultValueLabel] = useState<string>("");

    // Delete Coverages group
    const deleteCoverageGroupsMutation = useDeleteCoveragesGroupMutation(props.insurancePackageId);
    const deleteCoverageGroups = () => {
        if (activeItem) {
            deleteCoverageGroupsMutation.mutate(String(activeItem.id));
        }
    };

    useEffect(() => {
        setEditMode(false);
        setActiveItem(undefined);
        if (coverageGroupsQueryResult.isSuccess && coverageGroupsQueryResult.data) {
            if (location.state && location.state.activeGroupIndex) {
                setActiveItem(
                    coverageGroupsQueryResult.data.filter((group) => {
                        return group.id == location.state.activeGroupIndex;
                    })[0]
                );
            } else {
                setActiveItem(coverageGroupsQueryResult.data[0]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coverageGroupsQueryResult.isSuccess, coverageGroupsQueryResult.data]);

    useEffect(() => {
        if (status === "success" && data !== undefined) {
            setSelectCoverageOptions(
                data
                    .filter((registry: RegistryNotAssociatedDTO) => registry.type === "G")
                    .map((item: RegistryNotAssociatedDTO) => ({
                        text: `${item.label} (${item.code})`,
                        value: item.registryId,
                        key: item.registryId,
                    }))
            );
        }
    }, [data, status]);

    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={3}>
                    <Menu fluid vertical pointing>
                        <Menu.Item>
                            <Header size="medium">{t("Groups")}</Header>
                        </Menu.Item>
                        {coverageGroupsQueryResult.data?.map((item) => (
                            <Menu.Item
                                name={`groupe-${item.id}`}
                                key={`groupe-${item.id}`}
                                active={activeItem?.id === item?.id}
                                onClick={() => setActiveItem(item)}
                            >
                                <Grid>
                                    <Grid.Column width={10} verticalAlign="middle">
                                        <Header as="h5" floated="left" style={{wordBreak: "break-word"}}>
                                            <TextOverflow value={item.label} />
                                        </Header>
                                    </Grid.Column>
                                    <Grid.Column width={6} verticalAlign="middle">
                                        {props.version !== undefined && props.version.pipDate === null && (
                                            <Button.Group basic floated="right">
                                                <ModalDelete
                                                    isPending={deleteCoverageGroupsMutation.isPending}
                                                    isSuccess={deleteCoverageGroupsMutation.isSuccess}
                                                    isError={deleteCoverageGroupsMutation.isError}
                                                    error={deleteCoverageGroupsMutation.error}
                                                    resetMutation={deleteCoverageGroupsMutation.reset}
                                                    onValidate={deleteCoverageGroups}
                                                    objectToDelete="Coverages group"
                                                    objectType="male"
                                                    renderOpenButton={() => {
                                                        return (
                                                            <Button basic icon>
                                                                <Icon name={"trash alternate"} color="red" />
                                                            </Button>
                                                        );
                                                    }}
                                                />
                                            </Button.Group>
                                        )}
                                    </Grid.Column>
                                </Grid>
                            </Menu.Item>
                        ))}
                        {props.version !== undefined && props.version.pipDate === null && (
                            <ModalAdd
                                isValidationDisabled={Object.keys(addCoverageGroupsForm.formState.errors).length !== 0}
                                isPending={addCoverageGroupsMutation.isPending}
                                isSuccess={addCoverageGroupsMutation.isSuccess}
                                isError={addCoverageGroupsMutation.isError}
                                error={addCoverageGroupsMutation.error}
                                resetMutation={addCoverageGroupsMutation.reset}
                                objectToAdd="Coverages group"
                                objectToAddContext="male"
                                onValidate={addCoverageGroupsForm.handleSubmit(submitAddCoverageGroupsForm)}
                                onSuccess={() => {
                                    setDefaultValueLabel("");
                                    setActiveItem(addCoverageGroupsMutation.data);
                                }}
                                onCancel={() => setDefaultValueLabel("")}
                                renderModalContent={() => (
                                    <FormCoverageGroup
                                        control={addCoverageGroupsForm.control}
                                        errors={addCoverageGroupsForm.formState.errors}
                                        defaultValueLabel={defaultValueLabel}
                                        setDefaultValueLabel={setDefaultValueLabel}
                                    />
                                )}
                                renderOpenButton={() => {
                                    return (
                                        <Menu.Item>
                                            <Icon name="add" color="blue" />
                                            {t("Add coverages group")}
                                        </Menu.Item>
                                    );
                                }}
                            />
                        )}
                    </Menu>
                </Grid.Column>

                {activeItem != null && (
                    <Grid.Column width={13}>
                        <Segment>
                            {editMode ? (
                                <Menu borderless style={{border: "0", boxShadow: "none"}} fluid vertical>
                                    <Menu.Item>
                                        <Form>
                                            <Grid>
                                                <Grid.Column width={10}>
                                                    <Controller
                                                        control={updateCoverageGroupsForm.control}
                                                        defaultValue={activeItem.label}
                                                        rules={{required: true}}
                                                        name={"label"}
                                                        render={({field: {name, value, onChange, onBlur}}) => (
                                                            <Form.Input
                                                                fluid
                                                                required
                                                                type={"text"}
                                                                name={name}
                                                                placeholder={t("label")}
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                error={getErrorMessage(
                                                                    t,
                                                                    updateCoverageGroupsForm.formState.errors,
                                                                    "label"
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column width={6}>
                                                    <Button.Group floated="right">
                                                        <Button
                                                            icon="cancel"
                                                            color="black"
                                                            onClick={() => setEditMode(false)}
                                                        ></Button>
                                                        <ModalUpdate
                                                            isPending={updateCoverageGroupsMutation.isPending}
                                                            isSuccess={updateCoverageGroupsMutation.isSuccess}
                                                            isError={updateCoverageGroupsMutation.isError}
                                                            error={updateCoverageGroupsMutation.error}
                                                            resetMutation={updateCoverageGroupsMutation.reset}
                                                            onValidate={updateCoverageGroupsForm.handleSubmit(
                                                                submitUpdateCoverageGroupsForm
                                                            )}
                                                            objectToUpdate={t("Coverages group")}
                                                            objectType="male"
                                                            iconOnOpenButton={true}
                                                            openButtonIcon="check"
                                                        />
                                                    </Button.Group>
                                                </Grid.Column>
                                            </Grid>
                                        </Form>
                                    </Menu.Item>
                                </Menu>
                            ) : (
                                <Menu borderless style={{border: "0", boxShadow: "none"}}>
                                    <Menu.Item style={{padding: "0px 10px"}}>
                                        <Header size="large">
                                            <TextOverflow value={activeItem.label} />
                                        </Header>
                                    </Menu.Item>
                                    <Menu.Menu position="right">
                                        <Menu.Item style={{padding: "0px 10px"}}>
                                            {props.version !== undefined && props.version.pipDate === null && (
                                                <Button basic icon onClick={() => setEditMode(true)}>
                                                    <Icon name={"edit"} color="black" />
                                                </Button>
                                            )}
                                        </Menu.Item>
                                    </Menu.Menu>
                                </Menu>
                            )}
                        </Segment>
                        <Segment>
                            <CoverageList
                                coverageGroups={coverageGroupsQueryResult.data}
                                coverageOrOption={CoverageOrOption.COVERAGE}
                                activeCoverageGroupId={activeItem.id}
                                version={props.version}
                                selectCoverageOptions={selectCoverageOptions}
                            />
                        </Segment>
                        <Segment>
                            <OptionsList
                                coverageOrOption={CoverageOrOption.OPTION}
                                activeCoverageGroupId={String(activeItem.id)}
                                version={props.version}
                                selectCoverageOptions={selectCoverageOptions}
                            />
                        </Segment>
                    </Grid.Column>
                )}
            </Grid>
        </Tab.Pane>
    );
};
export default CoverageGroupList;
