import {useState} from "react";
import {FieldValues, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Button, Card} from "semantic-ui-react";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../../../Components/Modals/ModalUpdate";
import ModalUpdateForm from "../../../../../Components/Modals/ModalUpdateForm";
import {DndPropertiesType} from "../../../../../Hooks/useDragAndDropProperties";
import {
    useDeletePricingGridDimensionValueMutation,
    useReorderDimensionValuesMutation,
    useUpdatePricingGridDimensionValueMutation,
} from "../../../../../Services/PricingGrid/Queries";
import {
    DimensionGridDTOWithId,
    DimensionGridValueDTO,
    DimensionGridValueDTOWithId,
    DimensionOrderType,
    DimensionType,
    DimensionValueType,
} from "../../../../../Services/PricingGrid/Types";
import {useGetVersionQuery} from "../../../../../Services/Version/Queries";
import UpdateFormDimensionValue from "./UpdateFormDimensionValue";

interface IParams extends Params {
    id: string;
    versionId: string;
    gridId: string;
}

type PropsType = {
    dimension: DimensionGridDTOWithId;
    dimensionGridValue: DimensionGridValueDTOWithId;
    type: DimensionType;
    valueType: DimensionValueType;
    index: number;
    dndProperties: DndPropertiesType<DimensionGridValueDTOWithId>;
};

const PricingGridDimensionValueCard = ({
    dimension,
    dimensionGridValue,
    type,
    valueType,
    index,
    dndProperties,
}: PropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const {
        control,
        reset,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const reorderDimensionValues = useReorderDimensionValuesMutation(
        params.versionId,
        params.gridId,
        dimension.id.toString(),
        dimensionGridValue.id.toString()
    );
    const updatePricingGridDimensionMutation = useUpdatePricingGridDimensionValueMutation(
        params.versionId,
        params.gridId,
        dimension.id.toString(),
        dimensionGridValue.id.toString()
    );
    const deleteMutation = useDeletePricingGridDimensionValueMutation(
        params.versionId,
        params.gridId,
        dimension.id.toString(),
        dimensionGridValue.id.toString()
    );
    const {data: version} = useGetVersionQuery(params.id, params.versionId, true);
    const handleUpdate = (editedCell: {
        label: string;
        min: number;
        max: number;
        minDate: string;
        maxDate: string;
        value: string;
        order: number;
        id: number;
    }) => {
        if (dimension.id) {
            updatePricingGridDimensionMutation.mutate(editedCell);
        }
    };

    const handleDelete = () => {
        if (dimension.id) {
            deleteMutation.mutate({
                dimensionGridId: dimension.id.toString(),
                dimensionGridValueId: dimensionGridValue.id.toString(),
            });
        }
    };
    const submitForm = async (formData: FieldValues) => {
        const editedCell = {
            ...dimensionGridValue,
            label: formData.label,
            min:
                type === DimensionType.T && valueType !== DimensionValueType.DATE
                    ? formData.min
                    : dimensionGridValue.min,
            max:
                type === DimensionType.T && valueType !== DimensionValueType.DATE
                    ? formData.max
                    : dimensionGridValue.max,
            minDate:
                type === DimensionType.T && valueType === DimensionValueType.DATE
                    ? formData.minDate
                    : dimensionGridValue.minDate,
            maxDate:
                type === DimensionType.T && valueType === DimensionValueType.DATE
                    ? formData.maxDate
                    : dimensionGridValue.maxDate,
            value: type === DimensionType.F ? formData.value : dimensionGridValue.value,
            order: formData.order,
        };

        handleUpdate(editedCell);
    };

    const renderContent = () => (
        <Card.Content>
            <Card.Header style={{fontSize: "small"}}>
                {dimensionGridValue.value !== undefined &&
                    dimensionGridValue.value !== null &&
                    dimensionGridValue.value}

                {dimensionGridValue.min !== undefined &&
                    dimensionGridValue.min !== null &&
                    dimensionGridValue.max !== undefined &&
                    dimensionGridValue.max !== null &&
                    `${dimensionGridValue.min} - ${dimensionGridValue.max}`}

                {dimensionGridValue.minDate !== undefined &&
                    dimensionGridValue.minDate !== null &&
                    dimensionGridValue.maxDate !== undefined &&
                    dimensionGridValue.maxDate !== null &&
                    `${new Date(dimensionGridValue.minDate).toLocaleDateString("fr")} - ${new Date(
                        dimensionGridValue.maxDate
                    ).toLocaleDateString("fr")}`}

                {version !== undefined && version.pipDate === null && (
                    <Button.Group basic floated="right">
                        <ModalUpdateForm
                            isValidationDisabled={Object.keys(errors).length !== 0}
                            isPending={updatePricingGridDimensionMutation.isPending}
                            isSuccess={updatePricingGridDimensionMutation.isSuccess}
                            isError={updatePricingGridDimensionMutation.isError}
                            resetMutation={updatePricingGridDimensionMutation.reset}
                            error={updatePricingGridDimensionMutation.error}
                            onValidate={handleSubmit(submitForm)}
                            onSuccess={reset}
                            onCancel={reset}
                            onClose={reset}
                            iconOnOpenButton
                            openButtonIcon="edit"
                            objectToUpdate={t("Dimension_value")}
                            objectToUpdateContext="female"
                            basicButton
                            renderModalContent={() => {
                                const dimensiondimensionGridValue: DimensionGridValueDTO & {
                                    dimensionType: DimensionType;
                                    dimensionValueType: DimensionValueType;
                                } = {
                                    ...dimensionGridValue,
                                    dimensionType: type,
                                    dimensionValueType: valueType,
                                };
                                return (
                                    <UpdateFormDimensionValue
                                        control={control}
                                        errors={errors}
                                        dimensionItem={dimensiondimensionGridValue}
                                    />
                                );
                            }}
                        />

                        <ModalDelete
                            isSuccess={deleteMutation.isSuccess}
                            isPending={deleteMutation.isPending}
                            isError={deleteMutation.isError}
                            error={deleteMutation.error}
                            resetMutation={deleteMutation.reset}
                            onValidate={handleDelete}
                            objectToDelete="Dimension_value"
                            objectType="female"
                            openButtonIcon="trash alternate"
                            iconOnOpenButton
                            basicButton
                        />
                    </Button.Group>
                )}
            </Card.Header>
            <Card.Meta>{dimensionGridValue.label}</Card.Meta>
        </Card.Content>
    );

    const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);

    const isDraggable = () => {
        if (version?.pipDate !== null) return false;
        else if (dimension.dimensionOrderType === DimensionOrderType.VALEUR) return false;
        else return true;
    };

    const movingCardStyle = {
        transition: "0.3s",
        cursor: isDraggable() ? "grab" : "auto",
    };

    return (
        <>
            <div
                key={index}
                className="ui card"
                style={{
                    ...movingCardStyle,
                    opacity:
                        dndProperties.draggedItem !== undefined &&
                        dndProperties.draggedItem.id === dimensionGridValue.id
                            ? 0.3
                            : 1,
                    userSelect: "none",
                }}
                draggable={isDraggable()}
                onDragStart={() => dndProperties.setDraggedItem(dimensionGridValue)}
                onDragOver={(e) => {
                    e.preventDefault();

                    if (dndProperties.temporaryItems === undefined)
                        dndProperties.setInitialTargetItemId(dimensionGridValue.id);
                    if (!dndProperties.draggedItem || dndProperties.draggedItem === dimensionGridValue) return;

                    const currentIndex = dndProperties.renderedItems.indexOf(dndProperties.draggedItem);
                    const targetIndex = dndProperties.renderedItems.indexOf(dimensionGridValue);

                    if (currentIndex !== -1 && targetIndex !== -1) {
                        const newItems = [...dndProperties.renderedItems];
                        newItems.splice(currentIndex, 1);
                        newItems.splice(targetIndex, 0, dndProperties.draggedItem);
                        dndProperties.setTemporaryItems(newItems);
                    }
                }}
                onDragEnd={() => {
                    dndProperties.setDraggedItem(undefined);
                }}
                onDrop={() => {
                    if (dndProperties.initialTargetItemId && dndProperties.draggedItem) {
                        const initialItemIndex = dndProperties.renderedItems.indexOf(dndProperties.draggedItem);
                        if (
                            dndProperties.draggedItem.id !== dndProperties.initialTargetItemId &&
                            dndProperties.draggedItem.order !== initialItemIndex + 1
                        )
                            setOpenUpdateModal(true);
                        else dndProperties.refreshData();
                    }
                }}
            >
                {renderContent()}
            </div>

            {openUpdateModal && (
                <ModalUpdate
                    hidden
                    onValidate={() => {
                        reorderDimensionValues.mutate(
                            (dndProperties.renderedItems.indexOf(dimensionGridValue) + 1).toString()
                        );
                    }}
                    onSuccess={() => {
                        setOpenUpdateModal(false);
                        dndProperties.setTemporaryItems(undefined);
                        dndProperties.setInitialTargetItemId(undefined);
                        dndProperties.setDraggedItem(undefined);
                    }}
                    isModalOpenAtStart
                    customContent={t("You are about to reorder the dimension values")}
                    onCancel={() => {
                        setOpenUpdateModal(false);
                        dndProperties.refreshData();
                    }}
                    onClose={() => {
                        setOpenUpdateModal(false);
                        dndProperties.refreshData();
                    }}
                    resetMutation={reorderDimensionValues.reset}
                    isSuccess={reorderDimensionValues.isSuccess}
                    isPending={reorderDimensionValues.isPending}
                    isError={reorderDimensionValues.isError}
                    error={reorderDimensionValues.error}
                />
            )}
        </>
    );
};

export default PricingGridDimensionValueCard;
