import * as FileSaver from "file-saver";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Button, Grid, Icon} from "semantic-ui-react";
import queryClient from "../../../Config/queryClient";
import ModalUpdatePricingGrid from "../../../Pages/Products/PricingGrid/Fragments/ModalUpdatePricingGrid";
import FormDuplicatePricingGrid from "../../../Pages/Products/PricingGrid/Fragments/PricingGridView/FormDuplicatePricingGrid";
import {
    useDeleteGridMutation,
    useDuplicatePricingGrid,
    useExportPricingGridQuery,
    useImportPricingGridMutation,
} from "../../../Services/PricingGrid/Queries";
import ModalDelete from "../../Modals/ModalDelete";
import ModalDuplicate from "../../Modals/ModalDuplicate";
import ModalUpdate from "../../Modals/ModalUpdate";

interface IParams extends Params {
    id: string;
    versionId: string;
    gridId: string;
}

const PricingGridActions: React.FC<{pricingGrid: any; isVersionValid: boolean; gridLabel: string}> = ({...props}) => {
    const {t} = useTranslation();
    const {id, versionId, gridId} = useParams() as IParams;
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const [selectedFile, setSelectedFile] = useState(null);

    const deleteGridMutation = useDeleteGridMutation(id, versionId, gridId);
    const handleDelete = () => deleteGridMutation.mutate();

    const {data, status, refetch} = useExportPricingGridQuery(versionId, gridId, false);

    useEffect(() => {
        if (status === "success" && data !== undefined) {
            const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            FileSaver.saveAs(blob, `${props.pricingGrid.label}.xlsx`);

            queryClient.removeQueries({queryKey: ["ExportPricingGrid", {versionId, gridId}]});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, status, props.pricingGrid.label]);

    const ImportPricingGridData = useImportPricingGridMutation(versionId, gridId);

    const handleExportData = () => refetch();

    const handleSetFile = ({target: {files}}: any) => setSelectedFile(files[0]);

    const handleMutate = () => ImportPricingGridData.mutate(selectedFile);

    const handleClick = () => {
        if (hiddenFileInput.current !== null) {
            hiddenFileInput.current.click();
        }
    };

    const clearSelectedFile = () => {
        setSelectedFile(null);
        if (hiddenFileInput.current !== null) {
            hiddenFileInput.current.value = "";
        }
    };

    const duplicatePricingGridMutation = useDuplicatePricingGrid(id, versionId, gridId);
    const duplicatePricingGridForm = useForm<any>();
    const submitDuplicatePricingGridForm = (form: any) => {
        duplicatePricingGridMutation.mutate({
            code: form.duplicated_pricing_grid_code,
            label: form.duplicated_pricing_grid_label,
        });
    };

    const [duplicatePricingGridCode, setDuplicatePricingGridCode] = useState("");
    const [duplicatePricingGridLabel, setDuplicatePricingGridLabel] = useState("");
    const resetFormDuplicatePricingGrid = () => {
        setDuplicatePricingGridCode("");
        setDuplicatePricingGridLabel("");
    };

    return (
        <>
            <Grid.Column textAlign={"right"} className={"breadcrumb-actions"}>
                <div className={"breadcrumb-actions-button-list"}>
                    {!props.isVersionValid && (
                        <>
                            <ModalUpdatePricingGrid pricingGridLabel={props.gridLabel} />
                            <ModalDuplicate
                                isValidationDisabled={
                                    Object.keys(duplicatePricingGridForm.formState.errors).length !== 0
                                }
                                isPending={duplicatePricingGridMutation.isPending}
                                isSuccess={duplicatePricingGridMutation.isSuccess}
                                isError={duplicatePricingGridMutation.isError}
                                error={duplicatePricingGridMutation.error}
                                resetMutation={duplicatePricingGridMutation.reset}
                                onCancel={resetFormDuplicatePricingGrid}
                                onSuccess={resetFormDuplicatePricingGrid}
                                onValidate={duplicatePricingGridForm.handleSubmit(submitDuplicatePricingGridForm)}
                                objectToDuplicate="pricing grid"
                                objectToDuplicateCodeOrLabel={props.pricingGrid.code}
                                objectToDuplicateContext="female"
                                renderModalContent={() => (
                                    <FormDuplicatePricingGrid
                                        errors={duplicatePricingGridForm.formState.errors}
                                        control={duplicatePricingGridForm.control}
                                        defaultValueCode={duplicatePricingGridCode}
                                        setDefaultValueCode={setDuplicatePricingGridCode}
                                        defaultValueLabel={duplicatePricingGridLabel}
                                        setDefaultValueLabel={setDuplicatePricingGridLabel}
                                    />
                                )}
                                renderOpenButton={() => {
                                    return (
                                        <Button name="duplicatePricingGrid" color="green">
                                            {t("Duplicate")}
                                        </Button>
                                    );
                                }}
                            />
                        </>
                    )}
                    <Button name="download_grid" onClick={handleExportData} positive>
                        <Icon name={"download"} /> {t("Export")}
                    </Button>
                    {!props.isVersionValid ? (
                        <>
                            {selectedFile !== null && (
                                <ModalUpdate
                                    onValidate={handleMutate}
                                    isModalOpenAtStart
                                    customContent={t("You are about to replace the values in the price list")}
                                    onCancel={clearSelectedFile}
                                    onClose={clearSelectedFile}
                                    onSuccess={clearSelectedFile}
                                    resetMutation={ImportPricingGridData.reset}
                                    isSuccess={ImportPricingGridData.isSuccess}
                                    isPending={ImportPricingGridData.isPending}
                                    isError={ImportPricingGridData.isError}
                                />
                            )}
                            <Button name="upload" onClick={handleClick} positive>
                                <input ref={hiddenFileInput} type={"file"} hidden onChange={handleSetFile} />
                                <Icon name={"upload"} /> {t("Import")}
                            </Button>
                            <ModalDelete
                                onValidate={() => handleDelete()}
                                objectToDelete={t("Pricing grid")}
                                isSuccess={deleteGridMutation.isSuccess}
                                isPending={deleteGridMutation.isPending}
                                isError={deleteGridMutation.isError}
                                objectType={"female"}
                                resetMutation={deleteGridMutation.reset}
                            />
                        </>
                    ) : null}
                </div>
            </Grid.Column>
        </>
    );
};

export default PricingGridActions;
