import {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Menu, Message, Segment} from "semantic-ui-react";
import ModalAdd from "../../../../../Components/Modals/ModalAdd";
import useContainerDimensions from "../../../../../Hooks/useContainerDimensions";
import {useAddDeductibleCapital} from "../../../../../Services/DeductibleCapital/Queries";
import {DeductibleCapital, DeductibleCapitalDTO, DeductibleCapitalType} from "../../../../../Services/DeductibleCapital/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import DeductibleCapitalCard from "./DeductibleCapitalCard";
import {DeductibleCapitalActiveItemType, DeductibleCapitalSelectedTypeEnum} from "./DeductibleCapitalsList";
import FormDeductibleCapital from "./FormDeductibleCapital";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
    deductibleCapitalId: string;
}

type DeductibleCapitalCardListPropsType = {
    insurancePackageId: string;
    activeItem: DeductibleCapitalActiveItemType;
    deductibleCapitalsList: Array<DeductibleCapital>;
    version: VersionDTO;
};

const DeductibleCapitalCardList = ({
    insurancePackageId,
    activeItem,
    deductibleCapitalsList,
    version,
}: DeductibleCapitalCardListPropsType) => {
    const {t} = useTranslation();
    const {columnNumberAsText, ref} = useContainerDimensions();

    const [defaultValueLabel, setDefaultValueLabel] = useState<string>("");
    const [defaultValueType, setDefaultValueType] = useState<DeductibleCapitalType>(DeductibleCapitalType.C);

    const params = useParams() as IParams;

    const addDeductibleCapitalMutation = useAddDeductibleCapital(params.id, params.versionId, insurancePackageId);
    const addDeductibleCapitalForm = useForm<DeductibleCapitalDTO>();
    const submitAddDeductibleCapital = (form: DeductibleCapitalDTO) => {
        addDeductibleCapitalMutation.mutate({
            type: form.type,
            label: form.label,
            description: null,
            coverageGroupId:
                activeItem?.type === DeductibleCapitalSelectedTypeEnum.COVERAGE_GROUP ? activeItem.id : null,
            coverageId: activeItem?.type === DeductibleCapitalSelectedTypeEnum.COVERAGE ? activeItem.id : null,
            optionId: activeItem?.type === DeductibleCapitalSelectedTypeEnum.OPTION ? activeItem.id : null,
            deductibleCapitalConditionDTOs: [
                {
                    ifCondition: null,
                    conditionPartList: null,
                    conditionOrder: 1,
                    deductibleCapitalConditionElementDTOs: [
                        {
                            order: 1,
                            text: form.label,
                            questionName: null,
                            pricingCriteriaName: null,
                            variableName: null,
                            format: null,
                        },
                    ],
                },
            ],
        });
    };

    return (
        <Segment>
            {version.pipDate === null && (
                <Menu borderless style={{border: "0", boxShadow: "none"}}>
                    <Menu.Menu position="right">
                        <Menu.Item style={{padding: "0px 10px"}}>
                            <ModalAdd
                                isValidationDisabled={
                                    Object.keys(addDeductibleCapitalForm.formState.errors).length !== 0
                                }
                                isPending={addDeductibleCapitalMutation.isPending}
                                isSuccess={addDeductibleCapitalMutation.isSuccess}
                                isError={addDeductibleCapitalMutation.isError}
                                onValidate={addDeductibleCapitalForm.handleSubmit(submitAddDeductibleCapital)}
                                onCancel={() => {
                                    addDeductibleCapitalForm.reset();
                                    setDefaultValueLabel("");
                                    setDefaultValueType(DeductibleCapitalType.C);
                                }}
                                onSuccess={() => {
                                    addDeductibleCapitalForm.reset();
                                    setDefaultValueLabel("");
                                    setDefaultValueType(DeductibleCapitalType.C);
                                }}
                                objectToAdd={"Deductible capital"}
                                renderModalContent={() => {
                                    return (
                                        <FormDeductibleCapital
                                            control={addDeductibleCapitalForm.control}
                                            errors={addDeductibleCapitalForm.formState.errors}
                                            setDefaultValueLabel={setDefaultValueLabel}
                                            setDefaultValueType={setDefaultValueType}
                                            defaultValueType={defaultValueType}
                                            defaultValueLabel={defaultValueLabel}
                                        />
                                    );
                                }}
                                objectToAddContext={"female"}
                                iconOnOpenButton
                                openButtonIcon="plus"
                            />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            )}
            {deductibleCapitalsList.length !== 0 ? (
                <div className={"ui cards " + columnNumberAsText} ref={ref}>
                    {deductibleCapitalsList.map((deductibleCapital: DeductibleCapital, index: number) => (
                        <DeductibleCapitalCard
                            key={index}
                            insurancePackageId={insurancePackageId}
                            deductibleCapital={deductibleCapital}
                        />
                    ))}
                </div>
            ) : (
                <Message warning>
                    <Message.Header>{t("No deductible capitals")}</Message.Header>
                    <Message.Content>{t("There is no deductible capitals")}</Message.Content>
                </Message>
            )}
        </Segment>
    );
};

export default DeductibleCapitalCardList;
