import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {Container, Form, Grid} from "semantic-ui-react";
import ModalAdd from "../../../Components/Modals/ModalAdd";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import queryClient from "../../../Config/queryClient";
import {useProductsSidebarMenu} from "../../../Hooks/useProductsSidebarMenu";
import {useGetRangesQuery, useRiskTypesQuery} from "../../../Services/Company/Queries";
import {RangeType, RiskTypeItemType} from "../../../Services/Company/Types";
import {useAddProductMutation} from "../../../Services/Product/Queries";
import {DebitModeType, NewProductDTO} from "../../../Services/Product/Types";
import getErrorMessage from "../../Global/Form/ErrorMessage";

type optionsType = {
    key: string;
    text: string;
    value: string | undefined;
};

type optionsListType = Array<optionsType>;

type optionsEntriesType = (RiskTypeItemType | RangeType)[];

export enum DeadlineType {
    deadlineBirthday = "deadlineBirthday",
    deadlineFixe = "deadlineFixe",
    deadlineDayFixMonthBirthday = "deadlineDayFixMonthBirthday",
}

export enum TransientModeType {
    NBJOURAVTECH = "NBJOURAVTECH",
    JOURFIXE = "JOURFIXE",
}

const createOptions = (data: optionsEntriesType): optionsListType => {
    return data.map((item: any) => ({key: item.label, text: item.label, value: item.id}));
};

const NewProductPage = () => {
    const {t} = useTranslation();
    const addProductForm = useForm<any>();
    const addProductMutation = useAddProductMutation();
    const navigate = useNavigate();

    const submitNewProduct = (form: any) => {
        let product: NewProductDTO = {
            code: form.code,
            label: form.label,
            deadlineByDay: form.deadlineByDay !== undefined ? form.deadlineByDay : 0,
            deadlineByMonth: form.deadlineByMonth !== undefined ? form.deadlineByMonth : 0,
            principalCompanyCode: form.principalCompanyCode,
            quoteValidityDuration: form.quoteValidityDuration,
            endorsementQuoteValidityDuration: form.endorsementQuoteValidityDuration,
            riskTypeId: form.riskTypeId,
            rangeId: form.rangeId,
            currency: "EUR",
            openingDate: form.openingDate,
            debitMode: form.debitMode,
            delayedEffectLimit: form.delayedEffectLimit,
            transientMode: form.transientMode,
        };
        addProductMutation.mutate(product);
    };

    const onNewProductFail = () => {
        setDefaultValueCode("");
        setDefaultValueLabel("");
        setDefaultValueRiskType(null);
        setDefaultValueRange(null);
        setDefaultValueCompanyCode("");
        setDefaultValueQuoteValididyDuration("");
        setDefaultValueEndorsementQuoteValididyDuration("");
        setDefaultValueOpeningDate("");
        setDefaultValueDeadlineType("deadlineBirthday");
        setDefaultValueDeadlineByDay(0);
        setDefaultValueDeadlineByMonth(0);
        setDefaultValueTransientMode("");
        navigate(`/`);
    };

    const menus = useProductsSidebarMenu();

    const [defaultValueCode, setDefaultValueCode] = useState<string>("");
    const [defaultValueLabel, setDefaultValueLabel] = useState<string>("");
    const [defaultValueRiskType, setDefaultValueRiskType] = useState<string | null>(null);
    const [defaultValueRange, setDefaultValueRange] = useState<string | null>(null);
    const [defaultValueCompanyCode, setDefaultValueCompanyCode] = useState<string>("");
    const [defaultValueQuoteValididyDuration, setDefaultValueQuoteValididyDuration] = useState<string>("");
    const [defaultValueEndorsementQuoteValididyDuration, setDefaultValueEndorsementQuoteValididyDuration] =
        useState<string>("");
    const [defaultValueOpeningDate, setDefaultValueOpeningDate] = useState<string>("");
    const [defaultValueDeadlineType, setDefaultValueDeadlineType] = useState<string>("deadlineBirthday");
    const [defaultValueDeadlineByDay, setDefaultValueDeadlineByDay] = useState<number>(0);
    const [defaultValueDeadlineByMonth, setDefaultValueDeadlineByMonth] = useState<number>(0);
    const [defaultValueDebitMode, setDefaultValueDebitMode] = useState<DebitModeType>();
    const [defaultValueDelayedEffectLimit, setDefaultValueDelayedEffectLimit] = useState<number>();
    const [defaultValueTransientMode, setDefaultValueTransientMode] = useState<string>("");

    return (
        <Container fluid>
            <Sidebar title={t("Product", {count: 2})} menus={menus} />
            <ModalAdd
                isValidationDisabled={Object.keys(addProductForm.formState.errors).length !== 0}
                isPending={addProductMutation.isPending}
                isSuccess={addProductMutation.isSuccess}
                isError={addProductMutation.isError}
                resetMutation={addProductMutation.reset}
                error={addProductMutation.error}
                onValidate={addProductForm.handleSubmit(submitNewProduct)}
                onCancel={onNewProductFail}
                onClose={onNewProductFail}
                openModalButtonName="new_product"
                objectToAdd="Product"
                objectToAddContext="male"
                renderModalContent={() => {
                    return (
                        <FormProduct
                            errors={addProductForm.formState.errors}
                            control={addProductForm.control}
                            defaultValueCode={defaultValueCode}
                            setDefaultValueCode={setDefaultValueCode}
                            defaultValueLabel={defaultValueLabel}
                            setDefaultValueLabel={setDefaultValueLabel}
                            defaultValueRiskType={defaultValueRiskType}
                            setDefaultValueRiskType={setDefaultValueRiskType}
                            defaultValueRange={defaultValueRange}
                            setDefaultValueRange={setDefaultValueRange}
                            defaultValueCompanyCode={defaultValueCompanyCode}
                            setDefaultValueCompanyCode={setDefaultValueCompanyCode}
                            defaultValueQuoteValididyDuration={defaultValueQuoteValididyDuration}
                            setDefaultValueQuoteValididyDuration={setDefaultValueQuoteValididyDuration}
                            defaultValueEndorsementQuoteValididyDuration={defaultValueEndorsementQuoteValididyDuration}
                            setDefaultValueEndorsementQuoteValididyDuration={
                                setDefaultValueEndorsementQuoteValididyDuration
                            }
                            defaultValueOpeningDate={defaultValueOpeningDate}
                            setDefaultValueOpeningDate={setDefaultValueOpeningDate}
                            defaultValueDeadlineType={defaultValueDeadlineType}
                            setDefaultValueDeadlineType={setDefaultValueDeadlineType}
                            defaultValueDeadlineByDay={defaultValueDeadlineByDay}
                            setDefaultValueDeadlineByDay={setDefaultValueDeadlineByDay}
                            defaultValueDeadlineByMonth={defaultValueDeadlineByMonth}
                            setDefaultValueDeadlineByMonth={setDefaultValueDeadlineByMonth}
                            defaultValueDebitMode={defaultValueDebitMode}
                            setDefaultValueDebitMode={setDefaultValueDebitMode}
                            defaultValueDelayedEffectLimit={defaultValueDelayedEffectLimit}
                            setDefaultValueDelayedEffectLimit={setDefaultValueDelayedEffectLimit}
                            defaultValueTransientMode={defaultValueTransientMode}
                            setDefaultValueTransientMode={setDefaultValueTransientMode}
                        />
                    );
                }}
                isModalOpenAtStart
                hidden
                scrolling
            />
            <div className="main-container"></div>
        </Container>
    );
};

const FormProduct = ({
    control,
    errors,
    defaultValueCode,
    setDefaultValueCode,
    defaultValueLabel,
    setDefaultValueLabel,
    defaultValueRiskType,
    setDefaultValueRiskType,
    defaultValueRange,
    setDefaultValueRange,
    defaultValueCompanyCode,
    setDefaultValueCompanyCode,
    defaultValueQuoteValididyDuration,
    setDefaultValueQuoteValididyDuration,
    defaultValueEndorsementQuoteValididyDuration,
    setDefaultValueEndorsementQuoteValididyDuration,
    defaultValueOpeningDate,
    setDefaultValueOpeningDate,
    defaultValueDeadlineType,
    setDefaultValueDeadlineType,
    defaultValueDeadlineByDay,
    setDefaultValueDeadlineByDay,
    defaultValueDeadlineByMonth,
    setDefaultValueDeadlineByMonth,
    defaultValueDebitMode,
    setDefaultValueDebitMode,
    defaultValueDelayedEffectLimit,
    setDefaultValueDelayedEffectLimit,
    defaultValueTranscientMode,
    setDefaultValueTransientMode,
}: any) => {
    const {t} = useTranslation();

    const [riskTypesOptions, setRiskTypesOptions] = useState<optionsListType>([]);
    const [rangesOptions, setRangesOptions] = useState<optionsListType>([]);
    const [selectedRiskTypeOptions, setSelectedRiskTypeOptions] = useState<number>();

    const deadlineOptions = Object.values(DeadlineType).map((deadlineType: DeadlineType, index: number) => {
        return {
            key: "deadline" + index,
            text: t("enums.DeadlineType." + deadlineType),
            value: deadlineType,
        };
    });

    const debitModeOptions = Object.values(DebitModeType).map((debitModeType: DebitModeType, index: number) => {
        return {
            key: "debit" + index,
            text: t("enums.DebitModeType." + debitModeType),
            value: debitModeType,
        };
    });

    const transientModeOptions = Object.values(TransientModeType).map(
        (transientModeType: TransientModeType, index: number) => {
            return {
                key: "transientMode" + index,
                text: t("enums.TransientModeType." + transientModeType),
                value: transientModeType,
            };
        }
    );

    const RiskTypeQuery = useRiskTypesQuery();
    const RangeMutation = useGetRangesQuery(String(selectedRiskTypeOptions), selectedRiskTypeOptions !== undefined);

    useEffect(() => {
        if (RiskTypeQuery.isSuccess && RiskTypeQuery.data) {
            const riskTypeOptions = createOptions(RiskTypeQuery.data);
            setRiskTypesOptions([{key: "select", text: "Selectionner...", value: undefined}, ...riskTypeOptions]);
        }
        if (RangeMutation.isSuccess && RangeMutation.data) {
            const rangeOptions = createOptions(RangeMutation.data);
            setRangesOptions([{key: "select", text: "Selectionner...", value: undefined}, ...rangeOptions]);
        }
    }, [RiskTypeQuery, RangeMutation]);

    const handleChange = (onchange: (param: string) => void, value: any) => {
        if (value !== undefined) {
            const data = queryClient.getQueryData<RangeType[]>(["getRangesApi", value]);
            if (data !== undefined)
                setRangesOptions([{key: "select", text: "Selectionner...", value: undefined}, ...createOptions(data)]);
            setSelectedRiskTypeOptions(value);
        }
        onchange(value);
    };

    return (
        <Container>
            <Form>
                <Grid centered columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <Controller
                                control={control}
                                defaultValue={defaultValueCode}
                                rules={{required: true}}
                                name={"code"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Input
                                        autoFocus
                                        fluid
                                        required
                                        type={"text"}
                                        name={name}
                                        label={t("Code")}
                                        placeholder={t("Code")}
                                        onChange={(e, {value}) => {
                                            setDefaultValueCode(value);
                                            onChange(value);
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength="10"
                                        error={getErrorMessage(t, errors, "code")}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                defaultValue={defaultValueLabel}
                                rules={{required: true}}
                                name={"label"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        required
                                        type={"text"}
                                        name={name}
                                        label={t("Label")}
                                        placeholder={t("Label")}
                                        onChange={(e, {value}) => {
                                            setDefaultValueLabel(value);
                                            onChange(value);
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength="50"
                                        error={getErrorMessage(t, errors, "label")}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                rules={{required: true}}
                                defaultValue={defaultValueRiskType}
                                name={"riskTypeId"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Select
                                        fluid
                                        label={t("risk_type")}
                                        name={name}
                                        required
                                        onBlur={onBlur}
                                        placeholder={t("risk_type")}
                                        onChange={(e, {value}) => {
                                            setDefaultValueRiskType(value);
                                            handleChange(onChange, value);
                                        }}
                                        value={value}
                                        error={getErrorMessage(t, errors, "riskTypeId")}
                                        options={riskTypesOptions}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                defaultValue={defaultValueRange}
                                name={"rangeId"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Select
                                        fluid
                                        label={t("Range")}
                                        name={name}
                                        onBlur={onBlur}
                                        placeholder={t("Range")}
                                        onChange={(e, {value}) => {
                                            setDefaultValueRange(value);
                                            onChange(value === undefined ? null : value);
                                        }}
                                        value={value}
                                        error={getErrorMessage(t, errors, "rangeId")}
                                        options={rangesOptions}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                defaultValue={defaultValueCompanyCode}
                                rules={{required: true}}
                                name={"principalCompanyCode"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        required
                                        type={"text"}
                                        name={name}
                                        label={t("Company code")}
                                        placeholder={t("Company code")}
                                        onChange={(e, {value}) => {
                                            setDefaultValueCompanyCode(value);
                                            onChange(value);
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength="5"
                                        error={getErrorMessage(t, errors, "principalCompanyCode")}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                defaultValue={defaultValueQuoteValididyDuration}
                                rules={{required: true, min: 0}}
                                name={"quoteValidityDuration"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        required
                                        type={"number"}
                                        name={name}
                                        label={t("quotation_validity_duration")}
                                        placeholder={t("quotation_validity_duration_modification")}
                                        onChange={(e, {value}) => {
                                            setDefaultValueQuoteValididyDuration(value);
                                            onChange(value);
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        error={getErrorMessage(t, errors, "quoteValidityDuration")}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                defaultValue={defaultValueEndorsementQuoteValididyDuration}
                                rules={{required: true, min: 0}}
                                name={"endorsementQuoteValidityDuration"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        required
                                        type={"number"}
                                        name={name}
                                        label={t("endorsement_quotation_validity_duration")}
                                        placeholder={t("endorsement_quotation_validity_duration_modification")}
                                        onChange={(e, {value}) => {
                                            setDefaultValueEndorsementQuoteValididyDuration(value);
                                            onChange(value);
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        error={getErrorMessage(t, errors, "endorsementQuoteValidityDuration")}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                defaultValue={defaultValueOpeningDate}
                                name={"openingDate"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        type="datetime-local"
                                        name={name}
                                        label={t("Opening_date")}
                                        onChange={(e, {value}) => {
                                            setDefaultValueOpeningDate(value);
                                            onChange(value);
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        error={getErrorMessage(t, errors, "openingDate")}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                rules={{required: true}}
                                name={"transientMode"}
                                defaultValue={defaultValueTranscientMode}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Select
                                        fluid
                                        required
                                        label={t("Transient Mode")}
                                        name={name}
                                        onBlur={onBlur}
                                        onChange={(e, {value}) => {
                                            setDefaultValueTransientMode(value);
                                            onChange(value);
                                        }}
                                        value={value}
                                        error={getErrorMessage(t, errors, "transientMode")}
                                        options={transientModeOptions}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                rules={{required: true}}
                                defaultValue={defaultValueDeadlineType}
                                name={"deadlineType"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Select
                                        fluid
                                        required
                                        label={t("deadlineType")}
                                        name={name}
                                        onBlur={onBlur}
                                        placeholder={t("deadlineType")}
                                        onChange={(e, {value}) => {
                                            setDefaultValueDeadlineType(value);
                                            onChange(value);
                                            if (value === "deadlineFixe") {
                                                setDefaultValueDeadlineByDay(1);
                                                setDefaultValueDeadlineByMonth(1);
                                            } else if (value === "deadlineBirthday") {
                                                setDefaultValueDeadlineByDay(0);
                                                setDefaultValueDeadlineByMonth(0);
                                            } else {
                                                setDefaultValueDeadlineByDay(1);
                                                setDefaultValueDeadlineByMonth(0);
                                            }
                                        }}
                                        value={value}
                                        error={getErrorMessage(t, errors, "deadlineType")}
                                        options={deadlineOptions}
                                    />
                                )}
                            />

                            {(defaultValueDeadlineType === "deadlineFixe" ||
                                defaultValueDeadlineType === "deadlineDayFixMonthBirthday") && (
                                <Controller
                                    control={control}
                                    defaultValue={defaultValueDeadlineByDay}
                                    rules={{
                                        required: true,
                                        min: 1,
                                        max: [4, 6, 9, 11].includes(defaultValueDeadlineByMonth)
                                            ? 30
                                            : defaultValueDeadlineByMonth === 2
                                            ? 28
                                            : 31,
                                    }}
                                    name={"deadlineByDay"}
                                    render={({field: {name, value, onChange, onBlur}}) => (
                                        <Form.Input
                                            fluid
                                            required
                                            type={"number"}
                                            name={name}
                                            label={t("deadlineByDay")}
                                            placeholder={t("deadlineByDay")}
                                            onChange={(e, {value}) => {
                                                setDefaultValueDeadlineByDay(value);
                                                onChange(value);
                                            }}
                                            onBlur={onBlur}
                                            value={defaultValueDeadlineByDay !== 0 ? defaultValueDeadlineByDay : value}
                                            min="1"
                                            max={
                                                [4, 6, 9, 11].includes(defaultValueDeadlineByMonth)
                                                    ? 30
                                                    : defaultValueDeadlineByMonth === 2
                                                    ? 28
                                                    : 31
                                            }
                                            error={getErrorMessage(
                                                t,
                                                errors,
                                                "deadlineByDay",
                                                undefined,
                                                undefined,
                                                undefined,
                                                1,
                                                [4, 6, 9, 11].includes(defaultValueDeadlineByMonth)
                                                    ? 30
                                                    : defaultValueDeadlineByMonth === 2
                                                    ? 28
                                                    : 31
                                            )}
                                        />
                                    )}
                                />
                            )}

                            {defaultValueDeadlineType === "deadlineFixe" && (
                                <Controller
                                    control={control}
                                    defaultValue={defaultValueDeadlineByMonth}
                                    rules={{required: true, min: 1, max: 12}}
                                    name={"deadlineByMonth"}
                                    render={({field: {name, value, onChange, onBlur}}) => (
                                        <Form.Input
                                            fluid
                                            required
                                            type={"number"}
                                            name={name}
                                            label={t("deadlineByMonth")}
                                            placeholder={t("deadlineByMonth")}
                                            onChange={(e, {value}) => {
                                                setDefaultValueDeadlineByMonth(value);
                                                onChange(value);
                                                if (
                                                    [4, 6, 9, 11].includes(Number(value)) &&
                                                    defaultValueDeadlineByDay > 30
                                                ) {
                                                    setDefaultValueDeadlineByDay(30);
                                                } else if (value === "2" && defaultValueDeadlineByDay > 28) {
                                                    setDefaultValueDeadlineByDay(28);
                                                }
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            min="1"
                                            max="12"
                                            error={getErrorMessage(
                                                t,
                                                errors,
                                                "deadlineByMonth",
                                                undefined,
                                                undefined,
                                                undefined,
                                                1,
                                                12
                                            )}
                                        />
                                    )}
                                />
                            )}

                            <Controller
                                rules={{required: true}}
                                control={control}
                                name={"debitMode"}
                                render={({field: {name, onChange, onBlur}}) => (
                                    <Form.Select
                                        name={name}
                                        required
                                        placeholder={t("Debit mode")}
                                        label={t("Debit mode")}
                                        onBlur={onBlur}
                                        onChange={(e, {value}) => {
                                            setDefaultValueDebitMode(value);
                                            onChange(value);
                                        }}
                                        options={debitModeOptions}
                                        error={getErrorMessage(t, errors, name)}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name={"delayedEffectLimit"}
                                defaultValue={defaultValueDelayedEffectLimit}
                                rules={{min: 0}}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        label={t("Delayed effect limit")}
                                        placeholder={t("Delayed effect limit")}
                                        name={name}
                                        id={"form-product-details-delayed-effect-limit"}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        type="number"
                                        min="0"
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </Container>
    );
};

export default NewProductPage;
