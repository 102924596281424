import {useEffect, useReducer, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {UseMutationResult} from "@tanstack/react-query";
import {Params, useParams} from "react-router-dom";
import {
    AccordionContent,
    Button,
    Divider,
    Dropdown,
    Form,
    Grid,
    GridColumn,
    GridRow,
    Header,
    Icon,
    Label,
    Message,
} from "semantic-ui-react";
import EntityPicker from "../../../../../Components/Modals/EntityPicker";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../../../Components/Modals/ModalUpdate";
import {
    ConditionPart,
    DimensionGridSearchValueType,
    GridSearch,
    OperandEnum,
} from "../../../../../Services/Condition/Types";
import {
    useDeletePricingCriteriaCondition,
    useReorderPricingCriteriaConditionMutation,
    useUpdatePricingCriteriaConditionMutation,
} from "../../../../../Services/PricingCriterias/Queries";
import {
    PricingCriteriaConditionDTO,
    PricingCriteriaItem,
    PricingCriteriaItemType,
    ProcessType,
    UpdateAssignmentPricingCriteriaConditionDTO,
    UpdateVariableCDTO,
    UpdateVariableCriteriaCDTO,
    VariableCDTO,
    VariableCritereType,
} from "../../../../../Services/PricingCriterias/Types";
import {getPricingGridDimensions} from "../../../../../Services/PricingGrid/AxiosRequests";
import {useGetAllGridQuery} from "../../../../../Services/PricingGrid/Queries";
import {DimensionGridDTO} from "../../../../../Services/PricingGrid/Types";
import {useGetQuestionsQuery} from "../../../../../Services/Question/Queries";
import {QuestionDTO, QuestionValueTypeEnum} from "../../../../../Services/Question/Types";
import {CustomError} from "../../../../../Services/RequestUtils";
import {VersionDTO} from "../../../../../Services/Version/Types";
import {GenericReducerActionType} from "../../../../../Utils/ReducerUtils";
import {sortOptionsId, sortOptionsModificationDate, sortOptionsName} from "../../../../../Utils/SortUtils";
import getErrorMessage from "../../../../Global/Form/ErrorMessage";
import ConditionHandling from "../../../Condition/ConditionHandling";
import ConditionView from "../../../Condition/Fragments/ConditionView";
import GridSearchEdit from "../../../Condition/GridSearchEdit";
import GridSearchView from "../../../Condition/GridSearchView";
import ConditionAccordionTitle from "../../../../../Components/Condition/ConditionAccordionTitle";
import { DndPropertiesType } from "../../../../../Hooks/useDragAndDropProperties";
import QuestionCard from "../../../Version/Fragments/Questions/QuestionCard";

import { ConditionWarningEditForbidden } from "../../../../../Components/Condition/ConditionWarningEditForbidden";
import { isEditingConditionForbidden } from "../../../../../Utils/ConditionPartUtils";

interface IParams extends Params {
    id: string;
    versionId: string;
    pricingCriteriaId: string;
}

const conditionKindList = [
    {key: 1, text: "Pricing criteria", value: OperandEnum.CRITERE, type: "male"},
    {key: 2, text: "Question", value: OperandEnum.QUESTION, type: "female"},
    {key: 3, text: "System variable", value: OperandEnum.SOFTWARE_CRITERIA, type: "female"},
    {key: 4, text: "Fixed value", value: OperandEnum.FIXED_VALUE, type: "female"},
];

const convertVariableCDTOTOGridSearch = (variableCDTO: VariableCDTO) => {
    const gridSearch: GridSearch = {
        refGridCode: variableCDTO !== null ? variableCDTO.refGridCode : "",
        dimensions: [],
    };

    if (variableCDTO !== null) {
        variableCDTO.variableCriteriasCDTO.forEach((dimension) => {
            gridSearch.dimensions.push({
                refDimensionCode: dimension.refDimensionCode,
                value: (dimension.value ?? dimension.questionName ?? dimension.pricingCriteriaName) ?? "",
                valueType:
                    dimension.value !== null
                        ? DimensionGridSearchValueType.VALUE
                        : dimension.pricingCriteriaName !== null
                        ? DimensionGridSearchValueType.PRICING_CRITERIA
                        : DimensionGridSearchValueType.QUESTION,
            });
        });
    }

    return gridSearch;
};

type PricingCriteriaConditionAccordionPropsType = {
    condition: PricingCriteriaConditionDTO;
    version: VersionDTO;
    index: number;
    activeIndex: number;
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
    pricingCriteria: PricingCriteriaItem;
    numberOfConditions: number;
    dndProperties: DndPropertiesType<PricingCriteriaConditionDTO>
};

const PricingCriteriaConditionAccordion = ({
    condition,
    version,
    index,
    activeIndex,
    setActiveIndex,
    pricingCriteria,
    numberOfConditions,
    dndProperties,
}: PricingCriteriaConditionAccordionPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const [editCondition, setEditCondition] = useState<boolean>(false);

    const pricingCriteriaConditionUpdateMutation = useUpdatePricingCriteriaConditionMutation(
        params.versionId,
        params.pricingCriteriaId,
        condition.id
    );

    const deletePricingCriteriaConditionMutation = useDeletePricingCriteriaCondition(
        params.versionId,
        params.pricingCriteriaId
    );

    const reorderPricingCriteriaCondition = useReorderPricingCriteriaConditionMutation(
        params.versionId,
        params.pricingCriteriaId,
        condition.id.toString(),
    );

    const handleDelete = (conditionId: number) => {
        deletePricingCriteriaConditionMutation.mutate(conditionId);
    };

    const onDeleteSuccess = () => {
        setActiveIndex(-1);
    };

    useEffect(() => {
        if (activeIndex !== index) {
            setEditCondition(false);
        }
    }, [activeIndex, index]);

    return (
        <div key={index}>
            <ConditionAccordionTitle<PricingCriteriaConditionDTO>
                version={version}
                condition={condition}
                index={index}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                numberOfConditions={numberOfConditions}
                reorderFunction={reorderPricingCriteriaCondition}
                dndProperties={dndProperties}
            />

            <AccordionContent active={activeIndex === index}>
                {condition.ifCondition === "( ( 1 == 1 ) )" && (
                    <Message warning>
                        {t(
                            "This condition contains default values, it would be convenient to update it before validating product version's"
                        )}
                    </Message>
                )}
                <Divider horizontal>
                    <Header as="h4">{t("Condition")}</Header>
                </Divider>
                {editCondition ? (
                    <ConditionHandling
                        versionId={params.versionId}
                        conditionKindList={conditionKindList}
                        conditionPartList={condition.conditionPartList}
                        setConditionPartList={(conditionPartListUpdate: ConditionPart[]) => {
                            const conditionUpdate: PricingCriteriaConditionDTO = {
                                ifCondition: null,
                                conditionPartList: conditionPartListUpdate,
                                conditionOrder: condition.conditionOrder,
                                fixedValue: condition.fixedValue,
                                processParams: condition.processParams,
                                processType: condition.processType,
                                questionName: condition.questionName,
                                variableCDTO: condition.variableCDTO,
                                id: condition.id,
                                questionType: condition.questionType,
                                pricingCriteriaId: condition.pricingCriteriaId,
                                valid: condition.valid
                            };
                            pricingCriteriaConditionUpdateMutation.mutate(conditionUpdate);
                        }}
                        setEditCondition={setEditCondition}
                        updateMutation={pricingCriteriaConditionUpdateMutation}
                    />
                ) : (
                    <>
                        <Grid columns={3}>
                            {pricingCriteria.pricingCriteriaConditions.length === 1 ? (
                                <GridColumn width={2} textAlign="left" verticalAlign="middle">
                                    {t("Default value")}
                                </GridColumn>
                            ) : index !== pricingCriteria.pricingCriteriaConditions.length - 1 ? (
                                <>
                                    <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                        {t("If")} :
                                    </GridColumn>
                                    <GridColumn width={14} textAlign="center" className="condition">
                                        <ConditionView conditionPartList={condition.conditionPartList} />
                                    </GridColumn>
                                    {version !== undefined && version.pipDate === null && (
                                        <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                            {isEditingConditionForbidden(condition.conditionPartList) ? (
                                                <ConditionWarningEditForbidden />
                                            ) : (
                                                <Button
                                                    name={"editCondition" + Number(index + 1)}
                                                    color="grey"
                                                    icon="edit"
                                                    compact
                                                    basic
                                                    onClick={() => {
                                                        setEditCondition(true);
                                                    }}
                                                />
                                            )}
                                            
                                        </GridColumn>
                                    )}
                                </>
                            ) : (
                                <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                    {t("Else")}
                                </GridColumn>
                            )}
                        </Grid>

                        <Divider horizontal>
                            <Header as="h4">{t("Assignment")}</Header>
                        </Divider>

                        <ConditionAssignment
                            pricingCriteria={pricingCriteria}
                            condition={condition}
                            version={version}
                            pricingCriteriaType={pricingCriteria.type}
                            pricingCriteriaConditionUpdateMutation={pricingCriteriaConditionUpdateMutation}
                            activeIndex={activeIndex}
                            index={index}
                        />

                        {index !== pricingCriteria.pricingCriteriaConditions.length - 1 &&
                            version !== undefined &&
                            version.pipDate === null && (
                                <>
                                    <Divider />
                                    <Grid>
                                        <GridRow>
                                            <GridColumn textAlign="right">
                                                <ModalDelete
                                                    isSuccess={deletePricingCriteriaConditionMutation.isSuccess}
                                                    isPending={deletePricingCriteriaConditionMutation.isPending}
                                                    isError={deletePricingCriteriaConditionMutation.isError}
                                                    error={deletePricingCriteriaConditionMutation.error}
                                                    resetMutation={deletePricingCriteriaConditionMutation.reset}
                                                    objectToDelete="Condition"
                                                    objectType="female"
                                                    openModalButtonName={"deleteCondition" + Number(index + 1)}
                                                    onValidate={() => handleDelete(condition.id)}
                                                    onSuccess={() => onDeleteSuccess()}
                                                />
                                            </GridColumn>
                                        </GridRow>
                                    </Grid>
                                </>
                            )}
                    </>
                )}
            </AccordionContent>
        </div>
    );
};

export default PricingCriteriaConditionAccordion;

type ConditionAssignmentPropsType = {
    pricingCriteria: PricingCriteriaItem;
    condition: PricingCriteriaConditionDTO;
    pricingCriteriaType: PricingCriteriaItemType;
    version: VersionDTO;
    pricingCriteriaConditionUpdateMutation: UseMutationResult<PricingCriteriaConditionDTO, CustomError, UpdateAssignmentPricingCriteriaConditionDTO, Error>;
    activeIndex: number;
    index: number;
};

export enum AssignmentType {
    NOTHING = "",
    VALEUR_FIXE = "VALEUR_FIXE",
    QUESTION = "QUESTION",
    RECHERCHE_GRILLE = "RECHERCHE_GRILLE",
    RECHERCHE_GRILLE_REPLACE_DIMENSION = "RECHERCHE_GRILLE_REPLACE_DIMENSION",
    RECHERCHE_GRILLE_DIMENSION_VALUE = "RECHERCHE_GRILLE_DIMENSION_VALUE",
    RECHERCHE_GRILLE_DIMENSION_PRICING_CRITERIA = "RECHERCHE_GRILLE_DIMENSION_PRICING_CRITERIA",
    RECHERCHE_GRILLE_DIMENSION_QUESTION = "RECHERCHE_GRILLE_DIMENSION_QUESTION",
    QUESTION_PROCESS_PARAMS = "QUESTION_PROCESS_PARAMS",
    QUESTION_PROCESS_PARAMS_AGE = "QUESTION_PROCESS_PARAMS_AGE",
}

export type ConditionActionType =
    | {
          type: GenericReducerActionType.EDIT | GenericReducerActionType.REMOVE;
          assignmentType: AssignmentType.VALEUR_FIXE | AssignmentType.QUESTION | AssignmentType.NOTHING;
          payload: Partial<PricingCriteriaConditionDTO>;
      }
    | {type: GenericReducerActionType.UNDO; payload: PricingCriteriaConditionDTO}
    | {
          type: GenericReducerActionType.EDIT | GenericReducerActionType.REMOVE;
          assignmentType: AssignmentType.RECHERCHE_GRILLE;
          payload: Partial<UpdateVariableCDTO>;
      }
    | {
          type: GenericReducerActionType.EDIT;
          assignmentType: AssignmentType.RECHERCHE_GRILLE_REPLACE_DIMENSION;
          payload: UpdateVariableCriteriaCDTO[];
      }
    | {
          type: GenericReducerActionType.EDIT | GenericReducerActionType.REMOVE;
          assignmentType: AssignmentType.RECHERCHE_GRILLE_DIMENSION_VALUE;
          payload: {dimensionCode: string; value: string};
      }
    | {
          type: GenericReducerActionType.EDIT | GenericReducerActionType.REMOVE;
          assignmentType: AssignmentType.RECHERCHE_GRILLE_DIMENSION_PRICING_CRITERIA;
          payload: {dimensionCode: string; value: string};
      }
    | {
        type: GenericReducerActionType.EDIT | GenericReducerActionType.REMOVE;
        assignmentType: AssignmentType.RECHERCHE_GRILLE_DIMENSION_QUESTION;
        payload: {dimensionCode: string; value: string};
      }  
    | {
          type: GenericReducerActionType.EDIT | GenericReducerActionType.REMOVE;
          assignmentType: AssignmentType.QUESTION_PROCESS_PARAMS;
          payload: {processParamsMin: string; processParamsMax: string};
      }
    | {
          type: GenericReducerActionType.EDIT | GenericReducerActionType.REMOVE;
          assignmentType: AssignmentType.QUESTION_PROCESS_PARAMS_AGE;
          payload: "M" | "R" | "PJA";
      };

const ConditionAssignment = ({
    pricingCriteria,
    condition,
    pricingCriteriaType,
    version,
    pricingCriteriaConditionUpdateMutation,
    activeIndex,
    index,
}: ConditionAssignmentPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    const [editAssignment, setEditAssignment] = useState(false);
    const [openQuestionModal, setOpenQuestionModal] = useState(false);
    const [assignmentType, setAssignmentType] = useState<AssignmentType>(AssignmentType.VALEUR_FIXE);
    const gridsQueryResult = useGetAllGridQuery(String(version.id));

    const [variable, setVariable] = useState<Partial<UpdateVariableCDTO>>({});

    const reducerCondition = (conditionState: typeof condition, action: ConditionActionType) => {
        switch (action.type) {
            case GenericReducerActionType.EDIT: {
                switch (action.assignmentType) {
                    case AssignmentType.VALEUR_FIXE:
                        return {
                            ...conditionState,
                            fixedValue: action.payload.fixedValue ?? "",
                            questionName: "",
                            variableCDTO: {
                                id: 0,
                                name: "",
                                gridId: null,
                                valueType: VariableCritereType.STRING,
                                refGridCode: "",
                                variableCriteriasCDTO: [],
                            },
                            processParams: [],
                        };
                    case AssignmentType.QUESTION:
                        return {
                            ...conditionState,
                            fixedValue: "",
                            questionName:
                                action.payload.questionName ?? conditionState.questionName,
                            questionType:
                                action.payload.questionType ?? conditionState.questionType,
                            variableCDTO: {
                                id: 0,
                                name: "",
                                gridId: null,
                                valueType: VariableCritereType.STRING,
                                refGridCode: "",
                                variableCriteriasCDTO: [],
                            },
                            processType:
                                action.payload.processType ?? conditionState.processType,
                            processParams:
                                action.payload.processType !== undefined
                                    ? action.payload.processType === "SLICE"
                                        ? ["0", "0"]
                                        : ["M"]
                                    : conditionState.processParams,
                        };
                    case AssignmentType.QUESTION_PROCESS_PARAMS:
                        return {
                            ...conditionState,
                            processParams: [action.payload.processParamsMin, action.payload.processParamsMax],
                        };
                    case AssignmentType.QUESTION_PROCESS_PARAMS_AGE:
                        return {
                            ...conditionState,
                            processParams: [action.payload],
                        };
                    case AssignmentType.RECHERCHE_GRILLE: {
                        const variable = action.payload;
                        setVariable(variable);
                        return {
                            ...conditionState,
                            fixedValue: "",
                            questionName: "",
                            variableCDTO: {
                                ...conditionState.variableCDTO,
                                ...variable,
                            },
                            processParams: [],
                        };
                    }
                    case AssignmentType.RECHERCHE_GRILLE_REPLACE_DIMENSION: {
                        setVariable({
                            ...conditionState.variableCDTO,
                            variableCriteriasCDTO: action.payload,
                        });
                        return {
                            ...conditionState,
                            fixedValue: "",
                            questionName: "",
                            variableCDTO: {
                                ...conditionState.variableCDTO,
                                variableCriteriasCDTO: action.payload,
                            },
                            processParams: [],
                        };
                    }
                    case AssignmentType.RECHERCHE_GRILLE_DIMENSION_VALUE: {
                        setVariable({
                            ...conditionState.variableCDTO,
                            variableCriteriasCDTO: conditionState.variableCDTO.variableCriteriasCDTO.map(
                                (dimension) => {
                                    if (dimension.refDimensionCode === action.payload.dimensionCode) {
                                        dimension.value = action.payload.value;
                                        dimension.pricingCriteriaName = null;
                                    }
                                    return dimension;
                                }
                            ),
                        });
                        return {
                            ...conditionState,
                            fixedValue: "",
                            questionName: "",
                            variableCDTO: {
                                ...conditionState.variableCDTO,
                                variableCriteriasCDTO: conditionState.variableCDTO.variableCriteriasCDTO.map(
                                    (dimension) => {
                                        if (dimension.refDimensionCode === action.payload.dimensionCode) {
                                            dimension.value = action.payload.value;
                                            dimension.pricingCriteriaName = null;
                                        }
                                        return dimension;
                                    }
                                ),
                            },
                            processParams: [],
                        };
                    }
                    case AssignmentType.RECHERCHE_GRILLE_DIMENSION_PRICING_CRITERIA: {
                        setVariable({
                            ...conditionState.variableCDTO,
                            variableCriteriasCDTO: conditionState.variableCDTO.variableCriteriasCDTO.map(
                                (dimension) => {
                                    if (dimension.refDimensionCode === action.payload.dimensionCode) {
                                        dimension.value = null;
                                        dimension.questionName = null;
                                        dimension.pricingCriteriaName = action.payload.value;
                                    }
                                    return dimension;
                                }
                            ),
                        });
                        return {
                            ...conditionState,
                            fixedValue: "",
                            questionName: "",
                            variableCDTO: {
                                ...conditionState.variableCDTO,
                                variableCriteriasCDTO: conditionState.variableCDTO.variableCriteriasCDTO.map(
                                    (dimension) => {
                                        if (dimension.refDimensionCode === action.payload.dimensionCode) {
                                            dimension.value = null;
                                            dimension.questionName = null;
                                            dimension.pricingCriteriaName = action.payload.value;
                                        }
                                        return dimension;
                                    }
                                ),
                            },
                            processParams: [],
                        };
                    }
                    case AssignmentType.RECHERCHE_GRILLE_DIMENSION_QUESTION: {
                        setVariable({
                            ...conditionState.variableCDTO,
                            variableCriteriasCDTO: conditionState.variableCDTO.variableCriteriasCDTO.map(
                                (dimension) => {
                                    if (dimension.refDimensionCode === action.payload.dimensionCode) {
                                        dimension.value = null;
                                        dimension.pricingCriteriaName = null;
                                        dimension.questionName = action.payload.value;
                                    }
                                    return dimension;
                                }
                            ),
                        });
                        return {
                            ...conditionState,
                            fixedValue: "",
                            questionName: "",
                            variableCDTO: {
                                ...conditionState.variableCDTO,
                                variableCriteriasCDTO: conditionState.variableCDTO.variableCriteriasCDTO.map(
                                    (dimension) => {
                                        if (dimension.refDimensionCode === action.payload.dimensionCode) {
                                            dimension.value = null;
                                            dimension.pricingCriteriaName = null;
                                            dimension.questionName = action.payload.value;
                                        }
                                        return dimension;
                                    }
                                ),
                            },
                            processParams: [],
                        };
                    }
                    case AssignmentType.NOTHING:
                    default:
                        return conditionState;
                }
            }
            case GenericReducerActionType.REMOVE: {
                switch (action.assignmentType) {
                    case AssignmentType.VALEUR_FIXE:
                        setAssignmentType(AssignmentType.NOTHING);
                        return {...conditionState, fixedValue: ""};
                    case AssignmentType.QUESTION:
                        setAssignmentType(AssignmentType.NOTHING);
                        return {...conditionState, questionName: "", processParams: []};
                    case AssignmentType.RECHERCHE_GRILLE:
                        setAssignmentType(AssignmentType.NOTHING);
                        setVariable({
                            id: 0,
                            name: "",
                            gridId: null,
                            valueType: VariableCritereType.STRING,
                            refGridCode: "",
                            variableCriteriasCDTO: [],
                        });
                        return {
                            ...conditionState,
                            variableCDTO: {
                                id: 0,
                                name: "",
                                gridId: null,
                                valueType: VariableCritereType.STRING,
                                refGridCode: "",
                                variableCriteriasCDTO: [],
                            },
                        };
                    case AssignmentType.RECHERCHE_GRILLE_DIMENSION_VALUE:
                    case AssignmentType.RECHERCHE_GRILLE_DIMENSION_PRICING_CRITERIA: {
                        setVariable({
                            ...conditionState.variableCDTO,
                            variableCriteriasCDTO: conditionState.variableCDTO.variableCriteriasCDTO.map(
                                (dimension) => {
                                    if (dimension.refDimensionCode === action.payload.dimensionCode) {
                                        dimension.value = null;
                                        dimension.pricingCriteriaName = null;
                                        dimension.questionName = null;
                                    }
                                    return dimension;
                                }
                            ),
                        });
                        return {
                            ...conditionState,
                            fixedValue: "",
                            questionName: "",
                            variableCDTO: {
                                ...conditionState.variableCDTO,
                                variableCriteriasCDTO: conditionState.variableCDTO.variableCriteriasCDTO.map(
                                    (dimension) => {
                                        if (dimension.refDimensionCode === action.payload.dimensionCode) {
                                            dimension.value = null;
                                            dimension.pricingCriteriaName = null;
                                            dimension.questionName = null;
                                        }
                                        return dimension;
                                    }
                                ),
                            },
                            processParams: [],
                        };
                    }
                    case AssignmentType.RECHERCHE_GRILLE_DIMENSION_QUESTION: {
                        setVariable({
                            ...conditionState.variableCDTO,
                            variableCriteriasCDTO: conditionState.variableCDTO.variableCriteriasCDTO.map(
                                (dimension) => {
                                    if (dimension.refDimensionCode === action.payload.dimensionCode) {
                                        dimension.value = null;
                                        dimension.pricingCriteriaName = null;
                                        dimension.questionName = null;
                                    }
                                    return dimension;
                                }
                            ),
                        });
                        return {
                            ...conditionState,
                            fixedValue: "",
                            questionName: "",
                            variableCDTO: {
                                ...conditionState.variableCDTO,
                                variableCriteriasCDTO: conditionState.variableCDTO.variableCriteriasCDTO.map(
                                    (dimension) => {
                                        if (dimension.refDimensionCode === action.payload.dimensionCode) {
                                            dimension.value = null;
                                            dimension.pricingCriteriaName = null;
                                            dimension.questionName = null;
                                        }
                                        return dimension;
                                    }
                                ),
                            },
                            processParams: [],
                        };
                    }
                    case AssignmentType.NOTHING:
                    default:
                        setAssignmentType(AssignmentType.NOTHING);
                        return conditionState;
                }
            }
            case GenericReducerActionType.UNDO: {
                return action.payload;
            }
            default:
                return conditionState;
        }
    };

    const [conditionState, dispatchCondition] = useReducer(reducerCondition, condition);

    const {
        control,
        handleSubmit,
        formState: {errors},
        setValue,
        trigger,
    } = useForm({mode: "onBlur"});

    const updatePartiallyCriteriaCondition = async () => {
        if (gridsQueryResult.data !== undefined) {
            const conditionToUpdate: UpdateAssignmentPricingCriteriaConditionDTO = conditionState;
            if (assignmentType === AssignmentType.VALEUR_FIXE) {
                conditionToUpdate.questionName = null;
                conditionToUpdate.variableCDTO = null;
                conditionToUpdate.processType = null;
                conditionToUpdate.processParams = null;
            }
            if (assignmentType === AssignmentType.RECHERCHE_GRILLE) {
                const newDimensions: DimensionGridDTO[] = await getPricingGridDimensions(
                    String(gridsQueryResult.data.find((grid) => grid.code === variable.refGridCode)?.id)
                );
                conditionToUpdate.questionName = null;
                conditionToUpdate.fixedValue = null;
                conditionToUpdate.processType = null;
                conditionToUpdate.processParams = null;
                if (
                    conditionToUpdate.variableCDTO !== null &&
                    variable?.variableCriteriasCDTO !== undefined
                ) {
                    conditionToUpdate.variableCDTO.variableCriteriasCDTO = [];
                    variable.variableCriteriasCDTO
                        .map((dimension) => {
                            if (dimension.value?.trim() === "") {
                                dimension.value = null;
                            }
                            if (dimension.pricingCriteriaName?.trim() === "") {
                                dimension.pricingCriteriaName = null;
                            }
                            if (dimension.questionName?.trim() === "") {
                                dimension.questionName = null;
                            }
                            return dimension;
                        })
                        .filter((dimension) => {
                            return (
                                newDimensions.find(
                                    (newDimension) => newDimension.code === dimension.refDimensionCode
                                ) !== undefined
                            );
                        })
                        .forEach((criteria) => {
                            conditionToUpdate.variableCDTO?.variableCriteriasCDTO.push(criteria);
                        });
                }
            }
            if (assignmentType === AssignmentType.QUESTION) {
                conditionToUpdate.variableCDTO = null;
                conditionToUpdate.fixedValue = null;

                if (conditionToUpdate.processType === ProcessType.NONE) {
                    conditionToUpdate.processType = null;
                    conditionToUpdate.processParams = null;
                }
            }
            pricingCriteriaConditionUpdateMutation.mutate(conditionToUpdate);
        }
    };

    const determineAssignmentType = () => {
        if (condition.fixedValue !== null) {
            return AssignmentType.VALEUR_FIXE;
        } else if (condition.questionName !== null) {
            return AssignmentType.QUESTION;
        } else if (condition.variableCDTO !== undefined && condition.variableCDTO !== null) {
            return AssignmentType.RECHERCHE_GRILLE;
        } else {
            return AssignmentType.NOTHING;
        }
    };

    useEffect(() => {
        setAssignmentType(determineAssignmentType());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition.fixedValue, condition.questionName, condition.variableCDTO]);

    useEffect(() => {
        setEditAssignment(false);
        dispatchCondition({type: GenericReducerActionType.UNDO, payload: condition});
    }, [condition, params.pricingCriteriaId]);

    useEffect(() => {
        if (activeIndex !== index) {
            setEditAssignment(false);
            dispatchCondition({type: GenericReducerActionType.UNDO, payload: condition});
        }
    }, [condition, activeIndex, index]);

    const options = [
        {key: 1, text: "Valeur fixe", value: AssignmentType.VALEUR_FIXE},
        {key: 2, text: "Question", value: AssignmentType.QUESTION},
        {key: 3, text: "Recherche de grille", value: AssignmentType.RECHERCHE_GRILLE},
    ];

    const closeAssignmentEdition = () => {
        setAssignmentType(determineAssignmentType());
        dispatchCondition({type: GenericReducerActionType.UNDO, payload: condition});
        setEditAssignment(false);
    };

    const adaptGirdDimensions = async () => {
        if (
            gridsQueryResult.data !== undefined &&
            condition.variableCDTO !== null &&
            gridsQueryResult.data.find((grid) => grid.code === condition.variableCDTO.refGridCode) !== undefined
        ) {
            const newDimensions: DimensionGridDTO[] = await getPricingGridDimensions(
                String(gridsQueryResult.data.find((grid) => grid.code === condition.variableCDTO.refGridCode)?.id)
            );
            const dimensionCodes = new Set(
                condition.variableCDTO.variableCriteriasCDTO.map((dimension) => dimension.refDimensionCode)
            );

            const newVariableList: UpdateVariableCriteriaCDTO[] = [
                ...condition.variableCDTO.variableCriteriasCDTO,
                ...newDimensions
                    .filter((newDimension) => !dimensionCodes.has(newDimension.code))
                    .map((dimension) => {
                        return {refDimensionCode: dimension.code, value: null, pricingCriteriaName: null, questionName: null};
                    }),
            ];
            dispatchCondition({
                type: GenericReducerActionType.EDIT,
                assignmentType: AssignmentType.RECHERCHE_GRILLE_REPLACE_DIMENSION,
                payload: newVariableList,
            });
        }
    };

    const getInputTypeBasedOnPricingCriteriaValueType = (pricingCriteriaValueType: PricingCriteriaItemType) => {
        switch (pricingCriteriaValueType) {
            case PricingCriteriaItemType.NUMERIC:
                return "number";
            case PricingCriteriaItemType.STRING:
                return "text";
            case PricingCriteriaItemType.DATE:
                return "date";
        }
    };

    return editAssignment ? (
        <Grid>
            <GridRow>
                <GridColumn width={1} textAlign="center" verticalAlign="middle" style={{whiteSpace: "nowrap"}}>
                    {t("Then")} :
                </GridColumn>
                <GridColumn width={15} textAlign="left">
                    {assignmentType === AssignmentType.VALEUR_FIXE && (
                        <Controller
                            control={control}
                            defaultValue={conditionState.fixedValue || ""}
                            rules={{required: pricingCriteriaType !== "STRING"}}
                            name={"fixedValue"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    name={name}
                                    placeholder={t("Fixed value")}
                                    maxLength="100"
                                    type={getInputTypeBasedOnPricingCriteriaValueType(pricingCriteriaType)}
                                    onBlur={onBlur}
                                    onChange={(e) => {
                                        trigger("fixedValue");
                                        dispatchCondition({
                                            type: GenericReducerActionType.EDIT,
                                            assignmentType: AssignmentType.VALEUR_FIXE,
                                            payload: {fixedValue: e.target.value},
                                        });
                                        onChange(e);
                                    }}
                                    value={value}
                                    error={getErrorMessage(t, errors, "fixedValue")}
                                    action
                                >
                                    <input />
                                    <Button
                                        name="remove_fixedValue"
                                        icon="delete"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onChange("");
                                            dispatchCondition({
                                                type: GenericReducerActionType.REMOVE,
                                                assignmentType: AssignmentType.VALEUR_FIXE,
                                                payload: {},
                                            });
                                        }}
                                    />
                                </Form.Input>
                            )}
                        />
                    )}

                    {assignmentType === AssignmentType.QUESTION &&
                        (openQuestionModal ? (
                            <EntityPicker
                                object={"Question"}
                                objectContext={"female"}
                                entityListGetMethod={useGetQuestionsQuery}
                                entityListGetParameters={[params.versionId, true]}
                                renderCardContent={(question: QuestionDTO) => (
                                    <QuestionCard
                                        question={question}
                                        versionId={version.id.toString()}
                                        onClick={(question: QuestionDTO) => {
                                            setOpenQuestionModal(false);
                                            dispatchCondition({
                                                type: GenericReducerActionType.EDIT,
                                                assignmentType: AssignmentType.QUESTION,
                                                payload: {
                                                    questionName: question.name,
                                                    questionType: question.valueType,
                                                },
                                            });
                                        }}
                                    />
                                )}
                                filterEntity={() => {
                                    return true;
                                }}
                                filterBySearch={(question: QuestionDTO, search: string): boolean => {
                                    return (
                                        question.name.toLowerCase().includes(search.toLowerCase()) ||
                                        question.valueType.toLowerCase().includes(search.toLowerCase()) ||
                                        search === ""
                                    );
                                }}
                                onCancel={() => {
                                    setOpenQuestionModal(false);
                                    dispatchCondition({
                                        type: GenericReducerActionType.REMOVE,
                                        assignmentType: AssignmentType.QUESTION,
                                        payload: {},
                                    });
                                }}
                                sortOptions={[...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate]}
                                defaultSortMethod={(a: QuestionDTO, b: QuestionDTO) =>
                                    a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
                                }
                            />
                        ) : (
                            <Button
                                icon
                                style={{background: "white", border: "solid rgba(34,36,38,.15) 1px"}}
                                labelPosition="right"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpenQuestionModal(true);
                                }}
                            >
                                {conditionState.questionName || t("Questions")}
                                <Icon
                                    className="remove_question"
                                    name="delete"
                                    onClick={(e: MouseEvent) => {
                                        e.stopPropagation();
                                        dispatchCondition({
                                            type: GenericReducerActionType.REMOVE,
                                            assignmentType: AssignmentType.QUESTION,
                                            payload: {},
                                        });
                                    }}
                                />
                            </Button>
                        ))}

                    {assignmentType === AssignmentType.RECHERCHE_GRILLE && (
                        <GridSearchEdit
                            dispatchCondition={dispatchCondition}
                            variableCDTO={conditionState.variableCDTO}
                            versionId={String(version.id)}
                            originalCondition={condition}
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            trigger={trigger}
                        />
                    )}

                    {assignmentType === AssignmentType.NOTHING && (
                        <Dropdown
                            className="assignement_type_dropdown"
                            defaultValue={assignmentType}
                            onChange={(e, {value}) => {
                                setAssignmentType(value as AssignmentType);
                                if (value === AssignmentType.QUESTION) {
                                    e.stopPropagation();
                                    dispatchCondition({
                                        type: GenericReducerActionType.EDIT,
                                        assignmentType: AssignmentType.QUESTION,
                                        payload: {processType: ProcessType.NONE},
                                    });
                                    setOpenQuestionModal(true);
                                } else if (value === AssignmentType.RECHERCHE_GRILLE) {
                                    dispatchCondition({
                                        type: GenericReducerActionType.EDIT,
                                        assignmentType: AssignmentType.RECHERCHE_GRILLE,
                                        payload: {refGridCode: "", gridId: 0, variableCriteriasCDTO: []},
                                    });
                                }
                            }}
                            options={options}
                            selection
                        />
                    )}
                </GridColumn>
            </GridRow>
            {assignmentType === AssignmentType.QUESTION && (
                <GridRow>
                    <GridColumn width={2} textAlign="right" verticalAlign="middle">
                        {t("Associated_process")} :
                    </GridColumn>
                    <GridColumn width={6} textAlign="left" verticalAlign="middle">
                        <Controller
                            control={control}
                            defaultValue={conditionState.processType || "NONE"}
                            name="processType"
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Select
                                    fluid
                                    name={name}
                                    onBlur={onBlur}
                                    required
                                    options={
                                        conditionState.questionType === QuestionValueTypeEnum.DATE ||
                                        conditionState.processType === ProcessType.CALCUL_AGE
                                            ? [
                                                  {value: "NONE", key: "NONE", text: t("No process")},
                                                  {value: "SLICE", key: "SLICE", text: t("String slice")},
                                                  {value: "CALCUL_AGE", key: "CALCUL_AGE", text: t("Compute age")},
                                              ]
                                            : [
                                                  {value: "NONE", key: "NONE", text: t("No process")},
                                                  {value: "SLICE", key: "SLICE", text: t("String slice")},
                                              ]
                                    }
                                    onChange={(_e, {value}) => {
                                        const newProcessType = value as ProcessType;
                                        dispatchCondition({
                                            type: GenericReducerActionType.EDIT,
                                            assignmentType: AssignmentType.QUESTION,
                                            payload: {processType: newProcessType},
                                        });
                                        onChange(value);
                                    }}
                                    value={value}
                                    error={getErrorMessage(t, errors, "processType")}
                                />
                            )}
                        />
                    </GridColumn>

                    {conditionState.processType === "SLICE" ? (
                        <>
                            <GridColumn width={2} textAlign="right" verticalAlign="middle">
                                {t("Associated_parameters")} :
                            </GridColumn>
                            <GridColumn width={5} textAlign="left" verticalAlign="middle">
                                <Controller
                                    control={control}
                                    defaultValue={String(Number(conditionState.processParams[0]) + 1)}
                                    rules={{required: true}}
                                    name="processParamsMin"
                                    render={({field: {name, value, onChange, onBlur}}) => (
                                        <Form.Input
                                            style={{marginBottom: "10px"}}
                                            fluid
                                            onBlur={onBlur}
                                            label={t("start_position_in_chain")}
                                            name={name}
                                            required
                                            min={1}
                                            type="number"
                                            onChange={(_e, {value}) => {
                                                dispatchCondition({
                                                    type: GenericReducerActionType.EDIT,
                                                    assignmentType: AssignmentType.QUESTION_PROCESS_PARAMS,
                                                    payload: {
                                                        processParamsMin: String(Number(value) - 1),
                                                        processParamsMax: String(
                                                            Number(value) -
                                                                1 +
                                                                Number(conditionState.processParams[1]) -
                                                                Number(conditionState.processParams[0])
                                                        ),
                                                    },
                                                });
                                                onChange(value);
                                            }}
                                            value={value}
                                            error={getErrorMessage(t, errors, "processParamsMin")}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    defaultValue={String(
                                        Number(conditionState.processParams[1]) -
                                            Number(conditionState.processParams[0])
                                    )}
                                    rules={{required: true}}
                                    name="processParamsMax"
                                    render={({field: {name, value, onChange, onBlur}}) => (
                                        <Form.Input
                                            style={{marginBottom: "10px"}}
                                            fluid
                                            onBlur={onBlur}
                                            label={t("nb_character")}
                                            name={name}
                                            required
                                            min={0}
                                            type="number"
                                            onChange={(_e, {value}) => {
                                                dispatchCondition({
                                                    type: GenericReducerActionType.EDIT,
                                                    assignmentType: AssignmentType.QUESTION_PROCESS_PARAMS,
                                                    payload: {
                                                        processParamsMin: conditionState.processParams[0],
                                                        processParamsMax: String(
                                                            Number(conditionState.processParams[0]) + Number(value)
                                                        ),
                                                    },
                                                });
                                                onChange(value);
                                            }}
                                            value={value}
                                            error={getErrorMessage(t, errors, "processParamsMax")}
                                        />
                                    )}
                                />
                            </GridColumn>
                        </>
                    ) : conditionState.processType === "CALCUL_AGE" ? (
                        <>
                            <GridColumn width={2} textAlign="right" verticalAlign="middle">
                                {t("Associated_parameters")} :
                            </GridColumn>
                            <GridColumn width={5} textAlign="left" verticalAlign="middle">
                                <Controller
                                    control={control}
                                    defaultValue={conditionState.processParams[0]}
                                    rules={{required: true}}
                                    name="processParamsAge"
                                    render={({field: {name, value, onChange, onBlur}}) => (
                                        <Form.Select
                                            fluid
                                            onBlur={onBlur}
                                            name={name}
                                            required
                                            options={[
                                                {value: "M", key: "M", text: t("enums.ProcessParamsCalculAge.M")},
                                                {value: "R", key: "R", text: t("enums.ProcessParamsCalculAge.R")},
                                                {value: "PJA", key: "PJA", text: t("enums.ProcessParamsCalculAge.PJA")},
                                            ]}
                                            onChange={(_e, {value}) => {
                                                dispatchCondition({
                                                    type: GenericReducerActionType.EDIT,
                                                    assignmentType: AssignmentType.QUESTION_PROCESS_PARAMS_AGE,
                                                    payload:
                                                        (value as string) === "M"
                                                            ? "M"
                                                            : (value as string) === "R"
                                                            ? "R"
                                                            : "PJA",
                                                });
                                                onChange(value);
                                            }}
                                            value={value}
                                            error={getErrorMessage(t, errors, "processParamsAge")}
                                        />
                                    )}
                                />
                            </GridColumn>
                        </>
                    ) : null}
                </GridRow>
            )}
            <GridRow>
                <GridColumn textAlign={"right"}>
                    <Button name="cancel" secondary onClick={() => closeAssignmentEdition()}>
                        {t("Cancel")}
                    </Button>
                    <ModalUpdate
                        isModalDisabled={Object.keys(errors).length !== 0}
                        isPending={pricingCriteriaConditionUpdateMutation.isPending}
                        isSuccess={pricingCriteriaConditionUpdateMutation.isSuccess}
                        isError={pricingCriteriaConditionUpdateMutation.isError}
                        resetMutation={pricingCriteriaConditionUpdateMutation.reset}
                        error={pricingCriteriaConditionUpdateMutation.error}
                        onSuccess={() => setEditAssignment(false)}
                        onValidate={handleSubmit(() => updatePartiallyCriteriaCondition())}
                        objectToUpdate={t("Assignment")}
                        objectType="nonbinary"
                    />
                </GridColumn>
            </GridRow>
        </Grid>
    ) : (
        <Grid>
            <GridRow>
                <GridColumn width={1} textAlign="center" verticalAlign="middle" style={{whiteSpace: "nowrap"}}>
                    {t("Then")} :
                </GridColumn>
                <GridColumn width={14} textAlign="left">
                    {assignmentType === AssignmentType.VALEUR_FIXE ? (
                        <Label size="large" className="fixed_value_assignment">
                            {pricingCriteria.type === PricingCriteriaItemType.DATE
                                ? t("format_date", {value: condition.fixedValue})
                                : condition.fixedValue}
                        </Label>
                    ) : assignmentType === AssignmentType.QUESTION ? (
                        <Label size="large" className="question_assignment">
                            {condition.questionName}
                        </Label>
                    ) : assignmentType === AssignmentType.RECHERCHE_GRILLE ? (
                        <GridSearchView gridSearch={convertVariableCDTOTOGridSearch(condition.variableCDTO)} />
                    ) : null}
                </GridColumn>
                {version !== undefined && version.pipDate === null && (
                    <GridColumn floated="right" textAlign="center" verticalAlign="middle">
                        <Button
                            className="editAssignement"
                            color="grey"
                            icon="edit"
                            compact
                            basic
                            onClick={async () => {
                                setEditAssignment(true);
                                await adaptGirdDimensions();
                            }}
                        />
                    </GridColumn>
                )}
            </GridRow>
            {condition.processType !== null && assignmentType === AssignmentType.QUESTION && !editAssignment && (
                <GridRow>
                    <GridColumn width={2} textAlign="right" verticalAlign="middle">
                        {t("Associated_process")} :
                    </GridColumn>
                    <GridColumn width={4} textAlign="left" verticalAlign="middle">
                        <Label size="large" className="associated_process">
                            {t("enums.ProcessType." + condition.processType)}
                        </Label>
                    </GridColumn>
                    {condition.processParams !== null && (
                        <>
                            <GridColumn width={2} textAlign="right" verticalAlign="middle">
                                {t("Associated_parameters")} :
                            </GridColumn>
                            {condition.processType === "SLICE" ? (
                                <GridColumn width={5} textAlign="left" verticalAlign="middle">
                                    <Label size="large" className="associated_parameters">
                                        {t("start_position_in_chain") +
                                            " : " +
                                            String(Number(condition.processParams[0]) + 1)}
                                    </Label>
                                    <Label size="large" className="associated_parameters">
                                        {t("nb_character") +
                                            " : " +
                                            String(
                                                Number(condition.processParams[1]) - Number(condition.processParams[0])
                                            )}
                                    </Label>
                                </GridColumn>
                            ) : condition.processType === "CALCUL_AGE" ? (
                                <GridColumn width={5} textAlign="left" verticalAlign="middle">
                                    <Label size="large" className="associated_parameters">
                                        {t("enums.ProcessParamsCalculAge." + condition.processParams[0])}
                                    </Label>
                                </GridColumn>
                            ) : null}
                        </>
                    )}
                </GridRow>
            )}
        </Grid>
    );
};
