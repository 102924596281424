import {useState} from "react";
import {Control, Controller, FieldErrors} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid} from "semantic-ui-react";
import getErrorMessage from "../../Pages/Global/Form/ErrorMessage";
import {ApplicationMode, OfferType, PromotionalCampaign} from "../../Services/PromotionalCampagins/Types";
import FormPromotionalCampaignFreeMonth from "./FormPromotionalCampaignFreeMonth";

type FormPromotionalCampaignPropsType = {
    control: Control<PromotionalCampaign>;
    errors: FieldErrors<PromotionalCampaign>;
    defaultValueAddPromotionalCampaignForm: PromotionalCampaign;
    setDefaultValueAddPromotionalCampaignForm: React.Dispatch<React.SetStateAction<PromotionalCampaign>>;
};

const FormPromotionalCampaign = ({
    control,
    errors,
    defaultValueAddPromotionalCampaignForm,
    setDefaultValueAddPromotionalCampaignForm,
}: FormPromotionalCampaignPropsType) => {
    const {t} = useTranslation();

    const [pickedOfferType, setPickedOfferType] = useState<OfferType>(defaultValueAddPromotionalCampaignForm.offerType);

    // Options Type d'offre
    const offerTypeOptions = Object.values(OfferType).map((offerType: OfferType, index: number) => {
        return {
            key: index,
            text: t("enums.OfferType." + offerType),
            value: offerType,
        };
    });

    // Options Mode d'application
    const applicationModeOptions = Object.values(ApplicationMode).map(
        (applicationMode: ApplicationMode, index: number) => {
            return {
                key: index,
                text: t("enums.ApplicationMode." + applicationMode),
                value: applicationMode,
            };
        }
    );

    return (
        <Form id="modal-form">
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Controller
                            control={control}
                            name={"code"}
                            defaultValue={defaultValueAddPromotionalCampaignForm.code || ""}
                            rules={{required: true}}
                            render={({field: {onChange, onBlur, value, name}}) => (
                                <Form.Input
                                    autoFocus
                                    required
                                    fluid
                                    name={name}
                                    type="text"
                                    label={t("Code")}
                                    placeholder={t("Code")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueAddPromotionalCampaignForm({
                                            ...defaultValueAddPromotionalCampaignForm,
                                            code: value,
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name={"label"}
                            defaultValue={defaultValueAddPromotionalCampaignForm.label || ""}
                            render={({field: {onChange, onBlur, value, name}}) => (
                                <Form.Input
                                    fluid
                                    name={name}
                                    type="text"
                                    label={t("Label")}
                                    placeholder={t("Label")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueAddPromotionalCampaignForm({
                                            ...defaultValueAddPromotionalCampaignForm,
                                            label: value,
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name={"description"}
                            defaultValue={defaultValueAddPromotionalCampaignForm.description || ""}
                            render={({field: {onChange, onBlur, value, name}}) => (
                                <Form.Input
                                    fluid
                                    name={name}
                                    type="text"
                                    label={t("Description")}
                                    placeholder={t("Description")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueAddPromotionalCampaignForm({
                                            ...defaultValueAddPromotionalCampaignForm,
                                            description: value,
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name={"startDate"}
                            defaultValue={defaultValueAddPromotionalCampaignForm.startDate || ""}
                            rules={{required: true}}
                            render={({field: {onChange, onBlur, value, name}}) => (
                                <Form.Input
                                    required
                                    fluid
                                    name={name}
                                    type="datetime-local"
                                    label={t("Start date")}
                                    placeholder={t("Start date")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueAddPromotionalCampaignForm({
                                            ...defaultValueAddPromotionalCampaignForm,
                                            startDate: value,
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name={"endDate"}
                            defaultValue={defaultValueAddPromotionalCampaignForm.endDate || ""}
                            rules={{required: true}}
                            render={({field: {onChange, onBlur, value, name}}) => (
                                <Form.Input
                                    required
                                    fluid
                                    name={name}
                                    type="datetime-local"
                                    label={t("End date")}
                                    placeholder={t("End date")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueAddPromotionalCampaignForm({
                                            ...defaultValueAddPromotionalCampaignForm,
                                            endDate: value,
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name={"offerType"}
                            rules={{required: true}}
                            defaultValue={defaultValueAddPromotionalCampaignForm.offerType || ""}
                            render={({field: {onChange, value, name}}) => (
                                <Form.Select
                                    required
                                    fluid
                                    name={name}
                                    label={t("Offer type")}
                                    placeholder={t("Offer type")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueAddPromotionalCampaignForm({
                                            ...defaultValueAddPromotionalCampaignForm,
                                            offerType: value as OfferType,
                                        });
                                        onChange(value);
                                        setPickedOfferType(value as OfferType);
                                    }}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                    options={offerTypeOptions}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name={"applicationMode"}
                            rules={{required: true}}
                            defaultValue={defaultValueAddPromotionalCampaignForm.applicationMode || ""}
                            render={({field: {onChange, value, name}}) => (
                                <Form.Select
                                    required
                                    fluid
                                    name={name}
                                    label={t("Application mode")}
                                    placeholder={t("Application mode")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueAddPromotionalCampaignForm({
                                            ...defaultValueAddPromotionalCampaignForm,
                                            applicationMode: value as ApplicationMode,
                                        });
                                        onChange(value);
                                    }}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                    options={applicationModeOptions}
                                />
                            )}
                        />

                        {pickedOfferType === OfferType.MOIS_GRATUIT && (
                            <FormPromotionalCampaignFreeMonth
                                control={control}
                                errors={errors}
                                defaultValueAddPromotionalCampaignForm={defaultValueAddPromotionalCampaignForm}
                                setDefaultValueAddPromotionalCampaignForm={setDefaultValueAddPromotionalCampaignForm}
                            />
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormPromotionalCampaign;
