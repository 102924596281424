import axios, { AxiosError } from "axios";
import axiosInstance from "../ProductEngine.instance";
import { buildCustomError } from "../RequestUtils";
import {
    DeductibleCapital,
    DeductibleCapitalCondition,
    DeductibleCapitalConditionDTO,
    DeductibleCapitalDTO
} from "./Types";

export const getDeductibleCapitals = async (insurancePackageId: string): Promise<Array<DeductibleCapital>> => {
    try {
        const response = await axiosInstance.get(`/insurance_packages/${insurancePackageId}/deductible_capitals`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getDeductibleCapital = async (
    insurancePackageId: string,
    deductibleCapitalId: string
): Promise<DeductibleCapital> => {
    try {
        const response = await axiosInstance.get(
            `/insurance_packages/${insurancePackageId}/deductible_capitals/${deductibleCapitalId}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addDeductibleCapital = async (
    insurancePackageId: string,
    deductibleCapital: DeductibleCapitalDTO
): Promise<DeductibleCapital> => {
    try {
        const response = await axiosInstance.post<DeductibleCapital>(
            `/insurance_packages/${insurancePackageId}/deductible_capitals`,
            deductibleCapital
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteDeductibleCapital = async (
    insurancePackageId: string,
    deductibleCapitalId: string
): Promise<void> => {
    try {
        const response = await axiosInstance.delete(
            `/insurance_packages/${insurancePackageId}/deductible_capitals/${deductibleCapitalId}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateDeductibleCapital = async (
    insurancePackageId: string,
    deductibleCapitalId: string,
    deductibleCapital: DeductibleCapitalDTO
): Promise<DeductibleCapital> => {
    try {
        const response = await axiosInstance.put<DeductibleCapital>(
            `/insurance_packages/${insurancePackageId}/deductible_capitals/${deductibleCapitalId}`,
            deductibleCapital
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addDeductibleCapitalCondition = async (
    deductibleCapitalId: string,
    deductibleCapitalCondition: DeductibleCapitalConditionDTO
): Promise<DeductibleCapitalCondition> => {
    try {
        const response = await axiosInstance.post<DeductibleCapitalCondition>(
            `/deductible_capitals/${deductibleCapitalId}/conditions`,
            deductibleCapitalCondition
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteDeductibleCapitalCondition = async (
    deductibleCapitalId: string,
    deductibleCapitalConditionId: string
): Promise<void> => {
    try {
        const response = await axiosInstance.delete(
            `/deductible_capitals/${deductibleCapitalId}/conditions/${deductibleCapitalConditionId}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateDeductibleCapitalCondition = async (
    deductibleCapitalId: string,
    deductibleCapitalCondition: DeductibleCapitalConditionDTO,
    deductibleCapitalConditionId: string
): Promise<DeductibleCapitalCondition> => {
    try {
        const response = await axiosInstance.put<DeductibleCapitalCondition>(
            `/deductible_capitals/${deductibleCapitalId}/conditions/${deductibleCapitalConditionId}`,
            deductibleCapitalCondition
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const reorderDeductibleCapitalConditionMutation = async (insurancePackageId: string, deductibleCapitalId: string, deductibleCapitalConditionId: string, order: number): Promise<void> => {
    try {
        const response = await axiosInstance.patch(`/insurance_packages/${insurancePackageId}/deductible_capitals/${deductibleCapitalId}/deductible_capitals_conditions/${deductibleCapitalConditionId}/_reorder?order=${order}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
}
