import axios from "axios";
import axiosInstance from "../ProductEngine.instance";
import {buildCustomError} from "../RequestUtils";
import {QuestionAddDTO, QuestionDTO} from "./Types";

export const getQuestion = async (versionId: string, questionId: string): Promise<QuestionDTO> => {
    try {
        const response = await axiosInstance.get<QuestionDTO>(`/versions/${versionId}/questions/${questionId}`);
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getQuestions = async (versionId: string): Promise<Array<QuestionDTO>> => {
    try {
        const response = await axiosInstance.get<QuestionDTO[]>(`/versions/${versionId}/questions`);
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addQuestion = async (versionId: string, questionAddDTO: QuestionAddDTO): Promise<QuestionDTO> => {
    try {
        const response = await axiosInstance.post<QuestionDTO>(`/versions/${versionId}/questions`, questionAddDTO);
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateQuestion = async (
    versionId: string,
    questionId: string,
    questionUpdateDTO: QuestionAddDTO
): Promise<QuestionDTO> => {
    try {
        const response = await axiosInstance.put<QuestionDTO>(
            `/versions/${versionId}/questions/${questionId}`,
            questionUpdateDTO
        );
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteQuestion = async (versionId: string, questionId: string) => {
    try {
        await axiosInstance.delete(`/versions/${versionId}/questions/${questionId}`);
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const duplicateQuestion = async (versionId: string, questionId: string, name: string): Promise<QuestionDTO> => {
    try {
        const response = await axiosInstance.post<QuestionDTO>(
            `/versions/${versionId}/questions/${questionId}/_duplicate?name=${name}`
        );
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const downloadQuestionValuesList = async (versionId: string, questionId: string) => {
    try {
        const response = await axiosInstance.get(`/versions/${versionId}/questions/${questionId}/_export`, {
            responseType: "arraybuffer",
        });
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const uploadQuestionValuesList = async (
    versionId: string,
    questionId: string,
    formData: FormData
): Promise<void> => {
    try {
        const response = await axiosInstance.post(`/versions/${versionId}/questions/${questionId}/_import`, formData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const importValuesFromDimensionGrid = async (
    versionId: string,
    questionId: string,
    gridCode: string,
    dimensionCode: string
) => {
    try {
        const response = await axiosInstance.post(`/versions/${versionId}/questions/${questionId}/_import_from_dimension?gridCode=${gridCode}&dimensionCode=${dimensionCode}`);
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};
