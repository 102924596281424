import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Params, useParams} from "react-router-dom";
import ModalUpdateForm from "../../../../../Components/Modals/ModalUpdateForm";
import {useUpdateDeductibleCapital} from "../../../../../Services/DeductibleCapital/Queries";
import {DeductibleCapital, DeductibleCapitalType} from "../../../../../Services/DeductibleCapital/Types";
import FormDeductibleCapital from "../DeductibleCapitals/FormDeductibleCapital";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
    deductibleCapitalId: string;
}

interface ModalUpdateDeductibleCapitalProps {
    deductibleCapital: DeductibleCapital;
}

const ModalUpdateDeductibleCapital = ({...props}: ModalUpdateDeductibleCapitalProps) => {
    const params = useParams() as IParams;
    const updateDeductibleCapitalMutation = useUpdateDeductibleCapital(
        params.insurancePackageId,
        params.deductibleCapitalId
    );

    const updateDeductibleCapitalForm = useForm<any>();

    const [modifiedDeductibleCapital, setModifiedDeductibleCapital] = useState<DeductibleCapital>(
        props.deductibleCapital
    );
    const [modifiedLabel, setModifiedLabel] = useState<string>(props.deductibleCapital.label || "");
    const [modifiedType, setModifiedType] = useState<DeductibleCapitalType>(props.deductibleCapital.type);

    useEffect(() => {
        setModifiedDeductibleCapital(props.deductibleCapital);
        setModifiedLabel(props.deductibleCapital.label);
        setModifiedType(props.deductibleCapital.type);
    }, [props.deductibleCapital]);

    const submitUpdateDeductibleCapital = (formData: any) => {
        updateDeductibleCapitalMutation.mutate({
            ...modifiedDeductibleCapital,
            ...formData,
            deductibleCapitalConditionDTOs: [
                ...modifiedDeductibleCapital.deductibleCapitalConditions.map((condition) => {
                    return {
                        ...condition,
                        deductibleCapitalConditionElementDTOs: condition.deductibleCapitalConditionElements,
                    };
                }),
            ],
        });
    };

    return (
        <ModalUpdateForm
            isValidationDisabled={Object.keys(updateDeductibleCapitalForm.formState.errors).length !== 0}
            isPending={updateDeductibleCapitalMutation.isPending}
            isSuccess={updateDeductibleCapitalMutation.isSuccess}
            isError={updateDeductibleCapitalMutation.isError}
            resetMutation={updateDeductibleCapitalMutation.reset}
            error={updateDeductibleCapitalMutation.error}
            onValidate={updateDeductibleCapitalForm.handleSubmit(submitUpdateDeductibleCapital)}
            objectToUpdate={props.deductibleCapital.type === DeductibleCapitalType.F ? "Franchise" : "Capital"}
            onCancel={() => {
                setModifiedLabel(props.deductibleCapital.label);
                setModifiedType(props.deductibleCapital.type);
            }}
            openModalButtonColor="blue"
            validateButtonColor="green"
            objectToUpdateContext="female"
            renderModalContent={() => (
                <FormDeductibleCapital
                    errors={updateDeductibleCapitalForm.formState.errors}
                    control={updateDeductibleCapitalForm.control}
                    defaultValueLabel={modifiedLabel}
                    setDefaultValueLabel={setModifiedLabel}
                    defaultValueType={modifiedType}
                    setDefaultValueType={setModifiedType}
                />
            )}
        />
    );
};

export default ModalUpdateDeductibleCapital;
