import {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {Grid, Header} from "semantic-ui-react";
import Sections from "../../../../Components/CustomBreadcrumb/Fragments/Sections";

const BreadcrumbESelection = ({...props}) => {
    const {t} = useTranslation();

    return (
        <Fragment>
            <div className="header-container" data-cy="custom-breadcrumb-header">
                <Header as="h2" className="top-header">
                    {t("eSelection")}
                </Header>
                <div className="sub-header">
                    <Grid>
                        <Grid.Column width={6} floated={"left"}>
                            <Sections sections={props.sections} />
                        </Grid.Column>
                    </Grid>
                </div>
            </div>
        </Fragment>
    );
};

export default BreadcrumbESelection;
