import { useEffect, useState } from "react";
import { useRiskTypesQuery } from "../Services/Company/Queries";
import { RiskTypeItemType } from "../Services/Company/Types";

export const useRiskTypesFilterByType = (type: string | undefined = undefined): RiskTypeItemType | undefined => {
    const riskTypes = useRiskTypesQuery();
    const [filteredRiskType, setFilteredRiskType] = useState<RiskTypeItemType | undefined>();

    useEffect(() => {
        const filterRiskTypes = () => {
            if (riskTypes.isSuccess) {
                return riskTypes.data?.find((riskType) => riskType.label === type);
            }
        };

        setFilteredRiskType(filterRiskTypes());
    }, [riskTypes, type]);
    return filteredRiskType;
};
