import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import ModalUpdateForm from "../../../../Components/Modals/ModalUpdateForm";
import {useUpdateVariable} from "../../../../Services/Variables/Queries";
import {Variable, VariableDTO, VariableType, VariableValueType} from "../../../../Services/Variables/Types";
import FormVariable from "./FormVariable";

interface IParams extends Params {
    id: string;
    versionId: string;
    variableId: string;
}

type PropsType = {
    variable: Variable;
};

const ModalUpdateVariable = ({...props}: PropsType) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const updateVariableMutation = useUpdateVariable(params.versionId, params.variableId);
    const updateVariableForm = useForm<VariableDTO>();
    const submitUpdateVariable = (form: VariableDTO) => {
        const variable = {
            name: form.name,
            saveQuote: form.saveQuote,
            variableType: props.variable.variableType,
            valueType: form.valueType
        };

        updateVariableMutation.mutate(form.variableType === VariableType.V ? {...variable, valueType: form.valueType} : variable);
    };

    const [defaultValueName, setDefaultValueName] = useState<string>("");
    const [defaultValueType, setDefaultValueType] = useState<VariableValueType>();
    const [defaultValueSaveQuote, setDefaultValueSaveQuote] = useState<boolean>(false);

    useEffect(() => {
        setDefaultValueName(props.variable.name);
        setDefaultValueType(props.variable.valueType);
        setDefaultValueSaveQuote(props.variable.saveQuote);
    }, [props.variable.name, props.variable.saveQuote, props.variable.valueType]);

    const resetInputs = () => {
        setDefaultValueName(props.variable.name);
        setDefaultValueSaveQuote(props.variable.saveQuote);
    };

    return (
        <ModalUpdateForm
            isValidationDisabled={Object.keys(updateVariableForm.formState.errors).length !== 0}
            isPending={updateVariableMutation.isPending}
            isSuccess={updateVariableMutation.isSuccess}
            isError={updateVariableMutation.isError}
            resetMutation={updateVariableMutation.reset}
            error={updateVariableMutation.error}
            onValidate={updateVariableForm.handleSubmit(submitUpdateVariable)}
            onCancel={() => resetInputs()}
            openButtonIcon="edit"
            objectToUpdate={t("Variable").toLowerCase()}
            openModalButtonColor="blue"
            validateButtonColor="green"
            objectToUpdateContext="female"
            renderModalContent={() => {
                return (
                    <FormVariable
                        errors={updateVariableForm.formState.errors}
                        control={updateVariableForm.control}
                        type={props.variable.variableType}
                        defaultValueName={defaultValueName}
                        setDefaultValueName={setDefaultValueName}
                        defaultValueType={defaultValueType}
                        setDefaultValueType={setDefaultValueType}
                        defaultValueSaveQuote={defaultValueSaveQuote}
                        setDefaultValueSaveQuote={setDefaultValueSaveQuote}
                    />
                );
            }}
        />
    );
};

export default ModalUpdateVariable;
