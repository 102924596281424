export type SortOption = {
    text: string;
    field: string;
    fieldType: "STRING" | "NUMERIC" | "DATE";
    order: "ASCENDING" | "DESCENDING";
};

export const getSortMethod = (sortOption: SortOption | undefined): ((a: any, b: any) => number) => {
    if (sortOption !== undefined) {
        // STRING ASC
        const compareByFieldStringAsc = (a: any, b: any): number => {
            return a[sortOption.field].toLowerCase() > b[sortOption.field].toLowerCase() ? 1 : -1;
        };

        // STRING DESC
        const compareByFieldStringDesc = (a: any, b: any): number => {
            return a[sortOption.field].toLowerCase() < b[sortOption.field].toLowerCase() ? 1 : -1;
        };

        // NUMERIC ASC
        const compareByFieldNumericAsc = (a: any, b: any): number => {
            return a[sortOption.field] - b[sortOption.field];
        };

        // NUMERIC DESC
        const compareByFieldNumericDesc = (a: any, b: any): number => {
            return b[sortOption.field] - a[sortOption.field];
        };

        // DATE ASC
        const compareByFieldDateAsc = (a: any, b: any): number => {
            return new Date(a[sortOption.field]).getTime() > new Date(b[sortOption.field]).getTime() ? 1 : -1;
        };

        // DATE DESC
        const compareByFieldDateDesc = (a: any, b: any): number => {
            return new Date(a[sortOption.field]).getTime() < new Date(b[sortOption.field]).getTime() ? 1 : -1;
        };

        switch (sortOption.fieldType) {
            case "STRING":
                return sortOption.order === "ASCENDING" ? compareByFieldStringAsc : compareByFieldStringDesc;
            case "NUMERIC":
                return sortOption.order === "ASCENDING" ? compareByFieldNumericAsc : compareByFieldNumericDesc;
            case "DATE":
                return sortOption.order === "ASCENDING" ? compareByFieldDateAsc : compareByFieldDateDesc;
        }
    } else {
        return (a: any, b: any) => 1;
    }
};

export const sortOptionsName: SortOption[] = [
    {
        text: "enums.SortOptionEnum.AlphabeticalAscending",
        field: "name",
        fieldType: "STRING",
        order: "ASCENDING",
    },
    {
        text: "enums.SortOptionEnum.AlphabeticalDescending",
        field: "name",
        fieldType: "STRING",
        order: "DESCENDING",
    },
];

export const sortOptionsLabel: SortOption[] = [
    {
        text: "enums.SortOptionEnum.AlphabeticalAscending",
        field: "label",
        fieldType: "STRING",
        order: "ASCENDING",
    },
    {
        text: "enums.SortOptionEnum.AlphabeticalDescending",
        field: "label",
        fieldType: "STRING",
        order: "DESCENDING",
    },
];

export const sortOptionsCode: SortOption[] = [
    {
        text: "enums.SortOptionEnum.AlphabeticalAscending",
        field: "code",
        fieldType: "STRING",
        order: "ASCENDING",
    },
    {
        text: "enums.SortOptionEnum.AlphabeticalDescending",
        field: "code",
        fieldType: "STRING",
        order: "DESCENDING",
    },
];

export const sortOptionsVariableName: SortOption[] = [
    {
        text: "enums.SortOptionEnum.AlphabeticalAscending",
        field: "variableName",
        fieldType: "STRING",
        order: "ASCENDING",
    },
    {
        text: "enums.SortOptionEnum.AlphabeticalDescending",
        field: "variableName",
        fieldType: "STRING",
        order: "DESCENDING",
    },
];

export const sortOptionsId: SortOption[] = [
    {
        text: "enums.SortOptionEnum.CreationAscending",
        field: "id",
        fieldType: "NUMERIC",
        order: "ASCENDING",
    },
    {
        text: "enums.SortOptionEnum.CreationDescending",
        field: "id",
        fieldType: "NUMERIC",
        order: "DESCENDING",
    },
];

export const sortOptionsModificationDate: SortOption[] = [
    {
        text: "enums.SortOptionEnum.ModificationAscending",
        field: "modifiedDate",
        fieldType: "DATE",
        order: "ASCENDING",
    },
    {
        text: "enums.SortOptionEnum.ModificationDescending",
        field: "modifiedDate",
        fieldType: "DATE",
        order: "DESCENDING",
    },
];

export const sortOptionsDataType: SortOption[] = [
    {
        text: "enums.SortOptionEnum.DataTypeAscending",
        field: "variableCritereType",
        fieldType: "STRING",
        order: "ASCENDING",
    },
    {
        text: "enums.SortOptionEnum.DataTypeDescending",
        field: "variableCritereType",
        fieldType: "STRING",
        order: "DESCENDING",
    },
];

export const sortOptionsVariableType: SortOption[] = [
    {
        text: "enums.SortOptionEnum.DataTypeAscending",
        field: "valueType",
        fieldType: "STRING",
        order: "ASCENDING",
    },
    {
        text: "enums.SortOptionEnum.DataTypeDescending",
        field: "valueType",
        fieldType: "STRING",
        order: "DESCENDING",
    },
];

export const sortOptionsQuestionType: SortOption[] = [
    {
        text: "enums.SortOptionEnum.DataTypeAscending",
        field: "valueType",
        fieldType: "STRING",
        order: "ASCENDING",
    },
    {
        text: "enums.SortOptionEnum.DataTypeDescending",
        field: "valueType",
        fieldType: "STRING",
        order: "DESCENDING",
    },
];

export const sortOptionsType: SortOption[] = [
    {
        text: "enums.SortOptionEnum.DataTypeAscending",
        field: "type",
        fieldType: "STRING",
        order: "ASCENDING",
    },
    {
        text: "enums.SortOptionEnum.DataTypeDescending",
        field: "type",
        fieldType: "STRING",
        order: "DESCENDING",
    },
];
