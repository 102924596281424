import {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import ModalAdd from "../../../../Components/Modals/ModalAdd";
import {ConditionKindEnum} from "../../../../Services/Condition/Types";
import {ComputingRuleRoundingMode} from "../../../../Services/InsurancePackage/Types";
import {useAddVariable} from "../../../../Services/Variables/Queries";
import {VariableType, VariableValueType} from "../../../../Services/Variables/Types";
import FormVariable from "./FormVariable";

interface IParams extends Params {
    id: string;
    versionId: string;
    variableId: string;
}

type PropsType = {
    alternateOpenButton?: () => JSX.Element;
    variableType: VariableType;
};

const ModalAddVariable = ({...props}: PropsType) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const addVariableMutation = useAddVariable(params.id, params.versionId);
    const addVariableForm = useForm<any>();
    const submitAddVariable = (form: any) => {
        const conditionOrder = 1;
        if (form.valueType === undefined && props.variableType === VariableType.R) {
            form.valueType = "NUMERIC";
        }
        let defaultValue;
        switch (form.valueType) {
            case "STRING":
                defaultValue = "default";
                break;
            case "NUMERIC":
                defaultValue = "0";
                break;
            case "DATE":
                defaultValue = new Date().toISOString().split("T")[0];
        }

        const variable = {
            ...form,
            variableType: props.variableType,
            versionId: params.versionId,
            variableConditions: [
                {
                    orderCondition: conditionOrder,
                    variableCriterias:
                        props.variableType !== VariableType.R
                            ? [
                                  {
                                      value: defaultValue,
                                  },
                              ]
                            : [
                                  {
                                      computingRule: {
                                          id: null,
                                          computingRuleConditions: [
                                              {
                                                  id: null,
                                                  ifCondition: null,
                                                  conditionPartList: [],
                                                  orderCondition: 1,
                                                  calculFormule: null,
                                                  rulePartList: [
                                                      {value: "1", kind: ConditionKindEnum.NUMBER, contentType: null},
                                                  ],
                                                  roundingMode: ComputingRuleRoundingMode.ROUND_HALF_UP,
                                                  decimalCount: 2,
                                              },
                                          ],
                                      },
                                  },
                              ],
                },
            ],
        };

        addVariableMutation.mutate(variable);
    };

    const [defaultValueName, setDefaultValueName] = useState<string>("");
    const [defaultValueType, setDefaultValueType] = useState<VariableValueType | null>(null);
    const [defaultValueSaveQuote, setDefaultValueSaveQuote] = useState<boolean>(false);

    return (
        <ModalAdd
            isValidationDisabled={Object.keys(addVariableForm.formState.errors).length !== 0}
            isPending={addVariableMutation.isPending}
            isSuccess={addVariableMutation.isSuccess}
            isError={addVariableMutation.isError}
            resetMutation={addVariableMutation.reset}
            error={addVariableMutation.error}
            onValidate={addVariableForm.handleSubmit(submitAddVariable)}
            onCancel={() => {
                setDefaultValueName("");
                setDefaultValueType(null);
                setDefaultValueSaveQuote(false);
            }}
            onSuccess={() => {
                setDefaultValueName("");
                setDefaultValueType(null);
                setDefaultValueSaveQuote(false);
            }}
            iconOnOpenButton
            openButtonIcon="add"
            objectToAdd={props.variableType === VariableType.V ? "Data variable" : "Computing rule variables"}
            objectToAddContext="female"
            renderModalContent={() => (
                <FormVariable
                    errors={addVariableForm.formState.errors}
                    control={addVariableForm.control}
                    type={props.variableType}
                    defaultValueName={defaultValueName}
                    setDefaultValueName={setDefaultValueName}
                    defaultValueType={defaultValueType}
                    setDefaultValueType={setDefaultValueType}
                    defaultValueSaveQuote={defaultValueSaveQuote}
                    setDefaultValueSaveQuote={setDefaultValueSaveQuote}
                />
            )}
            renderOpenButton={props.alternateOpenButton}
        />
    );
};

export default ModalAddVariable;
