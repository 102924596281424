import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import { AccordionContent, Button, Divider, Grid, GridColumn, GridRow, Header, Message } from "semantic-ui-react";
import ConditionAccordionTitle from "../../../../../Components/Condition/ConditionAccordionTitle";
import { ConditionWarningEditForbidden } from "../../../../../Components/Condition/ConditionWarningEditForbidden";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import { DndPropertiesType } from "../../../../../Hooks/useDragAndDropProperties";

import { ConditionPart, OperandEnum } from "../../../../../Services/Condition/Types";
import { useDeleteInsurancePackageExclusionConditionMutation, useReorderInsurancePackageExclusionCondtionMutation, useUpdateInsurancePackageExclusionConditionMutation } from "../../../../../Services/InsurancePackage/Queries";
import { InsurancePackageExclusion } from "../../../../../Services/InsurancePackage/Types";
import { VersionDTO } from "../../../../../Services/Version/Types";
import ConditionHandling from "../../../Condition/ConditionHandling";
import ConditionView from "../../../Condition/Fragments/ConditionView";
import { isEditingConditionForbidden } from "../../../../../Utils/ConditionPartUtils";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
}

type InsurancePackageConditionAccordionPropsType = {
    condition: InsurancePackageExclusion;
    version: VersionDTO | undefined;
    index: number;
    activeIndex: number;
    setActiveIndex: Dispatch<SetStateAction<number>>;
    insurancePackageExclusionConditions: InsurancePackageExclusion[];
    dndProperties: DndPropertiesType<InsurancePackageExclusion>
};

const conditionKindList = [
    {key: 1, text: "Critère", value: OperandEnum.CRITERE, type: "male"},
    {key: 2, text: "Question", value: OperandEnum.QUESTION, type: "female"},
    {key: 3, text: "Fixed value", value: OperandEnum.FIXED_VALUE, type: "female"},
    {key: 4, text: "Variable", value: OperandEnum.VARIABLE, type: "male"},
    {key: 5, text: "Variable système", value: OperandEnum.SOFTWARE_CRITERIA, type: "male"}
];

export const InsurancePackageExclusionConditionAccordion = ({
    condition,
    version,
    index,
    activeIndex,
    setActiveIndex,
    insurancePackageExclusionConditions,
    dndProperties,
}: InsurancePackageConditionAccordionPropsType) => {
    const {t} = useTranslation();
    
    const params = useParams() as IParams;
    const [editCondition, setEditCondition] = useState<boolean>(false);

    const deleteInsurancePackageExclusionConditionMutation = useDeleteInsurancePackageExclusionConditionMutation(params.insurancePackageId);
    const updateInsurancePackageExclusionConditionMutation = useUpdateInsurancePackageExclusionConditionMutation(params.insurancePackageId);
    const handleDelete = (conditionId: number) => {
        deleteInsurancePackageExclusionConditionMutation.mutate(conditionId);
    };
    const reorderInsurancePackageExclusionConditionMutation = useReorderInsurancePackageExclusionCondtionMutation(params.insurancePackageId, condition.id.toString());

    const onDeleteSuccess = () => {
        setActiveIndex(-1);
    };

    return (
    <div key={index}>
        <ConditionAccordionTitle<InsurancePackageExclusion>
            version={version}
            customTitle={t("Exclusion condition")}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            condition={condition}
            index={index}
            reorderFunction={reorderInsurancePackageExclusionConditionMutation}
            dndProperties={dndProperties}
        />
        <AccordionContent active={activeIndex === index}>
            {condition.ifCondition === "( ( 1 == 1 ) )" && (
                <Message warning>
                    {t(
                        "This condition contains default values, it would be convenient to update it before validating product version's"
                    )} 
                </Message>
            )}
            <Divider horizontal>
                <Header as="h4">{t("Condition")}</Header>
            </Divider>
            {editCondition ? (
                <ConditionHandling
                    versionId={params.versionId}
                    conditionKindList={conditionKindList}
                    conditionPartList={condition.conditionPartList}
                    setConditionPartList={(conditionPartListUpdate: ConditionPart[]) => {
                        
                        const conditionUpdate: InsurancePackageExclusion = {
                            id: condition.id,
                            ifCondition: null,
                            conditionPartList: conditionPartListUpdate,
                            conditionOrder: condition.conditionOrder
                        };
                        updateInsurancePackageExclusionConditionMutation.mutate(conditionUpdate);
                    }}
                    setEditCondition={setEditCondition}
                    updateMutation={updateInsurancePackageExclusionConditionMutation}
                />
            ) : (
                <>
                    <Grid columns={3}>
                        <GridColumn width={1} textAlign="center" verticalAlign="middle">
                            {t("If")} :
                        </GridColumn>
                        <GridColumn width={14} textAlign="center" className="condition">
                            <ConditionView conditionPartList={condition.conditionPartList} />
                        </GridColumn>
                        {version !== undefined && version.pipDate === null && (
                            <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                {isEditingConditionForbidden(condition.conditionPartList) ? (
                                    <ConditionWarningEditForbidden />
                                ) : (
                                    <Button
                                        name={"editCondition" + Number(index + 1)}
                                        color="grey"
                                        icon="edit"
                                        compact
                                        basic
                                        onClick={() => {
                                            setEditCondition(true);
                                        }}
                                    />
                                )}
                            </GridColumn>
                        )}
                    </Grid>
                    {index !== insurancePackageExclusionConditions.length &&
                        version !== undefined &&
                        version.pipDate === null && (
                            <>
                                <Divider />
                                <Grid>
                                    <GridRow>
                                        <GridColumn width={14} />
                                        <GridColumn width={2}>
                                            <ModalDelete
                                                isSuccess={deleteInsurancePackageExclusionConditionMutation.isSuccess}
                                                isPending={deleteInsurancePackageExclusionConditionMutation.isPending}
                                                isError={deleteInsurancePackageExclusionConditionMutation.isError}
                                                error={deleteInsurancePackageExclusionConditionMutation.error}
                                                resetMutation={deleteInsurancePackageExclusionConditionMutation.reset}
                                                objectToDelete="Condition"
                                                objectType="female"
                                                openModalButtonName={"deleteCondition" + Number(index + 1)}
                                                onValidate={() => handleDelete(condition.id)}
                                                onSuccess={() => onDeleteSuccess()}
                                            />
                                        </GridColumn>
                                    </GridRow>
                                </Grid>
                            </>
                        )}
                </>
            )}
        </AccordionContent>
    </div>)
}