import * as FileSaver from "file-saver";
import _ from "lodash";
import React, {Fragment, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Button, Icon, Menu} from "semantic-ui-react";
import ModalDelete from "../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../Components/Modals/ModalUpdate";
import ModalUpdateForm from "../../../Components/Modals/ModalUpdateForm";
import queryClient from "../../../Config/queryClient";
import {
    useDeleteESelectionMatrixMutation,
    useExportESelectionMatrixQuery,
    useGetESelectionMatrixDTOQuery,
    useGetESelectionMatrixExportDTOQuery,
    useImportESelectionMatrixMutation,
    useUpdateESelectionMatrixMutation,
} from "../../Services/ESelection/Queries";
import {
    ESelectionDimensionType,
    ESelectionDimensionValueRecapDTO,
    ESelectionMatrixDTO,
} from "../../Services/ESelection/Types";
import FormESelection from "./Fragment/FormESelection";

type ESelectionActionsButtonsProps = {
    productId: string;
};

const ESelectionActionsButtons = ({productId}: ESelectionActionsButtonsProps) => {
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    // Get grille format affichage
    const getESelectionMatrixExportDTO = useGetESelectionMatrixExportDTOQuery(productId);

    // Get grille format DTO
    const getESelectionMatrixDTO = useGetESelectionMatrixDTOQuery(productId);

    // Update grille
    const updateESelectionMatrixMutation = useUpdateESelectionMatrixMutation(productId);
    const updateESelectionMatrixForm = useForm({mode: "onChange"});
    const sumbitUpdateESelectionMatrixForm = (form: any) => {
        let eSelectionMatrixDTO: ESelectionMatrixDTO = {
            eSelectionDimensionDTOs: [],
            eSelectionValueDTOs: [],
        };

        const DCIsActive = form["dc-is-active"];
        const IJIsActive = form["ij-is-active"];
        const FICTIVEIsActive = form["fictive-is-active"];
        const ageSlicesSize = form["age-slices-size"];
        const amountSlicesSize = form["amount-slices-size"];

        eSelectionMatrixDTO.eSelectionDimensionDTOs.push({
            type: ESelectionDimensionType.AGE,
            eSelectionDimensionValueDTOs: form[ESelectionDimensionType.AGE].slice(0, ageSlicesSize),
        });

        if (DCIsActive) {
            eSelectionMatrixDTO.eSelectionDimensionDTOs.push({
                type: ESelectionDimensionType.DC,
                eSelectionDimensionValueDTOs: form[ESelectionDimensionType.DC].slice(0, amountSlicesSize),
            });
        }

        if (IJIsActive) {
            eSelectionMatrixDTO.eSelectionDimensionDTOs.push({
                type: ESelectionDimensionType.IJ,
                eSelectionDimensionValueDTOs: form[ESelectionDimensionType.IJ].slice(0, amountSlicesSize),
            });
        }

        if (FICTIVEIsActive) {
            eSelectionMatrixDTO.eSelectionDimensionDTOs.push({
                type: ESelectionDimensionType.FICTIVE,
                eSelectionDimensionValueDTOs: form[ESelectionDimensionType.FICTIVE].slice(0, amountSlicesSize),
            });
        }

        for (let i = 0; i < ageSlicesSize; i++) {
            for (let j = 0; j < amountSlicesSize; j++) {
                let recap: Array<ESelectionDimensionValueRecapDTO> = [];

                recap.push({
                    type: ESelectionDimensionType.AGE,
                    min: form[ESelectionDimensionType.AGE][i].min,
                    max: form[ESelectionDimensionType.AGE][i].max,
                    rank: null,
                });

                if (DCIsActive) {
                    recap.push({
                        type: ESelectionDimensionType.DC,
                        min: form[ESelectionDimensionType.DC][j].min,
                        max: form[ESelectionDimensionType.DC][j].max,
                        rank: null,
                    });
                }

                if (IJIsActive) {
                    recap.push({
                        type: ESelectionDimensionType.IJ,
                        min: form[ESelectionDimensionType.IJ][j].min,
                        max: form[ESelectionDimensionType.IJ][j].max,
                        rank: null,
                    });
                }

                if (FICTIVEIsActive) {
                    recap.push({
                        type: ESelectionDimensionType.FICTIVE,
                        min: form[ESelectionDimensionType.FICTIVE][j].min,
                        max: form[ESelectionDimensionType.FICTIVE][j].max,
                        rank: null,
                    });
                }

                eSelectionMatrixDTO.eSelectionValueDTOs.push({
                    id: null,
                    value:
                        getESelectionMatrixDTO?.data?.eSelectionValueDTOs?.find(
                            (value) =>
                                _.differenceWith(value.eSelectionDimensionValueRecapDTOs, recap, (a, b) => {
                                    return _.isEqual(_.omit(a, ["rank"]), _.omit(b, ["rank"]));
                                }).length === 0
                        )?.value ?? "",
                    eSelectionDimensionValueRecapDTOs: recap,
                });
            }
        }

        updateESelectionMatrixMutation.mutate(eSelectionMatrixDTO);
    };

    // Delete grille
    const deleteESelectionMatrixMutation = useDeleteESelectionMatrixMutation(productId);
    const deleteESelectionMatrix = () => deleteESelectionMatrixMutation.mutate();

    // Export grille
    const exportESelectionMatrixQuery = useExportESelectionMatrixQuery(productId, false);
    const handleExportESelectionMatrix = () => exportESelectionMatrixQuery.refetch();
    useEffect(() => {
        if (exportESelectionMatrixQuery.isSuccess && exportESelectionMatrixQuery.data !== undefined) {
            const blob = new Blob([exportESelectionMatrixQuery.data as BlobPart], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileSaver.saveAs(blob, `Grille_eSelection.xlsx`);

            queryClient.removeQueries({queryKey: ["exportESelectionMatrix"]});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exportESelectionMatrixQuery.data, exportESelectionMatrixQuery.status]);

    // Import grille
    const importESelectionMatrixMutation = useImportESelectionMatrixMutation(productId);
    const handleImportESelectionMatrix = () => importESelectionMatrixMutation.mutate(selectedFile);
    const [selectedFile, setSelectedFile] = useState(null);
    const handleSetFile = ({target: {files}}: any) => setSelectedFile(files[0]);
    const handleClick = () => {
        if (hiddenFileInput.current !== null) {
            hiddenFileInput.current.click();
        }
    };
    const clearSelectedFile = () => {
        setSelectedFile(null);
        if (hiddenFileInput.current !== null) {
            hiddenFileInput.current.value = "";
        }
    };

    return (
        <Fragment>
            {getESelectionMatrixExportDTO.isSuccess &&
                getESelectionMatrixDTO.isSuccess &&
                getESelectionMatrixDTO.data && (
                    <Menu.Menu position="right" data-cy="e-selection-actions-buttons">
                        <Menu.Item fitted>
                            <ModalUpdateForm
                                isPending={updateESelectionMatrixMutation.isPending}
                                isSuccess={updateESelectionMatrixMutation.isSuccess}
                                isError={updateESelectionMatrixMutation.isError}
                                error={updateESelectionMatrixMutation.error}
                                resetMutation={updateESelectionMatrixMutation.reset}
                                objectToUpdate="eSelection grid"
                                objectToUpdateContext="female"
                                onValidate={updateESelectionMatrixForm.handleSubmit(sumbitUpdateESelectionMatrixForm)}
                                onCancel={() => {}}
                                onSuccess={() => {}}
                                renderModalContent={() => (
                                    <FormESelection
                                        control={updateESelectionMatrixForm.control}
                                        errors={updateESelectionMatrixForm.formState.errors}
                                        getValues={updateESelectionMatrixForm.getValues}
                                        trigger={updateESelectionMatrixForm.trigger}
                                        defaultValues={{
                                            dimensions: getESelectionMatrixDTO.data.eSelectionDimensionDTOs,
                                            age_slices_size: getESelectionMatrixDTO.data.eSelectionDimensionDTOs.find(
                                                (dimension) => dimension.type === ESelectionDimensionType.AGE
                                            )?.eSelectionDimensionValueDTOs.length,
                                            amount_slices_size:
                                                getESelectionMatrixDTO.data.eSelectionDimensionDTOs.find(
                                                    (dimension) =>
                                                        dimension.type === ESelectionDimensionType.DC ||
                                                        dimension.type === ESelectionDimensionType.IJ ||
                                                        dimension.type === ESelectionDimensionType.FICTIVE
                                                )?.eSelectionDimensionValueDTOs.length,
                                            dc_is_active: getESelectionMatrixDTO.data.eSelectionDimensionDTOs.some(
                                                (dimension) => dimension.type === ESelectionDimensionType.DC
                                            ),
                                            ij_is_active: getESelectionMatrixDTO.data.eSelectionDimensionDTOs.some(
                                                (dimension) => dimension.type === ESelectionDimensionType.IJ
                                            ),
                                            fictive_is_active: getESelectionMatrixDTO.data.eSelectionDimensionDTOs.some(
                                                (dimension) => dimension.type === ESelectionDimensionType.FICTIVE
                                            ),
                                        }}
                                    />
                                )}
                                scrolling
                                iconOnOpenButton
                                openButtonIcon="edit"
                                openModalButtonColor="blue"
                            />
                        </Menu.Item>
                        <Menu.Item fitted>
                            <Button icon color="green" onClick={handleExportESelectionMatrix} name="download">
                                <Icon name="download" />
                            </Button>
                        </Menu.Item>
                        <Menu.Item fitted>
                            <Button onClick={handleClick} color="green" icon name="upload">
                                <input ref={hiddenFileInput} type={"file"} hidden onChange={handleSetFile} />
                                <Icon name={"upload"} />
                            </Button>

                            {selectedFile !== null && (
                                <ModalUpdate
                                    onValidate={handleImportESelectionMatrix}
                                    isModalOpenAtStart
                                    objectToUpdate="eSelection grid"
                                    objectType="female"
                                    onCancel={clearSelectedFile}
                                    onClose={clearSelectedFile}
                                    onSuccess={clearSelectedFile}
                                    resetMutation={importESelectionMatrixMutation.reset}
                                    isSuccess={importESelectionMatrixMutation.isSuccess}
                                    isPending={importESelectionMatrixMutation.isPending}
                                    isError={importESelectionMatrixMutation.isError}
                                />
                            )}
                        </Menu.Item>
                        <Menu.Item fitted>
                            <ModalDelete
                                openModalButtonName="delete"
                                isPending={deleteESelectionMatrixMutation.isPending}
                                isSuccess={deleteESelectionMatrixMutation.isSuccess}
                                isError={deleteESelectionMatrixMutation.isError}
                                resetMutation={deleteESelectionMatrixMutation.reset}
                                onValidate={deleteESelectionMatrix}
                                objectToDelete={"eSelection grid"}
                                objectType={"female"}
                                iconOnOpenButton
                                openButtonIcon="trash"
                            />
                        </Menu.Item>
                    </Menu.Menu>
                )}
        </Fragment>
    );
};

export default ESelectionActionsButtons;
