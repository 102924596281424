import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseMutationResult } from "@tanstack/react-query";
import { Button, Grid, GridColumn, Message } from "semantic-ui-react";
import ModalUpdate from "../../../Components/Modals/ModalUpdate";
import {
    Block,
    ConditionExpressionView,
    ConditionKindEnum,
    ConditionPart,
    OperandEnum,
    SubBlock
} from "../../../Services/Condition/Types";
import { CustomError } from "../../../Services/RequestUtils";
import { GenericReducerActionType } from "../../../Utils/ReducerUtils";
import ConditionBlock from "./Fragments/ConditionBlock";

type ConditionHandlingPropsType = {
    conditionPartList: ConditionPart[];
    setConditionPartList: Function;
    conditionKindList: {key: number; text: string; value: OperandEnum; type: string}[];
    versionId?: string;
    setEditCondition: Function;
    updateMutation?: UseMutationResult<any, CustomError, any, Error>;
};

type ActionType =
    | {
          type: "editBlock";
          payload: {
              indexBlock: number;
              field: "logicalOperator";
              value: ConditionPart | undefined;
          };
      }
    | {
          type: "editBlockListSubBlock";
          payload: {
              indexBlock: number;
              field: "listSubBlock";
              value: SubBlock[];
          };
      }
    | {
          type: "add";
          payload: number;
      }
    | {type: "remove"; payload: number}
    | {type: "updateList"; payload: Block[]};

const ConditionHandling = ({
    conditionPartList,
    setConditionPartList,
    conditionKindList,
    versionId,
    setEditCondition,
    updateMutation,
}: ConditionHandlingPropsType) => {
    const {t} = useTranslation();
    const [isValid, setIsValid] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(true);

    const reducerBlockList = (state: {blockList: Block[]}, action: ActionType) => {
        const blockList = [...state.blockList];
        switch (action.type) {
            case "editBlock":
                blockList[action.payload.indexBlock][action.payload.field] = action.payload.value;
                return {blockList: blockList};
            case "editBlockListSubBlock":
                blockList[action.payload.indexBlock][action.payload.field] = action.payload.value;
                return {blockList: blockList};
            case "add":
                blockList.push({
                    listSubBlock: [
                        {
                            logicalNegation: undefined,
                            listConditionExpression: [
                                {
                                    leftOperand: {
                                        conditionValue: "",
                                        conditionKind: null,
                                        contentType: null,
                                    },
                                    operator: {conditionValue: "", conditionKind: null, contentType: null},
                                    rightOperand: {conditionValue: "", conditionKind: null, contentType: null},
                                    logicalOperator: undefined,
                                },
                            ],
                            logicalOperator: undefined,
                        },
                    ],
                    logicalOperator: undefined,
                });
                return {blockList: blockList};
            case "remove":
                blockList.splice(action.payload, 1);
                if (blockList.length > 0) {
                    blockList[blockList.length - 1]["logicalOperator"] = undefined;
                }
                return {blockList: blockList};
            case "updateList":
                return {blockList: action.payload};
            default:
                throw new Error();
        }
    };

    const [state, dispatchBlockList] = useReducer(reducerBlockList, {
        blockList: [],
    });

    useEffect(() => {
        dispatchBlockList({type: "updateList", payload: convertConditionPartListToBlockList()});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conditionPartList]);

    useEffect(() => {
        state.blockList.forEach((block, index) => {
            if (block.listSubBlock.length === 0) {
                dispatchBlockList({type: GenericReducerActionType.REMOVE, payload: index});
                if (state.blockList[0].listSubBlock.length === 0 && state.blockList.length === 1) {
                    dispatchBlockList({type: "add", payload: 0});
                }
            }
        });
    }, [state.blockList]);

    const checkIsValid = () => {
        var checkIsValid = true;
        setIsValid(true);
        state.blockList.forEach((block, indexBlock) => {
            if (indexBlock !== state.blockList.length - 1 && block.logicalOperator === undefined) {
                setIsValid(false);
                checkIsValid = false;
            }

            block.listSubBlock.forEach((subBlock, indexSubBlock) => {
                if (indexSubBlock !== block.listSubBlock.length - 1 && subBlock.logicalOperator === undefined) {
                    setIsValid(false);
                    checkIsValid = false;
                }
                subBlock.listConditionExpression.forEach((conditionExpression, index) => {
                    if (conditionExpression.leftOperand.conditionValue === "") {
                        setIsValid(false);
                        checkIsValid = false;
                    }
                    if (conditionExpression.operator.conditionValue === "") {
                        setIsValid(false);
                        checkIsValid = false;
                    }
                    if (conditionExpression.rightOperand.conditionValue === "") {
                        setIsValid(false);
                        checkIsValid = false;
                    }
                    if (
                        index !== subBlock.listConditionExpression.length - 1 &&
                        conditionExpression.logicalOperator === undefined
                    ) {
                        setIsValid(false);
                        checkIsValid = false;
                    }
                });
            });
        });
        if (checkIsValid) setIsError(false);
    };

    const isOpeningParenthesis = (conditionPart: ConditionPart) =>
        conditionPart.conditionValue === "(" && conditionPart.conditionKind === ConditionKindEnum.OPENING_PARENTHESIS;
    const isClosingParenthesis = (conditionPart: ConditionPart) =>
        conditionPart.conditionValue === ")" && conditionPart.conditionKind === ConditionKindEnum.CLOSING_PARENTHESIS;
    const isNotLogicalOperator = (conditionPart: ConditionPart) =>
        conditionPart.conditionValue.trim() === "NOT" &&
        conditionPart.conditionKind === ConditionKindEnum.LOGICAL_OPERATOR;
    const isOrLogicalOperator = (conditionPart: ConditionPart) =>
        conditionPart.conditionValue.trim() === "OR" &&
        conditionPart.conditionKind === ConditionKindEnum.LOGICAL_OPERATOR;
    const isAndLogicalOperator = (conditionPart: ConditionPart) =>
        conditionPart.conditionValue.trim() === "AND" &&
        conditionPart.conditionKind === ConditionKindEnum.LOGICAL_OPERATOR;

    /**
     * Renvoie les couples d'index des parenthèses de plus haut niveau. Les couples sont consititués de l'index de la parenthèse ouvrante à gauche, et celle fermante à droite.
     * <p>Exemples :</p>
     * <p>( ( 1 == 1 ) OR ( 1 == 1 ) ) ---> [(0, 12)]</p>
     * <p>( ( 1 == 1 ) ) OR ( ( 1 == 1 ) OR ( 1 == 1 ) ) OR ( ( 1 == 1 ) ) ---> [(0, 6), (8, 20), (22, 28)]</p>
     * @param conditionPartList La liste dans laquelle chercher
     * @returns
     */
    const retrieveParenthesisPairAtTopLevel = (conditionPartList: ConditionPart[]): {begin: number; end: number}[] => {
        var indexsPairs: {begin: number; end: number}[] = [];
        var deepLevel = 0;
        var beginIndex: number;

        conditionPartList.forEach((conditionPart, index) => {
            if (isOpeningParenthesis(conditionPart) && deepLevel === 0) {
                beginIndex = index;
                deepLevel++;
            } else if (isClosingParenthesis(conditionPart) && deepLevel === 1) {
                indexsPairs.push({begin: beginIndex, end: index});
                deepLevel--;
            } else if (isOpeningParenthesis(conditionPart) && deepLevel > 0) {
                deepLevel++;
            } else if (isClosingParenthesis(conditionPart) && deepLevel > 1) {
                deepLevel--;
            }
        });

        return indexsPairs;
    };

    const convertConditionPartListToBlockList = () => {
        var indexsOfBlocks = retrieveParenthesisPairAtTopLevel(conditionPartList);
        return indexsOfBlocks.map((indexPair) =>
            createBlock(
                conditionPartList.slice(indexPair.begin, indexPair.end + 1),
                conditionPartList[indexPair.end + 1]
            )
        );
    };

    const createBlock = (conditionPartList: ConditionPart[], logicalOperator: ConditionPart | undefined): Block => {
        var conditionPartListTruncated = conditionPartList.slice(1, conditionPartList.length);
        var indexsOfSubBlocks = retrieveParenthesisPairAtTopLevel(conditionPartListTruncated);
        var subBlocksList = indexsOfSubBlocks.map((indexPair) =>
            createSubBlock(
                conditionPartListTruncated[indexPair.begin - 1],
                conditionPartListTruncated.slice(indexPair.begin, indexPair.end + 1),
                conditionPartListTruncated[indexPair.end + 1]
            )
        );
        return {
            listSubBlock: subBlocksList,
            logicalOperator:
                logicalOperator !== undefined
                    ? {
                          ...logicalOperator,
                          conditionValue: logicalOperator.conditionValue.trim(),
                      }
                    : undefined,
        };
    };

    const createSubBlock = (
        logicalNegation: ConditionPart | undefined,
        conditionPartList: ConditionPart[],
        logicalOperator: ConditionPart | undefined
    ): SubBlock => {
        return {
            logicalNegation:
                logicalNegation !== undefined && isNotLogicalOperator(logicalNegation)
                    ? {
                          ...logicalNegation,
                          conditionValue: logicalNegation.conditionValue.trim(),
                      }
                    : undefined,
            listConditionExpression: createConditionExpressionList(
                conditionPartList.slice(1, conditionPartList.length)
            ),
            logicalOperator:
                logicalOperator !== undefined &&
                (isAndLogicalOperator(logicalOperator) || isOrLogicalOperator(logicalOperator))
                    ? {
                          ...logicalOperator,
                          conditionValue: logicalOperator.conditionValue.trim(),
                      }
                    : undefined,
        };
    };

    const createConditionExpressionList = (conditionPartList: ConditionPart[]): ConditionExpressionView[] => {
        var conditionExpressionViewList: ConditionExpressionView[] = [];
        for (var i = 0; i < conditionPartList.length; i += 4) {
            conditionExpressionViewList.push({
                leftOperand: conditionPartList[i],
                operator: conditionPartList[i + 1],
                rightOperand: conditionPartList[i + 2],
                logicalOperator:
                    conditionPartList[i + 3] !== undefined &&
                    (isAndLogicalOperator(conditionPartList[i + 3]) || isOrLogicalOperator(conditionPartList[i + 3]))
                        ? {
                              ...conditionPartList[i + 3],
                              conditionValue: conditionPartList[i + 3].conditionValue.trim(),
                          }
                        : undefined,
            });
        }
        return conditionExpressionViewList;
    };

    const convertBlockConditionExpressionToConditionPartList = () => {
        const conditionPartListBuild: ConditionPart[] = [];
        state.blockList.forEach((block) => {
            conditionPartListBuild.push({
                conditionValue: "(",
                conditionKind: ConditionKindEnum.OPENING_PARENTHESIS,
                contentType: null,
            });
            block.listSubBlock.forEach((subBlock) => {
                if (subBlock.logicalNegation !== undefined) {
                    conditionPartListBuild.push({
                        conditionValue: subBlock.logicalNegation.conditionValue,
                        conditionKind: subBlock.logicalNegation.conditionKind,
                        contentType: subBlock.logicalNegation.contentType,
                    });
                }
                conditionPartListBuild.push({
                    conditionValue: "(",
                    conditionKind: ConditionKindEnum.OPENING_PARENTHESIS,
                    contentType: null,
                });
                subBlock.listConditionExpression.forEach((conditionExpression) => {
                    // Pour le back, dans les conditions, les variables R et variables V sont traitées de la même manière.
                    // Il faut convertir le type de condition en VARIABLE si c'est une VARIABLE_COMPUTING_RULE.
                    if (conditionExpression.leftOperand.conditionKind === ConditionKindEnum.VARIABLE_COMPUTING_RULE)
                        conditionExpression.leftOperand.conditionKind = ConditionKindEnum.VARIABLE;
                    if (conditionExpression.rightOperand.conditionKind === ConditionKindEnum.VARIABLE_COMPUTING_RULE)
                        conditionExpression.rightOperand.conditionKind = ConditionKindEnum.VARIABLE;

                    conditionPartListBuild.push(conditionExpression.leftOperand);
                    conditionPartListBuild.push(conditionExpression.operator);
                    conditionPartListBuild.push(conditionExpression.rightOperand);

                    if (conditionExpression.logicalOperator !== undefined) {
                        conditionPartListBuild.push({
                            conditionValue: " " + conditionExpression.logicalOperator.conditionValue + " ",
                            conditionKind: conditionExpression.logicalOperator.conditionKind,
                            contentType: conditionExpression.logicalOperator.contentType,
                        });
                    }
                });
                conditionPartListBuild.push({
                    conditionValue: ")",
                    conditionKind: ConditionKindEnum.CLOSING_PARENTHESIS,
                    contentType: null,
                });
                if (subBlock.logicalOperator !== undefined) {
                    conditionPartListBuild.push({
                        conditionValue: " " + subBlock.logicalOperator.conditionValue + " ",
                        conditionKind: subBlock.logicalOperator.conditionKind,
                        contentType: subBlock.logicalOperator.contentType,
                    });
                }
            });
            conditionPartListBuild.push({
                conditionValue: ")",
                conditionKind: ConditionKindEnum.CLOSING_PARENTHESIS,
                contentType: null,
            });
            if (block.logicalOperator !== undefined) {
                conditionPartListBuild.push({
                    conditionValue: " " + block.logicalOperator.conditionValue + " ",
                    conditionKind: block.logicalOperator.conditionKind,
                    contentType: block.logicalOperator.contentType,
                });
            }
        });
        setConditionPartList(conditionPartListBuild);
    };

    return (
        <>
            {state.blockList.map((block, indexBlock) => (
                <ConditionBlock
                    key={indexBlock}
                    indexBlock={indexBlock}
                    block={block}
                    blockLength={state.blockList.length}
                    dispatchBlockList={dispatchBlockList}
                    conditionKindList={conditionKindList}
                    versionId={versionId}
                />
            ))}
            {!isValid && <Message warning header={t("Warning")} content={t("All_fields_must_be_completed")} />}
            <Grid>
                <GridColumn width={12}></GridColumn>
                <GridColumn width={4} textAlign="center">
                    <Button
                        color="black"
                        onClick={() => {
                            setEditCondition(false);
                        }}>
                        {t("Cancel")}
                    </Button>
                    {!isError && updateMutation !== undefined ? (
                        <ModalUpdate
                            isModalOpenAtStart={true}
                            isPending={updateMutation.isPending}
                            isSuccess={updateMutation.isSuccess}
                            isError={updateMutation.isError}
                            resetMutation={updateMutation.reset}
                            error={updateMutation.error}
                            onSuccess={() => setEditCondition(false)}
                            onValidate={() => convertBlockConditionExpressionToConditionPartList()}
                            onClose={() => setIsError(true)}
                            onCancel={() => setIsError(true)}
                            objectToUpdate={t("Condition")}
                            objectType="female"
                        />
                    ) : (
                        <Button
                            name="validate"
                            className="validate"
                            color="green"
                            onClick={() => {
                                checkIsValid();
                            }}>
                            {t("Validate")}
                        </Button>
                    )}
                </GridColumn>
            </Grid>
        </>
    );
};

export default ConditionHandling;
