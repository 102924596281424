import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Container, Grid, Segment, Tab} from "semantic-ui-react";
import CustomBreadcrumb from "../../../Components/CustomBreadcrumb/CustomBreadcrumb";
import Sidebar, {SidebarItem, SidebarMenu} from "../../../Components/Sidebar/Sidebar";
import {useGetProductDetailsQuery} from "../../../Services/Product/Queries";
import {useGetProductBehaviourQuery, useGetProductBehavioursQuery} from "../../../Services/ProductBehaviour/Queries";
import {ProductRegistryJoinDTO} from "../../../Services/ProductBehaviour/Types";
import {RegistryEntryType} from "../../../Services/Registry/Types";
import BehaviourDetails from "./Fragments/BehaviourDetails";
import BehaviourTaxes from "./Fragments/BehaviourTaxes";

interface IParams extends Params {
    id: string;
    behaviourId: string;
}

const ProductBehaviourPage = ({...props}) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const [menus, setMenus] = useState<any>([]);

    const {data: productData} = useGetProductDetailsQuery(params.id);

    const getProductBehavioursQuery = useGetProductBehavioursQuery(params.id);
    const {data: behaviorData, isSuccess, isLoading} = useGetProductBehaviourQuery(params.id, params.behaviourId);

    useEffect(() => {
        const adaptDataForSidebar = () => {
            if (getProductBehavioursQuery.status === "success" && getProductBehavioursQuery.data !== undefined) {
                const formattedData: {registryType: RegistryEntryType; items: ProductRegistryJoinDTO[]}[] = [
                    {
                        registryType: RegistryEntryType.G,
                        items: getProductBehavioursQuery.data.filter(
                            (productRegistryJoin: ProductRegistryJoinDTO) =>
                                productRegistryJoin.registryType === RegistryEntryType.G
                        ),
                    },
                    {
                        registryType: RegistryEntryType.F,
                        items: getProductBehavioursQuery.data.filter(
                            (productRegistryJoin: ProductRegistryJoinDTO) =>
                                productRegistryJoin.registryType === RegistryEntryType.F
                        ),
                    },
                ];

                const sidebarMenus = formattedData.map(
                    (coverageType: {registryType: RegistryEntryType; items: ProductRegistryJoinDTO[]}) =>
                        new SidebarMenu(
                            t("enums.RegistryEntryType." + coverageType.registryType),
                            coverageType.items.map(
                                (coverage: ProductRegistryJoinDTO) =>
                                    new SidebarItem(
                                        coverage.registryCode,
                                        coverage.registryLabel,
                                        `/products/${params.id}/behaviours/${coverage.id}`
                                    )
                            )
                        )
                );
                setMenus(sidebarMenus);
            }
        };
        adaptDataForSidebar();
    }, [getProductBehavioursQuery.data, getProductBehavioursQuery.status, params.id, t, productData, props.sections]);

    return (
        <Container fluid>
            <Sidebar title={`${t("behaviours")}`} menus={menus} />
            {isLoading && <CustomBreadcrumb sections={props.sections} />}
            {behaviorData !== undefined && isSuccess && (
                <>
                    <CustomBreadcrumb sections={props.sections} title={behaviorData.registry.label} />
                    <div className="main-container">
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Segment className="card-container">
                                        <Tab
                                            panes={[
                                                {
                                                    menuItem: t("behaviour details"),
                                                    render: () => <BehaviourDetails behaviour={behaviorData} />,
                                                },
                                                {
                                                    menuItem: t("Taxes"),
                                                    render: () => <BehaviourTaxes behaviour={behaviorData} />,
                                                },
                                            ]}
                                        />
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </>
            )}
        </Container>
    );
};

export default ProductBehaviourPage;
