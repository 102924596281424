import React from "react";
import {Control, Controller, DeepMap, FieldErrors} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid} from "semantic-ui-react";
import {DeductibleCapitalDTO, DeductibleCapitalType} from "../../../../../Services/DeductibleCapital/Types";
import getErrorMessage from "../../../../Global/Form/ErrorMessage";

type FormDeductibleCapitalPropsType = {
    control: Control<DeductibleCapitalDTO>;
    errors: DeepMap<any, FieldErrors>;
    setDefaultValueLabel: React.Dispatch<React.SetStateAction<string>>;
    setDefaultValueType: React.Dispatch<React.SetStateAction<DeductibleCapitalType>>;
    defaultValueType: DeductibleCapitalType;
    defaultValueLabel: string;
};

const FormDeductibleCapital = ({
    control,
    errors,
    setDefaultValueLabel,
    setDefaultValueType,
    defaultValueType,
    defaultValueLabel,
}: FormDeductibleCapitalPropsType) => {
    const {t} = useTranslation();

    const options = [
        {key: 1, text: t("Deductible"), value: DeductibleCapitalType.F},
        {key: 2, text: t("Capital"), value: DeductibleCapitalType.C},
    ];

    return (
        <Form id="modal-form">
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Controller
                            control={control}
                            defaultValue={defaultValueLabel}
                            rules={{required: true, maxLength: 100}}
                            name={"label"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    value={value}
                                    type={"text"}
                                    name={name}
                                    placeholder={t("Label")}
                                    label={t("Label")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueLabel(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    maxLength="100"
                                    error={getErrorMessage(t, errors, name, undefined, 100)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            defaultValue={defaultValueType}
                            rules={{required: true}}
                            name={"type"}
                            render={({field: {name, value, onBlur, onChange}}) => (
                                <Form.Select
                                    fluid
                                    required
                                    name={name}
                                    label={t("Type")}
                                    value={value}
                                    placeholder={t("Select a deductible capital type")}
                                    onBlur={onBlur}
                                    onChange={(_, {value}) => {
                                        onChange(value);
                                        setDefaultValueType(value as DeductibleCapitalType);
                                    }}
                                    options={options}
                                    selection
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormDeductibleCapital;
