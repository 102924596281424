import {Control, Controller, FieldErrors} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Checkbox, Divider, Form, Table} from "semantic-ui-react";
import getErrorMessage from "../../Pages/Global/Form/ErrorMessage";
import {useGetProductBehavioursQuery} from "../../Services/ProductBehaviour/Queries";
import {PromotionalCampaign} from "../../Services/PromotionalCampagins/Types";

type FormPromotionalCampaignFreeMonthPropsType = {
    control: Control<PromotionalCampaign>;
    errors: FieldErrors<PromotionalCampaign>;
    defaultValueAddPromotionalCampaignForm: PromotionalCampaign;
    setDefaultValueAddPromotionalCampaignForm: React.Dispatch<React.SetStateAction<PromotionalCampaign>>;
};

interface IParams extends Params {
    id: string;
}

const FormPromotionalCampaignFreeMonth = ({
    control,
    errors,
    defaultValueAddPromotionalCampaignForm,
    setDefaultValueAddPromotionalCampaignForm,
}: FormPromotionalCampaignFreeMonthPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const includedCoverage =
        defaultValueAddPromotionalCampaignForm.processParams &&
        defaultValueAddPromotionalCampaignForm.processParams[3] !== undefined &&
        defaultValueAddPromotionalCampaignForm.processParams[3] !== ""
            ? defaultValueAddPromotionalCampaignForm.processParams[3].split(",")
            : [];
    const getProductBehavioursQuery = useGetProductBehavioursQuery(params.id);

    return (
        <>
            <Divider />
            <Controller
                control={control}
                name={"processParams.0"}
                defaultValue={
                    defaultValueAddPromotionalCampaignForm.processParams
                        ? defaultValueAddPromotionalCampaignForm.processParams[0] || ""
                        : ""
                }
                rules={{required: true, min: 1}}
                render={({field: {onChange, onBlur, value, name}}) => (
                    <Form.Input
                        required
                        fluid
                        name={name}
                        type="number"
                        min={1}
                        label={t("Free month") + " (" + t("nth") + ")"}
                        placeholder={t("Free month") + " (" + t("nth") + ")"}
                        onChange={(_, {value}) => {
                            var currentProcessParams = defaultValueAddPromotionalCampaignForm.processParams || [];
                            currentProcessParams[0] = value;

                            setDefaultValueAddPromotionalCampaignForm({
                                ...defaultValueAddPromotionalCampaignForm,
                                processParams: currentProcessParams,
                            });
                            onChange(value);
                        }}
                        onBlur={onBlur}
                        value={value}
                        error={getErrorMessage(t, errors, name, undefined, undefined, undefined, 1, undefined)}
                    />
                )}
            />

            <Controller
                control={control}
                name={"processParams.1"}
                defaultValue={
                    defaultValueAddPromotionalCampaignForm.processParams
                        ? defaultValueAddPromotionalCampaignForm.processParams[1] || ""
                        : ""
                }
                rules={{required: true, min: 1}}
                render={({field: {onChange, onBlur, value, name}}) => (
                    <Form.Input
                        required
                        fluid
                        name={name}
                        type="date"
                        label={t("Authorized effective date start date")}
                        placeholder={t("Authorized effective date start date")}
                        onChange={(_, {value}) => {
                            var currentProcessParams = defaultValueAddPromotionalCampaignForm.processParams || [];
                            currentProcessParams[1] = value;

                            setDefaultValueAddPromotionalCampaignForm({
                                ...defaultValueAddPromotionalCampaignForm,
                                processParams: currentProcessParams,
                            });
                            onChange(value);
                        }}
                        onBlur={onBlur}
                        value={value}
                        error={getErrorMessage(t, errors, name)}
                    />
                )}
            />

            <Controller
                control={control}
                name={"processParams.2"}
                defaultValue={
                    defaultValueAddPromotionalCampaignForm.processParams
                        ? defaultValueAddPromotionalCampaignForm.processParams[2] || ""
                        : ""
                }
                rules={{required: true, min: 1}}
                render={({field: {onChange, onBlur, value, name}}) => (
                    <Form.Input
                        required
                        fluid
                        name={name}
                        type="date"
                        label={t("Authorized effective date end date")}
                        placeholder={t("Authorized effective date end date")}
                        onChange={(_, {value}) => {
                            var currentProcessParams = defaultValueAddPromotionalCampaignForm.processParams || [];
                            currentProcessParams[2] = value;

                            setDefaultValueAddPromotionalCampaignForm({
                                ...defaultValueAddPromotionalCampaignForm,
                                processParams: currentProcessParams,
                            });
                            onChange(value);
                        }}
                        onBlur={onBlur}
                        value={value}
                        error={getErrorMessage(t, errors, name)}
                    />
                )}
            />

            <Controller
                control={control}
                name={"processParams.3"}
                defaultValue={
                    defaultValueAddPromotionalCampaignForm.processParams
                        ? defaultValueAddPromotionalCampaignForm.processParams[3] || ""
                        : ""
                }
                rules={{validate: (value) => value.length > 0 || "At least one coverage must be selected"}}
                render={({field: {onChange, onBlur, name}}) => (
                    <div className="required field">
                        <label>{t("Coverage choices")}</label>
                        <Table basic celled style={{marginTop: "0"}}>
                            <Table.Body>
                                {getProductBehavioursQuery.data?.map((productBehaviour) => (
                                    <Table.Row key={productBehaviour.registryCode} className="no-hover">
                                        <Table.Cell textAlign="center">{t(productBehaviour.registryLabel)}</Table.Cell>
                                        <Table.Cell textAlign="center">
                                            <Checkbox
                                                name={name + "_" + productBehaviour.registryCode}
                                                style={{verticalAlign: "middle"}}
                                                toggle
                                                onChange={(_, {checked}) => {
                                                    var currentProcessParams =
                                                        defaultValueAddPromotionalCampaignForm.processParams || [];
                                                    var currentIncludedCoverage = includedCoverage;

                                                    if (checked) {
                                                        currentIncludedCoverage.push(productBehaviour.registryCode);
                                                    } else {
                                                        currentIncludedCoverage = currentIncludedCoverage.filter(
                                                            (item) => item !== productBehaviour.registryCode
                                                        );
                                                    }

                                                    currentProcessParams[3] = currentIncludedCoverage.join(",");

                                                    setDefaultValueAddPromotionalCampaignForm({
                                                        ...defaultValueAddPromotionalCampaignForm,
                                                        processParams: currentProcessParams,
                                                    });
                                                    onChange(currentProcessParams[3]);
                                                }}
                                                onBlur={onBlur}
                                                checked={includedCoverage.includes(productBehaviour.registryCode)}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>

                        {getErrorMessage(t, errors, name) && (
                            <div
                                className="ui pointing above prompt label"
                                role="alert"
                                aria-atomic="true"
                                style={{marginTop: "0"}}>
                                {t("At least one coverage must be selected")}
                            </div>
                        )}
                    </div>
                )}
            />
        </>
    );
};

export default FormPromotionalCampaignFreeMonth;
