export type ConditionPart = {
    conditionValue: string;
    conditionKind: ConditionKindEnum | null;
    contentType: ContentTypeEnum | null;
};

export enum ConditionKindEnum {
    OPENING_PARENTHESIS = "OPENING_PARENTHESIS",
    CLOSING_PARENTHESIS = "CLOSING_PARENTHESIS",
    STRING = "STRING",
    NUMBER = "NUMBER",
    DATE = "DATE",
    COMPARISON_OPERATOR = "COMPARISON_OPERATOR",
    LOGICAL_OPERATOR = "LOGICAL_OPERATOR",
    VARIABLE = "VARIABLE",
    VARIABLE_COMPUTING_RULE = "VARIABLE_COMPUTING_RULE",
    CRITERE = "CRITERE",
    QUESTION = "QUESTION",
    SOFTWARE_CRITERIA = "SOFTWARE_CRITERIA",
    OPERATOR = "OPERATOR",
}

export enum ContentTypeEnum {
    STRING = "STRING",
    NUMERIC = "NUMERIC",
    DATE = "DATE",
}

export enum OperandEnum {
    VARIABLE = "VARIABLE",
    VARIABLE_COMPUTING_RULE = "VARIABLE_COMPUTING_RULE",
    CRITERE = "CRITERE",
    QUESTION = "QUESTION",
    SOFTWARE_CRITERIA = "SOFTWARE_CRITERIA",
    FIXED_VALUE = "FIXED_VALUE",
    NOTHING = "NOTHING",
}

export type OperandList = {
    id?: number;
    value: string;
    name: string;
    type: string;
    description: string | undefined;
    modifiedDate?: string;
};

export enum OperatorEnum {
    "==" = "=",
    "!=" = "≠",
    "<" = "<",
    ">" = ">",
    "<=" = "≤",
    ">=" = "≥",
}

export enum LogicalOperatorEnum {
    AND = "AND",
    OR = "OR",
}

export type ConditionExpressionView = {
    leftOperand: ConditionPart;
    operator: ConditionPart;
    rightOperand: ConditionPart;
    logicalOperator: ConditionPart | undefined;
};

export type Block = {
    listSubBlock: SubBlock[];
    logicalOperator: ConditionPart | undefined;
};

export type SubBlock = {
    logicalNegation: ConditionPart | undefined;
    listConditionExpression: ConditionExpressionView[];
    logicalOperator: ConditionPart | undefined;
};

export type GridSearch = {
    refGridCode: string | null;
    dimensions: DimensionGridSearch[];
    defaultValue?: string | null;
};

export type DimensionGridSearch = {
    refDimensionCode: string | null;
    valueType: DimensionGridSearchValueType;
    value: string;
};

export enum DimensionGridSearchValueType {
    VALUE = "VALUE",
    VARIABLE = "VARIABLE",
    QUESTION = "QUESTION",
    PRICING_CRITERIA = "PRICING_CRITERIA",
    SOFTWARE_CRITERIA = "SOFTWARE_CRITERIA",
}
