import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Params, useNavigate, useParams} from "react-router-dom";
import {Grid, Input, Menu, Message, Segment, Tab} from "semantic-ui-react";
import CardListPlaceholder from "../../../../../Components/Placeholder/CardListPlaceholder";
import Sort from "../../../../../Components/Sort/Sort";
import useContainerDimensions from "../../../../../Hooks/useContainerDimensions";
import {useGetPricingCriteriasList} from "../../../../../Services/PricingCriterias/Queries";
import {PricingCriteriaItemType, PricingCriterias} from "../../../../../Services/PricingCriterias/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import {
    sortOptionsId,
    sortOptionsModificationDate,
    sortOptionsName,
    sortOptionsType,
} from "../../../../../Utils/SortUtils";
import ModalAddPricingCriteria from "../../../PricingCriterias/Fragments/ModalAddPricingCriteria";
import { PricingCriteriaCard } from "./PricingCriteriaCard";

interface IParams extends Params {
    id: string;
    versionId: string;
}

type PricingCriteriasListPropsType = {
    version: VersionDTO | undefined;
};

const PricingCriteriasList = ({version}: PricingCriteriasListPropsType) => {
    const {t} = useTranslation();
    const {id: productId, versionId} = useParams() as IParams;

    const getPricingCriteriasList = useGetPricingCriteriasList(versionId, true);
    const [sortMethod, setSortMethod] = useState<(a: PricingCriterias, b: PricingCriterias) => number>(() => (a: PricingCriterias, b: PricingCriterias) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
    const sortOptions = [...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate, ...sortOptionsType];

    const [search, setSearch] = useState("");
    const hiddenBySearch = (name: string, type: PricingCriteriaItemType) => {
        return (
            name.toLowerCase().includes(search.toLowerCase()) ||
            type.toLowerCase().includes(search.toLowerCase()) ||
            search === ""
        );
    };

    const navigate = useNavigate();

    const {columnNumberAsText, ref} = useContainerDimensions();

    return (
        <Tab.Pane style={{overflow: "auto"}}>
            <Grid divided>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Menu borderless style={{border: "0", boxShadow: "none", padding: 0}}>
                            <Menu.Menu position="right">
                                <Menu.Item>
                                    <Sort sortOptions={sortOptions} setSortMethod={setSortMethod} />
                                </Menu.Item>
                                <Menu.Item>
                                    <Input
                                        name="searchPricingCriteria"
                                        icon="search"
                                        placeholder={t("Find entity", {
                                            context: "male",
                                            entity: t("Criteria").toLowerCase(),
                                        })}
                                        defaultValue={search}
                                        onChange={(_, data) => setSearch(data.value)}
                                    />
                                </Menu.Item>
                                {version !== undefined && version.pipDate === null && (
                                    <Menu.Item>
                                        <ModalAddPricingCriteria />
                                    </Menu.Item>
                                )}
                            </Menu.Menu>
                        </Menu>

                        {getPricingCriteriasList.isSuccess &&
                            getPricingCriteriasList.data !== undefined &&
                            getPricingCriteriasList.data.length === 0 && (
                                <Segment style={{border: "0", boxShadow: "none"}}>
                                    <Message
                                        warning
                                        header={t("No pricing criteria")}
                                        content={t("There is no pricing criteria for this version")}
                                    />
                                </Segment>
                            )}

                        <div className={"ui cards " + columnNumberAsText} ref={ref}>
                            {getPricingCriteriasList.isSuccess &&
                                getPricingCriteriasList.data !== undefined &&
                                getPricingCriteriasList.data.length !== 0 &&
                                getPricingCriteriasList.data
                                    .slice()
                                    .filter((pricingCriteria: PricingCriterias) =>
                                        hiddenBySearch(pricingCriteria.name, pricingCriteria.type)
                                    )
                                    .sort(sortMethod)
                                    .map((pricingCriteria: PricingCriterias) => (
                                        <PricingCriteriaCard
                                            versionId={versionId}
                                            pricingCriteria={pricingCriteria}
                                            onClick={() => navigate(`/products/${productId}/versions/${versionId}/pricing-criterias/${pricingCriteria.id}`)}
                                        />
                                    ))}
                        </div>

                        {getPricingCriteriasList.isLoading && <CardListPlaceholder />}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
};

export default PricingCriteriasList;
