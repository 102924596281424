import {useEffect, useState} from "react";
import {Params, useParams} from "react-router-dom";
import {Dimmer, Grid, Loader, Segment, Tab} from "semantic-ui-react";
import {useGetPricingGridDimensionsQuery} from "../../../../../Services/PricingGrid/Queries";
import {Axe, DimensionGridDTO, VisualizationGridDTO} from "../../../../../Services/PricingGrid/Types";
import {useGetVersionQuery} from "../../../../../Services/Version/Queries";
import PricingGridDimensionMenu from "./PricingGridDimensionMenu";
import PricingGridDimensionValuesView from "./PricingGridDimensionValuesView";

interface IParams extends Params {
    id: string;
    versionId: string;
    gridId: string;
}

type PricingGridStructureProps = {
    gridCode: string;
};

const PricingGridStructure = ({gridCode}: PricingGridStructureProps) => {
    const params = useParams() as IParams;

    const getPricingGridDimensionsQuery = useGetPricingGridDimensionsQuery(params.gridId, true);
    const getVersionQuery = useGetVersionQuery(params.id, params.versionId, true);

    const [activeItem, setActiveItem] = useState<DimensionGridDTO | null | undefined>();

    const pricingGridDimensionSort = (
        visualizationGridDTO1: VisualizationGridDTO,
        visualizationGridDTO2: VisualizationGridDTO
    ) => {
        if (visualizationGridDTO1.axe === Axe.C && visualizationGridDTO2.axe === Axe.L) {
            return -1;
        } else if (visualizationGridDTO1.axe === Axe.L && visualizationGridDTO2.axe === Axe.C) {
            return 1;
        } else {
            return visualizationGridDTO1.rank - visualizationGridDTO2.rank;
        }
    };

    useEffect(() => {
        if (getPricingGridDimensionsQuery.isSuccess && getPricingGridDimensionsQuery.data !== undefined) {
            if (activeItem !== undefined) {
                setActiveItem(
                    getPricingGridDimensionsQuery.data.find(
                        (dimension: DimensionGridDTO) => activeItem?.code === dimension.code
                    )
                );
            } else {
                getPricingGridDimensionsQuery.data.sort((d1: DimensionGridDTO, d2: DimensionGridDTO) =>
                    pricingGridDimensionSort(d1.visualizationGridDTO, d2.visualizationGridDTO)
                );
                setActiveItem(getPricingGridDimensionsQuery.data[0]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPricingGridDimensionsQuery.isSuccess, getPricingGridDimensionsQuery.data]);

    return (
        <Tab.Pane>
            <Grid>
                {getVersionQuery.isSuccess &&
                    getVersionQuery.data &&
                    getPricingGridDimensionsQuery.isSuccess &&
                    !getPricingGridDimensionsQuery.isFetching &&
                    getPricingGridDimensionsQuery.data && (
                        <>
                            <PricingGridDimensionMenu
                                version={getVersionQuery.data}
                                dimensionsAxeColumn={getPricingGridDimensionsQuery.data.filter(
                                    (dimension: DimensionGridDTO) => dimension.visualizationGridDTO.axe === Axe.C
                                )}
                                dimensionsAxeLine={getPricingGridDimensionsQuery.data.filter(
                                    (dimension: DimensionGridDTO) => dimension.visualizationGridDTO.axe === Axe.L
                                )}
                                activeItem={activeItem}
                                setActiveItem={setActiveItem}
                            />

                            {activeItem !== undefined && (
                                <PricingGridDimensionValuesView
                                    version={getVersionQuery.data}
                                    gridCode={gridCode}
                                    dimensionId={activeItem?.id ? activeItem.id : 0}
                                />
                            )}
                        </>
                    )}

                {getPricingGridDimensionsQuery.isFetching && (
                    <Grid.Column width={16}>
                        <Segment style={{height: "400px"}}>
                            <Dimmer active inverted>
                                <Loader size="large">Loading</Loader>
                            </Dimmer>
                        </Segment>
                    </Grid.Column>
                )}
            </Grid>
        </Tab.Pane>
    );
};

export default PricingGridStructure;
