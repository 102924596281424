import {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, Params, useParams} from "react-router-dom";
import {Card, Grid, Input, Menu, Message, Segment, Tab} from "semantic-ui-react";
import {AuthContext} from "../../../../../Components/AuthProvider/AuthProvider";
import CardListPlaceholder from "../../../../../Components/Placeholder/CardListPlaceholder";
import Sort from "../../../../../Components/Sort/Sort";
import TextOverflow from "../../../../../Components/Text/TextOverflow";
import useContainerDimensions from "../../../../../Hooks/useContainerDimensions";
import {useGetAllGridQuery} from "../../../../../Services/PricingGrid/Queries";
import {BasicGridType} from "../../../../../Services/PricingGrid/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import {displayAuditLog} from "../../../../../Utils/AuditUtils";
import {sortOptionsId, sortOptionsLabel, sortOptionsModificationDate} from "../../../../../Utils/SortUtils";
import ModalAddPricingGrid from "../../../PricingGrid/Fragments/ModalAddPricingGrid";

interface IParams extends Params {
    id: string;
    versionId: string;
}

type PropsType = {
    version: VersionDTO | undefined;
};

const PricingGridList = ({version}: PropsType) => {
    const {t} = useTranslation();
    const userContext = useContext(AuthContext);
    const {id: productId, versionId} = useParams() as IParams;

    const getAllGridQuery = useGetAllGridQuery(versionId);
    const [sortMethod, setSortMethod] = useState<(a: any, b: any) => number>(() => (a: any, b: any) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    );
    const sortOptions = [...sortOptionsLabel, ...sortOptionsId, ...sortOptionsModificationDate];

    const [search, setSearch] = useState("");
    const hiddenBySearch = (code: string, label: string) => {
        return (
            code.toLowerCase().includes(search.toLowerCase()) ||
            label.toLowerCase().includes(search.toLowerCase()) ||
            search === ""
        );
    };

    const {columnNumberAsText, ref} = useContainerDimensions();

    return (
        <Tab.Pane>
            <Grid divided>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Menu borderless style={{border: "0", boxShadow: "none", padding: 0}}>
                            <Menu.Menu position="right">
                                <Menu.Item>
                                    <Sort sortOptions={sortOptions} setSortMethod={setSortMethod} />
                                </Menu.Item>
                                <Menu.Item>
                                    <Input
                                        icon="search"
                                        placeholder={t("Find entity", {
                                            context: "female",
                                            entity: t("Grid").toLowerCase(),
                                        })}
                                        defaultValue={search}
                                        onChange={(_, data) => setSearch(data.value)}
                                    />
                                </Menu.Item>
                                {version !== undefined && version.pipDate === null && (
                                    <Menu.Item>
                                        <ModalAddPricingGrid />
                                    </Menu.Item>
                                )}
                            </Menu.Menu>
                        </Menu>

                        {getAllGridQuery.isSuccess &&
                            getAllGridQuery.data !== undefined &&
                            getAllGridQuery.data.length === 0 && (
                                <Segment style={{border: "0", boxShadow: "none"}}>
                                    <Message
                                        warning
                                        header={t("No grids")}
                                        content={t("There is no grids for this version")}
                                    />
                                </Segment>
                            )}

                        <div className={"ui cards " + columnNumberAsText} ref={ref}>
                            {getAllGridQuery.isSuccess &&
                                getAllGridQuery.data !== undefined &&
                                getAllGridQuery.data.length !== 0 &&
                                getAllGridQuery.data
                                    .slice()
                                    .filter((pricingGrid: BasicGridType) =>
                                        hiddenBySearch(pricingGrid.code, pricingGrid.label)
                                    )
                                    .sort(sortMethod)
                                    .map((pricingGrid: BasicGridType, index: number) => (
                                        <Card
                                            key={`pricingGrid-${index}`}
                                            as={Link}
                                            to={`/products/${productId}/versions/${versionId}/pricing-grid/${pricingGrid.id}`}>
                                            <Card.Content>
                                                <Card.Header>
                                                    <TextOverflow value={pricingGrid.label} />
                                                </Card.Header>
                                                <Card.Meta>
                                                    <strong>
                                                        <TextOverflow value={pricingGrid.code} />
                                                    </strong>
                                                </Card.Meta>
                                            </Card.Content>

                                            {userContext.user !== undefined && (
                                                <Card.Content extra textAlign="right" style={{fontStyle: "italic"}}>
                                                    <span>
                                                        {displayAuditLog(
                                                            {
                                                                entityContext: "female",
                                                                createdDate: pricingGrid.createdDate,
                                                                modifiedDate: pricingGrid.modifiedDate,
                                                                createdBy: pricingGrid.createdBy,
                                                                modifiedBy: pricingGrid.modifiedBy,
                                                                createdOnVersionId: pricingGrid.createdOnVersionId,
                                                            },
                                                            userContext.user,
                                                            versionId,
                                                            t
                                                        )}
                                                    </span>
                                                </Card.Content>
                                            )}
                                        </Card>
                                    ))}
                        </div>

                        {getAllGridQuery.isLoading && <CardListPlaceholder />}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
};

export default PricingGridList;
