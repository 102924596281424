import {Grid, Placeholder, Segment} from "semantic-ui-react";

const CardListPlaceholder = () => {
    return (
        <Grid columns={4}>
            {[...Array.from(Array(8).keys())].map((value) => (
                <Grid.Column key={`cardListPlaceholder-${value}`}>
                    <Segment>
                        <Placeholder>
                            <Placeholder.Header>
                                <Placeholder.Line length="short" />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Paragraph>
                            <Placeholder.Paragraph>
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Paragraph>
                        </Placeholder>
                    </Segment>
                </Grid.Column>
            ))}
        </Grid>
    );
};

export default CardListPlaceholder;
