import { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Dropdown, Grid, Icon, Segment } from "semantic-ui-react";
import {
    Block,
    ConditionKindEnum,
    ConditionPart,
    LogicalOperatorEnum,
    OperandEnum,
    SubBlock
} from "../../../../Services/Condition/Types";
import ConditionSubBlock from "./ConditionSubBlock";

type ConditionBlockPropsTypes = {
    indexBlock: number;
    block: Block;
    blockLength: number;
    dispatchBlockList: Function;
    conditionKindList: {key: number; text: string; value: OperandEnum; type: string}[];
    versionId?: string;
};

type ActionType =
    | {type: "add"; payload: number}
    | {type: "remove"; payload: number}
    | {
          type: "editSubBlock";
          payload: {indexSubBlock: number; field: "logicalNegation" | "logicalOperator"; value: ConditionPart};
      }
    | {type: "updateList"; payload: SubBlock[]};

const ConditionBlock = ({
    indexBlock,
    block,
    blockLength,
    dispatchBlockList,
    conditionKindList,
    versionId,
}: ConditionBlockPropsTypes) => {
    const {t} = useTranslation();

    const logicalOperatorOptions = [
        {key: 1, text: t("enums.LogicalOperatorEnum." + LogicalOperatorEnum.AND), value: LogicalOperatorEnum.AND},
        {key: 2, text: t("enums.LogicalOperatorEnum." + LogicalOperatorEnum.OR), value: LogicalOperatorEnum.OR},
    ];

    const reducerSubBlockList = (state: {listSubBlock: SubBlock[]}, action: ActionType) => {
        const listSubBlock = [...state.listSubBlock];
        switch (action.type) {
            case "add":
                listSubBlock.push({
                    logicalNegation: undefined,
                    listConditionExpression: [
                        {
                            leftOperand: {
                                conditionValue: "",
                                conditionKind: null,
                                contentType: null,
                            },
                            operator: {conditionValue: "", conditionKind: null, contentType: null},
                            rightOperand: {conditionValue: "", conditionKind: null, contentType: null},
                            logicalOperator: undefined,
                        },
                    ],
                    logicalOperator: undefined,
                });
                listSubBlock[listSubBlock.length - 2].logicalOperator = listSubBlock[0].logicalOperator;
                dispatchBlockList({
                    type: "editBlockListSubBlock",
                    payload: {
                        indexBlock: indexBlock,
                        field: "listSubBlock",
                        value: listSubBlock,
                    },
                });
                return {listSubBlock: listSubBlock};
            case "remove":
                listSubBlock.splice(action.payload, 1);
                if (listSubBlock.length > 0) {
                    listSubBlock[listSubBlock.length - 1].logicalOperator = undefined;
                }
                dispatchBlockList({
                    type: "editBlockListSubBlock",
                    payload: {
                        indexBlock: indexBlock,
                        field: "listSubBlock",
                        value: listSubBlock,
                    },
                });
                return {listSubBlock: listSubBlock};
            case "editSubBlock":
                listSubBlock[action.payload.indexSubBlock][action.payload.field] = action.payload.value;
                dispatchBlockList({
                    type: "editBlockListSubBlock",
                    payload: {
                        indexBlock: indexBlock,
                        field: "listSubBlock",
                        value: listSubBlock,
                    },
                });
                return {listSubBlock: listSubBlock};
            case "updateList":
                return {listSubBlock: action.payload};
            default:
                throw new Error();
        }
    };

    const [state, dispatchSubBlockList] = useReducer(reducerSubBlockList, {
        listSubBlock: [],
    });

    useEffect(() => {
        dispatchSubBlockList({type: "updateList", payload: block.listSubBlock});
    }, [block, dispatchBlockList]);

    return (
        <div>
            <Segment tertiary textAlign="center">
                <Grid>
                    {state.listSubBlock.map((subBlock, indexSubBlock) => (
                        <ConditionSubBlock
                            key={indexSubBlock}
                            indexSubBlock={indexSubBlock}
                            subBlock={subBlock}
                            subBlockList={state.listSubBlock}
                            subBlockLength={state.listSubBlock.length}
                            dispatchSubBlockList={dispatchSubBlockList}
                            conditionKindList={conditionKindList}
                            versionId={versionId}
                        />
                    ))}
                </Grid>
            </Segment>
            {indexBlock < blockLength - 1 ? (
                <Divider horizontal>
                    <Dropdown
                        placeholder={t("Logical_operator")}
                        fluid
                        selection
                        data-cy="logicalOperatorBlock"
                        options={logicalOperatorOptions}
                        value={block.logicalOperator === undefined ? "" : block.logicalOperator.conditionValue}
                        style={{border: "dashed grey"}}
                        onChange={(e, value) => {
                            dispatchBlockList({
                                type: "editBlock",
                                payload: {
                                    indexBlock: indexBlock,
                                    field: "logicalOperator",
                                    value: {
                                        conditionValue: value.value,
                                        conditionKind: ConditionKindEnum.LOGICAL_OPERATOR,
                                        contentType: null,
                                    },
                                },
                            });
                        }}
                    />
                </Divider>
            ) : (
                <Divider horizontal fitted>
                    <Button
                        className="addBlock"
                        color="blue"
                        onClick={() => {
                            dispatchBlockList({type: "add", payload: indexBlock});
                        }}>
                        <Icon name="add" fitted></Icon>
                    </Button>
                </Divider>
            )}
        </div>
    );
};

export default ConditionBlock;
