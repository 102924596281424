import {AxiosResponse} from "axios";
import {UseQueryResult, useMutation, UseMutationResult, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import queryClient from "../../Config/queryClient";
import {CustomError} from "../RequestUtils";
import {
    addPricingGrid,
    addPricingGridDimension,
    addPricingGridDimensionValue,
    deletePricingGrid,
    deletePricingGridDimension,
    deletePricingGridDimensionValue,
    duplicatePricingGrid,
    exportPricingGrid,
    getPricingGrid,
    getPricingGridDimension,
    getPricingGridDimensions,
    getPricingGrids,
    getPricingGridView,
    importPricingGrid,
    reorderDimensionValues,
    importValuesFromQuestionTypeListToDimension,
    reorderPricingGridDimension,
    updatePricingGrid,
    updatePricingGridDimension,
    updatePricingGridDimensionValue,
    uploadDimensionValues,
} from "./AxiosRequests";
import {
    BasicGridType,
    DimensionGridDTO,
    DimensionGridDTOWithId,
    DimensionGridValueDTO,
    DimensionGridValueDTOWithId,
    PricingGridAddType,
    PricingGridViewType,
} from "./Types";

export const useGetAllGridQuery = (versionId: string): UseQueryResult<Array<BasicGridType>, Error> => {
    return useQuery({
        queryKey: ["getPricingGrids", versionId],
        queryFn: () => getPricingGrids(versionId),
    });
};

export const useGetGridQuery = (versionId: string, gridId: string, enabled: boolean = true) => {
    return useQuery({
        queryKey: ["getPricingGrid", {versionId, gridId}],
        queryFn: () => getPricingGrid(versionId, gridId),
        enabled,
    });
};

export const useDeleteGridMutation = (productId: string, versionId: string, gridId: string) => {
    const navigate = useNavigate();
    return useMutation({
        mutationFn: () => deletePricingGrid(versionId, gridId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingGrids", versionId]});
            queryClient.removeQueries({queryKey: ["getPricingGrid", {versionId, gridId}]});
            navigate(`/products/${productId}/versions/${versionId}`, {state: {activeTabIndex: 0}});
        },
    });
};

export const useUpdateGridMutation = (
    versionId: string,
    gridId: string
): UseMutationResult<BasicGridType, CustomError, string, Error> => {
    return useMutation<BasicGridType, CustomError, string, Error>({
        mutationFn: (label: string) => updatePricingGrid(versionId, gridId, label),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingGrid", {versionId, gridId}]});
            await queryClient.invalidateQueries({queryKey: ["getPricingGrids", versionId]});
        },
    });
};

export const useDuplicatePricingGrid = (
    productId: string,
    versionId: string,
    gridId: string
): UseMutationResult<BasicGridType, CustomError, {code: string; label: string}, Error> => {
    const navigate = useNavigate();
    return useMutation<BasicGridType, CustomError, {code: string; label: string}, Error>({
        mutationFn: (data: {code: string; label: string}) =>
            duplicatePricingGrid(versionId, gridId, data.code, data.label),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["getPricingGrids", versionId]});
            navigate(`/products/${productId}/versions/${versionId}/pricing-grid/${result.id}`);
        },
    });
};

export const useExportPricingGridQuery = (versionId: string, gridId: string, enabled: boolean = true) => {
    return useQuery({
        queryKey: ["ExportPricingGrid", {versionId, gridId}],
        queryFn: () => exportPricingGrid(versionId, gridId),
        enabled,
    });
};

export const useGetPricingGridViewQuery = (
    versionId: string,
    gridId: string
): UseQueryResult<PricingGridViewType, Error> => {
    return useQuery({
        queryKey: ["getPricingGridView", {versionId, gridId}],
        queryFn: () => getPricingGridView(versionId, gridId),
    });
};

export const useImportPricingGridMutation = (versionId: string, gridId: string) => {
    return useMutation({
        mutationFn: (files: File) => {
            const formData = new FormData();

            formData.append(
                "file",
                new Blob([files], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
            );

            return importPricingGrid(versionId, gridId, formData);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingGridView", {versionId, gridId}]});
            await queryClient.invalidateQueries({queryKey: ["getPricingGrids", versionId]});
            await queryClient.invalidateQueries({queryKey: ["getPricingGrid", {versionId, gridId}]});
        },
    });
};

export const useGetPricingGridDimensionsQuery = (
    gridId: string,
    enabled?: boolean
): UseQueryResult<Array<DimensionGridDTOWithId>, Error> => {
    return useQuery({
        queryKey: ["getPricingGridDimensions", gridId],
        queryFn: () => getPricingGridDimensions(gridId),
        enabled: enabled ?? false,
    });
};

export const useGetPricingGridDimensionQuery = (
    gridId: string,
    dimensionId: string
): UseQueryResult<DimensionGridDTOWithId, Error> => {
    return useQuery({
        queryKey: ["getPricingGridDimension", {gridId, dimensionId}],
        queryFn: () => getPricingGridDimension(gridId, dimensionId),
    });
};

export const useUpdatePricingGridDimensionValueMutation = (
    versionId: string,
    gridId: string,
    dimensionId: string,
    dimensionValueId: string
): UseMutationResult<DimensionGridDTO, CustomError, DimensionGridValueDTOWithId, Error> => {
    return useMutation({
        mutationFn: (formData: DimensionGridValueDTOWithId) =>
            updatePricingGridDimensionValue(dimensionId, dimensionValueId, formData),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingGridDimension", {gridId, dimensionId}]});
            await queryClient.invalidateQueries({queryKey: ["getPricingGridView", {versionId, gridId}]});
        },
    });
};

export const useAddPricingGridDimensionValueMutation = (
    versionId: string,
    gridId: string,
    dimensionId: string
): UseMutationResult<DimensionGridValueDTO, CustomError, DimensionGridValueDTO, Error> => {
    return useMutation({
        mutationFn: (formData: DimensionGridValueDTO) => addPricingGridDimensionValue(dimensionId, formData),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingGridDimension", {gridId, dimensionId}]});
            await queryClient.invalidateQueries({queryKey: ["getPricingGridView", {versionId, gridId}]});
        },
    });
};

export const useDeletePricingGridDimensionValueMutation = (
    versionId: string,
    gridId: string,
    dimensionId: string,
    dimensionValueId: string
): UseMutationResult<void, CustomError, {dimensionGridId: string; dimensionGridValueId: string}, Error> => {
    return useMutation({
        mutationFn: () => deletePricingGridDimensionValue(dimensionId, dimensionValueId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingGridDimension", {gridId, dimensionId}]});
            await queryClient.invalidateQueries({queryKey: ["getPricingGridView", {versionId, gridId}]});
        },
    });
};

export const useDeletePricingGridDimensionMutation = (
    versionId: string,
    gridId: string
): UseMutationResult<void, CustomError, string, Error> => {
    return useMutation({
        mutationFn: (dimensionId: string) => deletePricingGridDimension(dimensionId, gridId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingGridDimensions", gridId]});
            await queryClient.invalidateQueries({queryKey: ["getPricingGridView", {versionId, gridId}]});
            await queryClient.invalidateQueries({queryKey: ["getPricingCriterias", versionId]});
            await queryClient.invalidateQueries({queryKey: ["getPricingCriteria", {versionId}]});
        },
    });
};

export const useAddPricingGridDimensionMutation = (
    versionId: string,
    gridId: string
): UseMutationResult<DimensionGridDTO, CustomError, DimensionGridDTO, Error> => {
    return useMutation<DimensionGridDTO, CustomError, DimensionGridDTO, Error>({
        mutationFn: (dimension: DimensionGridDTO) => addPricingGridDimension(gridId, dimension),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingGridDimensions", gridId]});
            await queryClient.invalidateQueries({queryKey: ["getPricingGridView", {versionId, gridId}]});
            await queryClient.invalidateQueries({queryKey: ["getPricingCriterias", versionId]});
            await queryClient.invalidateQueries({queryKey: ["getPricingCriteria", {versionId}]});
        },
    });
};

export const useUpdatePricingGridDimensionMutation = (
    versionId: string,
    gridId: string,
    dimensionId: string
): UseMutationResult<DimensionGridDTO, CustomError, DimensionGridDTO, Error> => {
    return useMutation<DimensionGridDTO, CustomError, DimensionGridDTO, Error>({
        mutationFn: (dimension: DimensionGridDTO) => updatePricingGridDimension(dimension, gridId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingGridDimensions", gridId]});
            await queryClient.invalidateQueries({queryKey: ["getPricingGridDimension", {gridId, dimensionId}]});
            await queryClient.invalidateQueries({queryKey: ["getPricingGridView", {versionId, gridId}]});
            await queryClient.invalidateQueries({queryKey: ["getPricingCriterias", versionId]});
            await queryClient.invalidateQueries({queryKey: ["getPricingCriteria", {versionId}]});
        },
    });
};

export const useReorderPricingGridDimensionMutation = (
    versionId: string,
    gridId: string
): UseMutationResult<DimensionGridDTO, CustomError, {dimensionId: number; rank: number}, Error> => {
    return useMutation<DimensionGridDTO, CustomError, {dimensionId: number; rank: number}, Error>({
        mutationFn: (dimensionData: {dimensionId: number; rank: number}) =>
            reorderPricingGridDimension(gridId, dimensionData.dimensionId, dimensionData.rank),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingGridDimensions", gridId]});
            await queryClient.invalidateQueries({queryKey: ["getPricingGridView", {versionId, gridId}]});
        },
    });
};

export const useAddPricingGridMutation = (
    productId: string,
    versionId: string
): UseMutationResult<BasicGridType, CustomError, PricingGridAddType, Error> => {
    const navigate = useNavigate();
    return useMutation<BasicGridType, CustomError, PricingGridAddType, Error>({
        mutationFn: (formData: PricingGridAddType) => addPricingGrid(versionId, formData),
        onSuccess: async (result: BasicGridType) => {
            await queryClient.invalidateQueries({queryKey: ["getPricingGrids", versionId]});
            navigate(`/products/${productId}/versions/${versionId}/pricing-grid/${result.id}`);
        },
    });
};

export const useUploadDimensionValues = (
    versionId: string,
    gridId: string,
    dimensionId: string
): UseMutationResult<AxiosResponse, CustomError, File, Error> => {
    return useMutation<AxiosResponse, CustomError, File, Error>({
        mutationFn: (files: File) => {
            const formData = new FormData();

            formData.append(
                "file",
                new Blob([files], {
                    type: "text/csv",
                })
            );

            return uploadDimensionValues(formData, gridId, dimensionId);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingGridDimension", {gridId, dimensionId}]});
            await queryClient.invalidateQueries({queryKey: ["getPricingGridView", {versionId, gridId}]});
        },
    });
};

export const useReorderDimensionValuesMutation = (
    versionId: string,
    gridId: string,
    dimensionId: string,
    dimensionValueId: string
): UseMutationResult<DimensionGridValueDTOWithId, CustomError, string, Error> => {
    return useMutation<DimensionGridValueDTOWithId, CustomError, string, Error>({
        mutationFn: (order: string) => reorderDimensionValues(dimensionId, dimensionValueId, order),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingGridDimension", {gridId, dimensionId}]});
            await queryClient.invalidateQueries({queryKey: ["getPricingGridView", {versionId, gridId}]});
        },
    });
};

export const useImportValuesFromQuestionTypeListToDimensionMutation = (
    gridId: string,
    dimensionId: string,
    versionId: string
): UseMutationResult<AxiosResponse, CustomError, string, Error> => {
    return useMutation<AxiosResponse, CustomError, string, Error>({
        mutationFn: (questionName: string) =>
            importValuesFromQuestionTypeListToDimension(gridId, dimensionId, questionName),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPricingGridDimension", {gridId, dimensionId}]});
            await queryClient.invalidateQueries({queryKey: ["getPricingGridView", {versionId, gridId}]});
        },
    });
};
