import { useEffect, useState } from "react";

type PropsType = {
    value: string;
};

const TextShowMore = ({value}: PropsType) => {
    const [showMore, setShowMore] = useState(false);
    const [isTextLong, setIsTextLong] = useState(false);

    useEffect(() => {
        if (value.length > 30) {
            setIsTextLong(true);
        }
    }, [value]);

    const handleClick = () => setShowMore(!showMore);

    return (
        <>
            <strong>{isTextLong && !showMore ? <>{value.slice(0, 29)}...</> : <>{value}</>}</strong>
            <br />
            {isTextLong ? (
                <small onClick={handleClick}>{showMore ? "(afficher moins)" : "(afficher plus)"}</small>
            ) : null}
        </>
    );
};

export default TextShowMore;
