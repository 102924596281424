import {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Container, Grid, Menu, Message, Segment, Select} from "semantic-ui-react";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import {ProductRecap} from "../../../Services/Product/Types";
import {useGetESelectionEligibleProductsQuery} from "../../Services/ESelection/Queries";
import ESelectionActionsButtons from "./ESelectionActionsButtons";
import ESelectionMatrix from "./ESelectionMatrix";
import BreadcrumbESelection from "./Fragment/BreadcrumbESelection";
import {useProductsSidebarMenu} from "../../../Hooks/useProductsSidebarMenu";

const ESelectionPage = ({...props}) => {
    const {t} = useTranslation();
    const menus = useProductsSidebarMenu();

    // Get eligible products
    const getESelectionEligibleProductsQuery = useGetESelectionEligibleProductsQuery();

    // Get produits & select produit
    const [lifeProducts, setLifeProducts] = useState<Array<{key: string; value: string; text: string}>>([]);
    const [productIdFilter, setProductIdFilter] = useState<string>();

    useEffect(() => {
        if (getESelectionEligibleProductsQuery.isSuccess && getESelectionEligibleProductsQuery.data) {
            setLifeProducts(
                getESelectionEligibleProductsQuery.data.map((product: ProductRecap) => {
                    return {key: product.id.toString(), value: product.id.toString(), text: product.label};
                })
            );
        }
    }, [getESelectionEligibleProductsQuery.isSuccess, getESelectionEligibleProductsQuery.data]);

    useEffect(() => {
        if (lifeProducts.length > 0) {
            setProductIdFilter(lifeProducts[0].value);
        }
    }, [lifeProducts]);

    return (
        <Fragment>
            <Sidebar title={t("Product", {count: 2})} menus={menus} />
            <BreadcrumbESelection productId={productIdFilter} sections={props.sections} />
            <Container fluid>
                <div className="main-container">
                    <Grid centered>
                        <Grid.Row>
                            <Grid.Column>
                                <Segment>
                                    {getESelectionEligibleProductsQuery.isSuccess && (
                                        <Fragment>
                                            {getESelectionEligibleProductsQuery.data.length > 0 ? (
                                                <Fragment>
                                                    <Menu borderless secondary>
                                                        <Menu.Item fitted>
                                                            <Select
                                                                name="productFilter"
                                                                value={productIdFilter}
                                                                onChange={(_, input) =>
                                                                    setProductIdFilter(input.value as string)
                                                                }
                                                                options={lifeProducts}
                                                            />
                                                        </Menu.Item>

                                                        {productIdFilter && (
                                                            <ESelectionActionsButtons productId={productIdFilter} />
                                                        )}
                                                    </Menu>
                                                    {productIdFilter && (
                                                        <ESelectionMatrix productId={productIdFilter} />
                                                    )}
                                                </Fragment>
                                            ) : (
                                                <Message warning>
                                                    <Message.Header>
                                                        {t("No product is eligible for eSelection")}
                                                    </Message.Header>
                                                    <Message.Content>
                                                        {t("Amoung the life products, define eSelection")}
                                                    </Message.Content>
                                                </Message>
                                            )}
                                        </Fragment>
                                    )}
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Container>
        </Fragment>
    );
};

export default ESelectionPage;
