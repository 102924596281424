import {useState} from "react";
import {useForm} from "react-hook-form";
import {Params, useParams} from "react-router-dom";
import ModalAdd from "../../../../Components/Modals/ModalAdd";
import {useAddInsurancePackageMutation} from "../../../../Services/InsurancePackage/Queries";
import FormInsurancePackage, {FormInsurancePackageFormTypes} from "./FormInsurancePackage";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
}

type PropsType = {
    alternateOpenButton?: () => JSX.Element;
};

const ModalAddInsurancePackage = ({...props}: PropsType) => {
    const {id: productId, versionId} = useParams() as IParams;

    const addInsurancePackageMutation = useAddInsurancePackageMutation(productId, versionId);
    const addInsurancePackageForm = useForm<FormInsurancePackageFormTypes>();

    const [defaultValues, setDefaultValues] = useState<FormInsurancePackageFormTypes>({
        code: "",
        label: "",
        startDate: "",
        endDate: "",
        endDateEndorsement: "",
    });
    const resetDefaultValues = () => {
        addInsurancePackageForm.reset();
        setDefaultValues({
            code: "",
            label: "",
            startDate: "",
            endDate: "",
            endDateEndorsement: "",
        });
    };

    const submitNewInsurancePackage = (formData: FormInsurancePackageFormTypes) => {
        addInsurancePackageMutation.mutate({
            ...formData,
            startDate: formData.startDate + "T00:00:00.000Z",
            endDate: formData.endDate ? formData.endDate + "T00:00:00.000Z" : null,
            endDateEndorsement: formData.endDateEndorsement ? formData.endDateEndorsement + "T00:00:00.000Z" : null,
        });
    };

    return (
        <ModalAdd
            isValidationDisabled={Object.keys(addInsurancePackageForm.formState.errors).length !== 0}
            isPending={addInsurancePackageMutation.isPending}
            isSuccess={addInsurancePackageMutation.isSuccess}
            isError={addInsurancePackageMutation.isError}
            resetMutation={addInsurancePackageMutation.reset}
            error={addInsurancePackageMutation.error}
            onValidate={addInsurancePackageForm.handleSubmit(submitNewInsurancePackage)}
            onSuccess={resetDefaultValues}
            onCancel={resetDefaultValues}
            iconOnOpenButton
            openButtonIcon="add"
            objectToAdd="Insurance package"
            objectToAddContext="female"
            renderModalContent={() => (
                <FormInsurancePackage
                    errors={addInsurancePackageForm.formState.errors}
                    control={addInsurancePackageForm.control}
                    modifiedInsurancePackage={defaultValues}
                    setModifiedInsurancePackage={setDefaultValues}
                />
            )}
            renderOpenButton={props.alternateOpenButton}
        />
    );
};

export default ModalAddInsurancePackage;
