import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Accordion, Grid, GridColumn, GridRow, Message, Segment} from "semantic-ui-react";
import ModalAdd from "../../../../../Components/Modals/ModalAdd";
import useDragAndDropProperties from "../../../../../Hooks/useDragAndDropProperties";
import {useAddPricingCriteriaConditionMutation} from "../../../../../Services/PricingCriterias/Queries";
import {PricingCriteriaConditionDTO, PricingCriteriaItem} from "../../../../../Services/PricingCriterias/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import PricingCriteriaConditionAccordion from "./PricingCriteriaConditionAccordion";

interface IParams extends Params {
    id: string;
    versionId: string;
    pricingCriteriaId: string;
}

type PricingCriteriaConditionsListPropsType = {
    pricingCriteria: PricingCriteriaItem;
    version: VersionDTO;
};

const PricingCriteriaConditionsList = ({pricingCriteria, version}: PricingCriteriaConditionsListPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    const [activeConditionIndex, setActiveConditionIndex] = useState<number>(0);

    const pricingCriteriaConditionAddMutation = useAddPricingCriteriaConditionMutation(
        params.versionId,
        params.pricingCriteriaId
    );

    const onAddConditionValidation = () => {
        let defaultValue;
        switch (pricingCriteria.type) {
            case "STRING":
                defaultValue = "default";
                break;
            case "NUMERIC":
                defaultValue = "0";
                break;
            case "DATE":
                defaultValue = new Date().toISOString().split("T")[0];
                break;
            default:
                defaultValue = "default";
        }

        const condition = {
            ifCondition: "( ( 1 == 1 ) )",
            fixedValue: defaultValue,
        };

        pricingCriteriaConditionAddMutation.mutate(condition);
    };

    const onAddConditionSuccess = () => {
        if (pricingCriteria !== undefined) {
            setActiveConditionIndex(pricingCriteria.pricingCriteriaConditions.length - 2);
        }
    };

    const dndProperties = useDragAndDropProperties<PricingCriteriaConditionDTO>(
        pricingCriteria.pricingCriteriaConditions
    );

    const renderConditions = () => {
        return dndProperties.renderedItems.map((condition, index) => {
            return (
                <PricingCriteriaConditionAccordion
                    key={condition.id}
                    condition={condition}
                    numberOfConditions={pricingCriteria.pricingCriteriaConditions.length}
                    version={version}
                    index={index}
                    activeIndex={activeConditionIndex}
                    setActiveIndex={setActiveConditionIndex}
                    pricingCriteria={pricingCriteria}
                    dndProperties={dndProperties}
                />
            );
        });
    };

    return (
        <Segment className="card-container">
            <Accordion styled fluid onMouseLeave={() => dndProperties.refreshData()}>
                {renderConditions()}
            </Accordion>
            {version !== undefined && version.pipDate === null && (
                <Grid style={{marginTop: "0"}}>
                    <GridRow>
                        <GridColumn textAlign="center">
                            <ModalAdd
                                isPending={pricingCriteriaConditionAddMutation.isPending}
                                isSuccess={pricingCriteriaConditionAddMutation.isSuccess}
                                isError={pricingCriteriaConditionAddMutation.isError}
                                resetMutation={pricingCriteriaConditionAddMutation.reset}
                                error={pricingCriteriaConditionAddMutation.error}
                                onValidate={() => onAddConditionValidation()}
                                onSuccess={() => onAddConditionSuccess()}
                                objectToAdd="Condition"
                                objectToAddContext="female"
                                openModalButtonName="addCondition"
                                renderModalContent={() => (
                                    <Message info>
                                        {t(
                                            "You are about to add a new condition, this condition will be added second to last with default values which will be convenient to update"
                                        )}
                                    </Message>
                                )}
                            />
                        </GridColumn>
                    </GridRow>
                </Grid>
            )}
        </Segment>
    );
};

export default PricingCriteriaConditionsList;
