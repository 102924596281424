import {useMutation, UseMutationResult, useQuery} from "@tanstack/react-query";
import queryClient from "../../../Config/queryClient";
import {CustomError} from "../../../Services/RequestUtils";
import {getPremiumsPricingRights, uploadPremiumsPricingRights} from "./AxiosRequests";

export const useGetPremiumsPricingRights = () => {
    return useQuery({
        queryKey: ["getPremiumsPricingRights"],
        queryFn: () => getPremiumsPricingRights(),
    });
};

export const useUploadPremiumsPricingRights = (): UseMutationResult<void, CustomError, any, Error> => {
    return useMutation<void, CustomError, any, Error>({
        mutationFn: (files: any) => {
            const formData = new FormData();

            formData.append(
                "file",
                new Blob([files], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
            );

            return uploadPremiumsPricingRights(formData);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPremiumsPricingRights"]});
        },
    });
};

