import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Button, Divider, Form, Grid, Header, Segment} from "semantic-ui-react";
import FormPassword from "../../../../Components/Forms/FormPassword";
import CustomModal from "../../../../Components/Modals/CustomModal";
import ModalUpdate from "../../../../Components/Modals/ModalUpdate";
import {useUpdateSelfUserMutation} from "../../../../Services/Company/Queries";
import {UserDTO, UserUpdateSelfDTO} from "../../../../Services/Company/Types";
import getErrorMessage from "../../Form/ErrorMessage";

type UserProfilePropsType = {
    companyId: string;
    user: UserDTO;
};

type UserProfileViewPropsType = {
    companyId: string;
    user: UserDTO;
    t: Function;
    toggleEditMode: () => void;
};

type UserProfileEditPropsType = {
    companyId: string;
    user: UserDTO;
    t: Function;
    toggleEditMode: () => void;
};

const UserProfile = ({companyId, user}: UserProfilePropsType) => {
    const {t} = useTranslation();
    const [editMode, setEditMode] = useState<boolean>(false);
    const toggleEditMode = () => setEditMode(!editMode);

    return editMode ? (
        <UserProfileEdit companyId={companyId} user={user} t={t} toggleEditMode={toggleEditMode} />
    ) : (
        <UserProfileView companyId={companyId} user={user} t={t} toggleEditMode={toggleEditMode} />
    );
};

const UserProfileView = ({companyId, user, t, toggleEditMode}: UserProfileViewPropsType) => {
    const updateSelfUserMutation = useUpdateSelfUserMutation(companyId);
    const {
        control,
        formState: {errors},
        handleSubmit,
        watch,
    } = useForm<any>({mode: "onChange"});
    const watchPassword = watch("password", "");
    const watchRepeatedPassword = watch("repeat-password", "");

    const submitUpdatePasswordForm = async (formData: any) => {
        const userUpdateSelfDTO: UserUpdateSelfDTO = {
            username: null,
            password: formData.password,
        };

        updateSelfUserMutation.mutate(userUpdateSelfDTO);
    };

    return (
        <Segment className="card-container">
            <Header as="h2" dividing>
                {t("My profile")}
            </Header>

            <Grid verticalAlign="middle" className="page-card-padding">
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <strong>{t("Username")} : </strong>
                    </Grid.Column>
                    <Grid.Column>{user.username}</Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                    <Grid.Column>
                        <strong>{t("Email")} : </strong>
                    </Grid.Column>
                    <Grid.Column>{user.email}</Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                    <Grid.Column>
                        <strong>{t("Role")} : </strong>
                    </Grid.Column>
                    <Grid.Column>{user.role}</Grid.Column>
                </Grid.Row>
            </Grid>

            <Divider />

            <div className="bottom-button-card">
                <Button primary name="edit-user" onClick={toggleEditMode}>
                    {t("edit")}
                </Button>

                <CustomModal
                    openModalButtonName="update-password"
                    openModalButtonText={t("Update password")}
                    openModalButtonColor={"teal"}
                    cancelButtonColor={"black"}
                    validateButtonColor={"green"}
                    loadingText={t("Update in progress")}
                    errorText={t("An error occurred please contact support")}
                    isPending={updateSelfUserMutation.isPending}
                    isSuccess={updateSelfUserMutation.isSuccess}
                    isError={updateSelfUserMutation.isError}
                    resetMutation={updateSelfUserMutation.reset}
                    error={updateSelfUserMutation.error}
                    modalHeader={t("Enter new password")}
                    isValidationDisabled={
                        watchPassword === "" || watchRepeatedPassword === "" || Object.keys(errors).length !== 0
                    }
                    onValidate={handleSubmit(submitUpdatePasswordForm)}
                    renderModalContent={() => {
                        return (
                            <FormPassword
                                control={control}
                                password={watchPassword}
                                repeatedPassword={watchRepeatedPassword}
                            />
                        );
                    }}
                />
            </div>
        </Segment>
    );
};

const UserProfileEdit = ({companyId, user, t, toggleEditMode}: UserProfileEditPropsType) => {
    const {
        control,
        handleSubmit,
        formState: {errors},
    } = useForm<any>({mode: "onChange"});
    const updateSelfUserMutation = useUpdateSelfUserMutation(companyId);

    const submitUpdateUsernameForm = async (formData: any) => {
        const userUpdateSelfDTO: UserUpdateSelfDTO = {
            username: formData.username,
            password: null,
        };

        updateSelfUserMutation.mutate(userUpdateSelfDTO);
    };

    return (
        <Segment className="card-container">
            <Header as="h2" dividing>
                {t("My profile")}
            </Header>
            <Form>
                <Grid columns="equal" verticalAlign="middle" className="grid-padding" style={{padding: "10px"}}>
                    <Grid.Row style={{paddingBottom: "0"}}>
                        <Grid.Column width={8}>
                            <strong>{t("Username")} : </strong>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Controller
                                control={control}
                                defaultValue={user.username}
                                name={"username"}
                                rules={{required: true}}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        required
                                        name={name}
                                        placeholder={t("Username")}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength="50"
                                        error={getErrorMessage(t, errors, name)}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                        <Grid.Column width={8}>
                            <strong>{t("Email")} : </strong>
                        </Grid.Column>
                        <Grid.Column width={8}>{user.email}</Grid.Column>
                    </Grid.Row>

                    <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                        <Grid.Column width={8}>
                            <strong>{t("Role")} : </strong>
                        </Grid.Column>
                        <Grid.Column width={8}>{user.role}</Grid.Column>
                    </Grid.Row>
                </Grid>

                <Divider />

                <div className="bottom-button-card">
                    <Button name="cancel" secondary onClick={toggleEditMode}>
                        {t("Cancel")}
                    </Button>

                    <ModalUpdate
                        isModalDisabled={Object.keys(errors).length !== 0}
                        isSuccess={updateSelfUserMutation.isSuccess}
                        isPending={updateSelfUserMutation.isPending}
                        isError={updateSelfUserMutation.isError}
                        resetMutation={updateSelfUserMutation.reset}
                        error={updateSelfUserMutation.error}
                        onSuccess={toggleEditMode}
                        onValidate={handleSubmit(submitUpdateUsernameForm)}
                        objectToUpdate={"User"}
                        objectType={"nonbinary"}
                        openModalButtonName="update-user"
                    />
                </div>
            </Form>
        </Segment>
    );
};

export default UserProfile;
