import { useTranslation } from "react-i18next";
import { Header, Label } from "semantic-ui-react";
import TextOverflow from "../../../../Components/Text/TextOverflow";
import { InsurancePackageItem } from "../../../../Services/InsurancePackage/Types";

type InsurancePackageHeaderType = {
    insurancePackage: InsurancePackageItem;
};

const InsurancePackageHeader = ({insurancePackage}: InsurancePackageHeaderType) => {
    const {t} = useTranslation();

    return (
        <Header as="h2" className="top-header">
            <TextOverflow inline value={insurancePackage.label} />
            <p style={{display: "contents", color: "#9b9b9b", fontWeight: "lighter"}}> - </p>
            <TextOverflow
                inline
                value={insurancePackage.code}
                additionalStyle={{color: "#9b9b9b", fontWeight: "lighter"}}
            />
            <Label size="small" basic color="teal">
                {t("Start date")} : {t("format_date", {value: insurancePackage.startDate})}
            </Label>
            {insurancePackage.endDate !== null && (
                <Label size="small" basic color="blue">
                    {t("End date subscription")} : {t("format_date", {value: insurancePackage.endDate})}
                </Label>
            )}
            {insurancePackage.endDateEndorsement !== null && (
                <Label size="small" basic color="orange">
                    {t("End date endorsement")} : {t("format_date", {value: insurancePackage.endDateEndorsement})}
                </Label>
            )}
        </Header>
    );
};

export default InsurancePackageHeader;
