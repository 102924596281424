import { Control, Controller, DeepMap, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form, Grid, Radio } from "semantic-ui-react";
import getErrorMessage from "../../../../Global/Form/ErrorMessage";

type FormDuplicateProductPropsType = {
    control: Control<any>;
    errors: DeepMap<any, FieldErrors>;
    defaultValueCode: string;
    setDefaultValueCode: React.Dispatch<React.SetStateAction<string>>;
    defaultValueLabel: string;
    setDefaultValueLabel: React.Dispatch<React.SetStateAction<string>>;
    defaultValueWorkingVersion: boolean;
    setDefaultValueWorkingVersion: React.Dispatch<React.SetStateAction<boolean>>;
    haveWorkingAndValidatedVersion: boolean;
};

const FormDuplicateProduct = ({
    control,
    errors,
    defaultValueCode,
    setDefaultValueCode,
    defaultValueLabel,
    setDefaultValueLabel,
    defaultValueWorkingVersion,
    setDefaultValueWorkingVersion,
    haveWorkingAndValidatedVersion,
}: FormDuplicateProductPropsType) => {
    const {t} = useTranslation();

    return (
        <Form id="modal-form">
            <Grid centered columns={2}>
                <Grid.Row centered>
                    <Grid.Column>
                        <Controller
                            control={control}
                            defaultValue={defaultValueCode}
                            rules={{required: true, maxLength: 10}}
                            name={"duplicated_product_code"}
                            render={({field: {name, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Code")}
                                    placeholder={t("Code")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueCode(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueCode}
                                    maxLength="10"
                                    error={getErrorMessage(t, errors, "duplicated_product_code", undefined, 10)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            rules={{required: true}}
                            name="duplicated_product_label"
                            defaultValue={defaultValueLabel}
                            render={({field: {name, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Label")}
                                    placeholder={t("Label")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueLabel(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueLabel}
                                    error={getErrorMessage(t, errors, "duplicated_product_label")}
                                />
                            )}
                        />

                        {haveWorkingAndValidatedVersion && (
                            <>
                                <div className="ui form required field" style={{marginBottom: "5px"}}>
                                    <label style={{fontWeight: "bold"}}>{t("Select the version")}</label>
                                </div>
                                <Form.Field style={{marginBottom: "2px"}}>
                                    <Radio
                                        label={t("Last validated version")}
                                        name="duplicated_product_workingVersion_validated_version"
                                        checked={!defaultValueWorkingVersion}
                                        onChange={() => setDefaultValueWorkingVersion(false)}
                                    />
                                </Form.Field>
                                <Form.Field style={{marginBottom: "2px"}}>
                                    <Radio
                                        label={t("Working version")}
                                        name="duplicated_product_workingVersion_working_version"
                                        checked={defaultValueWorkingVersion}
                                        onChange={() => setDefaultValueWorkingVersion(true)}
                                    />
                                </Form.Field>
                            </>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormDuplicateProduct;
