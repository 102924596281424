import {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import ModalAdd from "../../../../Components/Modals/ModalAdd";
import {useAddPricingGridMutation} from "../../../../Services/PricingGrid/Queries";
import FormPricingGrid from "./FormPricingGrid";

interface IParams extends Params {
    id: string;
    versionId: string;
    gridId: string;
}

type PropsType = {
    alternateOpenButton?: () => JSX.Element;
};

const ModalAddPricingGrid = ({...props}: PropsType) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const addPricingGridMutation = useAddPricingGridMutation(params.id, params.versionId);
    const addPricingGridForm = useForm<any>();
    const submitAddPricingGrid = (form: any) => {
        addPricingGridMutation.mutate({
            ...form,
            dimensionGridDTOs: [],
            gridValueDTOs: [],
        });
    };

    const [defaultValueCode, setDefaultValueCode] = useState<string>("");
    const [defaultValueLabel, setDefaultValueLabel] = useState<string>("");

    return (
        <ModalAdd
            isValidationDisabled={Object.keys(addPricingGridForm.formState.errors).length !== 0}
            isPending={addPricingGridMutation.isPending}
            isSuccess={addPricingGridMutation.isSuccess}
            isError={addPricingGridMutation.isError}
            resetMutation={addPricingGridMutation.reset}
            error={addPricingGridMutation.error}
            onValidate={addPricingGridForm.handleSubmit(submitAddPricingGrid)}
            onCancel={() => {
                setDefaultValueCode("");
                setDefaultValueLabel("");
            }}
            onSuccess={() => {
                setDefaultValueCode("");
                setDefaultValueLabel("");
            }}
            iconOnOpenButton
            openButtonIcon="add"
            objectToAdd="Pricing grid"
            objectToAddContext="female"
            renderModalContent={() => (
                <FormPricingGrid
                    errors={addPricingGridForm.formState.errors}
                    control={addPricingGridForm.control}
                    defaultValueCode={defaultValueCode}
                    setDefaultValueCode={setDefaultValueCode}
                    defaultValueLabel={defaultValueLabel}
                    setDefaultValueLabel={setDefaultValueLabel}
                />
            )}
            renderOpenButton={props.alternateOpenButton}
        />
    );
};

export default ModalAddPricingGrid;
