import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Form } from "semantic-ui-react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { useProductsSidebarMenu } from "../../Hooks/useProductsSidebarMenu";
import HealthProductCreationFormStepper from "../Components/HealthProductCreationFormStepper";
import { HealthProductFormProvider } from "../Components/HealthProductFormContext";
import { RiskTypeModal } from "../Components/RiskTypeModal";

const NewProductCoverlife: React.FC = () => {
    const {t} = useTranslation();
    const [selectedRiskType, setSelectedRiskType] = useState<string | undefined>(undefined);
    const menus = useProductsSidebarMenu();

    return (
        <Container fluid>
            <Sidebar title={t("Product", {count: 2})} menus={menus} />
            <RiskTypeModal setSelectedRiskType={setSelectedRiskType} />
            <div className="main-container">
                <HealthProductFormProvider>
                    <Form>{selectedRiskType === "Santé" && <HealthProductCreationFormStepper />}</Form>
                </HealthProductFormProvider>
            </div>
        </Container>
    );
};
export default NewProductCoverlife;
