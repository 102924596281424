import {useContext} from "react";
import {Params, useParams} from "react-router-dom";
import {Grid, Header} from "semantic-ui-react";
import {AuthContext} from "../../../../../Components/AuthProvider/AuthProvider";
import Sections from "../../../../../Components/CustomBreadcrumb/Fragments/Sections";
import TextOverflow from "../../../../../Components/Text/TextOverflow";
import {DeductibleCapital} from "../../../../../Services/DeductibleCapital/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import {displayAuditLog} from "../../../../../Utils/AuditUtils";
import DeductibleCapitalActions from "./DeductibleCapitalActions";
import {useTranslation} from "react-i18next";

type BreadcrumbDeductibleCapitalType = {
    deductibleCapital: DeductibleCapital;
    version: VersionDTO;
    sections: Array<{name: string; path: string}>;
};
interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
    deductibleCapitalId: string;
}

const BreadcrumbDeductibleCapital = ({deductibleCapital, version, sections}: BreadcrumbDeductibleCapitalType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const {user} = useContext(AuthContext);

    return (
        <>
            <div className="header-container" data-cy="custom-breadcrumb-header">
                <Header as="h2" className="top-header">
                    <TextOverflow value={deductibleCapital.label} />
                </Header>
                <div className="sub-header">
                    <Grid>
                        <Grid.Column width={8} floated={"left"}>
                            <Sections sections={sections} />
                            {deductibleCapital !== undefined && user !== undefined && (
                                <div className="audit-display">
                                    <i>
                                        {displayAuditLog(
                                            {
                                                entityContext: "female",
                                                createdDate: deductibleCapital.createdDate,
                                                modifiedDate: deductibleCapital.modifiedDate,
                                                createdBy: deductibleCapital.createdBy,
                                                modifiedBy: deductibleCapital.modifiedBy,
                                                createdOnVersionId: deductibleCapital.createdOnVersionId,
                                            },
                                            user,
                                            params.versionId,
                                            t
                                        )}
                                    </i>
                                </div>
                            )}
                        </Grid.Column>
                        {deductibleCapital !== undefined && version !== undefined && (
                            <DeductibleCapitalActions
                                deductibleCapital={deductibleCapital}
                                isVersionValid={version !== null && version.pipDate === null}
                            />
                        )}
                    </Grid>
                </div>
            </div>
        </>
    );
};

export default BreadcrumbDeductibleCapital;
