import axios from "axios";
import { interceptAxiosRequestAndInjectTokenFromCookie } from "./RequestUtils";

const axiosInstance = axios.create({
    baseURL: `/moteurproduit/api`,
});

axiosInstance.interceptors.request.use(
    interceptAxiosRequestAndInjectTokenFromCookie,
    (error: Error): Promise<never> => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
