export type PromotionalCampaign = {
    id: number;
    code: string;
    label?: string;
    description?: string;
    startDate: string;
    endDate: string;
    offerType: OfferType;
    applicationMode: ApplicationMode;
    processParams: string[];

    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
};

export enum OfferType {
    MOIS_GRATUIT = "MOIS_GRATUIT",
}

export enum ApplicationMode {
    SYSTEMATIQUE = "SYSTEMATIQUE",
    PAR_DEFAUT = "PAR_DEFAUT",
    SUR_DEMANDE = "SUR_DEMANDE",
}
