import {useCallback, useContext, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Grid, GridColumn, Input, Menu, Message, Modal} from "semantic-ui-react";
import {SortOption} from "../../Utils/SortUtils";
import Sort from "../Sort/Sort";
import {useGetAllGridQuery} from "../../Services/PricingGrid/Queries";
import {SearchDimensionGridValueModal} from "../GridCardList/SearchDimensionGridValueModal";
import {BasicGridType} from "../../Services/PricingGrid/Types";
import TextOverflow from "../Text/TextOverflow";
import {AuthContext} from "../AuthProvider/AuthProvider";
import {displayAuditLog} from "../../Utils/AuditUtils";

type EntityPickerGridPropsType = {
    versionId: string;
    onClickOnEntity: (gridCode: string, dimensionCode: string) => void;
    onCancel: () => void;
    sortOptions?: SortOption[];
};

const EntityPickerGrid = ({versionId, onClickOnEntity, onCancel, sortOptions}: EntityPickerGridPropsType) => {
    const {t} = useTranslation();
    const {user} = useContext(AuthContext);

    const defaultSortMethod = (a: BasicGridType, b: BasicGridType) =>
        a.code.toLowerCase() > b.code.toLowerCase() ? 1 : -1;

    const filterBySearch = useCallback((item: BasicGridType, search: string): boolean => {
        return (
            item.code.toLowerCase().includes(search.toLowerCase()) ||
            item.label.toLowerCase().includes(search.toLowerCase()) ||
            search === ""
        );
    }, []);

    const [activeItem, setActiveItem] = useState<BasicGridType | undefined>(undefined);
    const renderCard = (entity: BasicGridType, index: number) => {
        return (
            <Menu.Item
                style={{display: filterBySearch(entity, search) ? "" : "none"}}
                key={index}
                active={activeItem !== undefined && activeItem.code === entity.code}
                onClick={() => setActiveItem(entity)}
            >
                {entity.label}{" "}
                <p style={{display: "contents", color: "#9b9b9b", fontWeight: "lighter"}}>
                    - <TextOverflow inline value={entity.code} />
                </p>
                {entity !== undefined && user !== undefined && (
                    <div className="audit-display">
                        <i>
                            {displayAuditLog(
                                {
                                    entityContext: "female",
                                    createdDate: entity.createdDate,
                                    modifiedDate: entity.modifiedDate,
                                    createdBy: entity.createdBy,
                                    modifiedBy: entity.modifiedBy,
                                    createdOnVersionId: entity.createdOnVersionId,
                                },
                                user,
                                versionId,
                                t
                            )}
                        </i>
                    </div>
                )}
            </Menu.Item>
        );
    };

    const {data: grids, status} = useGetAllGridQuery(versionId);
    const [sortMethod, setSortMethod] = useState<(a: BasicGridType, b: BasicGridType) => number>(
        () => defaultSortMethod
    );
    const [search, setSearch] = useState("");

    const filteredGrids: BasicGridType[] = useMemo(() => {
        if (status === "success" && grids !== undefined) {
            return grids.filter((grid) => filterBySearch(grid, search)).sort(sortMethod);
        }
        return [];
    }, [search, status, grids, filterBySearch, sortMethod]);

    return (
        <Modal open size="large" dimmer="blurring" data-cy="entityPicker">
            <Modal.Header>
                {t("Choose", {
                    name: t("Grid dimension").toLowerCase(),
                    context: "female",
                })}
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Menu borderless style={{border: "0", boxShadow: "none", padding: 0}}>
                        <Menu.Menu position="right">
                            <Menu.Item>
                                {sortOptions !== undefined && sortOptions.length !== 0 && (
                                    <Sort sortOptions={sortOptions} setSortMethod={setSortMethod} />
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                <Input
                                    icon="search"
                                    placeholder={t("Find entity", {
                                        context: "female",
                                        entity: t("Grid").toLowerCase(),
                                    })}
                                    defaultValue={search}
                                    onChange={(_, data) => setSearch(data.value)}
                                />
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                    {filteredGrids.length !== 0 && (
                        <Grid celled={"internally"}>
                            <GridColumn width={5} style={{paddingTop: "0", paddingBottom: "0"}}>
                                <Menu
                                    style={{
                                        width: "95%",
                                        height: filteredGrids.length === 1 ? "" : "100%",
                                        overflow: "hidden scroll",
                                        maxHeight: "30vh",
                                    }}
                                    pointing
                                    vertical
                                    data-cy="gridList"
                                >
                                    {filteredGrids.map((entity, index) => renderCard(entity, index))}
                                </Menu>
                            </GridColumn>
                            <GridColumn width={11} style={{paddingTop: "0", paddingBottom: "0"}}>
                                {activeItem !== undefined && (
                                    <SearchDimensionGridValueModal
                                        gridId={activeItem.id.toString()}
                                        gridCode={activeItem.code}
                                        gridLabel={activeItem.label}
                                        search={search}
                                        onClickOnEntity={onClickOnEntity}
                                    />
                                )}
                            </GridColumn>
                        </Grid>
                    )}
                    {status === "success" && filteredGrids.length === 0 && (
                        <Message warning>
                            {grids.length === 0 ? (
                                <Message.Content>
                                    {t("enums.WarningEntityPickerEmptyTypeMessage.pricing grid")}
                                </Message.Content>
                            ) : grids.length !== 0 && search === "" ? (
                                <Message.Content>
                                    {t("enums.WarningEntityPickerEmptyFilterFunctionTypeMessage.pricing grid")}
                                </Message.Content>
                            ) : (
                                <Message.Content>
                                    {t("enums.WarningEntityPickerEmptyFilterTypeMessage.pricing grid")}
                                </Message.Content>
                            )}
                        </Message>
                    )}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={onCancel}>
                    {t("Cancel")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default EntityPickerGrid;
