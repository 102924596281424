import {Dispatch, SetStateAction, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {
    AccordionContent,
    AccordionTitle,
    Button,
    Divider,
    Form,
    Grid,
    GridColumn,
    Header,
    Icon,
    Segment,
} from "semantic-ui-react";
import { ConditionWarningEditForbidden } from "../../../../../Components/Condition/ConditionWarningEditForbidden";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../../../Components/Modals/ModalUpdate";
import ModalUpdateForm from "../../../../../Components/Modals/ModalUpdateForm";

import {ConditionPart, OperandEnum} from "../../../../../Services/Condition/Types";
import {
    useDeleteRejectionRuleMutation,
    useUpdateRejectionRuleMutation,
} from "../../../../../Services/RejectionRule/Queries";
import {RejectionRule} from "../../../../../Services/RejectionRule/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import getErrorMessage from "../../../../Global/Form/ErrorMessage";
import ConditionHandling from "../../../Condition/ConditionHandling";
import ConditionView from "../../../Condition/Fragments/ConditionView";
import { isEditingConditionForbidden } from "../../../../../Utils/ConditionPartUtils";

interface IParams extends Params {
    id: string;
    versionId: string;
}

type RejectionRulesAccordionPropsType = {
    index: number;
    activeIndex: number;
    setActiveIndex: Dispatch<SetStateAction<number>>;
    condition: RejectionRule;
    version: VersionDTO;
};

const RejectionRulesAccordion = ({
    index,
    activeIndex,
    setActiveIndex,
    condition,
    version,
}: RejectionRulesAccordionPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    const [editCondition, setEditCondition] = useState<boolean>(false);
    const [editMessage, setEditMessage] = useState<boolean>(false);
    const [editLabel, setEditLabel] = useState<boolean>(false);

    const conditionKindList = [
        {key: 1, text: "System variable", value: OperandEnum.SOFTWARE_CRITERIA, type: "female"},
        {key: 2, text: "Question", value: OperandEnum.QUESTION, type: "female"},
        {key: 3, text: "Pricing criteria", value: OperandEnum.CRITERE, type: "male"},
        {key: 4, text: "Fixed value", value: OperandEnum.FIXED_VALUE, type: "female"},
    ];

    const updateRejectionRuleMutation = useUpdateRejectionRuleMutation(params.versionId, condition.id);
    const deleteRejectionRuleMutation = useDeleteRejectionRuleMutation(params.versionId, condition.id);

    const errorMessageForm = useForm<RejectionRule>();
    const nameForm = useForm<RejectionRule>();

    const updateCondition = (form: RejectionRule) => {
        let newErrorMessage = form.errorMessage;
        if (form.errorMessage === undefined) newErrorMessage = condition.errorMessage;

        const ConditionErrorMessage = {
            ...condition,
            label: form.label ? form.label : condition.label,
            errorMessage: newErrorMessage,
            ifCondition: condition.ifCondition,
            conditionPartList: condition.conditionPartList,
        };
        updateRejectionRuleMutation.mutate(ConditionErrorMessage);
    };

    return (
        <div key={index}>
            <AccordionTitle
                name={condition.label}
                active={activeIndex === index}
                index={index}
                onClick={() => {
                    if (!editLabel) {
                        if (activeIndex === index) setActiveIndex(-1);
                        else setActiveIndex(index);
                    }
                }}
            >
                <Icon name="dropdown" />
                {condition.label}
                {activeIndex === index && version.pipDate === null && (
                    <ModalUpdateForm
                        isValidationDisabled={Object.keys(nameForm.formState.errors).length !== 0}
                        isPending={updateRejectionRuleMutation.isPending}
                        isSuccess={updateRejectionRuleMutation.isSuccess}
                        isError={updateRejectionRuleMutation.isError}
                        onValidate={nameForm.handleSubmit(updateCondition)}
                        onOpen={() => {
                            setEditLabel(true);
                        }}
                        onCancel={() => {
                            setEditLabel(false);
                        }}
                        onClose={() => setEditLabel(false)}
                        stopPropagationOnOpenModal={true}
                        objectToUpdate={"Label"}
                        renderOpenButton={() => {
                            return (
                                <Button
                                    style={{marginLeft: "1%"}}
                                    name={"editLabel" + Number(index + 1)}
                                    icon="edit"
                                    compact
                                    basic
                                />
                            );
                        }}
                        renderModalContent={() => {
                            return (
                                <Form>
                                    <Grid centered columns={2}>
                                        <Grid.Row centered>
                                            <Grid.Column>
                                                <Controller
                                                    rules={{required: true}}
                                                    defaultValue={condition.label}
                                                    control={nameForm.control}
                                                    name="label"
                                                    render={({field: {name, value, onChange, onBlur}}) => (
                                                        <Form.Input
                                                            autoFocus
                                                            label={t("Label")}
                                                            placeholder={t("Label")}
                                                            required
                                                            name={name}
                                                            value={value}
                                                            onChange={(_e, {value}) => {
                                                                onChange(value);
                                                            }}
                                                            onBlur={onBlur}
                                                            error={getErrorMessage(t, nameForm.formState.errors, name)}
                                                        />
                                                    )}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form>
                            );
                        }}
                        objectToUpdateContext={"male"}
                    />
                )}
            </AccordionTitle>
            <AccordionContent active={activeIndex === index}>
                <Divider horizontal>
                    <Header as="h4">{t("Condition")}</Header>
                </Divider>
                {editCondition ? (
                    <ConditionHandling
                        conditionKindList={conditionKindList}
                        conditionPartList={condition.conditionPartList}
                        setConditionPartList={(conditionPartListUpdate: ConditionPart[]) => {
                            const conditionUpdate: RejectionRule = {
                                ...condition,
                                ifCondition: "",
                                conditionPartList: conditionPartListUpdate,
                            };
                            updateRejectionRuleMutation.mutate(conditionUpdate);
                        }}
                        setEditCondition={setEditCondition}
                        updateMutation={updateRejectionRuleMutation}
                        versionId={params.versionId}
                    />
                ) : (
                    <Grid>
                        <>
                            <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                {t("If")} :
                            </GridColumn>
                            <GridColumn width={14} textAlign="center" className="condition">
                                <ConditionView conditionPartList={condition.conditionPartList} />
                            </GridColumn>
                            {version.pipDate === null && (
                                <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                    {isEditingConditionForbidden(condition.conditionPartList) ? (
                                        <ConditionWarningEditForbidden />
                                    ) : (
                                        <Button
                                            name={"editCondition" + Number(index + 1)}
                                            color="grey"
                                            icon="edit"
                                            compact
                                            basic
                                            onClick={() => {
                                                setEditCondition(true);
                                            }}
                                        />
                                    )}
                                </GridColumn>
                            )}
                        </>
                    </Grid>
                )}
                <Divider horizontal>
                    <Header as="h4">{t("Error message")}</Header>
                </Divider>
                {editMessage ? (
                    <>
                        <Grid>
                            <GridColumn>
                                <Form>
                                    <Controller
                                        rules={{required: true}}
                                        defaultValue={condition.errorMessage}
                                        control={errorMessageForm.control}
                                        name="errorMessage"
                                        render={({field: {name, value, onChange, onBlur}}) => (
                                            <Form.TextArea
                                                name={name}
                                                style={{resize: "none"}}
                                                value={value}
                                                onChange={(_e, {value}) => {
                                                    onChange(value);
                                                }}
                                                onBlur={onBlur}
                                                error={getErrorMessage(t, errorMessageForm.formState.errors, name)}
                                            />
                                        )}
                                    />
                                </Form>
                            </GridColumn>
                        </Grid>
                        <Grid>
                            <Grid.Row textAlign="right">
                                <GridColumn>
                                    <Button
                                        color="black"
                                        onClick={() => {
                                            setEditMessage(false);
                                        }}
                                    >
                                        {t("Cancel")}
                                    </Button>
                                    <ModalUpdate
                                        isModalDisabled={Object.keys(errorMessageForm.formState.errors).length !== 0}
                                        isPending={updateRejectionRuleMutation.isPending}
                                        isSuccess={updateRejectionRuleMutation.isSuccess}
                                        isError={updateRejectionRuleMutation.isError}
                                        resetMutation={updateRejectionRuleMutation.reset}
                                        onSuccess={() => setEditMessage(false)}
                                        onValidate={errorMessageForm.handleSubmit(updateCondition)}
                                        objectToUpdate={"Error message"}
                                        objectType={"male"}
                                    />
                                </GridColumn>
                            </Grid.Row>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid>
                            <>
                                <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                    {t("Error")} :
                                </GridColumn>
                                <GridColumn width={14} textAlign="center" className="condition">
                                    <Segment style={{backgroundColor: "rgb(243,244,245)", color: "black"}}>
                                        {condition.errorMessage}
                                    </Segment>
                                </GridColumn>
                                <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                    {version.pipDate === null && (
                                        <Button
                                            name={"editMessage" + Number(index + 1)}
                                            color="grey"
                                            icon="edit"
                                            compact
                                            basic
                                            onClick={() => {
                                                setEditMessage(true);
                                            }}
                                        />
                                    )}
                                </GridColumn>
                            </>
                        </Grid>
                        {!editCondition && !editMessage && version.pipDate === null && (
                            <>
                                <Divider />
                                <Grid>
                                    <GridColumn floated={"right"} textAlign={"right"} style={{paddingRight: "15px"}}>
                                        <ModalDelete
                                            isSuccess={deleteRejectionRuleMutation.isSuccess}
                                            isPending={deleteRejectionRuleMutation.isPending}
                                            isError={deleteRejectionRuleMutation.isError}
                                            error={deleteRejectionRuleMutation.error}
                                            resetMutation={deleteRejectionRuleMutation.reset}
                                            objectToDelete="Condition"
                                            objectType="female"
                                            openModalButtonName={"delete-condition-" + Number(index + 1)}
                                            onValidate={deleteRejectionRuleMutation.mutate}
                                            onSuccess={() => setActiveIndex(-1)}
                                        />
                                    </GridColumn>
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </AccordionContent>
        </div>
    );
};

export default RejectionRulesAccordion;
