import {Control, Controller, FieldErrors} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid} from "semantic-ui-react";
import getErrorMessage from "../../Pages/Global/Form/ErrorMessage";
import {doubleValidation, integerValidation, useDateValidation} from "../../Pages/Global/Form/Validation";
import { DimensionGridDTOWithId, DimensionGridValueDTO } from "../../Services/PricingGrid/Types";

export interface IParams {
    id: string;
    versionId: string;
    gridId: string;
    insurancePackageId: string;
}

type FormDimensionValuePropsType = {
    control: Control<DimensionGridValueDTO>;
    errors: FieldErrors;
    dimension: DimensionGridDTOWithId;
    defaultValueCode: string;
    setDefaultValueCode: React.Dispatch<React.SetStateAction<string>>;
    defaultValueLabel: string;
    setDefaultValueLabel: React.Dispatch<React.SetStateAction<string>>;
    defaultValueMin: string;
    setDefaultValueMin: React.Dispatch<React.SetStateAction<string>>;
    defaultValueMax: string;
    setDefaultValueMax: React.Dispatch<React.SetStateAction<string>>;
}

const FormDimensionValue = ({
    control,
    errors,
    dimension,
    defaultValueCode,
    setDefaultValueCode,
    defaultValueLabel,
    setDefaultValueLabel,
    defaultValueMin,
    setDefaultValueMin,
    defaultValueMax,
    setDefaultValueMax,
}: FormDimensionValuePropsType) => {
    const {t} = useTranslation();

    const rulesByValueType = (valueType: string) => {
        switch (valueType) {
            case "STRING":
                return {required: true, maxLength: 100};
            case "INT":
                return {
                    required: true,
                    pattern: integerValidation(),
                };
            case "DOUBLE":
                return {
                    required: true,
                    pattern: doubleValidation(),
                };
            case "DATE":
                return {required: true, validate: {date: useDateValidation}};
        }
    };

    const inputByValueType = (valueType: string) => {
        switch (valueType) {
            case "STRING":
                return "text";
            case "INT":
            case "DOUBLE":
                return "number";
            case "DATE":
                return "date";
        }
    };

    const inputNameByValueType = (valueType: string, min: boolean = true): "min" | "max" | "minDate" | "maxDate" => {
        let name: "min" | "max" = min ? "min" : "max";
        return valueType === "DATE" ? `${name}Date` : name;
    };

    const inputLabelByValueType = (valueType: string, min: boolean = true) => {
        let name = min ? "Minimum" : "Maximum";
        return valueType === "DATE" ? `${name} date` : name;
    };

    return (
        <Form id="modal-form">
            {dimension !== undefined && (
                <Grid centered columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            {dimension.dimensionType === "F" ? (
                                <Controller
                                    control={control}
                                    defaultValue={defaultValueCode}
                                    rules={rulesByValueType(dimension.dimensionValueType)}
                                    name={"value"}
                                    render={({field: {name, value, onChange, onBlur}}) => (
                                        <Form.Input
                                            autoFocus
                                            fluid
                                            required
                                            type={inputByValueType(dimension.dimensionValueType)}
                                            name={name}
                                            label={t("Code")}
                                            placeholder={t("Code")}
                                            onChange={(_, {value}) => {
                                                setDefaultValueCode(value);
                                                onChange(value);
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            error={getErrorMessage(t, errors, name)}
                                        />
                                    )}
                                />
                            ) : (
                                <>
                                    <Controller
                                        control={control}
                                        defaultValue={defaultValueMin}
                                        rules={rulesByValueType(dimension.dimensionValueType)}
                                        name={inputNameByValueType(dimension.dimensionValueType)}
                                        render={({field: {name, value, onChange, onBlur}}) => (
                                            <Form.Input
                                                autoFocus
                                                fluid
                                                required
                                                type={inputByValueType(dimension.dimensionValueType)}
                                                name={name}
                                                label={t(inputLabelByValueType(dimension.dimensionValueType))}
                                                placeholder={t(inputLabelByValueType(dimension.dimensionValueType))}
                                                onChange={(_, {value}) => {
                                                    setDefaultValueMin(value);
                                                    onChange(value);
                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                                error={getErrorMessage(t, errors, name)}
                                            />
                                        )}
                                    />

                                    <Controller
                                        control={control}
                                        defaultValue={defaultValueMax}
                                        rules={rulesByValueType(dimension.dimensionValueType)}
                                        name={inputNameByValueType(dimension.dimensionValueType, false)}
                                        render={({field: {name, value, onChange, onBlur}}) => (
                                            <Form.Input
                                                fluid
                                                required
                                                type={inputByValueType(dimension.dimensionValueType)}
                                                name={name}
                                                label={t(inputLabelByValueType(dimension.dimensionValueType, false))}
                                                placeholder={t(
                                                    inputLabelByValueType(dimension.dimensionValueType, false)
                                                )}
                                                onChange={(_, {value}) => {
                                                    setDefaultValueMax(value);
                                                    onChange(value);
                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                                error={getErrorMessage(t, errors, name)}
                                            />
                                        )}
                                    />
                                </>
                            )}

                            <Controller
                                control={control}
                                defaultValue={defaultValueLabel}
                                name={"label"}
                                rules={{maxLength: 150, minLength: 1}}
                                render={({field: {value, name, onChange}}) => (
                                    <Form.Input
                                        fluid
                                        name={name}
                                        type={"text"}
                                        label={t("Label")}
                                        placeholder={t("Label")}
                                        onChange={(_, {value}) => {
                                            setDefaultValueLabel(value);
                                            onChange(value);
                                        }}
                                        value={value}
                                        maxLength="150"
                                        error={getErrorMessage(t, errors, name)}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </Form>
    );
};

export default FormDimensionValue;
