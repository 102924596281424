import {UseMutationResult, UseQueryResult, useMutation, useQuery} from "@tanstack/react-query";
import queryClient from "../../../Config/queryClient";
import {CustomError} from "../../../Services/RequestUtils";
import {exportCommercialReductions, getCommercialReductions, importCommercialReductions} from "./AxiosRequests";
import {CommercialReduction} from "./Types";

export const useGetCommercialReductions = (): UseQueryResult<Array<CommercialReduction>, Error> => {
    return useQuery({
        queryKey: ["getCommercialReductions"],
        queryFn: () => getCommercialReductions(),
    });
};

export const useExportCommercialReductions = (enabled: boolean = true): UseQueryResult<unknown, Error> => {
    return useQuery({
        queryKey: ["exportCommercialReductions"],
        queryFn: () => exportCommercialReductions(),
        enabled,
    });
};

export const useImportCommercialReductionsMutation = (): UseMutationResult<void, CustomError, BlobPart, Error> => {
    return useMutation({
        mutationFn: (files: BlobPart) => {
            const formData = new FormData();
            formData.append(
                "file",
                new Blob([files], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
            );

            return importCommercialReductions(formData);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getCommercialReductions"]});
        },
    });
};
