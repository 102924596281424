import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form, Grid } from "semantic-ui-react";
import { PricingCriteriaItemType } from "../../../../Services/PricingCriterias/Types";
import getErrorMessage from "../../../Global/Form/ErrorMessage";
import { codeValidation } from "../../../Global/Form/Validation";

const FormPricingCriterias = ({
    errors,
    control,
    defaultValueName,
    setDefaultValueName,
    defaultValueType,
    setDefaultValueType,
    defaultValueSaveQuote,
    setDefaultValueSaveQuote,
}: any) => {
    const {t} = useTranslation();

    const pricingCriteriaOptions = [
        {
            key: 1,
            text: t("enums.PricingCriteriaItemType." + PricingCriteriaItemType.STRING),
            value: PricingCriteriaItemType.STRING,
        },
        {
            key: 2,
            text: t("enums.PricingCriteriaItemType." + PricingCriteriaItemType.NUMERIC),
            value: PricingCriteriaItemType.NUMERIC,
        },
        {
            key: 3,
            text: t("enums.PricingCriteriaItemType." + PricingCriteriaItemType.DATE),
            value: PricingCriteriaItemType.DATE,
        },
    ];

    return (
        <Form id="modal-form">
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Controller
                            control={control}
                            defaultValue={defaultValueName}
                            rules={{required: true, pattern: codeValidation()}}
                            name={"name"}
                            render={({field: {name, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Name")}
                                    placeholder={t("Pricing criteria name")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueName(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueName}
                                    maxLength="50"
                                    error={getErrorMessage(t, errors, "name")}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            rules={{required: true}}
                            defaultValue={defaultValueType}
                            name={"type"}
                            render={({field: {name, onChange, onBlur}}) => (
                                <Form.Select
                                    label={t("Type")}
                                    name={name}
                                    required
                                    onBlur={onBlur}
                                    placeholder={t("Pricing criteria type")}
                                    onChange={(_, data) => {
                                        onChange(data.value);
                                        setDefaultValueType(data.value as PricingCriteriaItemType);
                                    }}
                                    value={defaultValueType}
                                    error={getErrorMessage(t, errors, "type")}
                                    options={pricingCriteriaOptions}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            defaultValue={defaultValueSaveQuote}
                            name={"saveQuote"}
                            render={({field: {name, onChange}}) => (
                                <Form.Checkbox
                                    toggle
                                    label={t("Save quote")}
                                    name={name}
                                    onChange={(_, {checked}) => {
                                        onChange(checked);
                                        setDefaultValueSaveQuote(checked);
                                    }}
                                    checked={defaultValueSaveQuote}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormPricingCriterias;
