export type ProductRetrocession = {
    id: number;
    businessProviderRank: number;
    retrocessionMode: RetrocessionMode;
    retrocessionType: RetrocessionType | null;
    variableNameBaseRate: string | null;
    variableNameCoveragesBase: string | null;
    rateDerogation: boolean | null;
};

export enum RetrocessionMode {
    CONVENTION = "CONVENTION",
    PRODUIT = "PRODUIT",
}

export enum RetrocessionType {
    HT = "HT",
    TTC = "TTC",
    COMMISSION = "COMMISSION",
}
