import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Dimmer, Icon, Loader, Message, Modal, SemanticCOLORS, SemanticICONS} from "semantic-ui-react";
import {CustomError, displayResponseError} from "../../Services/RequestUtils";

type PropsType = {
    openModalButtonName: string;
    openModalButtonText?: string;
    openModalButtonColor?: SemanticCOLORS;
    openModalButtonWithoutBackground?: boolean;
    cancelButtonColor?: SemanticCOLORS;
    cancelButtonText?: string;
    iconOnCancelButton?: boolean;
    cancelButtonIcon?: SemanticICONS;
    validateButtonColor?: SemanticCOLORS;
    validateButtonText?: string;
    iconOnOpenButton?: boolean;
    openButtonIcon?: SemanticICONS;
    iconOnValidateButton?: boolean;
    validateButtonIcon?: SemanticICONS;
    loadingText: string;
    errorText: string;
    isPending: boolean;
    isSuccess: boolean;
    isError: boolean;
    modalHeader: string;
    isModalDisabled?: boolean;
    isValidationDisabled?: boolean;
    displayModalSuccess?: boolean;
    modalSuccessHeaderText?: string;
    modalSuccessContentText?: string;
    iconAndTextOnOpenButton?: boolean;
    resetMutation?: () => void;
    onCancel?: () => void;
    onClose?: () => void;
    onSuccess?: () => void;
    onValidate: () => void;
    onOpen?: () => void;
    renderModalContent: () => JSX.Element;
    renderOpenButton?: () => JSX.Element;
    error?: CustomError | null;
    isModalOpenAtStart?: boolean;
    hidden?: boolean;
    basicButton?: boolean;
    returnToFormStepIfError?: boolean;
    stopPropagationOnOpenModal?: boolean;
    scrolling?: boolean;
};

const CustomModal: React.FC<PropsType> = ({
    openModalButtonName,
    openModalButtonText,
    openModalButtonColor,
    openModalButtonWithoutBackground,
    cancelButtonColor,
    cancelButtonText,
    iconOnCancelButton,
    cancelButtonIcon,
    validateButtonColor,
    iconOnOpenButton,
    openButtonIcon,
    iconOnValidateButton,
    validateButtonIcon,
    validateButtonText,
    loadingText,
    errorText,
    isPending,
    isSuccess,
    isError,
    modalHeader,
    isModalDisabled = false,
    isValidationDisabled = false,
    displayModalSuccess = false,
    modalSuccessHeaderText,
    modalSuccessContentText,
    iconAndTextOnOpenButton,
    resetMutation,
    onCancel,
    onClose,
    onValidate,
    onSuccess,
    onOpen,
    renderModalContent,
    renderOpenButton,
    error = null,
    isModalOpenAtStart = false,
    hidden = false,
    basicButton = false,
    returnToFormStepIfError = false,
    stopPropagationOnOpenModal = false,
    scrolling = false,
}) => {
    const {t} = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(true);
    const switchModalState = (e: any) => {
        if (onOpen && !isModalOpen) onOpen();

        if (stopPropagationOnOpenModal && !isModalOpen) {
            e.stopPropagation();
        }
        setIsModalOpen(!isModalOpen);
    };

    useEffect(() => {
        if (isModalOpen && isSuccess) {
            if (!displayModalSuccess) {
                setIsModalOpen(false);
            }
            if (onSuccess !== undefined) onSuccess();
        }
        // eslint-disable-next-line
    }, [isSuccess]);

    useEffect(() => {
        if (isModalOpenAtStart) {
            setIsModalOpen(true);
        }
    }, [isModalOpenAtStart]);

    const handleCancel = () => {
        if (onCancel !== undefined) onCancel();
        setIsModalOpen(false);
    };

    const handleValidate = () => {
        setShowErrorModal(false);
        onValidate();
        setShowErrorModal(true);
    };

    const handleClose = () => {
        setIsModalOpen(false);
        if (resetMutation !== undefined) resetMutation();
        if (onClose !== undefined) onClose();
    };

    const handleCloseError = () => {
        setShowErrorModal(false);
        if (resetMutation !== undefined) resetMutation();
        if (onClose !== undefined) onClose();
    };

    const renderOpenButtonWithOnClickEvent = () => {
        if (renderOpenButton) {
            const element = renderOpenButton();
            if (React.isValidElement(element)) {
                const newProps = {
                    onClick: switchModalState,
                    "data-cy": "modal-open-button",
                };
                return React.cloneElement(element, newProps);
            }
        }
    };

    const renderOpenButtonVariants = () => {
        if (hidden) return null;

        if (renderOpenButton) {
            return renderOpenButtonWithOnClickEvent();
        }

        if (iconAndTextOnOpenButton) {
            return (
                <Button
                    data-cy="modal-open-button"
                    style={openModalButtonWithoutBackground && {background: "none"}}
                    name={openModalButtonName}
                    disabled={isModalDisabled}
                    color={openModalButtonColor}
                    icon={openButtonIcon}
                    content={openModalButtonText}
                    onClick={switchModalState}></Button>
            );
        }

        if (iconOnOpenButton && basicButton) {
            return (
                <Button
                    data-cy="modal-open-button"
                    style={openModalButtonWithoutBackground && {background: "none"}}
                    name={openModalButtonName}
                    disabled={isModalDisabled}
                    icon={{color: openModalButtonColor, name: openButtonIcon}}
                    onClick={switchModalState}></Button>
            );
        }

        if (iconOnOpenButton) {
            return (
                <Button
                    data-cy="modal-open-button"
                    style={openModalButtonWithoutBackground && {background: "none"}}
                    name={openModalButtonName}
                    disabled={isModalDisabled}
                    color={openModalButtonColor}
                    icon={openButtonIcon}
                    onClick={switchModalState}></Button>
            );
        }

        return (
            <Button
                data-cy="modal-open-button"
                style={openModalButtonWithoutBackground && {background: "none"}}
                name={openModalButtonName}
                disabled={isModalDisabled}
                color={openModalButtonColor}
                onClick={switchModalState}>
                {openModalButtonText}
            </Button>
        );
    };

    const renderModalVariants = () => {
        if (isError && showErrorModal) {
            return (
                <ModalError
                    t={t}
                    contentText={errorText}
                    onCloseClick={returnToFormStepIfError ? handleCloseError : handleClose}
                    error={error}
                />
            );
        }

        if (isSuccess && displayModalSuccess) {
            return (
                <ModalSuccess
                    t={t}
                    contentText={modalSuccessContentText}
                    modalHeaderText={modalSuccessHeaderText}
                    onCloseClick={handleClose}
                />
            );
        }

        return (
            <Fragment>
                {isPending && <ModalLoading contentText={loadingText} />}
                <Fragment>
                    <Modal.Header data-cy="modal-header">{modalHeader}</Modal.Header>
                    <Modal.Content scrolling={scrolling} data-cy="modal-content">
                        {renderModalContent()}
                    </Modal.Content>
                    <Modal.Actions data-cy="modal-actions">
                        <Button name="cancel" color={cancelButtonColor} onClick={handleCancel}>
                            {iconOnCancelButton && <Icon name={cancelButtonIcon} />}
                            {cancelButtonText ? cancelButtonText : t("Cancel")}
                        </Button>
                        <Button
                            type="submit"
                            form="modal-form"
                            name="confirm"
                            color={validateButtonColor}
                            onClick={handleValidate}
                            disabled={isValidationDisabled}>
                            {iconOnValidateButton && <Icon name={validateButtonIcon} />}
                            {validateButtonText ? validateButtonText : t("Validate")}
                        </Button>
                    </Modal.Actions>
                </Fragment>
            </Fragment>
        );
    };

    return (
        <Fragment>
            {renderOpenButtonVariants()}
            <Modal open={isModalOpen} dimmer="blurring" onClose={handleClose}>
                {renderModalVariants()}
            </Modal>
        </Fragment>
    );
};

type ModalErrorPropsType = {
    t: Function;
    contentText: string;
    onCloseClick: () => void;
    error?: CustomError | null;
};

const ModalError: React.FC<ModalErrorPropsType> = ({t, contentText, onCloseClick, error = null}) => {
    return (
        <>
            <Modal.Header data-cy="modal-header">{t("An error occurred") + "..."}</Modal.Header>
            <Modal.Content data-cy="modal-content">
                <Message negative>
                    <strong> {t("Action did not succeed")} : </strong>{" "}
                    {error === null ? contentText : t(displayResponseError(error))}
                    {error !== null && error.details && error.details.length > 0 && (
                        <ul>{error?.details.map((detail) => {
                            const linePatternMatch = detail.match(/^Line\s(\d+)\s:\s(.*)/);
                            if (linePatternMatch !== null) {
                                return <li key={detail}>{`${t("Line")} ${linePatternMatch[1]} : ${t(linePatternMatch[2])}`}</li>
                            }

                            return <li key={detail}>{t(detail)}</li>;
                        })}</ul>
                    )}
                </Message>
            </Modal.Content>
            <Modal.Actions data-cy="modal-actions">
                <Button name="close" secondary onClick={onCloseClick}>
                    {t("Close")}
                </Button>
            </Modal.Actions>
        </>
    );
};

type ModalLoadingPropsType = {
    contentText: string;
};

const ModalLoading: React.FC<ModalLoadingPropsType> = ({contentText}) => {
    return (
        <Modal.Content data-cy="modal-content">
            <Dimmer active>
                <Loader size="small">{contentText}</Loader>
            </Dimmer>
        </Modal.Content>
    );
};

type ModalSuccessPropsType = {
    t: Function;
    modalHeaderText: string | undefined;
    contentText: string | undefined;
    onCloseClick: () => void;
};

const ModalSuccess: React.FC<ModalSuccessPropsType> = ({t, modalHeaderText, contentText, onCloseClick}) => {
    return (
        <>
            <Modal.Header data-cy="modal-header">{modalHeaderText}</Modal.Header>
            <Modal.Content data-cy="modal-content">
                <Message positive>{contentText}</Message>
            </Modal.Content>
            <Modal.Actions data-cy="modal-actions">
                <Button name="close" icon="check" onClick={onCloseClick} content={t("Close")} color="green" />
            </Modal.Actions>
        </>
    );
};

export default CustomModal;
