import {useTranslation} from "react-i18next";
import {PromotionalCampaign} from "../../../../../Services/PromotionalCampagins/Types";
import {Checkbox, Divider, Grid, Table} from "semantic-ui-react";
import {useGetProductBehavioursQuery} from "../../../../../Services/ProductBehaviour/Queries";

type PromotionalCampaignFreeMonthDetailsReadProps = {
    productId: string;
    promotionalCampaignId: string;
    promotionalCampaign: PromotionalCampaign;
};

const PromotionalCampaignFreeMonthDetailsRead: React.FC<PromotionalCampaignFreeMonthDetailsReadProps> = ({
    productId,
    promotionalCampaignId,
    promotionalCampaign,
}) => {
    const {t} = useTranslation();
    const includedCoverage = promotionalCampaign.processParams[3].split(",");
    const getProductBehavioursQuery = useGetProductBehavioursQuery(productId);

    return (
        <>
            <Divider />

            <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                <Grid.Column width={8}>
                    <strong>{t("Free month")} : </strong>
                </Grid.Column>
                <Grid.Column width={8}>{promotionalCampaign.processParams[0]}</Grid.Column>
            </Grid.Row>

            <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                <Grid.Column width={8}>
                    <strong>{t("Authorized effective date start date")} : </strong>
                </Grid.Column>
                <Grid.Column width={8}>
                    {t("format_date", {
                        value: promotionalCampaign.processParams[1],
                    })}
                </Grid.Column>
            </Grid.Row>

            <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                <Grid.Column width={8}>
                    <strong>{t("Authorized effective date end date")} : </strong>
                </Grid.Column>
                <Grid.Column width={8}>
                    {t("format_date", {
                        value: promotionalCampaign.processParams[2],
                    })}
                </Grid.Column>
            </Grid.Row>

            <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                <Grid.Column width={8}>
                    <strong>{t("Coverage choices")} : </strong>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row style={{paddingTop: "0"}}>
                <Table basic celled>
                    <Table.Body>
                        {getProductBehavioursQuery.data?.map((productBehaviour) => (
                            <Table.Row key={productBehaviour.registryLabel} className="no-hover">
                                <Table.Cell textAlign="center">{t(productBehaviour.registryLabel)}</Table.Cell>
                                <Table.Cell textAlign="center">
                                    <Checkbox
                                        style={{verticalAlign: "middle"}}
                                        toggle
                                        disabled
                                        checked={includedCoverage.includes(productBehaviour.registryCode)}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Grid.Row>
        </>
    );
};

export default PromotionalCampaignFreeMonthDetailsRead;
