import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useNavigate, useParams} from "react-router-dom";
import {Button, Divider, Grid, Header, Segment} from "semantic-ui-react";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import ModalDuplicate from "../../../../../Components/Modals/ModalDuplicate";
import TextShowMore from "../../../../../Components/Text/TextShowMore";
import {
    useDeleteProductMutation,
    useDuplicateProductMutation,
    useGetProductDetailsQuery,
} from "../../../../../Services/Product/Queries";
import {DeadlineType} from "../../NewProductPage";
import FormDuplicateProduct from "./FormDuplicateProduct";
import ProductDetailsEdit from "./ProductDetailsEdit";

interface IParams extends Params {
    id: string;
}

type PropsType = {
    isOnlyInvalidVersion?: boolean;
    haveWorkingAndValidatedVersion: boolean;
};

const ProductDetails = ({isOnlyInvalidVersion, haveWorkingAndValidatedVersion}: PropsType) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useParams() as IParams;

    const {status, data} = useGetProductDetailsQuery(params.id);

    const deleteProductMutation = useDeleteProductMutation(params.id);

    const [isEditing, setIsEditing] = useState(false);

    const openEditProductDetails = () => setIsEditing(!isEditing);

    const deleteProduct = () => {
        deleteProductMutation.mutate();
    };

    // Duplicate product
    const duplicateProductMutation = useDuplicateProductMutation(params.id);
    const duplicateProductForm = useForm();
    const sumbitDuplicateProductForm = () => {
        duplicateProductMutation.mutate({
            code: duplicateProductCode,
            label: duplicateProductLabel,
            workingVersion: duplicateProductWorkingVersion,
        });
    };

    const [duplicateProductCode, setDuplicateProductCode] = useState("");
    const [duplicateProductLabel, setDuplicateProductLabel] = useState("");
    const [duplicateProductWorkingVersion, setDuplicateProductWorkingVersion] = useState(false);
    const resetFormDuplicateProduct = () => {
        setDuplicateProductCode("");
        setDuplicateProductLabel("");
        setDuplicateProductWorkingVersion(false);
    };

    useEffect(() => {
        if (deleteProductMutation.isSuccess) {
            navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteProductMutation]);

    const renderProductDetails = () => {
        if (data !== undefined) {
            return isEditing ? (
                <ProductDetailsEdit
                    productDetails={data}
                    openEditProductDetails={openEditProductDetails}
                />
            ) : (
                <Segment className="card-container">
                    <Header as="h2" dividing>
                        {t("Product")}
                    </Header>

                    <Grid columns="equal" className="grid-padding">
                        <Grid.Row>
                            <Grid.Column width={6}>
                                <strong>{t("Code")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={10}>{data.code}</Grid.Column>

                            <Grid.Column width={6}>
                                <strong>{t("Label")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={10}>{data.label}</Grid.Column>

                            <Grid.Column width={6}>
                                <strong>{t("Company code")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={10}>{data.principalCompanyCode}</Grid.Column>

                            <Grid.Column width={6}>
                                <strong>{t("quotation_validity_duration")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                {data.quoteValidityDuration} {t("day(s)")}
                            </Grid.Column>

                            <Grid.Column width={6}>
                                <strong>{t("endorsement_quotation_validity_duration")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                {data.endorsementQuoteValidityDuration} {t("day(s)")}
                            </Grid.Column>

                            {data.openingDate !== null && (
                                <>
                                    <Grid.Column width={6}>
                                        <strong>{t("Opening_date")} : </strong>
                                    </Grid.Column>
                                    <Grid.Column width={10}>
                                        {t("format_datetime", {value: data.openingDate})}
                                    </Grid.Column>
                                </>
                            )}

                            <Grid.Column width={6}>
                                <strong>{t("Transient Mode")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={10}>{t("enums.TransientModeType." + data.transientMode)}</Grid.Column>

                            <Grid.Column width={6}>
                                <strong>{t("deadlinePrincipal")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                {data.deadlineByDay === 0 && data.deadlineByMonth === 0
                                    ? t("enums.DeadlineType." + DeadlineType.deadlineBirthday)
                                    : data.deadlineByDay !== 0 && data.deadlineByMonth === 0
                                    ? t("deadlineDayFixMonthBirthday at", {
                                          day: data.deadlineByDay < 10 ? "0" + data.deadlineByDay : data.deadlineByDay,
                                      })
                                    : t("deadlineFixe at", {
                                          day: data.deadlineByDay < 10 ? "0" + data.deadlineByDay : data.deadlineByDay,
                                          month:
                                              data.deadlineByMonth < 10
                                                  ? "0" + data.deadlineByMonth
                                                  : data.deadlineByMonth,
                                      })}
                            </Grid.Column>

                            <Grid.Column width={6}>
                                <strong>{t("Debit mode")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={10}>{data.debitMode}</Grid.Column>

                            {data.delayedEffectLimit !== null && (
                                <>
                                    <Grid.Column width={6}>
                                        <strong>{t("Delayed effect limit")} : </strong>
                                    </Grid.Column>
                                    <Grid.Column width={10}>{data.delayedEffectLimit}</Grid.Column>
                                </>
                            )}
                        </Grid.Row>
                    </Grid>

                    <Header as="h2" dividing>
                        {t("classification")}
                    </Header>

                    <Grid columns="equal" className="grid-padding">
                        <Grid.Row>
                            <Grid.Column width={6}>
                                <strong>{t("risk_type")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={10}>{data.riskType.label}</Grid.Column>

                            <Grid.Column width={6}>
                                <strong>{t("Range")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={10}>{data.range?.label}</Grid.Column>
                        </Grid.Row>
                    </Grid>

                    {data.productInfos && data.productInfos !== undefined && data.productInfos.length !== 0 && (
                        <>
                            <Header as="h2" dividing>
                                {t("Additional_information", {count: data.productInfos.length})}
                            </Header>
                            <Grid columns="equal" className="grid-padding">
                                {data.productInfos.map(
                                    (item) =>
                                        item &&
                                        item.value !== null && (
                                            <Grid.Row key={item.label}>
                                                <Grid.Column width={6}>
                                                    <strong>{item.label} : </strong>
                                                </Grid.Column>

                                                <Grid.Column width={10}>
                                                    <TextShowMore value={item.value} />
                                                </Grid.Column>
                                            </Grid.Row>
                                        )
                                )}
                            </Grid>
                        </>
                    )}

                    <div className="bottom-button-card">
                        <Divider />
                        <Button primary name="editProduct" onClick={openEditProductDetails}>
                            {t("edit")}
                        </Button>

                        <ModalDuplicate
                            isValidationDisabled={Object.keys(duplicateProductForm.formState.errors).length !== 0}
                            isPending={duplicateProductMutation.isPending}
                            isSuccess={duplicateProductMutation.isSuccess}
                            isError={duplicateProductMutation.isError}
                            error={duplicateProductMutation.error}
                            resetMutation={duplicateProductMutation.reset}
                            onCancel={resetFormDuplicateProduct}
                            onSuccess={resetFormDuplicateProduct}
                            onValidate={duplicateProductForm.handleSubmit(sumbitDuplicateProductForm)}
                            objectToDuplicate="Product"
                            objectToDuplicateCodeOrLabel={data.code}
                            objectToDuplicateContext="male"
                            renderModalContent={() => (
                                <FormDuplicateProduct
                                    errors={duplicateProductForm.formState.errors}
                                    control={duplicateProductForm.control}
                                    defaultValueCode={duplicateProductCode}
                                    setDefaultValueCode={setDuplicateProductCode}
                                    defaultValueLabel={duplicateProductLabel}
                                    setDefaultValueLabel={setDuplicateProductLabel}
                                    defaultValueWorkingVersion={duplicateProductWorkingVersion}
                                    setDefaultValueWorkingVersion={setDuplicateProductWorkingVersion}
                                    haveWorkingAndValidatedVersion={haveWorkingAndValidatedVersion}
                                />
                            )}
                            renderOpenButton={() => {
                                return (
                                    <Button name="duplicateProduct" color="green">
                                        {t("Duplicate")}
                                    </Button>
                                );
                            }}
                        />

                        {isOnlyInvalidVersion && (
                            <ModalDelete
                                onValidate={deleteProduct}
                                objectToDelete={t("Product")}
                                isSuccess={deleteProductMutation.isSuccess}
                                isPending={deleteProductMutation.isPending}
                                isError={deleteProductMutation.isError}
                                objectType={"male"}
                            />
                        )}
                    </div>
                </Segment>
            );
        }

        return null;
    };

    if (status === "error") {
        return null;
    }

    if (status === "loading") {
        return <span>Loading...</span>;
    }

    return renderProductDetails();
};

export default ProductDetails;
