import React from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Dropdown, Form, Grid, Icon, Menu} from "semantic-ui-react";
import {AuthContext} from "../../../../Components/AuthProvider/AuthProvider";
import CustomModal from "../../../../Components/Modals/CustomModal";
import queryClient from "../../../../Config/queryClient";
import {useGetCompaniesQuery, useSignInMutation} from "../../../../Services/Company/Queries";
import getErrorMessage from "../../Form/ErrorMessage";
import {emailValidation} from "../../Form/Validation";
import {AuthenticationDTO} from "../LoginPage";

const SuperAdminAuthModal = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {user, login} = React.useContext(AuthContext);

    // Récupération des sociétés disponibles
    const getCompaniesQuery = useGetCompaniesQuery();

    // Authentification
    const signInMutation = useSignInMutation();
    const superAdminAuthModalForm = useForm<AuthenticationDTO>({mode: "onChange"});
    const submitSuperAdminAuthModalForm = (form: AuthenticationDTO) => {
        signInMutation.mutate(form);
    };

    return (
        <CustomModal
            openModalButtonName="switch-company"
            loadingText={t("Authentification in progress...")}
            errorText={t("An error occurred please contact support")}
            cancelButtonColor="black"
            validateButtonColor="green"
            iconAndTextOnOpenButton
            openButtonIcon="lock"
            isPending={signInMutation.isPending}
            isSuccess={signInMutation.isSuccess}
            isError={signInMutation.isError}
            resetMutation={signInMutation.reset}
            error={signInMutation.error}
            modalHeader={t("Switch company")}
            onValidate={superAdminAuthModalForm.handleSubmit(submitSuperAdminAuthModalForm)}
            onSuccess={() => {
                if (login && signInMutation.isSuccess) {
                    login(signInMutation.data.token);
                    queryClient.invalidateQueries();
                    navigate("/");
                }
            }}
            renderModalContent={() => (
                <Form>
                    <Grid centered columns={2}>
                        <Grid.Row centered>
                            <Grid.Column>
                                <Controller
                                    control={superAdminAuthModalForm.control}
                                    name="email"
                                    rules={{
                                        required: true,
                                        pattern: emailValidation(),
                                    }}
                                    defaultValue={user?.sub}
                                    render={({field: {onChange, value, name, ref}}) => (
                                        <Form.Input
                                            data-cy="input-email-field"
                                            name={name}
                                            fluid
                                            icon="user"
                                            iconPosition="left"
                                            placeholder={t("Please enter your email address")}
                                            inputref={ref}
                                            onChange={onChange}
                                            value={value}
                                            error={getErrorMessage(t, superAdminAuthModalForm.formState.errors, name)}
                                        />
                                    )}
                                />
                                <Controller
                                    control={superAdminAuthModalForm.control}
                                    name="password"
                                    rules={{required: true}}
                                    defaultValue=""
                                    render={({field: {name, value, onChange}}) => (
                                        <Form.Input
                                            data-cy="input-password-field"
                                            name={name}
                                            fluid
                                            icon="lock"
                                            iconPosition="left"
                                            type="password"
                                            placeholder={t("Please enter your password")}
                                            onChange={onChange}
                                            value={value}
                                            error={getErrorMessage(t, superAdminAuthModalForm.formState.errors, name)}
                                        />
                                    )}
                                />
                                {getCompaniesQuery.isSuccess && getCompaniesQuery.data && (
                                    <Controller
                                        control={superAdminAuthModalForm.control}
                                        name="societeUser"
                                        rules={{
                                            required: true,
                                        }}
                                        defaultValue={user?.societeUser}
                                        render={({field: {name, value, onChange}}) => (
                                            <Dropdown
                                                data-cy="input-societeUser-field"
                                                fluid
                                                selection
                                                name={name}
                                                placeholder={t("Please select the company")}
                                                onChange={(_, {value}) => onChange(value)}
                                                value={value ?? user?.societeUser}
                                                error={getErrorMessage(
                                                    t,
                                                    superAdminAuthModalForm.formState.errors,
                                                    name
                                                )}
                                                options={getCompaniesQuery.data.map((company) => ({
                                                    key: company.label,
                                                    text: company.label.toUpperCase(),
                                                    value: company.label,
                                                }))}
                                            />
                                        )}
                                    />
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            )}
            renderOpenButton={() => (
                <Menu.Item icon>
                    <Icon name="shield" size="large" />
                </Menu.Item>
            )}
        />
    );
};

export default SuperAdminAuthModal;
