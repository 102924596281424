import {useContext} from "react";
import {Params, useParams} from "react-router-dom";
import {Grid} from "semantic-ui-react";
import {AuthContext} from "../../../../Components/AuthProvider/AuthProvider";
import InsurancePackageActions from "../../../../Components/CustomBreadcrumb/Fragments/InsurancePackageActions";
import Sections from "../../../../Components/CustomBreadcrumb/Fragments/Sections";
import "../../../../Components/CustomBreadcrumb/customBreadcrumb.css";
import {InsurancePackageItem} from "../../../../Services/InsurancePackage/Types";
import {VersionDTO} from "../../../../Services/Version/Types";
import {displayAuditLog} from "../../../../Utils/AuditUtils";
import InsurancePackageHeader from "./InsurancePackageHeader";
import {useTranslation} from "react-i18next";

type BreadcrumbInsurancePackageType = {
    insurancePackage: InsurancePackageItem;
    version: VersionDTO;
    sections: Array<{name: string; path: string}>;
};

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
}

const BreadcrumbInsurancePackage = ({insurancePackage, version, sections}: BreadcrumbInsurancePackageType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const {user} = useContext(AuthContext);

    return (
        <>
            <div className="header-container" data-cy="custom-breadcrumb-header">
                <Grid>
                    <Grid.Column floated={"left"} style={{width: "auto", margin: 0}}>
                        <InsurancePackageHeader insurancePackage={insurancePackage} />
                        <div className="sub-header">
                            <Sections sections={sections} />
                            {insurancePackage !== undefined && user !== undefined && (
                                <div className="audit-display">
                                    <i>
                                        {displayAuditLog(
                                            {
                                                entityContext: "female",
                                                createdDate: insurancePackage.createdDate,
                                                modifiedDate: insurancePackage.modifiedDate,
                                                createdBy: insurancePackage.createdBy,
                                                modifiedBy: insurancePackage.modifiedBy,
                                                createdOnVersionId: insurancePackage.createdOnVersionId,
                                            },
                                            user,
                                            params.versionId,
                                            t
                                        )}
                                    </i>
                                </div>
                            )}
                        </div>
                    </Grid.Column>
                    {insurancePackage !== undefined && version !== undefined && (
                        <InsurancePackageActions
                            insurancePackage={insurancePackage}
                            isVersionValid={version.pipDate !== null}
                        />
                    )}
                </Grid>
            </div>
        </>
    );
};

export default BreadcrumbInsurancePackage;
