import {UseMutationResult, UseQueryResult, useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router";
import queryClient from "../../Config/queryClient";
import {CustomError} from "../RequestUtils";
import {addSplitting, deleteSplitting, getSplitting, getSplittings, updateSplitting} from "./AxiosRequests";
import {Splitting, SplittingDTO} from "./Types";

export const useGetSplitting = (productId: string, splittingId: string): UseQueryResult<Splitting, Error> => {
    return useQuery({
        queryKey: ["getSplitting", productId, splittingId],
        queryFn: () => getSplitting(productId, splittingId),
    });
};

export const useGetSplittings = (productId: string): UseQueryResult<Array<Splitting>, Error> => {
    return useQuery({
        queryKey: ["getSplittings", productId],
        queryFn: () => getSplittings(productId),
    });
};

export const useAddSplittingMutation = (
    productId: string
): UseMutationResult<Splitting, CustomError, SplittingDTO, Error> => {
    return useMutation<Splitting, CustomError, SplittingDTO, Error>({
        mutationFn: (splitting: SplittingDTO) => addSplitting(productId, splitting),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getSplittings", productId]});
        },
    });
};

export const useUpdateSplittingMutation = (
    productId: string,
    splittingId: string
): UseMutationResult<Splitting, CustomError, Splitting, Error> => {
    return useMutation<Splitting, CustomError, Splitting, Error>({
        mutationFn: (splitting: Splitting) => updateSplitting(productId, splittingId, splitting),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getSplitting", productId, splittingId]});
            await queryClient.invalidateQueries({queryKey: ["getSplittings", productId]});
        },
    });
};

export const useDeleteSplittingMutation = (
    productId: string,
    splittingId: string
): UseMutationResult<void, CustomError, void, Error> => {
    const navigate = useNavigate();
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteSplitting(productId, splittingId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getSplittings", productId]});
            queryClient.removeQueries({queryKey: ["getSplitting", productId, splittingId]});
            navigate(`/products/${productId}`, {state: {activeTabIndex: 1}});
        },
    });
};

