export const useDateValidation = (value) => value === "" || value.match(/^\d{4}-\d{2}-\d{2}$/) != null;

export const codeValidation = () => ({
    value: /^[a-zA-Z0-9_]+$/,
    message: "The code must contain alpha numeric and underscore char only",
});

export const integerValidation = () => ({
    value: /^-?\d+$/,
    message: "The value must be an integer number",
});

export const doubleValidation = () => ({
    value: /^-?(0|[1-9]\d*)(\.\d+)?$/,
    message: "The value must be a decimal number",
});

export const emailValidation = () => ({
    value: /^((?:[A-Za-z0-9!#$%&'*+\-\/=?^_`{|}~]|(?<=^|\.)"|"(?=$|\.|@)|(?<=".*)[ .](?=.*")|(?<!\.)\.){1,64})(@)((?:[A-Za-z0-9.\-])*(?:[A-Za-z0-9])\.(?:[A-Za-z0-9]){2,})$/,
    message: "Please enter a valid email address",
});
