import {useContext} from "react";
import {Params, useParams} from "react-router-dom";
import {Grid, Header} from "semantic-ui-react";
import {AuthContext} from "../../../../../Components/AuthProvider/AuthProvider";
import Sections from "../../../../../Components/CustomBreadcrumb/Fragments/Sections";
import {CoverageRuleDTO} from "../../../../../Services/InsurancePackage/Types";
import {displayAuditLog} from "../../../../../Utils/AuditUtils";
import {useTranslation} from "react-i18next";

type BreadcrumbCoverageType = {
    coverage: CoverageRuleDTO;
    sections: Array<{name: string; path: string}>;
};
interface IParams extends Params {
    versionId: string;
}

const BreadcrumbCoverage = ({coverage, sections}: BreadcrumbCoverageType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const {user} = useContext(AuthContext);

    return (
        <>
            <div className="header-container" data-cy="custom-breadcrumb-header">
                <Header as="h2" className="top-header">
                    {coverage.label}
                </Header>
                <div className="sub-header">
                    <Grid>
                        <Grid.Column floated={"left"}>
                            <Sections sections={sections} />
                            {coverage !== undefined && user !== undefined && (
                                <div className="audit-display">
                                    <i>
                                        {displayAuditLog(
                                            {
                                                entityContext: "female",
                                                createdDate: coverage.createdDate,
                                                modifiedDate: coverage.modifiedDate,
                                                createdBy: coverage.createdBy,
                                                modifiedBy: coverage.modifiedBy,
                                                createdOnVersionId: coverage.createdOnVersionId,
                                            },
                                            user,
                                            params.versionId,
                                            t
                                        )}
                                    </i>
                                </div>
                            )}
                        </Grid.Column>
                    </Grid>
                </div>
            </div>
        </>
    );
};

export default BreadcrumbCoverage;
