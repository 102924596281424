import React, {Dispatch, SetStateAction, useState} from "react";
import {FieldValues, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Grid, Header, Icon, Menu} from "semantic-ui-react";
import ModalAdd from "../../../../../Components/Modals/ModalAdd";
import ModalUpdate from "../../../../../Components/Modals/ModalUpdate";
import {
    useAddPricingGridDimensionMutation,
    useReorderPricingGridDimensionMutation,
} from "../../../../../Services/PricingGrid/Queries";
import {
    Axe,
    DimensionGridDTO,
    DimensionGridDTOWithId,
    PricingGridFormType,
} from "../../../../../Services/PricingGrid/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import {FormDimension} from "./FormDimension";
import {PricingGridDimension} from "./PricingGridDimension";

interface IParams extends Params {
    id: string;
    versionId: string;
    gridId: string;
}

type PricingGridDimensionMenuPropsType = {
    version: VersionDTO;

    dimensionsAxeColumn: DimensionGridDTOWithId[];
    dimensionsAxeLine: DimensionGridDTOWithId[];

    activeItem: DimensionGridDTO | null | undefined;
    setActiveItem: Dispatch<SetStateAction<DimensionGridDTO | null | undefined>>;
};

const PricingGridDimensionMenu = ({
    version,
    dimensionsAxeColumn,
    dimensionsAxeLine,
    activeItem,
    setActiveItem,
}: PricingGridDimensionMenuPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    // Add dimension
    const addDimensionMutation = useAddPricingGridDimensionMutation(params.versionId, params.gridId);
    const addDimensionForm = useForm<PricingGridFormType>();
    const submitDimension = (form: FieldValues) => {
        const dimension = {
            code: form.code,
            label: form.label,
            dimensionType: form.dimensionType,
            dimensionValueType: form.dimensionValueType,
            dimensionOrderType: form.dimensionOrderType,
            visualizationGridDTO: {
                axe: form.dimensionAxe,
                rank: form.dimensionRank,
            },
            dimensionGridValueDTOs: [],
        };
        addDimensionMutation.mutate(dimension);
    };

    // Reorder dimension
    const [draggedItemIndex, setDraggedItemIndex] = useState<{index: number; axe: Axe} | null>(null);
    const [draggedOverItemIndex, setDraggedOverItemIndex] = useState<{index: number; axe: Axe} | null>(null);

    const onDragStartHandler = (_event: React.DragEvent<HTMLDivElement>, indexInfos: {index: number; axe: Axe}) => {
        setDraggedItemIndex(indexInfos);
    };

    const onDragOverHandler = (_event: React.DragEvent<HTMLDivElement>, indexInfos: {index: number; axe: Axe}) => {
        if (draggedItemIndex !== null && draggedItemIndex.axe === indexInfos.axe) {
            if (draggedItemIndex.index !== indexInfos.index) {
                setDraggedOverItemIndex(indexInfos);
            } else {
                setDraggedOverItemIndex(null);
            }
        }
    };

    const onDragEndHandler = () => {
        if (
            draggedItemIndex !== null &&
            draggedOverItemIndex !== null &&
            draggedItemIndex.index !== draggedOverItemIndex.index &&
            draggedItemIndex.axe === draggedOverItemIndex.axe
        ) {
            setReorderValidationModalIsOpen(true);
        } else {
            reorderFinishedOrCancelledHandler();
        }
    };

    const [reorderValidationModalIsOpen, setReorderValidationModalIsOpen] = useState<boolean>(false);
    const reorderPricingGridDimensionMutation = useReorderPricingGridDimensionMutation(params.versionId, params.gridId);
    const mutateReorderPricingGridDimensionMutation = () => {
        if (draggedItemIndex !== null && draggedOverItemIndex !== null && draggedItemIndex !== draggedOverItemIndex) {
            const axe = draggedOverItemIndex.axe;
            const basedData = axe === "C" ? dimensionsAxeColumn : dimensionsAxeLine;

            const sortedDimensions = [...basedData].sort(
                (d1: DimensionGridDTO, d2: DimensionGridDTO) =>
                    d1.visualizationGridDTO.rank - d2.visualizationGridDTO.rank
            );

            if (draggedItemIndex.index < draggedOverItemIndex.index) {
                reorderPricingGridDimensionMutation.mutate({
                    dimensionId: sortedDimensions[draggedItemIndex.index].id,
                    rank: sortedDimensions[draggedOverItemIndex.index].visualizationGridDTO.rank + 1,
                });
            } else {
                reorderPricingGridDimensionMutation.mutate({
                    dimensionId: sortedDimensions[draggedItemIndex.index].id,
                    rank: sortedDimensions[draggedOverItemIndex.index].visualizationGridDTO.rank,
                });
            }
        }
    };

    const reorderFinishedOrCancelledHandler = () => {
        setReorderValidationModalIsOpen(false);
        setDraggedItemIndex(null);
        setDraggedOverItemIndex(null);
    };

    return (
        <Grid.Column width={4}>
            {dimensionsAxeColumn.length !== 0 && (
                <Menu fluid vertical>
                    <Menu.Item>
                        <Header size="medium">
                            {t("Dimensions")} - {t("enums.DimensionAxeType.C")}
                        </Header>
                    </Menu.Item>

                    {[...dimensionsAxeColumn]
                        .sort(
                            (d1: DimensionGridDTO, d2: DimensionGridDTO) =>
                                d1.visualizationGridDTO.rank - d2.visualizationGridDTO.rank
                        )
                        .map((dimensionGrid: DimensionGridDTOWithId, index: number) => (
                            <Menu.Item
                                key={`dimension-C-${dimensionGrid.code}`}
                                data-cy={`dimension-C-${index}`}
                                active={activeItem?.code === dimensionGrid.code}
                                onClick={() => setActiveItem(dimensionGrid)}
                                draggable={true}
                                onDragStart={(event: React.DragEvent<HTMLDivElement>) =>
                                    onDragStartHandler(event, {index, axe: Axe.C})
                                }
                                onDragEnter={(event: React.DragEvent<HTMLDivElement>) =>
                                    onDragOverHandler(event, {index, axe: Axe.C})
                                }
                                onDragEnd={onDragEndHandler}
                                onDragOver={(event: React.DragEvent<HTMLDivElement>) => event.preventDefault()}
                                style={
                                    draggedItemIndex !== null &&
                                    draggedOverItemIndex !== null &&
                                    draggedItemIndex !== draggedOverItemIndex &&
                                    draggedOverItemIndex.index === index &&
                                    draggedOverItemIndex.axe === Axe.C
                                        ? draggedOverItemIndex.index > draggedItemIndex.index
                                            ? {borderBottom: "1px solid blue"}
                                            : {borderTop: "1px solid blue"}
                                        : {}
                                }
                            >
                                <PricingGridDimension
                                    index={index}
                                    version={version}
                                    dimensionGrid={dimensionGrid}
                                    gridId={params.gridId}
                                    setActiveItem={setActiveItem}
                                    dimensionAxe={dimensionGrid.visualizationGridDTO.axe}
                                />
                            </Menu.Item>
                        ))}
                </Menu>
            )}

            {dimensionsAxeLine.length !== 0 && (
                <Menu fluid vertical>
                    <Menu.Item>
                        <Header size="medium">
                            {t("Dimensions")} - {t("enums.DimensionAxeType.L")}
                        </Header>
                    </Menu.Item>

                    {[...dimensionsAxeLine]
                        .sort(
                            (d1: DimensionGridDTO, d2: DimensionGridDTO) =>
                                d1.visualizationGridDTO.rank - d2.visualizationGridDTO.rank
                        )
                        .map((dimensionGrid: DimensionGridDTOWithId, index: number) => (
                            <Menu.Item
                                key={`dimension-L-${dimensionGrid.code}`}
                                data-cy={`dimension-L-${index}`}
                                active={activeItem?.code === dimensionGrid.code}
                                onClick={() => setActiveItem(dimensionGrid)}
                                draggable={true}
                                onDragStart={(event: React.DragEvent<HTMLDivElement>) =>
                                    onDragStartHandler(event, {index, axe: Axe.L})
                                }
                                onDragEnd={onDragEndHandler}
                                onDragOver={(event: React.DragEvent<HTMLDivElement>) =>
                                    onDragOverHandler(event, {index, axe: Axe.L})
                                }
                                style={
                                    draggedItemIndex !== null &&
                                    draggedOverItemIndex !== null &&
                                    draggedItemIndex !== draggedOverItemIndex &&
                                    draggedOverItemIndex.index === index &&
                                    draggedOverItemIndex.axe === Axe.L
                                        ? draggedOverItemIndex.index > draggedItemIndex.index
                                            ? {borderBottom: "1px solid blue"}
                                            : {borderTop: "1px solid blue"}
                                        : {}
                                }
                            >
                                <PricingGridDimension
                                    index={index}
                                    version={version}
                                    dimensionGrid={dimensionGrid}
                                    gridId={params.gridId}
                                    setActiveItem={setActiveItem}
                                    dimensionAxe={dimensionGrid.visualizationGridDTO.axe}
                                />
                            </Menu.Item>
                        ))}
                </Menu>
            )}
            {version !== undefined && version.pipDate === null && (
                <Menu fluid vertical name="pricing-grid-structure-add-dimension">
                    <ModalAdd
                        isValidationDisabled={Object.keys(addDimensionForm.formState.errors).length !== 0}
                        isPending={addDimensionMutation.isPending}
                        isSuccess={addDimensionMutation.isSuccess}
                        isError={addDimensionMutation.isError}
                        resetMutation={addDimensionMutation.reset}
                        error={addDimensionMutation.error}
                        onValidate={addDimensionForm.handleSubmit(submitDimension)}
                        onSuccess={addDimensionForm.reset}
                        onCancel={addDimensionForm.reset}
                        objectToAdd="Dimension"
                        objectToAddContext="female"
                        renderModalContent={() => {
                            return <FormDimension form={addDimensionForm} dimension={undefined} />;
                        }}
                        renderOpenButton={() => {
                            return (
                                <Menu.Item>
                                    <Icon name="add" color="blue" />
                                    {t("Add_dimension")}
                                </Menu.Item>
                            );
                        }}
                    />
                </Menu>
            )}

            {reorderValidationModalIsOpen && (
                <ModalUpdate
                    hidden
                    isModalOpenAtStart
                    isPending={reorderPricingGridDimensionMutation.isPending}
                    isSuccess={reorderPricingGridDimensionMutation.isSuccess}
                    isError={reorderPricingGridDimensionMutation.isError}
                    error={reorderPricingGridDimensionMutation.error}
                    resetMutation={reorderPricingGridDimensionMutation.reset}
                    onSuccess={reorderFinishedOrCancelledHandler}
                    onCancel={reorderFinishedOrCancelledHandler}
                    onClose={reorderFinishedOrCancelledHandler}
                    onValidate={mutateReorderPricingGridDimensionMutation}
                    objectToUpdate="Dimension"
                    objectType="female"
                />
            )}
        </Grid.Column>
    );
};

export default PricingGridDimensionMenu;
