import {Fragment, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Button, Divider, Form, Grid} from "semantic-ui-react";
import EntityPicker from "../../../../Components/Modals/EntityPicker";
import ModalUpdate from "../../../../Components/Modals/ModalUpdate";
import CardListPlaceholder from "../../../../Components/Placeholder/CardListPlaceholder";
import {useUpdateProductRetrocessionMutation} from "../../../../Services/ProductRetrocession/Queries";
import {ProductRetrocession, RetrocessionMode, RetrocessionType} from "../../../../Services/ProductRetrocession/Types";
import {useGetVariablesList} from "../../../../Services/Variables/Queries";
import {Variable, VariableType, VariableValueType} from "../../../../Services/Variables/Types";
import {useGetVersionsQuery} from "../../../../Services/Version/Queries";
import {sortOptionsId, sortOptionsModificationDate, sortOptionsName} from "../../../../Utils/SortUtils";
import getErrorMessage from "../../../Global/Form/ErrorMessage";
import {VariableCard} from "../../Version/Fragments/Variables/VariableCard";

type ProductRetrocessionDetailsEditProps = {
    productId: string;
    retrocessionId: string;
    productRetrocession: ProductRetrocession;
    setEdit: (edit: boolean) => void;
};

const ProductRetrocessionDetailsEdit = ({
    productId,
    retrocessionId,
    productRetrocession,
    setEdit,
}: ProductRetrocessionDetailsEditProps) => {
    const {t} = useTranslation();
    const params = useParams() as {id: string};

    // Update
    const updateProductRetrocessionMutation = useUpdateProductRetrocessionMutation(productId, retrocessionId);
    const {
        control,
        setValue,
        handleSubmit,
        formState: {errors},
        watch,
    } = useForm<ProductRetrocession>({
        defaultValues: productRetrocession,
    });
    const updateProductRetrocession = (formData: ProductRetrocession) => {
        if (formData.retrocessionMode === RetrocessionMode.PRODUIT) {
            updateProductRetrocessionMutation.mutate({
                ...formData,
                rateDerogation: null,
            });
        } else {
            updateProductRetrocessionMutation.mutate({
                ...formData,
                retrocessionType: null,
                variableNameCoveragesBase: null,
                variableNameBaseRate: formData.rateDerogation ? formData.variableNameBaseRate : null,
            });
        }
    };

    // Options RetrocessionMode
    const retrocessionModeOptions = Object.values(RetrocessionMode).map((retrocessionMode: RetrocessionMode) => {
        return {
            key: retrocessionMode.toString(),
            text: t("enums.RetrocessionMode." + retrocessionMode),
            value: retrocessionMode,
        };
    });

    // Options RetrocessionType
    const retrocessionTypeOptions = Object.values(RetrocessionType).map((retrocessionType: RetrocessionType) => {
        return {
            key: retrocessionType.toString(),
            text: t("enums.RetrocessionType." + retrocessionType),
            value: retrocessionType,
        };
    });

    // Entity Picker
    const [variableNameBaseRateEntityPickerIsOpen, setVariableNameBaseRateEntityPickerIsOpen] =
        useState<boolean>(false);
    const [variableNameCoveragesBaseEntityPickerIsOpen, setVariableNameCoveragesBaseEntityPickerIsOpen] =
        useState<boolean>(false);

    // Version
    const [versionId, setVersionId] = useState<string>();
    const versionList = useGetVersionsQuery(params.id);
    useEffect(() => {
        if (versionList.isSuccess) {
            const versionListSorted = versionList.data.toSorted(
                (v1, v2) => new Date(v2.effectiveDate).getTime() - new Date(v1.effectiveDate).getTime()
            );
            setVersionId(String(versionListSorted[0].id));
        }
    }, [versionList]);

    const [retrocessionMode, rateDerogation] = watch(["retrocessionMode", "rateDerogation"]);

    return (
        <Form>
            <Grid columns="equal" verticalAlign="middle" className="grid-padding" style={{padding: "10px"}}>
                <Grid.Row style={{paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong className="required">{t("Business provider rank")}</strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"businessProviderRank"}
                            rules={{required: true, min: 1, max: 5}}
                            render={({field: {name, value, onBlur, onChange}}) => (
                                <Form.Input
                                    data-cy={`form-retrocession-${name}`}
                                    required
                                    name={name}
                                    fluid
                                    type="number"
                                    min="1"
                                    max="5"
                                    placeholder={t("Business provider rank")}
                                    onChange={onChange}
                                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                        let newValue = Number(e.target.value);
                                        if (newValue > 5) newValue = 5;
                                        else if (newValue < 1) newValue = 1;
                                        setValue("businessProviderRank", newValue);
                                        onBlur();
                                    }}
                                    value={value}
                                    error={getErrorMessage(t, errors, name, undefined, undefined, undefined, 1, 5)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong className="required">{t("Retrocession mode")}</strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"retrocessionMode"}
                            rules={{required: true}}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Select
                                    data-cy={`form-retrocession-${name}`}
                                    required
                                    name={name}
                                    fluid
                                    placeholder={t("Retrocession mode")}
                                    onChange={(_e, {value}) => {
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                    options={retrocessionModeOptions}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                {retrocessionMode !== undefined && retrocessionMode === RetrocessionMode.PRODUIT && (
                    <Fragment>
                        <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                            <Grid.Column width={8}>
                                <strong className="required">{t("Base amount")}</strong>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Controller
                                    control={control}
                                    name={"retrocessionType"}
                                    rules={{required: true}}
                                    render={({field: {name, value, onChange, onBlur}}) => (
                                        <Form.Select
                                            data-cy={`form-retrocession-${name}`}
                                            required
                                            name={name}
                                            fluid
                                            placeholder={t("Base amount")}
                                            onChange={(_e, {value}) => {
                                                onChange(value);
                                            }}
                                            onBlur={onBlur}
                                            value={value ?? ""}
                                            error={getErrorMessage(t, errors, name)}
                                            options={retrocessionTypeOptions}
                                        />
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                            <Grid.Column width={8}>
                                <strong className="required">{t("Variable name coverages base")}</strong>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Controller
                                    control={control}
                                    name={"variableNameCoveragesBase"}
                                    rules={{required: true}}
                                    render={({field: {onChange, value, name}}) => {
                                        if (variableNameCoveragesBaseEntityPickerIsOpen)
                                            return (
                                                <EntityPicker
                                                    object={"Variable"}
                                                    objectContext={"female"}
                                                    entityListGetMethod={useGetVariablesList}
                                                    entityListGetParameters={[versionId, VariableType.V]}
                                                    renderCardContent={(variable: Variable) => (
                                                        <VariableCard
                                                            versionId={versionId}
                                                            variable={variable}
                                                            onClick={(variable: Variable) => {
                                                                onChange(variable.name);
                                                                setVariableNameCoveragesBaseEntityPickerIsOpen(false);
                                                            }}
                                                        />
                                                    )}
                                                    filterEntity={(variable: Variable) =>
                                                        variable.valueType === VariableValueType.STRING
                                                    }
                                                    filterBySearch={(variable: Variable, search: string) =>
                                                        variable.name.toLowerCase().includes(search.toLowerCase()) ||
                                                        variable.valueType
                                                            .toLowerCase()
                                                            .includes(search.toLowerCase()) ||
                                                        search === ""
                                                    }
                                                    onCancel={() =>
                                                        setVariableNameCoveragesBaseEntityPickerIsOpen(false)
                                                    }
                                                    sortOptions={[
                                                        ...sortOptionsName,
                                                        ...sortOptionsId,
                                                        ...sortOptionsModificationDate,
                                                    ]}
                                                    defaultSortMethod={(a: Variable, b: Variable) =>
                                                        a.name.localeCompare(b.name)
                                                    }
                                                    placeholder={<CardListPlaceholder />}
                                                />
                                            );

                                        return (
                                            <div className="required field" data-cy={`form-retrocession-${name}`}>
                                                <div className="ui fluid action input" style={{height: "38px"}}>
                                                    <button
                                                        className="ui icon right labeled button fluid"
                                                        style={
                                                            errors[name]
                                                                ? {
                                                                      borderRadius: ".28571429rem",
                                                                      background: "#fff6f6",
                                                                      border: "1px solid #e0b4b4",
                                                                      color: "#9f3a38",
                                                                  }
                                                                : {
                                                                      borderRadius: ".28571429rem",
                                                                      background: "#fff",
                                                                      border: "1px solid rgba(34,36,38,.15)",
                                                                  }
                                                        }
                                                        onClick={() => {
                                                            setVariableNameCoveragesBaseEntityPickerIsOpen(true);
                                                        }}
                                                    >
                                                        {value !== undefined && value !== null && value !== "" ? (
                                                            <p style={{color: "#000000C0", fontWeight: 400}}>{value}</p>
                                                        ) : (
                                                            <p
                                                                style={
                                                                    errors[name]
                                                                        ? {
                                                                              color: "#9f3a38",
                                                                              fontWeight: 400,
                                                                          }
                                                                        : {
                                                                              color: "rgba(191,191,191,.87)",
                                                                              fontWeight: 400,
                                                                          }
                                                                }
                                                            >
                                                                {t("Choose", {
                                                                    name: "variable",
                                                                    context: "female",
                                                                })}
                                                                ...
                                                            </p>
                                                        )}
                                                    </button>
                                                </div>
                                                {errors[name] && (
                                                    <div
                                                        className="ui pointing above prompt label"
                                                        role="alert"
                                                        aria-atomic="true"
                                                    >
                                                        {t("this field is required")}
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Fragment>
                )}

                {retrocessionMode !== undefined && retrocessionMode === RetrocessionMode.CONVENTION && (
                    <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                        <Grid.Column width={8}>
                            <strong className="required">{t("Product retrocession rate derogation")}</strong>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Controller
                                control={control}
                                name={"rateDerogation"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Checkbox
                                        data-cy={`form-retrocession-${name}`}
                                        toggle
                                        name={name}
                                        checked={value ?? false}
                                        onBlur={onBlur}
                                        onChange={(_e, {checked}) => onChange(checked)}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}

                {retrocessionMode !== undefined &&
                    (retrocessionMode === RetrocessionMode.PRODUIT || rateDerogation) && (
                        <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                            <Grid.Column width={8}>
                                <strong className="required">{t("Variable name base rate")}</strong>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Controller
                                    control={control}
                                    name={"variableNameBaseRate"}
                                    rules={{required: true}}
                                    render={({field: {onChange, value, name}}) => {
                                        if (variableNameBaseRateEntityPickerIsOpen)
                                            return (
                                                <EntityPicker
                                                    object={"Variable"}
                                                    objectContext={"female"}
                                                    entityListGetMethod={useGetVariablesList}
                                                    entityListGetParameters={[versionId, VariableType.V]}
                                                    renderCardContent={(variable: Variable) => (
                                                        <VariableCard
                                                            versionId={versionId}
                                                            variable={variable}
                                                            onClick={(variable: Variable) => {
                                                                onChange(variable.name);
                                                                setVariableNameBaseRateEntityPickerIsOpen(false);
                                                            }}
                                                        />
                                                    )}
                                                    filterEntity={(variable: Variable) =>
                                                        variable.valueType === VariableValueType.NUMERIC
                                                    }
                                                    filterBySearch={(variable: Variable, search: string) =>
                                                        variable.name.toLowerCase().includes(search.toLowerCase()) ||
                                                        variable.valueType
                                                            .toLowerCase()
                                                            .includes(search.toLowerCase()) ||
                                                        search === ""
                                                    }
                                                    onCancel={() => setVariableNameBaseRateEntityPickerIsOpen(false)}
                                                    sortOptions={[
                                                        ...sortOptionsName,
                                                        ...sortOptionsId,
                                                        ...sortOptionsModificationDate,
                                                    ]}
                                                    defaultSortMethod={(a: Variable, b: Variable) =>
                                                        a.name.localeCompare(b.name)
                                                    }
                                                    placeholder={<CardListPlaceholder />}
                                                />
                                            );

                                        return (
                                            <div className="required field" data-cy={`form-retrocession-${name}`}>
                                                <div className="ui fluid action input" style={{height: "38px"}}>
                                                    <button
                                                        className="ui icon right labeled button fluid"
                                                        style={
                                                            errors[name]
                                                                ? {
                                                                      borderRadius: ".28571429rem",
                                                                      background: "#fff6f6",
                                                                      border: "1px solid #e0b4b4",
                                                                      color: "#9f3a38",
                                                                  }
                                                                : {
                                                                      borderRadius: ".28571429rem",
                                                                      background: "#fff",
                                                                      border: "1px solid rgba(34,36,38,.15)",
                                                                  }
                                                        }
                                                        onClick={() => setVariableNameBaseRateEntityPickerIsOpen(true)}
                                                    >
                                                        {value !== undefined && value !== null && value !== "" ? (
                                                            <p style={{color: "#000000C0", fontWeight: 400}}>{value}</p>
                                                        ) : (
                                                            <p
                                                                style={
                                                                    errors[name]
                                                                        ? {
                                                                              color: "#9f3a38",
                                                                              fontWeight: 400,
                                                                          }
                                                                        : {
                                                                              color: "rgba(191,191,191,.87)",
                                                                              fontWeight: 400,
                                                                          }
                                                                }
                                                            >
                                                                {t("Choose", {
                                                                    name: "variable",
                                                                    context: "female",
                                                                })}
                                                                ...
                                                            </p>
                                                        )}
                                                    </button>
                                                </div>
                                                {errors[name] && (
                                                    <div
                                                        className="ui pointing above prompt label"
                                                        role="alert"
                                                        aria-atomic="true"
                                                    >
                                                        {t("this field is required")}
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    )}

                <Grid.Column>
                    <Grid.Row>
                        <Divider />
                    </Grid.Row>
                    <Grid.Row>
                        <div className={"bottom-button-card"} style={{bottom: 0}}>
                            <Button secondary name="cancel-edit" onClick={() => setEdit(false)}>
                                {t("Cancel")}
                            </Button>

                            <ModalUpdate
                                isModalDisabled={Object.keys(errors).length !== 0}
                                isPending={updateProductRetrocessionMutation.isPending}
                                isSuccess={updateProductRetrocessionMutation.isSuccess}
                                isError={updateProductRetrocessionMutation.isError}
                                resetMutation={updateProductRetrocessionMutation.reset}
                                error={updateProductRetrocessionMutation.error}
                                onValidate={handleSubmit(updateProductRetrocession)}
                                objectToUpdate="Retrocession"
                                objectType="female"
                            />
                        </div>
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        </Form>
    );
};

export default ProductRetrocessionDetailsEdit;
