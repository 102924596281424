import {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Card, Label, SemanticCOLORS} from "semantic-ui-react";
import {AuthContext} from "../../../../../Components/AuthProvider/AuthProvider";
import SaveQuoteLabel from "../../../../../Components/Labels/SaveQuoteLabel";
import TextOverflow from "../../../../../Components/Text/TextOverflow";
import {Variable, VariableValueType} from "../../../../../Services/Variables/Types";
import {displayAuditLog} from "../../../../../Utils/AuditUtils";

type VariableCardType = {
    versionId: string | undefined;
    variable: Variable;
    onClick: (() => void) | ((operandItem: Variable) => void);
};

export const VariableCard = ({versionId, variable, onClick}: VariableCardType) => {
    const {t} = useTranslation();
    const userContext = useContext(AuthContext);

    const getColorOfValueTypeLabel = (variableValueType: VariableValueType): SemanticCOLORS | undefined => {
        switch (variableValueType) {
            case VariableValueType.STRING:
                return "blue";
            case VariableValueType.DATE:
                return "green";
            case VariableValueType.NUMERIC:
                return "purple";
            default:
                return undefined;
        }
    };

    return (
        <Card onClick={() => onClick(variable)}>
            <Card.Content>
                <Card.Header>
                    <TextOverflow value={variable.name} />
                </Card.Header>
                <Card.Description
                    style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Label
                        name={"valueTypeCard"}
                        color={getColorOfValueTypeLabel(variable.valueType)}
                        data-cy="variable-card-valueType"
                    >
                        {t("enums.VariableValueType." + variable.valueType)}
                    </Label>
                    {variable.saveQuote && <SaveQuoteLabel />}
                </Card.Description>

                {!variable.valid && <Label corner="right" color="red" size="tiny" icon="warning sign" />}
            </Card.Content>

            {userContext.user !== undefined && (
                <Card.Content extra textAlign="right" style={{fontStyle: "italic"}}>
                    <span>
                        {versionId &&
                            displayAuditLog(
                                {
                                    entityContext: "female",
                                    createdDate: variable.createdDate,
                                    modifiedDate: variable.modifiedDate,
                                    createdBy: variable.createdBy,
                                    modifiedBy: variable.modifiedBy,
                                    createdOnVersionId: variable.createdOnVersionId,
                                },
                                userContext.user,
                                versionId,
                                t
                            )}
                    </span>
                </Card.Content>
            )}
        </Card>
    );
};
