import {useMutation, UseMutationResult, useQuery, UseQueryResult} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import queryClient from "../../Config/queryClient";
import {DeductibleCapitalSelectedTypeEnum} from "../../Pages/Products/InsurancePackage/Fragments/DeductibleCapitals/DeductibleCapitalsList";
import {CustomError} from "../RequestUtils";
import {
    addDeductibleCapital,
    addDeductibleCapitalCondition,
    deleteDeductibleCapital,
    deleteDeductibleCapitalCondition,
    getDeductibleCapital,
    getDeductibleCapitals,
    reorderDeductibleCapitalConditionMutation,
    updateDeductibleCapital,
    updateDeductibleCapitalCondition,
} from "./AxiosRequests";
import {
    DeductibleCapital,
    DeductibleCapitalCondition,
    DeductibleCapitalConditionDTO,
    DeductibleCapitalDTO,
} from "./Types";

export const useGetDeductibleCapitals = (insurancePackageId: string): UseQueryResult<Array<DeductibleCapital>, Error> =>
    useQuery({
        queryKey: ["getDeductibleCapitals", {insurancePackageId}],
        queryFn: () => getDeductibleCapitals(insurancePackageId),
    });

export const useGetDeductibleCapital = (
    insurancePackageId: string,
    deductibleCapitalId: string
): UseQueryResult<DeductibleCapital, Error> =>
    useQuery({
        queryKey: ["getDeductibleCapital", {insurancePackageId, deductibleCapitalId}],
        queryFn: () => getDeductibleCapital(insurancePackageId, deductibleCapitalId),
    });

export const useAddDeductibleCapital = (
    productId: string,
    versionId: string,
    insurancePackageId: string
): UseMutationResult<DeductibleCapital, CustomError, DeductibleCapitalDTO, Error> => {
    const navigate = useNavigate();
    return useMutation<DeductibleCapital, CustomError, DeductibleCapitalDTO, Error>({
        mutationFn: (deductibleCapital: DeductibleCapitalDTO) =>
            addDeductibleCapital(insurancePackageId, deductibleCapital),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["getDeductibleCapitals", {insurancePackageId}]});
            navigate(
                `/products/${productId}/versions/${versionId}/insurance-packages/${insurancePackageId}/deductible-capitals/${result.id}`
            );
        },
    });
};

export const useUpdateDeductibleCapital = (
    insurancePackageId: string,
    deductibleCapitalId: string
): UseMutationResult<DeductibleCapital, CustomError, DeductibleCapitalDTO, Error> => {
    return useMutation<DeductibleCapital, CustomError, DeductibleCapitalDTO, Error>({
        mutationFn: (deductibleCapital: DeductibleCapitalDTO) =>
            updateDeductibleCapital(insurancePackageId, deductibleCapitalId, deductibleCapital),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getDeductibleCapitals", {insurancePackageId}]});
            await queryClient.invalidateQueries({
                queryKey: ["getDeductibleCapital", {insurancePackageId, deductibleCapitalId}],
            });
        },
    });
};

export const useDeleteDeductibleCapital = (
    productId: string,
    versionId: string,
    insurancePackageId: string,
    deductibleCapital: DeductibleCapital,
    type: DeductibleCapitalSelectedTypeEnum,
    activeId: number | null
): UseMutationResult<void, CustomError, void, Error> => {
    const navigate = useNavigate();
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteDeductibleCapital(insurancePackageId, String(deductibleCapital.id)),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getDeductibleCapitals", {insurancePackageId}]});
            queryClient.removeQueries({
                queryKey: ["getDeductibleCapital", {insurancePackageId, deductibleCapitalId: deductibleCapital.id}],
            });
            navigate(`/products/${productId}/versions/${versionId}/insurance-packages/${insurancePackageId}`, {
                state: {
                    activeTabIndex: 3,
                    activeType: type,
                    activeId: activeId,
                },
            });
        },
    });
};

export const useAddDeductibleCapitalConditions = (
    insurancePackageId: string,
    deductibleCapitalId: string
): UseMutationResult<DeductibleCapitalCondition, CustomError, DeductibleCapitalConditionDTO, Error> => {
    return useMutation<DeductibleCapitalCondition, CustomError, DeductibleCapitalConditionDTO, Error>({
        mutationFn: (deductibleCapitalCondition: DeductibleCapitalConditionDTO) =>
            addDeductibleCapitalCondition(deductibleCapitalId, deductibleCapitalCondition),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getDeductibleCapitals", {insurancePackageId}]});
            await queryClient.invalidateQueries({
                queryKey: ["getDeductibleCapital", {insurancePackageId, deductibleCapitalId}],
            });
        },
    });
};

export const useUpdateDeductibleCapitalConditions = (
    insurancePackageId: string,
    deductibleCapitalId: string,
    deductibleCapitalConditionId: string
): UseMutationResult<DeductibleCapitalCondition, CustomError, DeductibleCapitalConditionDTO, Error> => {
    return useMutation<DeductibleCapitalCondition, CustomError, DeductibleCapitalConditionDTO, Error>({
        mutationFn: (deductibleCapitalCondition: DeductibleCapitalConditionDTO) =>
            updateDeductibleCapitalCondition(
                deductibleCapitalId,
                deductibleCapitalCondition,
                deductibleCapitalConditionId
            ),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getDeductibleCapitals", {insurancePackageId}]});
            await queryClient.invalidateQueries({
                queryKey: ["getDeductibleCapital", {insurancePackageId, deductibleCapitalId}],
            });
        },
    });
};

export const useDeleteDeductibleCapitalConditions = (
    insurancePackageId: string,
    deductibleCapitalId: string,
    deductibleCapitalConditionId: string
): UseMutationResult<void, CustomError, void, Error> => {
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteDeductibleCapitalCondition(deductibleCapitalId, deductibleCapitalConditionId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getDeductibleCapitals", {insurancePackageId}]});
            await queryClient.invalidateQueries({
                queryKey: ["getDeductibleCapital", {insurancePackageId, deductibleCapitalId}],
            });
        },
    });
};

export const useReorderDeductibleCapitalCondtionMutation = (
    insurancePackageId: string,
    deductibleCapitalId: string,
    deductibleCapitalConditionId: string,
): UseMutationResult<void, CustomError, number, Error> => {
    return useMutation<void, CustomError, number, Error>({
    mutationFn: (order: number) => reorderDeductibleCapitalConditionMutation(insurancePackageId, deductibleCapitalId, deductibleCapitalConditionId, order),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getDeductibleCapital", {insurancePackageId, deductibleCapitalId}]});
        },
    });
};
