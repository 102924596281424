import {ConditionKindEnum, ConditionPart, ContentTypeEnum} from "../Condition/Types";
import {ComputingRuleRoundingMode} from "../InsurancePackage/Types";

export type ComputingRule = {
    id: number | null;
    computingRuleConditions: ComputingRuleCondition[];
    createdDate?: string;
    modifiedDate?: string;
    createdBy?: string;
    modifiedBy?: string;
    createdOnVersionId?: number;
};

export type ComputingRuleCondition = {
    id: number | null;
    ifCondition: string | null;
    orderCondition: number;
    calculFormule: string | null;
    conditionPartList: ConditionPart[];
    rulePartList: RulePart[];
    roundingMode: ComputingRuleRoundingMode;
    decimalCount: number;
};

export type ComputingRuleConditionDTO = {
    id?: number | null;
    ifCondition: string | null;
    orderCondition?: number;
    calculFormule?: string | null;
    conditionPartList?: ConditionPart[];
    rulePartList?: RulePart[];
    roundingMode: ComputingRuleRoundingMode;
    decimalCount: number;
};

export type RulePart = {
    value: string;
    kind: ConditionKindEnum | null;
    contentType: ContentTypeEnum | null;
};

export enum ComputingOperatorEnum {
    "*" = "*",
    "+" = "+",
    "/" = "/",
    "-" = "-",
    "^" = "^",
}

export enum ParenthesisEnum {
    "(" = "(",
    ")" = ")",
}

export enum ComputingRuleTypeEnum {
    DATA = "DATA",
    OPERATOR = "OPERATOR",
    FIXED_VALUE = "FIXED_VALUE",
    PARENTHESIS = "PARENTHESIS",
}
