import React from "react";
import {useTranslation} from "react-i18next";
import {SemanticICONS} from "semantic-ui-react";
import {CustomError} from "../../Services/RequestUtils";
import CustomModal from "./CustomModal";

type ModalAddType = {
    isPending: boolean;
    isSuccess: boolean;
    isError: boolean;
    resetMutation?: () => void;
    onCancel?: () => void;
    onClose?: () => void;
    onSuccess?: () => void;
    onValidate: () => void;
    isModalDisabled?: boolean;
    isValidationDisabled?: boolean;
    objectToAdd: string;
    openModalButtonName?: string;
    iconOnOpenButton?: boolean;
    openButtonIcon?: SemanticICONS;
    renderModalContent: () => JSX.Element;
    objectToAddContext: "male" | "female" | "nonbinary";
    error?: CustomError | null;
    isModalOpenAtStart?: boolean;
    hidden?: boolean;
    basicButton?: boolean;
    iconAndTextOnOpenButton?: boolean;
    renderOpenButton?: () => JSX.Element;
    scrolling?: boolean;
};

const ModalAdd: React.FC<ModalAddType> = ({
    isPending,
    isSuccess,
    isError,
    resetMutation,
    onCancel,
    onClose,
    onSuccess,
    onValidate,
    isModalDisabled,
    isValidationDisabled,
    objectToAdd,
    openModalButtonName = "add",
    iconOnOpenButton = false,
    openButtonIcon,
    renderModalContent,
    objectToAddContext = "male",
    error = null,
    isModalOpenAtStart = false,
    hidden = false,
    basicButton,
    iconAndTextOnOpenButton = false,
    renderOpenButton,
    scrolling = false,
}) => {
    const {t} = useTranslation();

    return (
        <CustomModal
            openModalButtonName={openModalButtonName}
            iconOnOpenButton={iconOnOpenButton}
            openButtonIcon={openButtonIcon}
            openModalButtonText={t("New entity", {
                entity: t(objectToAdd, {count: 1}).toLowerCase(),
                context: objectToAddContext,
            })}
            openModalButtonColor="blue"
            cancelButtonColor="black"
            cancelButtonText={t("Cancel")}
            validateButtonColor="blue"
            validateButtonText={t("Validate")}
            loadingText={t("Creation of the new entity this action can take a few minutes", {
                name: t(objectToAdd, {count: 1}),
                context: objectToAddContext,
            })}
            errorText={t("An error occurred during the creation")}
            modalHeader={t("Create a new", {
                name: t(objectToAdd, {count: 1}).toLowerCase(),
                context: objectToAddContext,
            })}
            renderModalContent={renderModalContent}
            isModalDisabled={isModalDisabled}
            isValidationDisabled={isValidationDisabled}
            isPending={isPending}
            isSuccess={isSuccess}
            isError={isError}
            resetMutation={resetMutation}
            onCancel={onCancel}
            onClose={onClose}
            onSuccess={onSuccess}
            onValidate={onValidate}
            error={error}
            isModalOpenAtStart={isModalOpenAtStart}
            hidden={hidden}
            basicButton={basicButton}
            iconAndTextOnOpenButton={iconAndTextOnOpenButton}
            renderOpenButton={renderOpenButton}
            returnToFormStepIfError={true}
            scrolling={scrolling}
        />
    );
};

export default ModalAdd;
