import {UseQueryResult, useMutation, UseMutationResult, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import queryClient from "../../Config/queryClient";
import {CustomError} from "../RequestUtils";
import {
    getPromotionalCampaigns,
    getPromotionalCampaign,
    addPromotionalCampaign,
    updatePromotionalCampaign,
    deletePromotionalCampaign,
} from "./AxiosRequests";
import {PromotionalCampaign} from "./Types";

export const useGetPromotionalCampaignQuery = (
    productId: string,
    promotionalCampaignId: string
): UseQueryResult<PromotionalCampaign, Error> => {
    return useQuery({
        queryKey: ["getPromotionalCampaign", {productId, promotionalCampaignId}],
        queryFn: () => getPromotionalCampaign(productId, promotionalCampaignId),
    });
};

export const useGetPromotionalCampaignsQuery = (
    productId: string
): UseQueryResult<Array<PromotionalCampaign>, Error> => {
    return useQuery({
        queryKey: ["getPromotionalCampaigns", {productId}],
        queryFn: () => getPromotionalCampaigns(productId),
    });
};

export const useAddPromotionalCampaignMutation = (
    productId: string
): UseMutationResult<PromotionalCampaign, CustomError, PromotionalCampaign, Error> => {
    const navigate = useNavigate();
    return useMutation<PromotionalCampaign, CustomError, PromotionalCampaign, Error>({
        mutationFn: (promotionalCampaign: PromotionalCampaign) =>
            addPromotionalCampaign(productId, promotionalCampaign),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["getPromotionalCampaigns", {productId}]});
            navigate(`/products/${productId}/promotional_campaigns/${result.id}`);
        },
    });
};

export const useUpdatePromotionalCampaignMutation = (
    productId: string,
    promotionalCampaignId: string
): UseMutationResult<PromotionalCampaign, CustomError, PromotionalCampaign, Error> => {
    const navigate = useNavigate();
    return useMutation<PromotionalCampaign, CustomError, PromotionalCampaign, Error>({
        mutationFn: (promotionalCampaign: PromotionalCampaign) =>
            updatePromotionalCampaign(productId, promotionalCampaignId, promotionalCampaign),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPromotionalCampaigns", {productId}]});
            await queryClient.invalidateQueries({
                queryKey: ["getPromotionalCampaign", {productId, promotionalCampaignId}],
            });
            navigate(`/products/${productId}/promotional_campaigns/${promotionalCampaignId}`);
        },
    });
};

export const useDeletePromotionalCampaignMutation = (
    productId: string,
    promotionalCampaignId: string
): UseMutationResult<void, CustomError, void, Error> => {
    const navigate = useNavigate();
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deletePromotionalCampaign(productId, promotionalCampaignId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getPromotionalCampaigns", {productId}]});
            queryClient.removeQueries({queryKey: ["getPromotionalCampaign", {productId, promotionalCampaignId}]});
            navigate(`/products/${productId}`, {state: {activeTabIndex: 3}});
        },
    });
};

