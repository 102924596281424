import {useTranslation} from "react-i18next";
import {Label, Segment} from "semantic-ui-react";
import {ConditionKindEnum, ConditionPart} from "../../../../Services/Condition/Types";

type ConditionViewPropsType = {
    conditionPartList: ConditionPart[];
};

const ConditionView = ({conditionPartList}: ConditionViewPropsType) => {
    const {t} = useTranslation();

    return (
        <Segment secondary>
            {conditionPartList.map((conditionPart: ConditionPart, index) => {
                let labelContent = conditionPart.conditionValue.trim();
                if (conditionPart.conditionKind === ConditionKindEnum.SOFTWARE_CRITERIA) {
                    if (!conditionPart.conditionValue.trim().includes("option")) {
                        labelContent =  t(`enums.SystemVariable.${labelContent}`);
                    }
                } else {
                    labelContent =  t(labelContent);
                }

                return (
                    <Label
                        key={index}
                        data-cy={"condition-part"}
                        size="large"
                        basic
                        style={{
                            border: "none",
                            margin: 0,
                            padding: "0.1em",
                            minWidth: "2em",
                            background: "none #fff0",
                        }}
                        horizontal
                        title={t(`enums.ConditionKindEnum.${conditionPart.conditionKind}`)}>
                        {labelContent}
                    </Label>
                );
            })}
        </Segment>
    );
};

export default ConditionView;
