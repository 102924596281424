import {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {Button, Checkbox, Divider, Grid} from "semantic-ui-react";
import ModalDelete from "../../../../Components/Modals/ModalDelete";
import {useDeleteProductRetrocessionMutation} from "../../../../Services/ProductRetrocession/Queries";
import {ProductRetrocession, RetrocessionMode} from "../../../../Services/ProductRetrocession/Types";

type PropsType = {
    productId: string;
    retrocession: ProductRetrocession;
    setEdit: (edit: boolean) => void;
};

const ProductRetrocessionDetailsRead = ({productId, retrocession, setEdit}: PropsType) => {
    const {t} = useTranslation();

    const deleteProductRetrocessionMutation = useDeleteProductRetrocessionMutation(productId, retrocession.id);
    const handleDelete = deleteProductRetrocessionMutation.mutate;

    return (
        <>
            <Grid columns="equal" verticalAlign="middle" className="grid-padding" style={{padding: "10px"}}>
                <Grid.Row style={{paddingBottom: "0"}} data-cy={"business-provider-rank"}>
                    <Grid.Column width={8} data-cy={"business-provider-rank-label"}>
                        <strong>{t("Business provider rank")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8} data-cy={"business-provider-value"}>
                        {retrocession.businessProviderRank}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}} data-cy={"retrocession-mode"}>
                    <Grid.Column width={8} data-cy={"retrocession-mode-label"}>
                        <strong>{t("Retrocession mode")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8} data-cy={"retrocession-mode-value"}>
                        {t(`enums.RetrocessionMode.${retrocession.retrocessionMode}`)}
                    </Grid.Column>
                </Grid.Row>

                {retrocession.retrocessionMode === RetrocessionMode.PRODUIT && (
                    <Fragment>
                        <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}} data-cy={"retrocession-type"}>
                            <Grid.Column width={8} data-cy={"retrocession-type-label"}>
                                <strong>{t("Base amount")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={8} data-cy={"retrocession-type-value"}>
                                {t(`enums.RetrocessionType.${retrocession.retrocessionType}`)}
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row
                            style={{paddingTop: "0", paddingBottom: "0"}}
                            data-cy={"variable-name-coverages-base"}
                        >
                            <Grid.Column width={8} data-cy={"variable-name-coverages-base-label"}>
                                <strong>{t("Variable name coverages base")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={8} data-cy={"variable-name-coverages-base-value"}>
                                {retrocession.variableNameCoveragesBase}
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row style={{paddingTop: "0"}} data-cy={"variable-name-base-rate"}>
                            <Grid.Column width={8} data-cy={"variable-name-base-rate-label"}>
                                <strong>{t("Variable name base rate")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={8} data-cy={"variable-name-base-rate-value"}>
                                {retrocession.variableNameBaseRate}
                            </Grid.Column>
                        </Grid.Row>
                    </Fragment>
                )}

                {retrocession.retrocessionMode === RetrocessionMode.CONVENTION && (
                    <Fragment>
                        <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}} data-cy={"rate-derogation"}>
                            <Grid.Column width={8} data-cy={"rate-derogation-label"}>
                                <strong>{t("Product retrocession rate derogation")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={8} data-cy={"rate-derogation-value"}>
                                <Checkbox toggle checked={retrocession.rateDerogation ?? false} disabled />
                            </Grid.Column>
                        </Grid.Row>

                        {retrocession.rateDerogation && (
                            <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}} data-cy={"variable-name-base-rate"}>
                                <Grid.Column width={8} data-cy={"variable-name-base-rate-labem"}>
                                    <strong>{t("Variable name base rate")} : </strong>
                                </Grid.Column>
                                <Grid.Column width={8} data-cy={"variable-name-base-rate-value"}>
                                    {retrocession.variableNameBaseRate}
                                </Grid.Column>
                            </Grid.Row>
                        )}
                    </Fragment>
                )}
            </Grid>

            <Divider />

            <div className="bottom-button-card">
                <Button primary name="edit" onClick={() => setEdit(true)}>
                    {t("edit")}
                </Button>

                <ModalDelete
                    openModalButtonName="deleteProductRetrocession"
                    isSuccess={deleteProductRetrocessionMutation.isSuccess}
                    isPending={deleteProductRetrocessionMutation.isPending}
                    isError={deleteProductRetrocessionMutation.isError}
                    error={deleteProductRetrocessionMutation.error}
                    resetMutation={deleteProductRetrocessionMutation.reset}
                    onValidate={handleDelete}
                    objectToDelete="Product retrocession"
                    objectType="female"
                />
            </div>
        </>
    );
};

export default ProductRetrocessionDetailsRead;
