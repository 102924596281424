import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Params, useNavigate, useParams} from "react-router-dom";
import {Card, Grid, Input, Menu, Message, Segment, Tab} from "semantic-ui-react";
import CardListPlaceholder from "../../../../../Components/Placeholder/CardListPlaceholder";
import Sort from "../../../../../Components/Sort/Sort";
import useContainerDimensions from "../../../../../Hooks/useContainerDimensions";
import {useGetVariablesList} from "../../../../../Services/Variables/Queries";
import {Variable, VariableType, VariableValueType} from "../../../../../Services/Variables/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import {
    sortOptionsId,
    sortOptionsModificationDate,
    sortOptionsName,
    sortOptionsVariableType,
} from "../../../../../Utils/SortUtils";
import ModalAddVariable from "../../../Variables/Fragments/ModalAddVariable";
import {VariableCard} from "./VariableCard";

interface IParams extends Params {
    id: string;
    versionId: string;
}

type PropsType = {
    version: VersionDTO | undefined;
    type: VariableType;
};

const VariablesList = ({version, type}: PropsType) => {
    const {t} = useTranslation();
    const {id: productId, versionId} = useParams() as IParams;

    const getVariablesList = useGetVariablesList(versionId, type);
    const [sortMethod, setSortMethod] = useState<(a: Variable, b: Variable) => number>(
        () => (a: Variable, b: Variable) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
    );

    //Ne pas trier sur le type de données pour les variables règles de calcul
    const sortOptions =
        type !== VariableType.R
            ? [...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate, ...sortOptionsVariableType]
            : [...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate];

    const [search, setSearch] = useState("");
    const hiddenBySearch = (name: string, valueType: VariableValueType) => {
        return (
            name.toLowerCase().includes(search.toLowerCase()) ||
            valueType.toLowerCase().includes(search.toLowerCase()) ||
            search === ""
        );
    };

    const navigate = useNavigate();

    const {columnNumberAsText, ref} = useContainerDimensions();

    return (
        <Tab.Pane>
            <Grid divided>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Menu borderless style={{border: "0", boxShadow: "none", padding: 0}}>
                            <Menu.Menu position="right">
                                <Menu.Item>
                                    <Sort sortOptions={sortOptions} setSortMethod={setSortMethod} />
                                </Menu.Item>
                                <Menu.Item>
                                    <Input
                                        name="searchVariables"
                                        icon="search"
                                        placeholder={t("Find entity", {
                                            context: "female",
                                            entity: t("Variable").toLowerCase(),
                                        })}
                                        defaultValue={search}
                                        onChange={(_, data) => setSearch(data.value)}
                                    />
                                </Menu.Item>
                                {version !== undefined && version.pipDate === null && (
                                    <Menu.Item>
                                        <ModalAddVariable variableType={type}></ModalAddVariable>
                                    </Menu.Item>
                                )}
                            </Menu.Menu>
                        </Menu>

                        {getVariablesList.isSuccess &&
                            getVariablesList.data !== undefined &&
                            getVariablesList.data.length === 0 &&
                            (type === VariableType.R ? (
                                <Segment style={{border: "0", boxShadow: "none"}}>
                                    <Message
                                        warning
                                        header={t("No variable computing rule")}
                                        content={t("There is no variable computing rule for this version")}
                                    />
                                </Segment>
                            ) : (
                                <>
                                    <Card.Group itemsPerRow={4}>
                                        {getVariablesList.data
                                            .slice()
                                            .filter((variable: Variable) =>
                                                hiddenBySearch(variable.name, variable.valueType)
                                            )
                                            .sort(sortMethod)
                                            .map((variable: Variable) => (
                                                <VariableCard
                                                    key={variable.id}
                                                    versionId={versionId}
                                                    variable={variable}
                                                    onClick={() =>
                                                        navigate(
                                                            `/products/${productId}/versions/${versionId}/variables/${variable.id}`
                                                        )
                                                    }
                                                />
                                            ))}
                                    </Card.Group>
                                    <Segment style={{border: "0", boxShadow: "none"}}>
                                        <Message
                                            warning
                                            header={t("No variable")}
                                            content={t("There is no variable for this version")}
                                        />
                                    </Segment>
                                </>
                            ))}

                        <div className={"ui cards " + columnNumberAsText} ref={ref}>
                            {getVariablesList.isSuccess &&
                                getVariablesList.data !== undefined &&
                                getVariablesList.data.length !== 0 &&
                                getVariablesList.data
                                    .slice()
                                    .filter((variable: Variable) => hiddenBySearch(variable.name, variable.valueType))
                                    .sort(sortMethod)
                                    .map((variable: Variable) => (
                                        <VariableCard
                                            key={variable.id}
                                            versionId={versionId}
                                            variable={variable}
                                            onClick={() =>
                                                navigate(
                                                    `/products/${productId}/versions/${versionId}/variables/${variable.id}`
                                                )
                                            }
                                        />
                                    ))}
                        </div>

                        {getVariablesList.isLoading && <CardListPlaceholder />}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
};

export default VariablesList;
